import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HrmMaintenanceService } from 'src/app/hrm-maintenance/service/hrm-maintenance.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { Holiday } from '../../../models/holiday';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.scss'],
})
export class AddHolidayComponent implements OnInit {
  isEdit:boolean=false;
  holidayFormGroup: FormGroup;
  listHolidayTypes:any [];
  yearsList=[]
  holiday:Holiday;

  constructor(
    public formatDatepicker: FormatMatDatepicker,
    public dialogRef: MatDialogRef<AddHolidayComponent>,
    public fb:FormBuilder,
    public hrmMaintenanceService: HrmMaintenanceService,
    public commonUIService: UIService,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView
    ){

    }

  ngOnInit() {
    console.log(this.modalViewData)
    this.setYearsList()
    this.fetchHolidayTypes();
    this.checkIsEdit(this.modalViewData);
    this.initForm();
    this.bindValuesToForm(this.modalViewData);
  }

  setYearsList()
  {
    let currentYear = new Date().getFullYear();
    const year={
      year:currentYear
    }
    this.yearsList.push(year)
    for(let i=0;i<1;i++)
    {
      const year2={
        year:currentYear+1
      }
     this.yearsList.push(year2)
    }
  }

  fetchHolidayTypes()
  {
    this.hrmMaintenanceService.getAllHolidaystypes().subscribe(res=> {
      if(res.data)
      {
        this.listHolidayTypes=res.data;
      }
    },error=>{
      console.log(error);
    });
  }

  checkIsEdit(data:any)
  {
    	if(data.data.modalData!=null && data.data.modalData.id){
        this.isEdit=true;
      }else if(data.data.modalData==null){
        this.isEdit=false;
      }
  }

  initForm()
  {
    this.holidayFormGroup = this.fb.group({
      id:[0],
      description:['',Validators.required],
      from:['',Validators.required],
      to:[''],
      year:['',Validators.required],
      active:[1],
      holidaysTypesId:['',Validators.required]
    });
  }

  bindValuesToForm(editObj:any)
  {
    if(this.isEdit)
    {
      this.holidayFormGroup.patchValue({
        id: editObj.data.modalData.id,
        description:  editObj.data.modalData.description,
        from: editObj.data.modalData.from,
        year: editObj.data.modalData.year,
        active: editObj.data.modalData.active,
        holidaysTypesId:  editObj.data.modalData.holidaysTypesId
      });
      this.holidayFormGroup.updateValueAndValidity();
    }
  }

  onSumbit()
  {
    if(this.holidayFormGroup.valid)
    {
      this.holiday = this.holidayFormGroup.value

      if(!this.isEdit){
        this.hrmMaintenanceService.saveHolidayInfo(this.holiday).subscribe(res=>{
            if(res.isSuccsess){
              this.saveSuccess();
            }else{
              this.saveFail();
            }
        },error=>{
          this.saveFail();
          console.log(error);
        });

      }else if(this.isEdit){
        this.hrmMaintenanceService.editHolidayInfo(this.holiday).subscribe(res=>{
          if(res.isSuccsess){
            this.saveSuccess();
          }else{
            this.saveFail();
          }
        },error=>{
          this.saveFail();
          console.log(error);
        });

      }
    }
  }

  saveSuccess()
  {
    const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
    this.commonUIService.openSnackBar(snack);
    this.closeDialog();
  }

  saveFail()
  {
    const snack = new SnackBar(SnackBarType.error, 'Error saved', '', 4000);
    this.commonUIService.openSnackBar(snack);
    this.closeDialog();
  }

  closeDialog()
  {
    this.dialogRef.close();
  }


}
