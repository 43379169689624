import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { APIService } from '../../services/api.service';
import { Users } from 'src/app/auth/entities/user';
import { SharedClientService } from 'src/app/client/services/shared.client.service';
import { UIService } from '../../services/ui.service';
import { CommonDropdownService } from '../../services/common.dropdown.service';
import API from '../../../shared/services/api.routes.json';
import { SharedHeaderService } from '../../services/shared.header.services';
import { SharedLinkedInService } from '../../../linkedin/service/shared.linkedin.service';
import { AuthAppService } from '../../services/auth-app.service';
import { SnackBar, SnackBarType } from '../snackbar/entities/snackbar';
import { HrmService } from '../../../hrm/service/hrm.service';
import { ErmService } from '../../../erm/service/erm.service';
import { ClientService } from '../../../client/services/client.service';
import { DropDownOption } from '../../entities/dropdown-option';
import { Company } from '../../../company/entities/company';
import { UserService } from '../../../auth/users/service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnChanges, OnInit {
  currentusers: Users;
  email: any;
  loginuser: Users;
  navLinks: any[];
  navHRMLinks: any[];
  navERMLinks: any[];
  activeLinkIndex = -1;
  isRoute: boolean = false;
  permissions: Array<string>;
  company: any;
  public companyList: any = [];
  loggedUserCompany: any;
  abbreviation: any;
  permissionsList: any[] = [];
  showNavLinks = false;
  showHRMNavLinks = false;
  showERMNavLinks = false;
  logoLable: string = '';
  _companyListDropDown: any[];
  _loggedinUser: Users = new Users();
  _companyList: any = [];

  constructor(
    private route: Router,
    private LoginServic: LoginService,
    private api: APIService,
    private commonDropdownService: CommonDropdownService,
    public sharedClientService: SharedClientService,
    public sharedLinkedInService: SharedLinkedInService,
    public sharedHeaderService: SharedHeaderService,
    private commonUIService: UIService,
    private router: Router,
    private authAppService: AuthAppService,
    private userService: UserService,
    private hrmService: HrmService,
    private ermService: ErmService,
    private clientService: ClientService
  ) {
    this.showNavLinks = false;
    this.showHRMNavLinks = false;
    this.showERMNavLinks = false;
    this.getLoggedInPermission();

    this.navLinks = [
      {
        label: 'CRM',
        link: '/client',
        index: 0,
        isPermit: this.permissions.includes('2000'),
      },
      {
        label: 'Task',
        link: '/task',
        index: 1,
        isPermit: this.permissions.includes('2000'),
      },
      //Don't remove this comments. Need for future developments
      //{
      //  label: 'Estimations',
      //  link: '/estimation',
      //  index: 2,
      //  isPermit: this.permissions.includes("2000")
      //},
      {
        label: 'LinkedIn',
        link: '/linkedin',
        index: 3,
        isPermit: this.permissions.includes('2800'),
      },
    ];
    this.navHRMLinks = [
      {
        label: 'Dashboard',
        link: '/hrm',
        index: 0,
        isPermit: this.permissions.includes('3000'),
      },
      {
        label: 'Insurance',
        link: '/hrm',
        index: 1,
        isPermit: this.permissions.includes('3200'),
      },
      {
        label: 'Leaves',
        link: '/approvals',
        index: 2,
        isPermit: this.permissions.includes('3100'),
      },
    ];
    this.navERMLinks = [
      {
        label: 'Dashboard',
        link: '/erm',
        index: 0,
        isPermit: this.permissions.includes('4000'),
      },
      {
        label: 'Leaves',
        link: '/leave',
        index: 1,
        isPermit: this.permissions.includes('4100'),
      },
    ];
    this.currentusers = new Users();
  }

  ngOnInit(): void {
    this.loadAllCompanies();
    let url = this.router.url;
    if (url) {
      if (url == '/hrm') {
        this.showHRMNavLinks = true;
        this.showNavLinks = false;
        this.showERMNavLinks = false;
        this.logoLable = 'HRM';
      } else if (url == '/erm') {
        this.showERMNavLinks = true;
        this.showHRMNavLinks = false;
        this.showNavLinks = false;
        this.logoLable = 'ERM';
      } else if (url == '/inventory') {
        this.showHRMNavLinks = false;
        this.showNavLinks = false;
        this.showERMNavLinks = false;
        this.logoLable = 'Inventory';
      } else {
        this.showHRMNavLinks = false;
        this.showNavLinks = true;
        this.showERMNavLinks = false;
        this.logoLable = 'CRM';
      }
    }
    this.currentuser();
    this.LoadCompantList();
    this.subscribeRefreshCompanyDropdown();
    this.permissionsList = this.authAppService.getLoggedInPermission();
    this.getLoggedinUser();
  }

  ngOnChanges() {
    this.subscribeRefreshCompanyDropdown();
  }

  logout() {
    this.LoginServic.logout();
  }

  getLoggedinUser() {
    this._loggedinUser = this.authAppService.getLoggedInUser();
  }

  async currentuser() {
    let jsonString = await this.LoginServic.getSessionAppUser();
    this.currentusers = jsonString ? JSON.parse(jsonString) : '';
    if (this.currentusers.imageUrl == undefined) {
      this.currentusers.imageUrl = 'assets/images/profile.jpg';
    }
    this.loggedUserCompany =
      await this.sharedHeaderService.getLoggedUserCopmany();
  }

  getLoggedInPermission() {
    this.permissions = this.authAppService.getLoggedInPermission();
  }

  public LoadCompantList() {
    this.api.get(API.company.listAll).subscribe(
      (response) => {
        this.companyList = response.data;
        if (this.companyList && this.companyList.length > 0) {
          this.companyList = this.companyList.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          this.sharedHeaderService.selectedCompanyId =
            this.loggedUserCompany.id;
          this.sharedHeaderService.selectedCompanyName =
            this.loggedUserCompany.name;
          this.sharedHeaderService.isMainBranch =
            this.loggedUserCompany.name
              .toLowerCase()
              .trim()
              .replace(' ', '') === 'qualitapps';
          this.company = this.loggedUserCompany.name;
          this.abbreviation = this.loggedUserCompany.abbreviation;
          if (this._loggedinUser) {
            if (this._loggedinUser.seeAllBranches == true) {
              let customObj = new Company();
              customObj.abbreviation = null;
              customObj.id = 0;
              customObj.name = 'ALL BRANCHES';
              customObj.main = false;
              this.companyList.unshift(customObj);
              this.sharedHeaderService.selectedCompanyId = 0;
              this.sharedHeaderService.selectedCompanyName = 'ALL BRANCHES';
              this.company = this.sharedHeaderService.selectedCompanyName;
            } else if (
              this._loggedinUser.seeAllBranches == false &&
              this._loggedinUser.companyUser.length == 0
            ) {
              this.companyList = [];
              this.companyList.push(this._loggedinUser.company);
              this.companyList = this.companyList.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              this.sharedHeaderService.selectedCompanyId =
                this.loggedUserCompany.id;
              this.sharedHeaderService.selectedCompanyName =
                this.loggedUserCompany.name;
              this.company = this.sharedHeaderService.selectedCompanyName;
            } else if (
              this._loggedinUser.seeAllBranches == false &&
              this._loggedinUser.companyUser.length > 0
            ) {
              this.companyList = [];
              this._loggedinUser.companyUser.forEach((ele) => {
                this.companyList.push(ele.company);
              });
              this.companyList.push(this._loggedinUser.company);
              this.companyList = this.companyList.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
              let customObj = new Company();
              customObj.abbreviation = null;
              customObj.id = 0;
              customObj.name = 'ALL BRANCHES';
              customObj.main = false;
              this.companyList.unshift(customObj);
              this.sharedHeaderService.selectedCompanyId = 0;
              this.sharedHeaderService.selectedCompanyName = 'ALL BRANCHES';
              this.company = this.sharedHeaderService.selectedCompanyName;
            }
          }
        } else {
          this.sharedClientService.passCompanyIdToClient.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedClientService.passCompanyIdToTask.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedLinkedInService.passCompanyIdToLinkedIn.emit({
            companyId: 0,
            isLoad: true,
          });
        }
      },
      (err) => {
        console.error(err);
      },
      () => { }
    );
  }

  public LoadCompanyListByUserId() {
    if (this._loggedinUser.id) {
      this.userService.getUserById(this._loggedinUser.id).subscribe(
        (response) => {
          if (response && response.data) {
            this._loggedinUser = response.data;
            this.companyList = [];
            if (
              this._loggedinUser.company != null &&
              this._loggedinUser.companyUser.length > 0
            ) {
              this.sharedHeaderService.selectedCompanyId =
                this.loggedUserCompany.id;
              this.sharedHeaderService.selectedCompanyName =
                this.loggedUserCompany.name;
              this.sharedHeaderService.isMainBranch =
                this.loggedUserCompany.name
                  .toLowerCase()
                  .trim()
                  .replace(' ', '') === 'qualitapps';
              this.company = this.loggedUserCompany.name;
              this.abbreviation = this.loggedUserCompany.abbreviation;
              if (this._loggedinUser) {
                if (this._loggedinUser.seeAllBranches == true) {
                  let customObj = new Company();
                  customObj.abbreviation = null;
                  customObj.id = 0;
                  customObj.name = 'ALL BRANCHES';
                  customObj.main = false;
                  this.companyList.unshift(customObj);
                  this.sharedHeaderService.selectedCompanyId = 0;
                  this.sharedHeaderService.selectedCompanyName = 'ALL BRANCHES';
                  this.company = this.sharedHeaderService.selectedCompanyName;
                  this._loggedinUser.companyUser.forEach((ele) => {
                    this.companyList.push(ele.company);
                  });
                  this.companyList.push(this._loggedinUser.company);
                  this.companyList = this.companyList.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  );
                } else if (
                  this._loggedinUser.seeAllBranches == false &&
                  this._loggedinUser.companyUser.length == 0
                ) {
                  this.companyList = [];
                  this.companyList.push(this._loggedinUser.company);
                  this.companyList = this.companyList.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  );
                  this.sharedHeaderService.selectedCompanyId =
                    this.loggedUserCompany.id;
                  this.sharedHeaderService.selectedCompanyName =
                    this.loggedUserCompany.name;
                  this.company = this.sharedHeaderService.selectedCompanyName;
                } else if (
                  this._loggedinUser.seeAllBranches == false &&
                  this._loggedinUser.companyUser.length > 0
                ) {
                  this.companyList = [];
                  this._loggedinUser.companyUser.forEach((ele) => {
                    this.companyList.push(ele.company);
                  });
                  this.companyList.push(this._loggedinUser.company);
                  this.companyList = this.companyList.sort((a, b) =>
                    a.name.localeCompare(b.name)
                  );
                  let customObj = new Company();
                  customObj.abbreviation = null;
                  customObj.id = 0;
                  customObj.name = 'ALL BRANCHES';
                  customObj.main = false;
                  this.companyList.unshift(customObj);
                  this.sharedHeaderService.selectedCompanyId = 0;
                  this.sharedHeaderService.selectedCompanyName = 'ALL BRANCHES';
                  this.company = this.sharedHeaderService.selectedCompanyName;
                }
              }
            } else {
              this.sharedClientService.passCompanyIdToClient.emit({
                companyId: this._loggedinUser.company.id,
                isLoad: true,
              });
              this.sharedClientService.passCompanyIdToTask.emit({
                companyId: this._loggedinUser.company.id,
                isLoad: true,
              });
              this.sharedLinkedInService.passCompanyIdToLinkedIn.emit({
                companyId: this._loggedinUser.company.id,
                isLoad: true,
              });
            }
          }
        },
        (err) => {
          console.error(err);
        },
        () => { }
      );
    }
  }

  selectCompany(_company: any, event) {
    if (_company) {
      this.company = _company.name;
      this.abbreviation = _company.abbreviation;
      this.abbreviation = _company.abbreviation;
      this.sharedHeaderService.selectedCompanyId = _company.id;
      this.sharedHeaderService.selectedCompanyName = _company.name;
      this.sharedHeaderService.isMainBranch =
        _company.name.toLowerCase().trim().replace(' ', '') === 'qualitapps'
          ? true
          : false;
      this.clientService.filterId = '2';

      if (this._loggedinUser) {
        if (
          this._loggedinUser.seeAllBranches == true &&
          _company.name == 'ALL BRANCHES'
        ) {
          this.sharedClientService.passCompanyIdToClient.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedClientService.passCompanyIdToTask.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedLinkedInService.passCompanyIdToLinkedIn.emit({
            companyId: 0,
            isLoad: true,
          });
        } else if (
          this._loggedinUser.seeAllBranches == false &&
          _company.name == 'ALL BRANCHES'
        ) {
          this.sharedClientService.passCompanyIdToClient.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedClientService.passCompanyIdToTask.emit({
            companyId: 0,
            isLoad: true,
          });
          this.sharedLinkedInService.passCompanyIdToLinkedIn.emit({
            companyId: 0,
            isLoad: true,
          });
        } else if (_company.id > 0) {
          this.sharedClientService.passCompanyIdToClient.emit({
            companyId: _company.id,
            isLoad: true,
          });
          this.sharedClientService.passCompanyIdToTask.emit({
            companyId: _company.id,
            isLoad: true,
          });
          this.sharedLinkedInService.passCompanyIdToLinkedIn.emit({
            companyId: _company.id,
            isLoad: true,
          });
        }
      }
      this.sharedClientService.refreshCompanySelect.emit();
    }
  }

  subscribeRefreshCompanyDropdown() {
    this.sharedHeaderService.refreshCompanyDropdownList.subscribe((data) => {
      if (data) {
        this.LoadCompanyListByUserId();
      }
    });
  }

  signInWithCRM() {
    this.commonUIService.openMask();
    if (this.permissionsList.includes('2000')) {
      this.showNavLinks = true;
      this.showHRMNavLinks = false;
      this.showERMNavLinks = false;
      this.logoLable = 'CRM';
      this.clientService.filterId = '2';
      let url = this.permissionsList.includes('2000')
        ? '/client'
        : this.permissionsList.includes('2800')
          ? '/linkedin'
          : '/no-permission';
      this.router.navigate([url]);
    } else {
      var snack = new SnackBar(
        SnackBarType.error,
        'User has no permission for CRM application',
        '',
        5000
      );
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  signInWithHRM() {
    this.commonUIService.openMask();
    if (this.permissionsList.includes('3000')) {
      this.showNavLinks = false;
      this.showHRMNavLinks = true;
      this.showERMNavLinks = false;
      this.hrmService.isEmployeeSelected = false;
      this.logoLable = 'HRM';
      this.router.navigate(['/hrm']);
    } else {
      var snack = new SnackBar(
        SnackBarType.error,
        'User has no permission for HRM application',
        '',
        5000
      );
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  signInWithERM() {
    this.commonUIService.openMask();
    if (this.permissionsList.includes('4000')) {
      this.showNavLinks = false;
      this.showHRMNavLinks = false;
      this.showERMNavLinks = true;
      this.ermService.isEmployeeSelected = false;
      this.logoLable = 'ERM';
      this.router.navigate(['/erm']);
    } else {
      var snack = new SnackBar(
        SnackBarType.error,
        'User has no permission for ERM application',
        '',
        5000
      );
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  viewUsers() {
    if (this.permissionsList.includes('1002')) {
      this.router.navigate(['/users']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewAuxiliary() {
    if (this.permissionsList.includes('1001')) {
      this.router.navigate(['/maintenance']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewInventoryMaintenance() {
    this.router.navigate(['/inventory-maintenance']);
  }

  viewHrmMaintenance() {
    this.router.navigate(['/hrm-maintenance']);
  }

  viewCRMDocumentType() {
    if (this.permissionsList.includes('3030')) {
      this.router.navigate(['/crm-document-type']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewBranches() {
    if (this.permissionsList.includes('1004')) {
      this.router.navigate(['/company']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewPermits() {
    if (this.permissionsList.includes('1003')) {
      this.router.navigate(['/permits']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewProduct() {
    if (this.permissionsList.includes('3040')) {
      this.router.navigate(['/client-product']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  viewYearEndProcess() {
    if (this.permissionsList.includes('3060')) {
      this.router.navigate(['/year-end-process']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }

  signInWithInventory() {
    this.commonUIService.openMask();
    if (this.permissionsList.includes('3020')) {
      this.showNavLinks = false;
      this.showHRMNavLinks = false;
      this.showERMNavLinks = false;
      this.logoLable = 'Inventory';
      this.router.navigate(['/inventory']);
    } else {
      var snack = new SnackBar(
        SnackBarType.error,
        'User has no permission for Inventory application',
        '',
        5000
      );
      this.commonUIService.openSnackBar(snack);
    }
    this.commonUIService.closeMask();
  }

  changeHRMNavLink(index) {
    if (index != undefined || index != null) {
      if (index == 0) {
        this.hrmService.isShowLeaveCard = true;
        this.hrmService.isShowInsuarenceCard = false;
        this.authAppService.listAllEmployees.emit(null);
      } else {
        this.hrmService.isShowInsuarenceCard = true;
        this.hrmService.isShowLeaveCard = false;
        this.authAppService.listAllEmployees.emit(null);
      }
    }
  }

  changeERMNavLink(index) {
    if (index != undefined || index != null) {
      if (index == 0) {
        this.ermService.isShowLeaveCard = true;
        this.ermService.isShowInsuarenceCard = false;
        this.authAppService.listAllEmployees.emit(null);
      } else {
        this.ermService.isShowInsuarenceCard = true;
        this.ermService.isShowLeaveCard = false;
        this.authAppService.listAllEmployees.emit(null);
      }
    }
  }

  changeCRMNavLink(index) {
    if (index != undefined || index != null) {
      if (index == 0) {
        this.clientService.filterId = null;
      }
      if (index == 1) {
        this.clientService.isSelectedClient = false;
        this.clientService.filterId = null;
      }
      if (index == 2) {
        this.clientService.isSelectedClient = false;
      }
    }
  }

  public loadAllCompanies() {
    this.api.get(API.company.listAll).subscribe(
      (response) => {
        this._companyList = response.data;
      },
      (err) => {
        console.error(err);
      },
      () => { }
    );
  }

  viewLinkedInTemplete() {
    if (this.permissionsList.includes('3050')) {
      this.router.navigate(['/linkedin-templete']);
    } else {
      this.router.navigate(['/no-permission']);
    }
  }
}
