import { Component, OnInit } from '@angular/core';
import { currencyModel } from 'src/app/shared/entities/currencyModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddEditCurrencyComponent } from './add-edit-currency/add-edit-currency.component';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})

export class CurrencyComponent implements OnInit {
  _CurrencyList: currencyModel[] = [];
  currencyList: currencyModel[] = [];
  updateCurrency: currencyModel;
  _idSelectedCurrency;
  isLoading: boolean = false;

  constructor(private uIService: UIService,
    private _apiService: APIService,
    private alertService: AlertService,
    private maintananceService: MaintenanceService) {
    this._CurrencyList = [];
  }

  ngOnInit() {
    this.updateCurrency = new currencyModel();
    this.LoadCurrency();
    this.subscribeCurrencyList();
    this.maintananceService.subjectCurrencyRefresh.subscribe(isRefresh => {
      if (isRefresh) {
        this.LoadCurrency();
      }
    });
  }

  subscribeCurrencyList() {
    this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete Currency") {
        this.deleteCurrencyConfirm(this._idSelectedCurrency);
      }
    });
  }

  addCurrency() {
    this.uIService.openDialog(AddEditCurrencyComponent, null);
  }

  deleteCurrency(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Currency";
    this._idSelectedCurrency = id;
    this.uIService.openAlert(deleteData);
  }

  editCurrency(data) {
    const setdataCurrency = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataCurrency, null, null);
    this.uIService.openDialog(AddEditCurrencyComponent, dialogViewData);

  }

  LoadCurrency() {
    this.isLoading = true;
    this._apiService.get(API.currency.listAll).subscribe(currencydata => {
      if (currencydata) {
        if (currencydata.data) {
          this.currencyList = currencydata.data;
          this._CurrencyList = this.currencyList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    });

  }

  deleteCurrencyConfirm(id) {
    this.maintananceService.deleteCurrency(id).subscribe(data => {
      if (data.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (data.data) this.LoadCurrency()
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }
  activeDeactive(event, model) {
    model.status = event.checked;
    this.maintananceService.UpdateCurrency(model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this.maintananceService.CurrencyRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
