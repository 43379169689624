export class Project {
  id: number;
  projectName: string;
  projectDescription: string;
  technologies: string;
  clientId: number;
  status: boolean;
  projectDate: any;
  projectStatusId: any;
  actionProjects: any;
  employeeProjects: any;
  tasks: any;
  technologyList: any = [];
  technologyListForView: any = [];
  isTaskList: boolean = false;
  isActionList: boolean = false;
  documentProject:any=[];
  isProjectDocumentList: boolean = false;
  projectStatus:any;
  projectStatusValue:string;
  closeReasonId ?:number;
  closeReason: any;
  closeReasonText:string;
  clientName: string;
  isEdit: boolean;
  estimation: any;
  isEstimationList: boolean = false;
  projectDocuments: any = [];  
}
