import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { DialogDataForModalView } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-linkedin-reason-popup',
  templateUrl: './linkedin-reason-popup.component.html',
  styleUrls: ['./linkedin-reason-popup.component.scss']
})
export class LinkedinReasonPopupComponent implements OnInit {
  reasonText: string = null;
  showError: boolean = false;

  constructor(public dialogRef: MatDialogRef<LinkedinReasonPopupComponent>,
    private linkedInService: LinkedInService,
    @Inject(MAT_DIALOG_DATA) public linkedinData: DialogDataForModalView) { }

  ngOnInit() {
  }

  setReasonText(event, text) {
    if (text) {
      this.showError = false;
      this.linkedInService.linkedInCloseReasonEmit.emit(text);
      this.closeDialog();
    } else
      this.showError = true;
  }

  cancelReason() {
    this.closeDialog();
    this.linkedInService.linkedInCloseReasonEmit.emit(null);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
