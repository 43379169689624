import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { debug } from 'util';
import { Furniture } from '../../../entities/furniture';
import { ValidationService } from '../../../../shared/services/validation.service';
import { InventoryService } from '../../../service/inventoryService';
import { SnackBarType, SnackBar } from '../../../../shared/components/snackbar/entities/snackbar';
import { UIService } from '../../../../shared/services/ui.service';
import { MatDialogRef } from '@angular/material';
import { FloorFurniture } from '../../../entities/floorFurniture';
import { FurnitureModel, FloorFurnitureModel } from '../../../entities/furnitureModel';

@Component({
  selector: 'app-add-furniture',
  templateUrl: './add-furniture.component.html',
  styleUrls: ['./add-furniture.component.scss']
})
export class AddFurnitureComponent implements OnInit {

  furnitureFormGroup: FormGroup;
  _furnitureForSave: Furniture;
  _floorFurnitureForSave: FloorFurniture;
  isDisabledFurniture = true;
  furnitureModel: FurnitureModel;
  furnitureModelForSave: FurnitureModel;

  constructor(public commonDropdownService: CommonDropdownService, private _formBuilder: FormBuilder,
    private validationService: ValidationService, public inventoryService: InventoryService,
    private commonUIService: UIService, public dialogRef: MatDialogRef<AddFurnitureComponent>) {
    this.furnitureModel = new FurnitureModel();

  }

  ngOnInit() {
    this.initFormGroup();
  }

  initFormGroup() {
    this.furnitureFormGroup = this._formBuilder.group({
      floorId: ['', [Validators.required]],
      categoryId: ['', [Validators.required]],
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      furnitureCount: ['', [Validators.required]]
    });
  }

  async submit(furniture) {
    this.isDisabledFurniture = false;
    let furnitureObj = Object.assign({}, furniture);
    if (this.furnitureFormGroup.invalid) {
      this.isDisabledFurniture = true;
      return;
    }
    else {
      this.saveFurniture(furnitureObj);
    }
  }

  saveFurniture(furnitureObj) {
    this.bindToModel(furnitureObj);
    this.inventoryService.saveFurniture(this.furnitureModelForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledFurniture = true;
          if (res.data) {
            let floorFrnitureObj = new FloorFurnitureModel();
            floorFrnitureObj.floorId = furnitureObj.floorId;
            floorFrnitureObj.furnitureId = res.data.id;
            floorFrnitureObj.furnitureCount = furnitureObj.furnitureCount;
            this.inventoryService.saveFloorFurniture(floorFrnitureObj).subscribe(
              res => {
                if (res.isSuccsess) {
                  const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
                  this.commonUIService.openSnackBar(snack);
                  this.closeDialog();
                  this.isDisabledFurniture = true;
                  this.inventoryService.refreshFurniture.emit(true);
                } else {
                  const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
                  this.commonUIService.openSnackBar(snack);
                  this.isDisabledFurniture = true;
                }
              },
              () => { console.error(); this.closeDialog(); this.isDisabledFurniture = true; });
          }
        } else { }
      },
      () => { console.error(); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  bindToModel(furnitureObject) {
    this.furnitureModelForSave = Object.assign({}, furnitureObject);
  }
}
