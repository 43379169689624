import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({ name: 'DateFormatPipe' })

export class DateFormatPipe implements PipeTransform {
  transform(value: string) {
    if (!value) { return ''; }
    const date = moment(value).format('MM-DD-YYYY HH:mm:ss');
    const datePipe = new DatePipe('en-US');
    if(date ==="Invalid date" ){
      value = value.split(' ')[0];
    }
    else{
      value = datePipe.transform(date, 'dd/MM/yyyy');
    } 
    return value;
  }
}
