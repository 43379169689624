import { Component, OnInit, Input, Inject, ViewChild, OnChanges } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HrmService } from '../../../../service/hrm.service';
import { FormatMatDatepicker } from '../../../../../shared/format-datepicker';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { Employee } from '../../../../entities/empoyee';
import API from 'src/app/shared/services/api.routes.json';
import { SnackBar, SnackBarType } from '../../../../../shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'hrm-attendance-calender',
  templateUrl: './hrm-attendance-calender.component.html',
  styleUrls: ['./hrm-attendance-calender.component.scss']
})

export class HRMAttendanceCalenderComponent implements OnInit, OnChanges {

  @Input() employeeModel: Employee;
  employeeAttendanceTypeList: any = [];
  employeeAttendanceEventList: any = [];
  leaveAttendanceList: any = [];
  isAddAttendancePopupOpen = true;

  calendarOptionsForAttendance: CalendarOptions = {
    initialView: 'dayGridMonth',
    selectable: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: ''
    }
  };

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService) {
  }

  ngOnInit() { 
  }

  ngOnChanges() {
    if (this.employeeModel.id) {
      this.getEmployeeAttendanceEventListByEmployeeId(this.employeeModel.id);
      this.subscribeRefershEmloyeeAttendanceCalender();
    }
  }

  async getEmployeeAttendanceEventListByEmployeeId(employeeId) {  
    this.hrmService.getEmployeeAttendanceEventListByEmployeeId(employeeId).subscribe(
      res => {        
        if (res && res.data) {
          this.calendarOptionsForAttendance.events = res.data;
        } else
          this.calendarOptionsForAttendance.events = [];
      },
      err => { console.error(); });
  }

  subscribeRefershEmloyeeAttendanceCalender() {
    this.hrmService.refershEmloyeeAttendanceCalender.subscribe(res => {
      if (res > 0) {
        this.getEmployeeAttendanceEventListByEmployeeId(res);
      }
    });
  }
}
