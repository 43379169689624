import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatAutocompleteSelectedEvent, MatDatepickerInputEvent, MatDialogRef, MatRadioChange, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { ClientPack } from '../../entities/clientPack';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';
import { SubscriptionType } from '../../entities/subscriptionType';
import { ProductPack } from '../../entities/productPack';

@Component({
  selector: 'app-add-edit-client-pack',
  templateUrl: './add-edit-client-pack.component.html',
  styleUrls: ['./add-edit-client-pack.component.scss']
})

export class AddEditClientPackComponent implements OnInit {

  isEdit: boolean = false;
  clientPackFormGroup: FormGroup;
  _clientPackModal: ClientPack;
  isDisabledClientPack: boolean = false;
  isRenewalDateSelected: boolean = false;
  isDisabledSubscriptionType: boolean = false;
  expirationSelectionText: string = '';
  productPackList: ProductPack[] = [];
  subscriptionTypeList: SubscriptionType[];

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    public dialogRef: MatDialogRef<AddEditClientPackComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
  }

  ngOnInit() {
    this.initFormGroup();
    this.setClientPackDataForEditView();
  }

  initFormGroup() {
    this.clientPackFormGroup = this._formBuilder.group({
      state: [''],
      contractDate: ['', [Validators.required]],
      expirationDate: [''],
      renewalDate: [''],
      cancelDate: [''],
      details: [''],
      subscriptionType: [''],
      oneTimePrice: [''],
      subscriptionPrice: [''],
      billed: [''],
      productPackId: [null],
      pack: ['', [Validators.required]],
    });
  }

  setClientPackDataForEditView(): void {
    if (this.modalViewData.data) {
      let tempPackModal = Object.assign({}, this.modalViewData.data.modalData);
      this._clientPackModal = tempPackModal;

      if (tempPackModal.productPack) {
        this._clientPackModal.pack = tempPackModal.productPack.packName;
        this._clientPackModal.productPackId = tempPackModal.productPack.id;
      }

      //NOTE:If the renewal date is not null, then the 'Renewal date' radio button option should be set as selected
      if (this._clientPackModal.renewalDate) {
        this.isRenewalDateSelected = true;
      }

      let productId: number = 0;

      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
        this._clientPackModal.clientProductId = this.modalViewData.data.modalData.clientProductId;
        productId = this.productService.selectedClientProduct;
      } else {
        this.isEdit = false;
        this._clientPackModal.clientProductId = this.modalViewData.data.modalData.clientProductDetails.id;
        productId = this.modalViewData.data.modalData.clientProductDetails.productId;
      }

      this.loadActiveSubscriptionTypes();
      this.getProductPackList(productId);
    }
  }

  getProductPackList(productId: number) {
    this.productService.getProductById(productId).subscribe(
      res => {
        this.productPackList = res.data.productPack;
        this.productPackList = this.productPackList.filter(x => x.active == null || x.active == this.productService.isActiveProductPack);
      },
      (error) => {
        console.log(error);
      });
  }

  async submit(clientPack) {    
    if (this.clientPackFormGroup.invalid) {
      this.isDisabledClientPack = false;
      return;
    }
    let clientPackObj = Object.assign({}, clientPack);
    clientPackObj.clientProductId = this._clientPackModal.clientProductId;
    clientPackObj.expirationDate = this._clientPackModal.expirationDate;
    clientPackObj.contractDate = typeof (this._clientPackModal.contractDate) !== 'undefined' ? this._clientPackModal.contractDate : null;
    clientPackObj.renewalDate = typeof (this._clientPackModal.renewalDate) !== 'undefined' ? this._clientPackModal.renewalDate : null;
    clientPackObj.cancelDate = typeof (this._clientPackModal.cancelDate) !== 'undefined' ? this._clientPackModal.cancelDate : null;
    clientPackObj.oneTimePrice = typeof (this._clientPackModal.oneTimePrice) !== 'undefined' ? this._clientPackModal.oneTimePrice : null;
    clientPackObj.contractDateStr = this._clientPackModal.contractDate ? this.formatDatepicker.ConvertDateFormatForSave(this._clientPackModal.contractDate) : null;
    clientPackObj.expirationDateStr = this._clientPackModal.expirationDate ? this.formatDatepicker.ConvertDateFormatForSave(this._clientPackModal.expirationDate) : null;
    clientPackObj.cancelDateStr = this._clientPackModal.cancelDate ? this.formatDatepicker.ConvertDateFormatForSave(this._clientPackModal.cancelDate) : null;
    clientPackObj.subscriptionPrice = typeof (this._clientPackModal.subscriptionPrice) !== 'undefined' ? this._clientPackModal.subscriptionPrice : null;
    clientPackObj.subscriptionType = typeof (this._clientPackModal.subscriptionType) !== 'undefined' ? this._clientPackModal.subscriptionType : null;
    clientPackObj.billed = typeof (this._clientPackModal.billed) !== 'undefined' ? this._clientPackModal.billed : null;
    clientPackObj.details = typeof (this._clientPackModal.details) !== 'undefined' ? this._clientPackModal.details : null;
    clientPackObj.isRenewalDateSelected = this.isRenewalDateSelected;
    clientPackObj.expirationPeriodString = this.expirationSelectionText;
    clientPackObj.packName = this._clientPackModal.pack;
    clientPackObj.pack = this._clientPackModal.pack;
    clientPackObj.productPackId = this._clientPackModal.productPackId;

    //<< NOTE: Send null values for the following fields for both add/edit modes >>
    //The values for these fileds will be processed in the API backend 
    clientPackObj.state = null;
    clientPackObj.renewalDate = null;
    clientPackObj.renewalDateStr = null;
    clientPackObj.expirationDate = null;
    clientPackObj.expirationDateStr = null;
    //<< End of null passed values >>

    if (this.isEdit) {
      this.updateClientPack(clientPack.id, clientPackObj);
    } else {
      this.addClientPack(clientPackObj);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, selected: boolean): void {
    if (selected) {
      let pack = this.productPackList.filter(x => x.id == event.option.value)[0];
      this._clientPackModal.productPackId = event.option.value;
      this._clientPackModal.pack = pack.packName;

      this.productService.getProductPackById(event.option.value).subscribe(response => {

        if (response.data) {
          let result = response.data;
          this._clientPackModal.pack = result.packName;
          this._clientPackModal.subscriptionType = result.subscriptionType != null ? parseInt(result.subscriptionType) : null;
          this._clientPackModal.oneTimePrice = result.oneTimePrice != null ? parseInt(result.oneTimePrice) : null;
          this._clientPackModal.subscriptionPrice = result.subscriptionPrice != null ? parseInt(result.subscriptionPrice) : null;
          this.onSubscriptionPriceChange(this._clientPackModal.subscriptionPrice);
        }
        else {
          this._clientPackModal.pack = null;
          this._clientPackModal.subscriptionType = null;
          this._clientPackModal.oneTimePrice = null;
          this._clientPackModal.subscriptionPrice = null;
          this.onSubscriptionPriceChange(null);
        }

      }, err => {
        console.error(err)
      }, () => { });

    }
    else {
      this._clientPackModal.productPackId = null;
    }

  }

  loadProductPackDetails(event: MatSelectChange) {
    if (event && event.value) {
      this.productService.getProductPackById(parseInt(event.value)).subscribe(response => {

        if (response.data) {
          let result = response.data;
          this._clientPackModal.pack = result.packName;
          this._clientPackModal.subscriptionType = result.subscriptionType != null ? parseInt(result.subscriptionType) : null;
          this._clientPackModal.oneTimePrice = result.oneTimePrice != null ? parseInt(result.oneTimePrice) : null;
          this._clientPackModal.subscriptionPrice = result.subscriptionPrice != null ? parseInt(result.subscriptionPrice) : null;
          this.onSubscriptionPriceChange(this._clientPackModal.subscriptionPrice);
        }
        else {
          this._clientPackModal.pack = null;
          this._clientPackModal.subscriptionType = null;
          this._clientPackModal.oneTimePrice = null;
          this._clientPackModal.subscriptionPrice = null;
          this.onSubscriptionPriceChange(null);
        }

      }, err => {
        console.error(err)
      }, () => { });
    }
    else {
      this._clientPackModal.pack = null;
      this._clientPackModal.subscriptionType = null;
      this._clientPackModal.oneTimePrice = null;
      this._clientPackModal.subscriptionPrice = null;
    }
  }

  addClientPack(clientPackObj) {
    this.isDisabledClientPack = true;
    this.productService.saveClientPackData(clientPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.closeDialog();
          this.productService.refreshClientProductDetails.emit(res.data);
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
        else {
          const snack = new SnackBar(SnackBarType.success, res.message, '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateClientPack(clientPackId, clientPackObj) {
    this.productService.updateClientPack(clientPackId, clientPackObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.productService.refreshClientProductDetails.emit(res.data);
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  billedCheckEvent(event) {
    this._clientPackModal.billed = event.checked;
  }

  setExpirationDate(text) {
    if (text) {
      this.expirationSelectionText = text;
    }
  }

  renewalDateSelectedEvent(event: MatRadioChange) {
    this.isRenewalDateSelected = event.source.checked;
  }

  loadActiveSubscriptionTypes() {
    this.commonDropdownService.loadActiveSubscriptionTypes()
      .subscribe(response => {
        this.commonDropdownService.subscriptionTypeList = response.data;
        this.subscriptionTypeList = this.commonDropdownService.subscriptionTypeList;
        if (this.isEdit) {
          if (this.subscriptionTypeList && this.subscriptionTypeList.length > 0) {
            //The subscription type should be 'One Time' by default if the subscription price is null or 0
            if (this._clientPackModal.subscriptionPrice == null || this._clientPackModal.subscriptionPrice == 0) {
              this.subscriptionTypeList = this.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") == 'onetime');
              this._clientPackModal.subscriptionType = this.subscriptionTypeList && typeof (this.subscriptionTypeList) != 'undefined' && this.subscriptionTypeList.length > 0 ? parseInt(this.subscriptionTypeList[0].id) : null;
              this.isDisabledSubscriptionType = true;
            }
            else {
              this.subscriptionTypeList = this.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") != 'onetime');
              this._clientPackModal.subscriptionType = this.modalViewData.data.modalData.subscriptionType;
              this.isDisabledSubscriptionType = false;
            }
          }
        }
        else {
          this.isDisabledSubscriptionType = true;
          if (this.subscriptionTypeList && this.subscriptionTypeList.length > 0) {
            //Since the subscription price is empty on new client pack addition, the subscription type should be 'One Time' by default
            let oneTimeOption = this.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") == 'onetime');
            this._clientPackModal.subscriptionType = oneTimeOption && typeof (oneTimeOption) != 'undefined' && oneTimeOption.length > 0 ? parseInt(oneTimeOption[0].id) : null;
          }
        }
      }, err => {
        console.error(err)
      }, () => { });
  }



  //#region "handle subscription type drop down values based on entered Subscription price"

  onSubscriptionPriceChange(inputVal: any) {
    if (inputVal == null || parseInt(inputVal) == 0 || inputVal.length == 0) {
      this._clientPackModal.subscriptionType = null;
      this.subscriptionTypeList = this.commonDropdownService.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") == 'onetime');
      this._clientPackModal.subscriptionType = this.subscriptionTypeList[0] && typeof (this.subscriptionTypeList[0]) != 'undefined' && this.commonDropdownService.subscriptionTypeList.length > 0 ? parseInt(this.commonDropdownService.subscriptionTypeList[0].id) : null;
      this.isDisabledSubscriptionType = true;
    }
    else {
      let selectedSubscriptionType = this._clientPackModal.subscriptionType;
      this.subscriptionTypeList = this.commonDropdownService.subscriptionTypeList.filter(a => a.name.toLowerCase().trim().replace(" ", "") != 'onetime');
      this.isDisabledSubscriptionType = false;

      //Retain the previously selected value after reloading the drop down
      this._clientPackModal.subscriptionType = selectedSubscriptionType;
    }
  }

  //#endregion

}
