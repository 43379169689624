import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { InventoryUserModel } from '../../../../entities/inventoryUser';
import { isNumber } from 'util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-inventory-user',
  templateUrl: './add-inventory-user.component.html',
  styleUrls: ['./add-inventory-user.component.scss']
})

export class AddInventoryUserComponent implements OnInit {
  inventoryId: number = 0;
  inventoryUserModel: InventoryUserModel;
  inventoryUserModelForSave: InventoryUserModel;
  inventoryUserFormGroup: FormGroup;
  subscriber: Subscription;
  minDate: Date;
  checkToDate: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddInventoryUserComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private _formBuilder: FormBuilder,
    public commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private inventoryService: InventoryService,
    private commonUIService: UIService) {
    this.inventoryUserModel = new InventoryUserModel();
    this.inventoryUserModelForSave = new InventoryUserModel();
  }

  ngOnInit() {
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
    this.commonDropdownService.LoadUserList();
    this.initFormGroup();
    this.setDataForUserAddView();
  }

  initFormGroup() {
    this.inventoryUserFormGroup = this._formBuilder.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
      user: ['', [Validators.required, , this.validationService.noWhitespaceValidator]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.inventoryService.refreshInventor.emit({ list: this.modalViewData.data.modalData, tabName: "" });
  }

  submit(model: InventoryUserModel) {
    if (this.inventoryUserFormGroup.invalid) {
      return;
    }
    this.inventoryUserModelForSave = Object.assign({}, model);
    this.inventoryUserModelForSave.fromDateStr = model.fromDate ? this.formatDatepicker.ConvertDateFormatForSave(model.fromDate) : model.fromDate;
    this.inventoryUserModelForSave.toDateStr = model.toDate ? this.formatDatepicker.ConvertDateFormatForSave(model.toDate) : model.toDate;
    this.saveInventoryUser()
  }

  saveInventoryUser() {
    if (this.inventoryUserModelForSave) {
      this.inventoryService.saveInventoryUser(this.inventoryUserModelForSave).subscribe(res => {
        if (res && res.isSuccsess) {
          this.modalViewData.data.modalData.name = res.data.user;
          this.modalViewData.data.modalData.currentUser = res.data.user;
          if (this.modalViewData.data.modalData.type == 'LAPTOP') {
            this.inventoryService.submitUpdateInventory(this.modalViewData.data.modalData, 0, false);
          } else if (this.modalViewData.data.modalData.type == 'PHONE' || this.modalViewData.data.modalData.type == 'MOBILE' || this.modalViewData.data.modalData.type == 'PAD') {
            this.inventoryService.submitUpdateInventory(this.modalViewData.data.modalData, 1, false);
          } else if (this.modalViewData.data.modalData.type == 'MONITOR' || this.modalViewData.data.modalData.type == 'TV') {
            this.inventoryService.submitUpdateInventory(this.modalViewData.data.modalData, 2, false);
          }
          const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.inventoryService.refreshInventoryUser.emit(true);
          this.closeDialog();
          this.commonUIService.closeMask();
        }
        else {
          const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
        }
      });
    }
  }

  setDataForUserAddView(): void {
    if (this.modalViewData.data) {
      this.inventoryUserModel.inventoryId = this.modalViewData.data.modalData.id;
    }
  }

  onDateChange(event) {
    if (event.value) {
      if (this.inventoryUserModel.fromDate && this.inventoryUserModel.toDate) {
        this.checkToDate = !this.inventoryUserModel.toDate < this.inventoryUserModel.fromDate;          
      }
      this.minDate = event.value;
    }
  }
}
