import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { BehaviorSubject } from 'rxjs';
import { RefereshModal } from '../entities/referesh-modal';
import { ClientContact } from '../entities/clientContact';
import { ActionClient } from '../entities/actionClient';
import { Project } from '../entities/project';
import { ProjectTask } from 'src/app/shared/entities/projectTask';
import { ProjectAction } from '../entities/projectAction';
import { ClientDocument } from 'src/app/client/entities/clientDocument';
import { ProjectDocuments } from '../entities/projectDocument';
import { MoreFilterModel } from '../entities/moreFilterModel';
import { ClientTask } from '../entities/clientTask';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { FilterClientProjectTaskModel } from '../../task/entities/filterClientProjectTaskModel';
import { FilterTaskModel } from '../../task/entities/filterTaskModel';
import { FilterEstimationModel } from '../../estimation/entities/filterEstimationModel';
import { Estimation } from '../entities/estimation';
import { EstimationPhase } from '../entities/estimationPhase';

import API from './../../shared/services/api.routes.json';
const behaviorSubjectForRefreshEvents = new BehaviorSubject<RefereshModal>(new RefereshModal());

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  @Output() refershListInventor: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClient: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedClient: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientTask: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDeletedClient: EventEmitter<any> = new EventEmitter<any>();
  @Output() moreFilterSelectEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelProjectDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() newProjectDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedTask: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientAndProjectTaskList: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearTaskFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() clientProjectTaskUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshFilterClientAndProjectTasks: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshUpdateClient: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshListForEstimationTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshMappedProducts: EventEmitter<any> = new EventEmitter<any>();
  private _refreshEventsSource = behaviorSubjectForRefreshEvents;
  public refreshEvents = this._refreshEventsSource.asObservable();

  _refreshModal: RefereshModal;
  isMoreFilterActive: boolean;
  permissions: Array<string>;
  moreFilterModel: MoreFilterModel = new MoreFilterModel();
  isSelectedClient: boolean = false;
  isClientEdit: boolean = true;
  isClientAdd: boolean = true;
  refereeList: any = [];
  isMoreFilterActiveForTask: boolean;
  clientTaskCount: any = null;
  refresPrject: boolean = false;
  clientActiveProjectList: any[] = [];
  clientInactiveProjectList: any[] = [];
  _clientDocumentList: any = [];
  filterId: any;
  selectedClientAndProjectObject: any = null;
  showMore = false;
  showMoreClientDes = false;
  showMoreLinkedInComment = false;
  _clientProductList: any = [];
  _clientId : number=0;
  isToInActive :boolean=false; 
  nextActiveProjectIndex : number;
  isProjectDeletion : boolean = false;
  selectedProjectId : any ;
  isLoading_task:boolean=false;
  isDifferentClientSelected:boolean=false;
  actionTypeListForIcon:any;

  constructor(private apiService: APIService, private authAppService: AuthAppService) {
    this._refreshModal = new RefereshModal();

  }

  getProjectListByClientId(clientId) {
    let url = this.apiService.getEndPoint("project.getProjectListByClientId", [["{clientID}", clientId]]);
    return this.apiService.get(url);
  }

  getInactiveProjectListAll(clientId) {
    let url = this.apiService.getEndPoint("project.inactiveProjectListAll", [["{clientID}", clientId]]);
    return this.apiService.get(url);
  }

  getClientList(FilterObject: MoreFilterModel) {
    let url = this.apiService.getEndPoint("client.getClientList", null);
    return this.apiService.post(url, FilterObject, true);
  }

  getClientById(clientId,isRefreshProductList = false) {
    let url = this.apiService.getEndPoint("client.getClientById", [["{clientId}", clientId],["{isRefreshProductList}", isRefreshProductList]]);
    return this.apiService.get(url);
  }

  getClientLogo(clientId) {
    return API.baseUrl + this.apiService.getEndPoint("client.ClientLogo",
      [["{clientId}", clientId], ["{rn}", this.getRandomInt(1000)]]);
  }

  getProjectById(ProjectId, UserId) {
    let url = this.apiService.getEndPoint("project.getProjectById", [["{ProjectId}", ProjectId], ["{UserId}", UserId]]);
    return this.apiService.get(url);
  }

  setRefreshEvents(refreshEvents: RefereshModal) {
    this._refreshEventsSource.next(refreshEvents);
  }

  loadClientContactList(clientId) {
    let url = this.apiService.getEndPoint("clientContact.listByClientId", [["{clientId}", clientId]]);
    return this.apiService.get(url);
  }

  updateProject(projectId, projectObj) {
    let url = this.apiService.getEndPoint("project.updateProject", [["{projectID}", projectId]]);
    return this.apiService.put(url, projectObj, true);
  }

  updateClient(clientId, clientObj) {
    let url = this.apiService.getEndPoint("client.UpdateClient", [["{clientID}", clientId]]);
    return this.apiService.put(url, clientObj, true);
  }

  setValuesForRefresh(isRefresh, status, isEdit, isListView, listType, id, projectId) {
    this._refreshModal = new RefereshModal();
    this._refreshModal.isReferesh = isRefresh;
    this._refreshModal.status = status;
    this._refreshModal.isEdit = isEdit;
    this._refreshModal.isListView = isListView;
    this._refreshModal.listType = listType;
    this._refreshModal.id = id;
    this._refreshModal.projectId = projectId;
  }

  SaveClientContact(clientObj: ClientContact) {
    let url = this.apiService.getEndPoint("clientContact.addClientContact", null);
    return this.apiService.post(url, clientObj, true);
  }

  updateClientContact(clientContactID, clientObj) {
    let url = this.apiService.getEndPoint("clientContact.updateClientContact", [["{clientContactID}", clientContactID]]);
    return this.apiService.put(url, clientObj, true);
  }

  // region client action start
  SaveClientAction(actionObj: ActionClient) {
    let url = this.apiService.getEndPoint("actionClient.addActionClient", null);
    return this.apiService.post(url, actionObj, true);
  }

  loadClientActionList(clientId) {
    let url = this.apiService.getEndPoint("actionClient.getActionListByClientId", [["{clientId}", clientId]]);
    return this.apiService.get(url);
  }

  updateClientAction(clientActionID, clientActionObj) {
    let url = this.apiService.getEndPoint("actionClient.updateActionClient", [["{clientActionID}", clientActionID]]);
    return this.apiService.put(url, clientActionObj, true);
  }

  updateProjectAction(projectActionID, projectActionObj) {
    let url = this.apiService.getEndPoint("actionProject.updateActionProject", [["{actionProjectId}", projectActionID]]);
    return this.apiService.put(url, projectActionObj, true);
  }

  deleteClientContact(id) {
    let url = this.apiService.getEndPoint("clientContact.clientContact", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  deleteClientAction(id) {
    let url = this.apiService.getEndPoint("actionClient.actionClient", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }
  // region client action end

  SaveProject(ClientProjectObi: Project) {
    let url = this.apiService.getEndPoint("project.addProject", null);
    return this.apiService.post(url, ClientProjectObi, true);
  }

  saveClient(clientObj) {
    let url = this.apiService.getEndPoint("client.AddClient", null);
    return this.apiService.post(url, clientObj, true);
  }

  SaveTask(taskObj: ClientTask) {
    let url = this.apiService.getEndPoint("clientTask.addClientTask", null);
    return this.apiService.post(url, taskObj, true);
  }

  SaveProjectTask(projectTaskObj: ProjectTask) {
    let url = this.apiService.getEndPoint("task.saveProjectTask", null);
    return this.apiService.post(url, projectTaskObj, true);
  }

  updateClientTask(clientTaskID, clientTaskObj) {

    let url = this.apiService.getEndPoint("clientTask.updateClientTask", [["{clientTaskID}", clientTaskID]]);
    return this.apiService.put(url, clientTaskObj, true);
  }

  updateProjectTask(projectTaskID, projectTaskObj) {
    let url = this.apiService.getEndPoint("task.updateProjectTask", [["{id}", projectTaskID]]);
    return this.apiService.put(url, projectTaskObj, true);
  }

  deleteClientTask(id) {

    let url = this.apiService.getEndPoint("clientTask.deleteTask",null);
    return this.apiService.delete(url, id);
  }

  changeClientTaskStatus(id, status) {
    let url = this.apiService.getEndPoint("clientTask.updateClientTaskStatus", [["{id}", id], ["{status}", status]]);
    return this.apiService.put(url);
  }

  changeClientTaskDate(id, taskDate) {
    let url = this.apiService.getEndPoint("clientTask.UpdateClientTaskDate", [["{id}", id], ["{taskDate}", taskDate]]);
    return this.apiService.put(url);
  }

  loadToDoClientTaskList(id) {
    let url = this.apiService.getEndPoint("clientTask.toDoListByClientId", [["{clientId}", id]]);
    return this.apiService.get(url);
  }

  deleteClient(id) {
    let url = this.apiService.getEndPoint("client.DeleteClient", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  loadToDoProjectTaskList(id) {
    let url = this.apiService.getEndPoint("task.toDoListByProjectId", [["{projectId}", id]]);
    return this.apiService.get(url);
  }

  deleteProjectTask(id) {
    let url = this.apiService.getEndPoint("task.deleteTask",null);   
    return this.apiService.delete(url, id);
  }

  changeProjectTaskStatus(id, status) {
    let url = this.apiService.getEndPoint("task.updateTaskStatus", [["{id}", id], ["{status}", status]]);
    return this.apiService.put(url);
  }

  //#region project action
  SaveProjectAction(projectActionObj: ProjectAction) {
    let url = this.apiService.getEndPoint("actionProject.addActionProject", null);
    return this.apiService.post(url, projectActionObj, true);
  }

  loadProjectActionList(projectId) {
    let url = this.apiService.getEndPoint("actionProject.getActionListByProjectId", [["{projectId}", projectId]]);
    return this.apiService.get(url);
  }

  deleteProjectAction(id) {
    let url = this.apiService.getEndPoint("actionProject.actionProject", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }
  //#endregion

  //#region client document
  saveClientDocument(clientDocumentObj: ClientDocument) {
    let url = this.apiService.getEndPoint("clientDocument.addClientDocument", null);
    return this.apiService.post(url, clientDocumentObj, true);
  }

  uploadClientDocument(formData) {
    let url = this.apiService.getEndPoint("fileUpload.upload", null);
    return this.apiService.upload(url, formData);
  }

  loadClientDocumentList(clientId) {
    let url = this.apiService.getEndPoint("clientDocument.getClientDocumentListByClientId", [["{clientId}", clientId]]);
    return this.apiService.get(url);
  }

  deleteClientDocument(id) {
    let url = this.apiService.getEndPoint("clientDocument.clientDocument", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  updateClientDocument(clientDocumentId, clientDocumentObj: ClientDocument) {
    let url = this.apiService.getEndPoint("clientDocument.updateClientDocument", [["{clientDocumentId}", clientDocumentId]]);
    return this.apiService.put(url, clientDocumentObj, true);
  }

  saveClientDocumentRequest(clientDocumentObj: ClientDocument) {
    let url = this.apiService.getEndPoint("clientDocument.saveClientDocumentRequest", null);
    return this.apiService.post(url, clientDocumentObj, true);
  }
  //#endregion client document

  deleteProject(id) {
    let url = this.apiService.getEndPoint("project.deleteProject", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  saveRolePermission(rolePermissionObj) {

    let url = this.apiService.getEndPoint("rolePermission.AddRolePermission", null);
    return this.apiService.post(url, rolePermissionObj, true);
  }

  deleteRolePermission(id, roleId, permissionId) {
    let url = this.apiService.getEndPoint("rolePermission.deleteRolePermission", [["{roleId}", roleId], ["{permissionId}", permissionId], ["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  deleteProjectDocument(id) {
    let url = this.apiService.getEndPoint("projectDocuments.deleteProjectDocoument", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }
  saveProjectDocument(projectDocumentObj: ProjectDocuments) {
    let url = this.apiService.getEndPoint("projectDocuments.addprojectDocoument", null);
    return this.apiService.post(url, projectDocumentObj, true);
  }

  changeProjectTaskDate(id, taskDate) {
    let url = this.apiService.getEndPoint("task.UpdateProjectTaskDate", [["{id}", id], ["{taskDate}", taskDate]]);
    return this.apiService.put(url);
  }

  getLoggedInPermission() {
    this.permissions = this.authAppService.getLoggedInPermission();
  }

  getRefereeList() {
    let url = this.apiService.getEndPoint("client.getRefereeList", null);
    return this.apiService.get(url);
  }

  uploadClientLogo(formData) {
    let url = this.apiService.getEndPoint("fileUpload.uploadClientLogo", null);
    return this.apiService.upload(url, formData);
  }

  uploadDocuments(formData) {
    let url = this.apiService.getEndPoint("fileUpload.upload", null);
    return this.apiService.upload(url, formData);
  }

  getClientAndProjectTaskList(companyId) {
    let url = this.apiService.getEndPoint("client.getClientAndProjectTaskList", [["{companyId}", companyId]]);
    return this.apiService.get(url);
  }

  filterClientAndProjectTasks(filterObject: FilterClientProjectTaskModel) {
    if (filterObject.filterId != null)
      filterObject.filterId = +filterObject.filterId;
    let url = this.apiService.getEndPoint("client.filterClientAndProjectTasks", null);
    return this.apiService.post(url, filterObject, true);
  }

  filterTasks(filterObject: FilterTaskModel) {
    if (filterObject.filterId != null)
      filterObject.filterId = +filterObject.filterId;
    if (filterObject.clientId != null)
      filterObject.clientId = +filterObject.clientId;
    let url = this.apiService.getEndPoint("client.filterTasks", null);
    return this.apiService.post(url, filterObject, true);
  }

  deleteEstimation(id) {
    let url = this.apiService.getEndPoint("estimation.deleteEstimation", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  updateEstimation(estimationId, estimationObj) {
    if (estimationObj.type != null)
      estimationObj.type = +estimationObj.type;
    if (estimationObj.state != null)
      estimationObj.state = +estimationObj.state;
    if (estimationObj.estimationHours != null)
      estimationObj.estimationHours = parseFloat(estimationObj.estimationHours);
    if (estimationObj.maxEstimationHours != null)
      estimationObj.maxEstimationHours = parseFloat(estimationObj.maxEstimationHours);
    if (estimationObj.budgetHours != null)
      estimationObj.budgetHours = parseFloat(estimationObj.budgetHours);
    if (estimationObj.maxBudgetHours != null)
      estimationObj.maxBudgetHours = parseFloat(estimationObj.maxBudgetHours);
    if (estimationObj.totalBudget != null)
      estimationObj.totalBudget = parseFloat(estimationObj.totalBudget);
    if (estimationObj.totalMaxBudget != null)
      estimationObj.totalMaxBudget = parseFloat(estimationObj.totalMaxBudget);
    let url = this.apiService.getEndPoint("estimation.updateEstimation", [["{estimationId}", estimationId]]);
    return this.apiService.put(url, estimationObj, true);
  }

  addEstimation(estimationObj: Estimation) {
    if (estimationObj.type != null)
      estimationObj.type = +estimationObj.type;
    if (estimationObj.state != null)
      estimationObj.state = +estimationObj.state;
    if (estimationObj.estimationHours != null)
      estimationObj.estimationHours = parseFloat(estimationObj.estimationHours);
    if (estimationObj.maxEstimationHours != null)
      estimationObj.maxEstimationHours = parseFloat(estimationObj.maxEstimationHours);
    if (estimationObj.budgetHours != null)
      estimationObj.budgetHours = parseFloat(estimationObj.budgetHours);
    if (estimationObj.maxBudgetHours != null)
      estimationObj.maxBudgetHours = parseFloat(estimationObj.maxBudgetHours);
    if (estimationObj.totalBudget != null)
      estimationObj.totalBudget = parseFloat(estimationObj.totalBudget);
    if (estimationObj.totalMaxBudget != null)
      estimationObj.totalMaxBudget = parseFloat(estimationObj.totalMaxBudget);
    let url = this.apiService.getEndPoint("estimation.addEstimation", null);
    return this.apiService.post(url, estimationObj, true);
  }

  deleteEstimationPhase(id) {
    let url = this.apiService.getEndPoint("estimationPhase.deleteEstimationPhase", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  updateEstimationPhase(estimationPhaseId, estimationPhaseObj) {
    if (estimationPhaseObj.type != null)
      estimationPhaseObj.type = +estimationPhaseObj.type;
    if (estimationPhaseObj.state != null)
      estimationPhaseObj.state = +estimationPhaseObj.state;
    if (estimationPhaseObj.userType != null)
      estimationPhaseObj.userType = +estimationPhaseObj.userType;
    if (estimationPhaseObj.hours != null)
      estimationPhaseObj.hours = parseFloat(estimationPhaseObj.hours);
    if (estimationPhaseObj.maxHours != null)
      estimationPhaseObj.maxHours = parseFloat(estimationPhaseObj.maxHours);
    if (estimationPhaseObj.hourFee != null)
      estimationPhaseObj.hourFee = parseFloat(estimationPhaseObj.hourFee);
    if (estimationPhaseObj.budget != null)
      estimationPhaseObj.budget = parseFloat(estimationPhaseObj.budget);
    if (estimationPhaseObj.maxBudget != null)
      estimationPhaseObj.maxBudget = parseFloat(estimationPhaseObj.maxBudget);
    estimationPhaseObj.estimationOption = [];
    estimationPhaseObj.estimationDocument = [];
    let url = this.apiService.getEndPoint("estimationPhase.updateEstimationPhase", [["{estimationPhaseId}", estimationPhaseId]]);
    return this.apiService.put(url, estimationPhaseObj, true);
  }

  addEstimationPhase(estimationPhaseObj: EstimationPhase) {
    if (estimationPhaseObj.type != null)
      estimationPhaseObj.type = +estimationPhaseObj.type;
    if (estimationPhaseObj.state != null)
      estimationPhaseObj.state = +estimationPhaseObj.state;
    if (estimationPhaseObj.userType != null)
      estimationPhaseObj.userType = +estimationPhaseObj.userType;
    if (estimationPhaseObj.hours != null)
      estimationPhaseObj.hours = parseFloat(estimationPhaseObj.hours);
    if (estimationPhaseObj.maxHours != null)
      estimationPhaseObj.maxHours = parseFloat(estimationPhaseObj.maxHours);
    if (estimationPhaseObj.hourFee != null)
      estimationPhaseObj.hourFee = parseFloat(estimationPhaseObj.hourFee);
    if (estimationPhaseObj.budget != null)
      estimationPhaseObj.budget = parseFloat(estimationPhaseObj.budget);
    if (estimationPhaseObj.maxBudget != null)
      estimationPhaseObj.maxBudget = parseFloat(estimationPhaseObj.maxBudget);
    let url = this.apiService.getEndPoint("estimationPhase.addEstimationPhase", null);
    return this.apiService.post(url, estimationPhaseObj, true);
  }

  updateEstimationOption(estimationOptionId, estimationOptionList) {
    if (estimationOptionList.length > 0) {
      estimationOptionList.forEach(es => {
        if (es.hours != null)
          es.hours = parseFloat(es.hours);
        if (es.maxHours != null)
          es.maxHours = parseFloat(es.maxHours);
        if (es.hourFee != null)
          es.hourFee = parseFloat(es.hourFee);
        if (es.budget != null)
          es.budget = parseFloat(es.budget);
        if (es.maxBudget != null)
          es.maxBudget = parseFloat(es.maxBudget);
        if (es.state != null) {
          es.state = +es.state;
        }
      });
    }
    let url = this.apiService.getEndPoint("estimationOption.updateEstimationOption", [["{estimationOptionId}", 0]]);
    return this.apiService.put(url, estimationOptionList, true);
  }

  addEstimationOption(estimationOptionList) {
    if (estimationOptionList.length > 0) {
      estimationOptionList.forEach(es => {
        if (es.hours != null)
          es.hours = parseFloat(es.hours);
        if (es.maxHours != null)
          es.maxHours = parseFloat(es.maxHours);
        if (es.hourFee != null)
          es.hourFee = parseFloat(es.hourFee);
        if (es.budget != null)
          es.budget = parseFloat(es.budget);
        if (es.maxBudget != null)
          es.maxBudget = parseFloat(es.maxBudget);
        if (es.state != null) {
          es.state = +es.state;
        }
      });
    }
    let url = this.apiService.getEndPoint("estimationOption.addEstimationOption", null);
    return this.apiService.post(url, estimationOptionList, true);
  }

  saveEstimationDocument(estimationDocumentObj) {
    let url = this.apiService.getEndPoint("estimationDocument.addEstimationDocument", null);
    return this.apiService.post(url, estimationDocumentObj, true);
  }

  listAllEstimations() {
    let url = this.apiService.getEndPoint("estimation.listAll", null);
    return this.apiService.get(url);
  }

  filterEstimations(filterObject: FilterEstimationModel) {
    let url = this.apiService.getEndPoint("estimation.filterEstimations", null);
    return this.apiService.post(url, filterObject, true);
  }

  loadProjectEstimationList(projectId) {
    let url = this.apiService.getEndPoint("estimation.getEstimationListByProjectId", [["{projectId}", projectId]]);
    return this.apiService.get(url);
  }

  uploadEstimationDocument(formData) {
    let url = this.apiService.getEndPoint("fileUpload.upload", null);
    return this.apiService.upload(url, formData);
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  filterActions(filterObject: FilterTaskModel) {
    if (filterObject.filterId != null)
      filterObject.filterId = +filterObject.filterId;
    if (filterObject.clientId != null)
      filterObject.clientId = +filterObject.clientId;
    let url = this.apiService.getEndPoint("client.filterActions", null);
    return this.apiService.post(url, filterObject, true);
  }

  loadProjectTasks() {
    let url = this.apiService.getEndPoint("task.getAllProjectTasks", null);
    return this.apiService.get(url);
  }

  getEstimationById(id) {
    let url = this.apiService.getEndPoint("estimation.getEstimationById", [["{id}", id]]);
    return this.apiService.get(url);
  }

  deleteEstimationDocument(id) {
    let url = this.apiService.getEndPoint("estimationDocument.estimationDocument", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }

  getMainDocumentByClient(clientId, roleIds, userId) {
    let obj = {
      clientId: clientId,
      userId: userId,
      roleIds: roleIds
    };
    let url = this.apiService.getEndPoint("documentPermits.getMainDocumentByClient", null);
    return this.apiService.post(url, obj, true);
  }

  isInactiveProjectStatusType(id) {
    let url = this.apiService.getEndPoint("projectStatus.isInactiveProjectStatusType",null);
    url = `${url}/${id}`
    return this.apiService.get(url);
  }

  isExistingClientName(clientName:string) 
  {
    let url = this.apiService.getEndPoint("client.isExistingClientName",null);
    url = `${url}/${clientName.toLowerCase().trim().replace(" ","")}`
    return this.apiService.get(url);
  }

  getAllClients() 
  {
    let url = this.apiService.getEndPoint("client.listAllNames",null);
    return this.apiService.get(url);
  }

  deleteEstimationOption(id) {
    let url = this.apiService.getEndPoint("estimationOption.deleteEstimationOption", [["{id}", id]]);
    return this.apiService.delete(url, id);
  }
}


