import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Client } from '../entities/client';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatAutocomplete, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { Subject } from 'rxjs';
import { ClientService } from '../services/client.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Router } from '@angular/router';
import { SharedClientService } from '../services/shared.client.service';
import { ClientContact } from '../entities/clientContact';
import moment from 'moment';
import { AddEditActionComponent } from '../client-detail/client-view-data/add-edit-action/add-edit-action.component';
import { ActionClient } from '../entities/actionClient';
import { Users } from '../../auth/entities/user';
import { AuthAppService } from '../../shared/services/auth-app.service';
import { ClientViewService } from '../services/client-view.service';
import { APIService } from '../../shared/services/api.service';
const refereeTextChanged = new Subject<string>();
const regWeb = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
const techTextChanged = new Subject<string>();
import API from '../../shared/services/api.routes.json';
import { Company } from '../../company/entities/company';
import { SharedHeaderService } from '../../shared/services/shared.header.services';
import { map } from 'rxjs/operators';

@Component({
  selector: 'Add-client',
  templateUrl: './Add-client.component.html',
  styleUrls: ['./Add-client.component.scss'],
  host: {
    "(window:paste)": "handlePaste( $event )"
  }
})

export class AddclientComponent implements OnInit {

  @ViewChild("serviceTypeSearch",{ static: true }) serviceTypeSearch:ElementRef
  @ViewChild("referralSearch",{ static: true }) referralSearch:ElementRef
  @ViewChild("statusSearch",{ static: true }) statusSearch:ElementRef
  @ViewChild("companySizeSearch",{ static: true }) companySizeSearch:ElementRef
  @ViewChild("branchSearch",{ static: true }) branchSearch:ElementRef
  @ViewChild("countrySearch",{ static: true }) countrySearch:ElementRef
  @ViewChild("provinceSearch",{ static: true }) provinceSearch:ElementRef
  @ViewChild("positionSearch",{ static: true }) positionSearch:ElementRef

  //region Variables
  typeGlobal: string;
  _client: Client;
  isChecked = true;
  clientFormGroup: FormGroup;
  isEdit: boolean = false;
  _clientStatusGlobal: any = 1;
  _currentStatusObj: any;
  isSelected = false;
  _clientForSave: Client;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  technologyList: any[] = [];
  allTechnologies: any[] = [];
  autoFilteredTechnologies: any[] = []; //List for auto complete
  chipTechnologies: any[] = []; //list for chip view
  selectedTechValues: string = '';
  techIdList: any[] = [];
  techUpdateList: any[] = [];
  controlNameArray: any[] = [];
  isDisabledClient = true;
  lengthNumber: number = 12;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  refereeList: any[] = [];
  _clientContact: ClientContact;
  _clientContactForSave: ClientContact;
  _loggedinUser: Users = new Users();

  imageDataViewForEdit: any;
  imageFront: any;
  imageFileNameFront: any;
  imageName: any;
  firstOpen: boolean = true;
  isClosedClient: boolean = false;
  isCloseReasonRequired: boolean = false;
  initialState: string;
  isTechNumeric: boolean = false;
  autoFilteredRefereeList: any = [];
  referralGlobalId: number;
  _companyIdFormControl: any;
  lengthNumber1: number = 15;
  lengthNumber2: number = 15;
  lengthWhatsappNumber: number = 15;
  isClientNameInUse: boolean = false;
  private _clientList: any = [];
  private _clientLegalNameOnEdit: string = "";

  // Private
  private _unsubscribeAll: Subject<any>;
  config;
  companyList: any = [];
  countryList :any=[];

  constructor(
    public service: ClientViewService,
    private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private commonUIService: UIService,
    public commonDropdownService: CommonDropdownService,
    private validationService: ValidationService,
    private formatDatepicker: FormatMatDatepicker,
    public router: Router,
    private authAppService: AuthAppService,
    private api: APIService,
    private sharedClientService: SharedClientService,
    public sharedHeaderService: SharedHeaderService,
    public dialogRef: MatDialogRef<AddclientComponent>,
    @Inject(MAT_DIALOG_DATA) public clientEditViewData: DialogDataForModalView,
    @Inject(MAT_DIALOG_DATA) public currentCompanyData: DialogDataForModalView
  ) {
    this._unsubscribeAll = new Subject();
    this._client = new Client();
    this._clientContact = new ClientContact();
    this._clientContactForSave = new ClientContact();
    this.initDropdownValues();
  }
  // endregion

  ngOnInit() {
    this._client.sector = "IT";
    this._loggedinUser = this.authAppService.getLoggedInUser();
    this.commonDropdownService.LoadCompantListForClient();
    this.commonDropdownService.LoadClientStatusList();

    
    this.initFormGroup();
    setTimeout( () => { this.countryList=this.commonDropdownService._countryList.filter(obj=> obj.status==true) }, 5000 );
    

    //Don't remove this comment. Need for future developments.
    //if (this.currentCompanyData.data.companyId !== null) {
    //  this._client.companyId = this.currentCompanyData.data.companyId ? this.currentCompanyData.data.companyId : 0;
    //  this._client.companyName = this.currentCompanyData.data.type ? this.currentCompanyData.data.type : null;
    //  this._companyIdFormControl = this.clientFormGroup.get('companyId');
    //}

    this.config = {
      hasAllCheckBox: true,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500
    };

    this.firstOpen = true;
    techTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadDataForAutoComplete(res);
    });

    refereeTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((res) => {
      this.loadrefereeForAutoComplete(res);
    });

    this.loadCompantList();
    this.clientService.getRefereeList().subscribe((list) => {
      if (list.data) {
        this.refereeList = list.data;
      }
    });
    this.commonDropdownService.LoadCompantList();
    this.commonDropdownService.LoadPositionList();
  }

  
  // searchReferralSource(){
  //   const searchInput = this.referralSearch.nativeElement.value ?
  //     this.referralSearch.nativeElement.value.toLowerCase():'';

  //     this.commonDropdownService._referralSourceDropdownList = this.commonDropdownService._referralSourceList.filter(m=>{
  //       if(m.refSourceName!=null)
  //       {
  //         const viewValue : string = m.refSourceName.toLowerCase();
          
  //         return viewValue.indexOf(searchInput) > -1;
  //       }
  //   })
  // }




  


  searchMethodForDropDown(searchWord, dropDown){
    
    const searchInput = searchWord.target.value ?
    searchWord.target.value.toLowerCase():'';

    if(dropDown=='serviceType'){
      this.commonDropdownService._seriviceTypeList = this.commonDropdownService._masterServiceTypeList.filter(m=>{
        if(m.serviceTypeName!=null)
        {
          const viewValue : string = m.serviceTypeName.toLowerCase();
          
          return viewValue.indexOf(searchInput) > -1;
        }
      })

    } else if(dropDown=='referralSource'){

      this.commonDropdownService._referralSourceDropdownList = this.commonDropdownService._referralSourceList.filter(m=>{
        if(m.refSourceName!=null)
        {
          const viewValue : string = m.refSourceName.toLowerCase();
          
          return viewValue.indexOf(searchInput) > -1;
        }
      })

    } else if(dropDown=='status'){

      this.commonDropdownService._activeClientStatusList = this.commonDropdownService._masterStatusList.filter(m=>{
        if(m.clientState!=null)
        {
          const viewValue : string = m.clientState.toLowerCase();
          
          return viewValue.indexOf(searchInput) > -1;
        }
      })
  

    } else if(dropDown=='companySize'){
        console.log('companySize');  // Hardcoded values

    } else if(dropDown=='branch'){ //not sorting the list
      console.log('branch');

      this.commonDropdownService._companyList = this.commonDropdownService._countryList.filter(m=>{
        if(m.name!=null)
        {
          const viewValue : string = m.name.toLowerCase();
          return viewValue.indexOf(searchInput) > -1;
        }
      })

    } else if(dropDown=='country'){
      console.log('country');

      this.commonDropdownService._activeCountryList = this.commonDropdownService._countryList.filter(m=>{
        if(m.name!=null)
        {
          const viewValue : string = m.name.toLowerCase();
          return viewValue.indexOf(searchInput) > -1;
        }
      })

    } else if(dropDown=='province'){
      console.log('province');

      this.commonDropdownService._provinceDropDown = this.commonDropdownService._masterProvinceDropDown.filter(m=>{
        if(m.viewValue!=null)
        {
          const viewValue : string = m.viewValue.toLowerCase();
          return viewValue.indexOf(searchInput) > -1;
        }
      })

    } else if(dropDown=='position'){
      console.log('position');
      
      this.commonDropdownService._positionlist = this.commonDropdownService._masterPositionlist.filter(m=>{
        if(m.description!=null)
        {
          const viewValue : string = m.description.toLowerCase();
          return viewValue.indexOf(searchInput) > -1;
        }
      })
    }
  }



  searchCompanyForAutoComplete(event) {
    refereeTextChanged.next(event);
  }

  loadrefereeForAutoComplete(event) {
    let array = this.refereeList;
    array.forEach(element => {
      if (element.referralSourceId == this.referralGlobalId) {
        this.autoFilteredRefereeList = element.clientRefereeModelList;
      }

    });
    let value = event.target.value === '' ? null : event.target.value;
    if (value) {
      this.autoFilteredRefereeList = this.autoFilteredRefereeList.filter(x => x.referee != null && x.referee.toLowerCase().indexOf(value.toLowerCase()) >= 0);
      this.autoFilteredRefereeList = this.autoFilteredRefereeList.filter(
        (thing, i, arr) => arr.findIndex(t => t.referee === thing.referee) === i
      );
    } else {
      this.autoFilteredRefereeList = [];
    }
  }

  refsourceSelected(event) {
    this.referralGlobalId = event;
  }

  refereeSelected(event) {
    this._client.referee = event.option.value;
  }

  initDropdownValues() {
    this.commonDropdownService.LoadAddClientData();
    debugger
    this.commonDropdownService.LoadCountryList();
    this.commonDropdownService.LoadServiceTypeList();
    this.commonDropdownService.LoadCompantList();
    this.commonDropdownService.LoadProvinceList();
    this.commonDropdownService.LoadAddClientData();
    this.commonDropdownService.LoadPositionList();
  }

  initTagValues() {
    this.commonDropdownService.LoadTechnologyList().subscribe((list) => {
      this.technologyList = list;
      this.allTechnologies = this.technologyList;
      this.setClientDataForEditView();
    });
    this._clientForSave = new Client();
  }

  setClientDataForEditView() {
    this.commonUIService.openMask();
    if (this.clientEditViewData.data.modalData) {
      if (this.clientEditViewData.data.modalData.id) {
        this.isEdit = true;
        //Don't remove this comment. Need for future developments.
        //this.commonDropdownService.LoadCompanyList().subscribe((list) => {
        //  if (list && this.isEdit) {
        //    this.commonDropdownService._companyListForClient = list;
        //  }
        //});
        this.referralGlobalId = this.clientEditViewData.data.modalData.referralSourceId;
        this.isChecked = this.clientEditViewData.data.status;
        this._client = Object.assign({}, this.clientEditViewData.data).modalData;
        this._client.closeReasonId = this._client.closeReasonId ? this._client.closeReasonId.toString() : null;
        this._client.clientStatusId = this._client.clientStatusId ? this._client.clientStatusId.toString() : null;
        if (this._client.phoneNumber) {
          if (this._client.phoneNumber.charAt(0) == "+") {
            this.lengthNumber = 14;
          }
          else {
            this.lengthNumber = 15;
          }
        }
        if (this._client.logoUrl)
          this.imageDataViewForEdit = this.clientService.getClientLogo(this._client.id);
        this.setTechnologyValueForEditView();
      } else {
        if (this.sharedHeaderService.selectedCompanyId == 0 && this.sharedHeaderService.selectedCompanyName == "ALL BRANCHES") {
          this._client.companyId = 0;
        } else if (this.sharedHeaderService.selectedCompanyId > 0) {
          this._client.companyId = this.sharedHeaderService.selectedCompanyId;
        } else {
          this._client.companyId = undefined;
          this._client.companyName = undefined;
        }
        //Don't remove this comment. Need for future developments
        //if (this._client.companyName == "QualitApps") {
        //  if (this.commonDropdownService._companyListForClient) {
        //    let branch = this.commonDropdownService._companyListForClient.filter(x => x.name == "Europe");
        //    if (branch.length > 0) {
        //      this._client.companyId = branch[0].id;
        //      this._client.companyName = branch[0].name;
        //    }
        //    else {
        //      this._client.companyId = undefined;
        //      this._client.companyName = undefined;
        //    }
        //  }
        //} else if (this._client.companyName == "Andorra") {
        //  if (this.commonDropdownService._companyListForClient) {
        //    let branch = this.commonDropdownService._companyListForClient.filter(x => x.name == "Arabia");
        //    if (branch.length > 0) {
        //      this._client.companyId = branch[0].id;
        //      this._client.companyName = branch[0].name;
        //    }
        //    else {
        //      this._client.companyId = undefined;
        //      this._client.companyName = undefined;
        //    }
        //  }
        //}
        //else {
        //  this._client.companyId = undefined;
        //  this._client.companyName = undefined;
        //}
      }
      this.setInitialClientStatus(this.clientEditViewData.data.modalData.clientStatusValue);
    }
    this._client.country = this._client.country && this._client.country != null && this._client.country != "" ? this._client.country : "Spain";
    this.loadAllClients();
    this._clientLegalNameOnEdit = this.isEdit ? this._client.legelName : "";
    this.commonUIService.closeMask();
  }

  initFormGroup() {
    this.clientFormGroup = this._formBuilder.group({
      legelName: [
        {
          value: '',
          disabled: false
        }, [Validators.required, this.noWhitespaceValidator.bind(this)]
      ],
      addressLine1: ['', [Validators.maxLength(500)]],
      city: ['',],
      postalCode: ['', [Validators.maxLength(5)]],
      country: [''],
      entrydate: [''],
      businessDescription: [''],
      website: ['', [Validators.pattern(regWeb), Validators.maxLength(500)]],
      phoneNumber: ['', [this.validationService.checkLimit(6, 15)]],
      sector: [''],
      serviceTypeId: [''],
      technologies: [''],
      status: [true],
      referralSourceId: ['', [Validators.required]],
      hourlyRate: [''],
      logo: [''],
      logoUrl: [''],
      province: [''],
      provinceId: [''],
      email: ['', [Validators.email]],
      companySize: [''],
      clientStatusId: ['', [Validators.required]],
      referee: [''],
      closeReasonId: [''],
      companyId: ['', [Validators.required]],
      //properties for client contact
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      positionId: ['', [Validators.required]],
      contactEmail: ['', [Validators.email]],
      additionalPhoneNumber: ['', [this.validationService.checkLimit(6, 15)]],
      contactPhoneNumber: ['', [this.validationService.checkLimit(6, 15)]],
      whatsapp: ['', [this.validationService.checkLimit(6, 15)]],
      skype: [''],
      linkdin: ['']
    });
    let today = moment();
    let date = moment(today);
    this._client.entrydate = date;
    this.clientFormGroup.controls['entrydate'].setValue(date);
    this.initTagValues();
  }

  onKeyUp(numb) {
      this.lengthNumber = 15;
  }

  async submit(client, clientContact) {
    if (this.isEdit) {
      this.clientFormGroup.controls.name.setValue('valid');
      this.clientFormGroup.controls.positionId.setValue(0);
    }
    if (client.companyId == 0 || client.companyId == null || client.companyId == undefined) {
      this.clientFormGroup.get('companyId').markAsUntouched();
      this.clientFormGroup.get('companyId').setErrors({ 'incorrect': true });
    } else {
      this.clientFormGroup.get('companyId').markAsTouched();
    }
    this.isDisabledClient = false;
    this.isTechNumeric = false;
    this.clientFormGroup.controls.technologies.setValue(this.selectedTechValues);
    this._clientContact = Object.assign({}, clientContact);
    this._clientContact.email = clientContact.email;
    this._clientContact.phoneNumber = clientContact.phoneNumber;


    let clientObj = Object.assign({}, client);
    clientObj.entryDateStr = clientObj.entrydate ? this.formatDatepicker.ConvertDateFormatForSave(clientObj.entrydate) : clientObj.entrydate;
    if (client.clientStatusValue === "0" && this.isClosedClient && (this._client.closeReasonId == undefined || this._client.closeReasonId == null)) {
      this.isCloseReasonRequired = true;
      this.isDisabledClient = true;
      return;
    }
    else this.isCloseReasonRequired = false;

    if (this.clientFormGroup.invalid) {
      this.isDisabledClient = true;
      return;
    }
    clientObj.status = true;
    let objectFix = this.validationService.nullValueSave(this.controlNameArray, clientObj);
    if (this.isEdit) {
      this.updateClientData(objectFix.id, objectFix);
    }
    else {
      this.saveClientData(objectFix);
    }
  }

  saveClientContact(clientContactObj) {
    this.bindToModelClientContact(clientContactObj);
    this.clientService.SaveClientContact(this._clientContactForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.clientService.setRefreshEvents(this.clientService._refreshModal);
          this.clientService.refreshClientContact.emit(res.data);
        }
      },
      () => { console.error(); });
  }

  bindToModelClientContact(clientContactObj) {
    this._clientContactForSave = new ClientContact();
    this._clientContactForSave.clientId = clientContactObj.clientId;
    this._clientContactForSave.name = clientContactObj.name;
    this._clientContactForSave.email = clientContactObj.email;
    this._clientContactForSave.whatsapp = clientContactObj.whatsapp;
    this._clientContactForSave.skype = clientContactObj.skype;
    this._clientContactForSave.linkdin = clientContactObj.linkdin;
    this._clientContactForSave.positionId = clientContactObj.positionId;
    this._clientContactForSave.phoneNumber = clientContactObj.phoneNumber;
    this._clientContactForSave.additionalPhoneNumber = clientContactObj.additionalPhoneNumber;
    this._clientContactForSave.status = clientContactObj.status === true ? 1 : 0;
  }

  saveClientData(clientObj) {
    this.commonUIService.openMask();
    this._clientStatusGlobal = clientObj.clientStatusId;
    const clientStatusObj = this.GetClientStatusById(this._clientStatusGlobal);
    this.BindToModel(clientObj);
    this.clientService.saveClient(this._clientForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledClient = true;
          //save client contact
          this._clientContact.status = true;
          this._clientContact.clientId = res.data.id;
          this.saveClientContact(this._clientContact);

          //save client logo
          if (this._client.fileList && res.data.logo) {
            this.uploadClientLogo(res, clientStatusObj);
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 5000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            let savedClient: any = res.data;
            savedClient.isEdit = false;
            this.clientService.refreshClient.emit(savedClient);
            this.openAddActionPopup(res.data);
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 7000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledClient = true;
        }
        this.commonUIService.closeMask();
      },
      () => {
        console.error();
        this.closeDialog();
        this.commonUIService.closeMask();
      });
  }

  openAddActionPopup(client) {
    this.showNoTaskPopup();
    this.typeGlobal = client.type;
    let today = moment();
    let date = moment(today);
    let action = {
      clientId: client.id,
      actionDate: date,
      userId: this._loggedinUser ? this._loggedinUser.id : null,
      author: this._loggedinUser ? this._loggedinUser.name : null,
      loggedUserId: this._loggedinUser ? this._loggedinUser.id : null
    };
    const dialogViewData = this.commonUIService.setDataForModalDialog(client.id, null, 'actionClient', action, null, null);
    this.commonUIService.openDialog(AddEditActionComponent, dialogViewData);
  }

  showNoTaskPopup() {
    const snack = new SnackBar(SnackBarType.info, 'No tasks for this client', '', 7000);
    this.commonUIService.openSnackBar(snack);
  }

  uploadClientLogo(client, clientStatusObj) {
    this.commonUIService.openMask();

    const formData: FormData = new FormData();
    formData.append('file', this._client.fileList[0], client.data.logo);
    formData.append('folderName', 'Client');
    formData.append('id', client.data.id.toString());
    formData.append('path', 'Logo');
    let savedClient: any = client.data;

    this.clientService.uploadClientLogo(formData).subscribe(
      res => {
        if (+res == 200) {
          if (!this.isEdit) {
            const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            savedClient.clientStatusGlobal = savedClient.clientStatusValue;
            savedClient.isEdit = false;
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Updated successfully', '', 4000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            savedClient.isEdit = true;
            savedClient.clientStatusGlobal = clientStatusObj ? clientStatusObj[0].status : 1;
            savedClient.clientStatusValue = clientStatusObj ? clientStatusObj[0].status.toString() : '1';
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on logo upload', '', 7000);
          this.commonUIService.openSnackBar(snack);
          savedClient.clientStatusGlobal = clientStatusObj ? clientStatusObj[0].status : 1;
          savedClient.clientStatusValue = clientStatusObj ? clientStatusObj[0].status.toString() : '1';
        }
        this.clientService.refreshClient.emit(savedClient);
        this.commonUIService.closeMask();
      },
      () => {
        console.error();
        this.closeDialog();
        this.commonUIService.closeMask();
      });
  }

  updateClientData(clientId, clientObj) {
    this.commonUIService.openMask();
    const clientStatusObj = this.GetClientStatusById(Number(clientObj.clientStatusValue));
    clientObj.clientStatusId = clientStatusObj[0].id;
    clientObj.technologyList = [];
    this.BindToModel(clientObj);
    this._clientForSave.id = clientId;
    this._clientForSave.technologyListForView = [];

    this.clientService.updateClient(clientId, this._clientForSave).subscribe(
      res => {
        if (res.isSuccsess) {          
          this.isDisabledClient = true;
          if (this._client.fileList && res.data.logo) {
            this.uploadClientLogo(res, clientStatusObj);
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            let savedClient: any = res.data;
            savedClient.isEdit = true;
            savedClient.clientStatusGlobal = savedClient.clientStatusValue;          
            let url = this.router.url;
            this.service.isLoading_clientDescriptionAndTechList=true;
            if (url == "/client") {
              //Need for future developments
              //this.sharedClientService.refreshClientForUpdate.emit(savedClient);           
              this.clientService.refreshClient.emit(savedClient);
             
              if (this.clientService.selectedClientAndProjectObject){
                this.clientService.selectedClient.emit(this.clientService.selectedClientAndProjectObject);

              }
            } else if (url == "/task") {
              if (this.clientService.selectedClientAndProjectObject)
                this.clientService.selectedClient.emit(this.clientService.selectedClientAndProjectObject);
              this.clientService.refreshUpdateClient.emit(true);
            } else if (url == "/estimation") {
              this.clientService.refreshClient.emit(savedClient);
            }
          }
        }
        this.commonUIService.closeMask();
      },
      () => {
        console.error();
        this.closeDialog();
        this.commonUIService.closeMask();
      });
  }

  GetClientStatusById(status) {
    let _clientStatusListGlobal = this.commonDropdownService._activeClientStatusList;
    this._currentStatusObj = _clientStatusListGlobal.filter(x => x.status == status);
    return this._currentStatusObj;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  remove(tech: any): void {
    const index = this.chipTechnologies.indexOf(tech);
    if (index >= 0) {
      this.chipTechnologies.splice(index, 1);
      this.updateSelectedTechValues(tech, 1);
      this.updateAutoCompleteList(tech, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, selected: boolean): void {
    if (this.firstOpen == false) {
      this.chipTechnologies.pop();
      this.techUpdateList.pop();
      this.techIdList.pop();
    }
    this.isSelected = selected;
    let listChip: any = [];
    listChip.id = event.option.value;
    listChip.name = event.option.viewValue;
    let color = this.allTechnologies.filter(x => x.id === listChip.id)[0].color;
    let icon = this.allTechnologies.filter(x => x.id === listChip.id)[0].icons;
    listChip.color = color;
    listChip.icons = icon;
    this.chipTechnologies.push(listChip);
    this.updateSelectedTechValues(listChip, 0);
    this.updateAutoCompleteList(listChip, 0);
  }

  onEnter(obj) {
    let val = obj.target.value;
    let numbers = /^[0-9]+$/;
    if (val.match(numbers)) {
      obj.target.value = null;
      this.isTechNumeric = true;
      return;
    }
    if (!val.trim()) {
      obj.target.value = null;
      return;
    }

    this.isTechNumeric = false;
    let listChip: any = [];
    listChip.id = val;
    listChip.name = val;
    obj.target.value = null;
    this.chipTechnologies.push(listChip);
    this.updateSelectedTechValues(listChip, 0);
    this.updateAutoCompleteList(listChip, 0);
    this.firstOpen = false;
  }

  searchDataForAutoComplete(event) {
    if (event && event.code != "Space") {
      techTextChanged.next(event);
    }
  }

  loadDataForAutoComplete(event) {
    let value = event.target.value === '' ? null : event.target.value;
    if (value) {
      this.autoFilteredTechnologies = this.allTechnologies.filter(x => x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    } else {
      this.autoFilteredTechnologies = [];
    }
  }

  updateAutoCompleteList(list, type) {
    const index = this.allTechnologies.findIndex(x => x.id === list.id);
    if (type === 0) this.allTechnologies.splice(index, 1);
    else if (type === 1) this.allTechnologies.push(list);
  }

  updateSelectedTechValues(item, type) {
    if (this.isEdit == true) {
      if (type === 0) {
        this.techUpdateList.push(item.id);
        this.selectedTechValues = this.techUpdateList.length > 0 ? JSON.stringify(this.techUpdateList).replace(/]|[[]|["]/g, '') : '';
      }
      else if (type === 1) {
        const index = this.techUpdateList.indexOf(item.id.toString());
        this.techUpdateList.splice(index, 1);
        this.selectedTechValues = this.techUpdateList.length > 0 ? JSON.stringify(this.techUpdateList).replace(/]|[[]|["]/g, '') : '';
      }
    }
    else {
      if (type === 0) {
        this.techIdList.push(item.id);
      }
      else if (type === 1) {
        const index = this.techIdList.findIndex(x => x === item.id);
        this.techIdList.splice(index, 1);
      }
      this.selectedTechValues = this.techIdList.length > 0 ? JSON.stringify(this.techIdList).replace(/]|[[]|["]/g, '') : '';
    }
    this._client.technologies = this.selectedTechValues;
  }

  setTechnologyValueForEditView() {
    this.chipTechnologies = this._client.technologyListForView.map((t) => t);
    this.techUpdateList = this._client.technologies.split(',');
    this.selectedTechValues = this._client.technologies;
    this._client.technologyListForView.forEach(ele => {
      const index = this.allTechnologies.findIndex(x => x.id === ele.id);
      this.allTechnologies.splice(index, 1);
      this.techIdList.push(ele.id);

    });
    this.autoFilteredTechnologies = this.allTechnologies;
  }

  //#region PASTE IMAGE REGION START
  public handlePaste(event: ClipboardEvent): void {
    let pastedImage = this.getPastedImage(event);
    if (!pastedImage) {
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(pastedImage);
    this.imageName = pastedImage;
    reader.onloadend = () => {
      this.imageFront = reader.result;
      this.imageFileNameFront = this.imageName.name;
      this.imageDataViewForEdit = this.imageFront;
    }
  }

  private getPastedImage(event: ClipboardEvent): File | void {
    if (
      event.clipboardData &&
      event.clipboardData.files &&
      event.clipboardData.files.length &&
      this.isImageFile(event.clipboardData.files[0])
    ) {
      this._client.fileList = event.clipboardData.files;
      return (event.clipboardData.files[0]);
    }
    return (null);
  }

  private isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }
  //#endregion PASTE IMAGE REGION END

  //#region UPLOAD IMAGE REGION START
  changeListenerFront(event: any): void {
    this._client.fileList = event.target.files;
    this.readThisFront(event.target);
  }

  readThisFront(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = () => {
      this.imageFront = myReader.result;
      this.imageFileNameFront = file.name;
      this.imageDataViewForEdit = this.imageFront;
    };
    myReader.readAsDataURL(file);
  }
  //#endregion UPLOAD IMAGE REGION END

  BindToModel(clientObject: Client) {
    this._clientForSave = Object.assign({}, clientObject);
    this._clientForSave.logo = this.imageFileNameFront ? this.imageFileNameFront : this._clientForSave.logo;
    this._clientForSave.logoFamily = this.imageFront;
    this._clientForSave.clientStatusId = Number(clientObject.clientStatusId);
    this._clientForSave.closeReasonId = clientObject.closeReasonId ? Number(clientObject.closeReasonId) : null;
    this._clientForSave.clientStatusValue = clientObject.clientStatusValue ? clientObject.clientStatusValue.toString() : null;
  }

  setInitialClientStatus(status) {
    const clientStatusObj = this.GetClientStatusById(Number(status));
    this._client.clientStatusId = clientStatusObj[0].id;
    this._client.clientStatusValue = clientStatusObj[0].status.toString();
    this.isClosedClient = this._client.clientStatusValue === '0';
    this.isCloseReasonRequired = false;
  }

  loadCloseReasonDropdown(event) {
    if (event && event.source && event.source.selected) {
      let status = Number(event.source.selected.id);
      this._client.clientStatusValue = event.source.selected.id;
      this._client.closeReasonId = status === 0 ? this._client.closeReasonId : null;
      this._client.closeReasonText = status === 0 ? this._client.closeReasonText : null;
      this._client.isStatusChange = this.isEdit && this.initialState !== this._client.clientStatusValue;
      this.isClosedClient = status === 0;
      this.isCloseReasonRequired = this.isClosedClient && (this._client.closeReasonId == undefined || this._client.closeReasonId == null);
    }
  }

  selectCloseReason(event, modal) {
    if (!event) return
    this.isCloseReasonRequired = event.value ? false : true;
  }

  onKeyUpCity(city: string) {
    if (city) {
      if (this.commonDropdownService._provinceDropDown) {
        let province = this.commonDropdownService._provinceDropDown.filter(x => x.value.toLowerCase() == city.toLowerCase());
        if (province.length > 0)
          this._client.province = province[0].value;
        else
          this._client.province = null;
      }
    }
  }

  onKeyPhone(numb, type) {
    if (type === 1) this.lengthNumber1 = 15; //For future ref://numb.charAt(0) == "+" ? 15 : 9;
    if (type === 2) this.lengthNumber2 = 15;
    if (type === 3) this.lengthWhatsappNumber = 15;
  }

  loadCompantList() {
    this.api.get(API.company.listAll)
      .subscribe(response => {      
        this.companyList = response.data;
        if (this.companyList && this.companyList.length > 0) {
          if (this._loggedinUser) {
            if (this._loggedinUser.seeAllBranches == true) {
              let customObj = new Company();
              customObj.abbreviation = null;
              customObj.id = 0;
              customObj.name = "ALL BRANCHES";
              customObj.main = false;
              this.companyList.unshift(customObj);
            } else if (this._loggedinUser.seeAllBranches == false && this._loggedinUser.companyUser.length == 0) {
              this.companyList = [];
              this.companyList.push(this._loggedinUser.company);
              let customObj = new Company();
              customObj.abbreviation = null;
              customObj.id = 0;
              customObj.name = "ALL BRANCHES";
              customObj.main = false;
              this.companyList.unshift(customObj);
            } else if (this._loggedinUser.seeAllBranches == false && this._loggedinUser.companyUser.length > 0) {
              this.companyList = [];
              this._loggedinUser.companyUser.forEach(ele => {
                this.companyList.push(ele.company);
              });
              this.companyList.push(this._loggedinUser.company);
              let customObj = new Company();
              customObj.abbreviation = null;
              customObj.id = 0;
              customObj.name = "ALL BRANCHES";
              customObj.main = false;
              this.companyList.unshift(customObj);
            }
          }
        }
      }, err => {
        console.error(err)
      }, () => { });
  }

  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;

    if (!this.isEdit) {
      if (isValid && control.value) {
        this.isExistingClientName(control.value);
        isValid = !this.isClientNameInUse;
      }
      else {
        isValid = true;
      }
    }
    else {
      this.isExistingClientName(control.value, true, this._clientLegalNameOnEdit);
      isValid = !this.isClientNameInUse;
    }
    return isValid ? null : { 'whitespace': true };
  }

  //To check for client name duplication
  public isExistingClientName(clientName: string, skipCurrentName: boolean = false, currentName: string = "") {

    this.isClientNameInUse = false;
    if (clientName) {
      if (skipCurrentName && currentName != "") //In edit mode do not check duplicate issue against current name
      {
        //NOTE : Do not trim 'in-between' space when checking for duplicate names.Trim only starting and ending space
        let filteredList = this._clientList.filter(x => x.legelName.toString().toLowerCase().trim() != currentName.toLowerCase().trim());
        this.isClientNameInUse = filteredList.findIndex(x => x.legelName.toString().toLowerCase().trim() == clientName.toLowerCase().trim()) != -1;
      }
      else {
        this.isClientNameInUse = this._clientList.findIndex(x => x.legelName.toString().toLowerCase().trim() == clientName.toLowerCase().trim()) != -1;
      }
    }
    else {
      this.isClientNameInUse = false;
    }
  }

  //Load all clients
  public loadAllClients() {
    this.clientService.getAllClients().subscribe(res => {
      this._clientList = res.data;
    }, error => {
      console.log(error);
      this._clientList = [];
    })
  }
}
