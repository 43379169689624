import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ClientService } from '../services/client.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { Project } from '../entities/project';
import { Client } from '../entities/client';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { AddEditProjectComponent } from './add-edit-project/add-edit-project.component';
import { SharedClientService } from '../services/shared.client.service';
import { ProjectViewService } from '../services/project-view.service';
import * as $ from "jquery";
import { Router } from '@angular/router';
import { ClientViewService } from '../services/client-view.service';

@Component({
  selector: 'client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientdetailComponent {

  initialProjectList: Project[];
  activeProjectList: any[] = [];
  inactiveProjectList: any[] = [];
  client: Client;
  selectedProject: Project;
  selectedProjectId: number = 0;
  selectedId: number = 0;
  selectedClientId: number = 0;
  detectChange = false;
  eventValue: any;
  _selectedIndex: number = 0;
  xProjectList: any[] = [];
  taskType: number; 

  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;

  constructor(
    public clientService: ClientService,
    private projectViewService: ProjectViewService,
    public commonUIService: UIService,
    public commonDropdownService: CommonDropdownService,
    public sharedClientService: SharedClientService,
    public cdRef: ChangeDetectorRef,
    public router: Router,
    public clientViewService: ClientViewService,
  ) {
    this.client = new Client();
    this.selectedProject = new Project();
    this.clientService.getLoggedInPermission();
  }

  ngOnInit() {
    this.subscribeSelectedClient();
    this.subscribeDeletedClient();
    this.subscribeDeleteProject();
    this.subscribeRefreshClientViewByCompany();
    this.subscribeRefreshProject();
    this.subscribeDeleteProjectTask();
    this.projectViewService.refreshProjectIndex.subscribe(res => {
      this.refreshProjectOnPlace(res);
    });
  }

  subscribeDeletedClient() {
    this.clientService.refreshDeletedClient.subscribe(() => {
      this.clientService.isSelectedClient = false;
    });
  }

  subscribeRefreshClientViewByCompany() {
    this.sharedClientService.refreshCompanySelect.subscribe(() => {
      this.clientService.isSelectedClient = false;
    });
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

  subscribeSelectedClient() {    
    this.clientService.selectedClient.subscribe(data => {    
      if (data) {     
        if (data.url == undefined || data.url == null) {
          data.url = this.router.url;
        }                
        if (data.url == "/client") {
          if (data.clientTasks && data.clientTasks.length > 0) {
            this.clientService.clientTaskCount = data.clientTasks.length;
          } else {
            this.clientService.clientTaskCount = null;
          }
          this.clientService.isSelectedClient = data.isSelectedItem;
          this.selectedClientId = data.id;
          this.detectChange = !this.detectChange;
          this.clientService.selectedProjectId = -1;          
          this.getProjectListByClientId(data.id);
        } else if (data.url == "/task") {
          if (data.projectId == 0) {
            this.clientService.isSelectedClient = data.isSelectedItem;
            this.selectedClientId = data.clientId;
            this.detectChange = !this.detectChange;
            this.taskType = 1;
            this.getProjectListByClientId(data.clientId);
            this.clientService.clientTaskCount = data.taskcount;            
          } else {
            if (data.projectStatus == 1) {
              this.clientService.isSelectedClient = data.isSelectedItem;
              this.clientService.selectedProjectId =  data.projectId;             
              this.selectedClientId = data.clientId;
              this.detectChange = !this.detectChange;
              this.clientService.clientTaskCount = data.taskcount;
              this.taskType = 2;
              this.getProjectListByClientId(data.clientId);
            } else {
              this.clientService.isSelectedClient = data.isSelectedItem;
              this.selectedId = data.projectId;
              this.selectedClientId = data.clientId;
              this.detectChange = !this.detectChange;
              this.clientService.clientTaskCount = data.taskcount;
              this.taskType = 3;
              this.getProjectListByClientId(data.clientId);
            }
          }
        } else if (data.url == "/estimation") {
          if (data.projectId == 0) {
            this.clientService.isSelectedClient = data.isSelectedItem;
            this.selectedClientId = data.clientId;
            this.detectChange = !this.detectChange;
            this.taskType = 1;
            this.getProjectListByClientId(data.clientId);
             //Don't remove this comments. Need for future developments
            //if (data.project.tasks)
            //  this.clientService.clientTaskCount = data.project.tasks.length;
          } else {
            if (data.projectStatusId == 1) {
              this.clientService.isSelectedClient = data.isSelectedItem;
              this.clientService.selectedProjectId =  data.projectId;           
              this.selectedClientId = data.clientId;
              this.detectChange = !this.detectChange;
               //Don't remove this comments. Need for future developments
              //if (data.project.tasks)
              //  this.clientService.clientTaskCount = data.project.tasks.length;
              this.taskType = 2;
              this.getProjectListByClientId(data.clientId);
            } else {
              this.clientService.isSelectedClient = data.isSelectedItem;
              this.selectedId = data.projectId;
              this.selectedClientId = data.clientId;
              this.detectChange = !this.detectChange;
               //Don't remove this comments. Need for future developments
              //if (data.project.tasks)
              //  this.clientService.clientTaskCount = data.project.tasks.length;
              this.taskType = 3;
              this.getProjectListByClientId(data.clientId);
            }
          }
        }
      }
    });
  }

  async subscribeRefreshProject() {
    this.projectViewService.refreshProject.subscribe(data => {     
      if (data) {          
        this.clientService.selectedProjectId = data.id;
        this.getProjectListByClientId(this.selectedClientId);       
        this.cdRef.detectChanges();
      }
    })
  }

  subscribeDeleteProject() {
    this.projectViewService.refreshDeleteProject.subscribe(() => {
      this.getProjectListByClientId(this.selectedClientId).then();
    })
  }

  async getProjectListByClientId(clientId) {   
    this.commonUIService.openMask();
    this.commonDropdownService.LoadProjectStatusList();
    this.clientService.getProjectListByClientId(clientId).subscribe(res => {
      let urlData = this.router.url;
      if (res) {
        this.activeProjectList = [];
        this.inactiveProjectList = [];
        this.initialProjectList = res.data;
        this.initialProjectList.forEach(ele => {
          let pname = ele.projectName;
          if (pname.length > 30) {
            pname = pname.substring(0, 15);
            ele.projectName = pname + "...";
          }
          let ps = ele.projectStatus.status;
          if (ps === 1 || ps === 2 || ps === 3 || ps === 4 || ps === 5 || ps === 6) {
            this.activeProjectList.push(ele);
            this.xProjectList.push(ele);
            this.activeProjectList.forEach(ele => {
              if (ele.tasks && ele.tasks.length > 0) {
                let xList = ele.tasks.length > 0 ? ele.tasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime()) : ele.tasks;
                ele.projectTaskCount = xList.length;
                if (ele.projectTaskCount == 0) {
                  ele.projectTaskCount = null;
                }
              }
              else
                ele.projectTaskCount = null;
            });
          }
          else {
            this.inactiveProjectList.push(ele);
            this.inactiveProjectList.forEach(ele => {
              if (ele.tasks && ele.tasks.length > 0) {
                let xList = ele.tasks.length > 0 ? ele.tasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime()) : ele.tasks;
                ele.projectTaskCount = xList.length;
                if (ele.projectTaskCount == 0) {
                  ele.projectTaskCount = null;
                }
              }
              else
                ele.projectTaskCount = null;
            });
            this.clientService.clientInactiveProjectList = this.inactiveProjectList;
          }
        });
        if (this.activeProjectList && this.activeProjectList.length > 0) {
          if (this.activeProjectList[0])
            this.activeProjectList[0].colorStyles = { 'background-color': '#0e3d93' };
          if (this.activeProjectList[1])
            this.activeProjectList[1].colorStyles = { 'background-color': '#dd8f00' };
          if (this.activeProjectList[2])
            this.activeProjectList[2].colorStyles = { 'background-color': '#b10064' };
          if (this.activeProjectList[3])
            this.activeProjectList[3].colorStyles = { 'background-color': '#760492' };
          if (this.activeProjectList[4])
            this.activeProjectList[4].colorStyles = { 'background-color': '#ddca00' };
          if (this.activeProjectList[5])
            this.activeProjectList[5].colorStyles = { 'background-color': '#dd3500' };
          this.activeProjectList = this.activeProjectList.map((item, i) => {
            if (i >= 6) {
              item.colorStyles = { 'background-color': '#dd3500' };
            }
            return item;
          })
        }        
        this.clientService.clientActiveProjectList = this.activeProjectList;
        if (urlData == '/client') {
          if (this.clientService.refresPrject) {
            if (this.matTabGroup && this.matTabGroup._tabs)
            {            
              let lastActiveIndex = this.getLastActiveProjectTabIndex(this.matTabGroup._tabs,this.clientService.selectedProjectId);
              this._selectedIndex =lastActiveIndex+1;//this.matTabGroup._tabs.length + 1;
            }              
          }
          //Check if different client is selcted and set the selected tab as Client tab
          this._selectedIndex = this.clientService.isDifferentClientSelected?0:this._selectedIndex ;


          //Don't remove this comments. Need for future developments
          //else {
          //  this.selectedId = 0;
          //  this._selectedIndex = 0;
          //}
        }
        if (urlData == '/task') {
          let index = (this.taskType == 1) ? 1 : (this.taskType == 2)
            ? (this.activeProjectList.findIndex(x => x.id == this.clientService.selectedProjectId) + 2) : (this.activeProjectList.length + 2);
          setTimeout(() => {
            let ele = $("div[aria-posinset='" + index + "']")[0];
            if (ele) ele.click();
            setTimeout(() => {this.commonUIService.closeMask();});
          }, 300);
          if (this.taskType == 3) { // if inactive project
            this.newProjectSelect(this.selectedId);
          }
          }
          if (urlData == '/estimation') {
              let index = (this.taskType == 1) ? 1 : (this.taskType == 2)
                  ? (this.activeProjectList.findIndex(x => x.id == this.clientService.selectedProjectId) + 2) : (this.activeProjectList.length + 2);
              setTimeout(() => { $("div[aria-posinset='" + index + "']")[0].click(); }, 200);
              if (this.taskType == 3) { // if inactive project
                  this.newProjectSelect(this.selectedId);
              }
          }
      }
      if (urlData != '/task')
        this.commonUIService.closeMask();
      
    },
      () => { console.error(); this.commonUIService.closeMask();});
  }

  projectSelect(event, selectedProjectId) {   
    if (event) {
      if (this.clientService.refresPrject == true) {
        if (this.matTabGroup && this.matTabGroup._tabs)
          this.clientService.refresPrject = false;
      }
      else { this._selectedIndex = event.index; }
      const _selectedTab = event.tab._viewContainerRef.element.nativeElement;
      this.clientService.selectedProjectId = _selectedTab.id;     
      this.selectedId = 0;
      this.clientService.refreshFilterClientAndProjectTasks.emit(true);
    } else {
      this.clientService.selectedProjectId = selectedProjectId;     
      this.selectedId = 0;
    }
  }

  newProjectSelect(id) { 
    this.selectedId = id;
    this.clientService.selectedProjectId = id;
  }

  openAddProjectPopup(clientId) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(clientId, null, null, null, null, null);
    this.commonUIService.openDialog(AddEditProjectComponent, dialogViewData)
  }

  refreshProjectOnPlace(res) {    
    if (!res) return;
    this.matTabGroup._tabHeader._inkBar.show();
    if (this.eventValue) {
      this.clientService.selectedProjectId = this.eventValue.tab._viewContainerRef.element.nativeElement.id;    
      this._selectedIndex = this.eventValue.index;
    }
    //Don't remove this comments. Need for future developments
    //else {
    //  this._selectedIndex = 0;
    //}
  }

  subscribeDeleteProjectTask() {
    this.projectViewService.refreshDeleteProjectTask.subscribe(data => {
      if (data == true) {
        this.getProjectListByClientId(this.selectedClientId);
      }
    })
  }

  getLastActiveProjectTabIndex(tabList, currentProjectId) {  
    let lastActiveIndex: number = 0;
    //In 'delete project' scenario the immediate next active tab should be shown
    //If no active tab is present after the deleted project,load the last tab(inactive tab)  
    if (this.clientService.isProjectDeletion) {
      lastActiveIndex = this.clientService.nextActiveProjectIndex == -1 ? tabList.length - 1 : this.clientService.nextActiveProjectIndex;
    }
    else {
      if (this.clientService.isToInActive) {
        lastActiveIndex = tabList.length - 1;
      }
      else {
        lastActiveIndex = this.clientService.clientActiveProjectList.findIndex(x => parseInt(x.id) == parseInt(currentProjectId));
      }
    }
    return lastActiveIndex;

  }

}
