import { Component, OnInit } from '@angular/core';
import { designationModel } from 'src/app/shared/entities/designationModel';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import API from '../../shared/services/api.routes.json';
import { AddEditDesignationComponent } from './add-edit-designation/add-edit-designation.component';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.scss']
})
export class DesignationComponent implements OnInit {

  isLoading: boolean = false;
  _designationList: designationModel[] = [];
  designationList: designationModel[] = [];
  _idSelectedDesignation: number;
  subscriber: any;

  constructor(
    private _apiService: APIService, 
    private uIService: UIService,
    private alertService: AlertService, 
    private maintananceService: MaintenanceService
  ) { }

  ngOnInit() {
    this.loadDesignationList();
    this.SubscribeDesignationList();
    this.maintananceService.subjectDesignationTypeRefresh.subscribe(istrue => {
      if (istrue) {
        this.loadDesignationList();
      }
    })
  }

  loadDesignationList() {
    this.isLoading = true;
    this._apiService.get(API.designation.getAllDesignation).subscribe(response => {
      if (response) {
        if (response.data) {
          this.designationList = response.data;
          this._designationList = this.designationList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    });
  }

  addDesignation() {
    this.uIService.openDialog(AddEditDesignationComponent, null);
  }

  
  editDesignation(data) {
    const setdataDept = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataDept, null, null);
    this.uIService.openDialog(AddEditDesignationComponent, dialogViewData);
  }

  openDeleteAlert(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Designation";
    this._idSelectedDesignation = id;
    this.uIService.openAlert(deleteData);
  }

  activeDeactive(event, model) {
    model.status = event.checked;
    this.maintananceService.updateDesignation(model.id, model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.DesignationTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  SubscribeDesignationList() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete Designation") {
        this.deleteDesignation(this._idSelectedDesignation)
      }
    });
  }

  deleteDesignation(id) {
    this.maintananceService.deleteDesignation(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (res.data) this.loadDesignationList();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }
  

}