
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocomplete, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteSelectedEvent } from '@angular/material';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MaintenanceService } from 'src/app/maintenance/service/maintenance.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { Users } from 'src/app/auth/entities/user';
import { LoginService } from 'src/app/auth/login/login.service';
import moment from 'moment';
import { FilterLaptopDetailModel } from '../../../entities/filterLaptopDetailModel';
import { InventoryService } from '../../../service/inventoryService';
import { APIService } from '../../../../shared/services/api.service';
import { Subscription } from 'rxjs';
import { InventoryModel } from '../../../entities/inventoryModel';
import { SimmModels } from '../../../entities/simm';
import { ProvidersModel } from '../../../entities/providers';
import API from '../../../../shared/services/api.routes.json';

@Component({
  selector: 'filter-laptop-details',
  templateUrl: './filter-laptop-details.component.html',
  styleUrls: ['./filter-laptop-details.component.scss'],
})

export class FilterLaptopDetailComponent implements OnInit {
  laptopFilterFormGroup: FormGroup;
  filterLaptopDetailModel: FilterLaptopDetailModel;
  inventorylist: InventoryModel[] = [];
  laptopBrandlist: any[] = [];
  laptopRamTypelist: any[] = [];

  constructor(
    public commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<FilterLaptopDetailComponent>,
    public inventoryService: InventoryService,
    public commonUIservice: UIService,
    public _apiService: APIService,
    private _formBuilder: FormBuilder) {
    this.inventorylist = [];
    this.filterLaptopDetailModel = new FilterLaptopDetailModel();
    if (this.filterLaptopDetailModel.state == -1) {
      this.filterLaptopDetailModel.state = "Select";
    }
  }

  ngOnInit() {
    this.initDropdownValues();
    this.loadinventorylist(null, 0, null);
    this.formvalidate();
    this.setFilterModelValue();

    if (this.filterLaptopDetailModel.state == undefined)
      this.filterLaptopDetailModel.state = "Select";

    if (this.filterLaptopDetailModel.brand == undefined)
      this.filterLaptopDetailModel.brand = "Select";

    if (this.filterLaptopDetailModel.cpuType == undefined)
      this.filterLaptopDetailModel.cpuType = "Select";

    if (this.filterLaptopDetailModel.ramType == undefined)
      this.filterLaptopDetailModel.ramType = "Select";

    if (this.filterLaptopDetailModel.currentStorageType == undefined)
      this.filterLaptopDetailModel.currentStorageType = "Select";
  }

  initDropdownValues() {
    this.commonDropdownService.LoadInventoryStateDropdown();
    //this.commonDropdownService.LoadInventoryCpuTypDropdown();
  }

  setFilterModelValue() {
    var value = localStorage.getItem('filterStorageModel');
    if (value) {
      var obj = JSON.parse(value);
      this.filterLaptopDetailModel.state = obj && obj.state ? obj.state : 0;
      this.filterLaptopDetailModel.brand = obj && obj.brand ? obj.brand : 0;
      this.filterLaptopDetailModel.cpuType = obj && obj.cpuType ? obj.cpuType : 0;
      this.filterLaptopDetailModel.ramType = obj && obj.ramType ? obj.ramType : 0;
      this.filterLaptopDetailModel.currentStorageType = obj && obj.currentStorageType ? obj.currentStorageType : 0;
    }

  }

  formvalidate() {
    this.laptopFilterFormGroup = this._formBuilder.group({
      state: [''],
      brand: [''],
      cpuType: [''],
      ramType: [''],
      currentStorageType: ['']
    })
  }

  filterLaptopDetails(filterLaptopDetailModel) {
    if (this.filterLaptopDetailModel.state == "Select")
      this.filterLaptopDetailModel.state = -1;
    if (this.filterLaptopDetailModel.brand != "Select" || this.filterLaptopDetailModel.cpuType != "Select" || this.filterLaptopDetailModel.currentStorageType != "Select" ||
      this.filterLaptopDetailModel.ramType != "Select" || this.filterLaptopDetailModel.state != -1) {
      localStorage.setItem('filterStorageModel', JSON.stringify(filterLaptopDetailModel));
      this.inventoryService.isMoreFilterActive = true;
      this.inventoryService.filterLaptopDetails(this.filterLaptopDetailModel.state, this.filterLaptopDetailModel.brand, this.filterLaptopDetailModel.cpuType, this.filterLaptopDetailModel.ramType, this.filterLaptopDetailModel.currentStorageType).subscribe(
        res => {
          if (res) {
            if (res.data) {
              this.inventoryService.refreshLaptopList.emit(res.data);
              this.closeDialog();
            }
          }
        },
        err => {
        });

    } else {
      this.filterLaptopDetailModel.state = "Select";
      const snack = new SnackBar(SnackBarType.error, 'Apply atleast one filter', '', 5000);
      this.commonUIservice.openSnackBar(snack);
      return;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  loadinventorylist(id, index, simProviderIds) {
    let type = index;
    this.inventoryService.getInventoryList(type).subscribe(res => {
      if (res && res.data) {
        this.inventorylist = res.data;
        if (this.inventorylist) {
          this.laptopBrandlist = this.inventorylist.map(m => { if (m.brand) return m.brand; });
          this.laptopRamTypelist = this.inventorylist.map(m => { if (m.ramType) return m.ramType; });
        }
        for (let x = 0; x < this.inventorylist.length; x++) {
          if (this.inventorylist[x].type == "LAPTOP") {
            if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
            else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
          } else {
            this.inventorylist[x].statestr = null;
          }
        }
      }
      else {
        this.inventorylist = null;
      }
    });
  }

  exportLaptopDetailsAsXLSX(): void {
    if (this.filterLaptopDetailModel.state == "Select")
      this.filterLaptopDetailModel.state = -1;

    if (this.filterLaptopDetailModel) {
      const params = new URLSearchParams();
      for (const key in this.filterLaptopDetailModel) {
        params.set(key, this.filterLaptopDetailModel[key])
      }
      const query = params.toString().replace('+', '%20')
      var url = API.baseUrl + `/api/Inventory/LaptopDetailExcelExport?${query}`;
      window.open(url, '_blank');
    } else {
      return;
    }
  }

  clearLaptopDetails() {
    localStorage.removeItem("filterStorageModel");
    this.inventoryService.isMoreFilterActive = false;
    this.inventoryService.clearLaptopList.emit(true);
    this.closeDialog();
  }
}
