import { Component, OnInit, Type } from '@angular/core';
import { ErmService } from '../erm/service/erm.service';
import { SnackBar, SnackBarType } from '../shared/components/snackbar/entities/snackbar';
import { UIService } from '../shared/services/ui.service';

@Component({
  selector: 'app-year-end-process',
  templateUrl: './year-end-process.component.html',
  styleUrls: ['./year-end-process.component.scss']
})
export class YearEndProcessComponent implements OnInit {

  leaveAllocationList: any = [];
  selectedLeaveObj: any;

  constructor(public ermService: ErmService, private commonUIService: UIService,) { }

  ngOnInit() {
    this.loadEmployeeLeaveAllocation((new Date()).getFullYear());
  }

  selectLeave(leaveId: any) {
    this.selectedLeaveObj = this.leaveAllocationList.find(leave => leave.id === leaveId);
  }

  loadEmployeeLeaveAllocation(year) {
    this.ermService.getEmployeeLeaveAllocation(year).subscribe(res => {
      if (res && res.data) {
        this.leaveAllocationList = [];
        this.leaveAllocationList = res.data;
      }
      else {
        this.leaveAllocationList = [];
      }
    });
  }

  calculateYearEnd() {

    this.ermService.calculateYearEndProcess().subscribe(res => {
      
      if (res.isSuccsess && res.data) {
        this.loadEmployeeLeaveAllocation((new Date()).getFullYear());
        const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
        this.commonUIService.openSnackBar(snack);

      }
      else if (!res.isSuccsess) {
        const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
        this.commonUIService.openSnackBar(snack);

      }
      else if (!res.data) {
        const snack = new SnackBar(SnackBarType.error, 'There is no data to update', '', 4000);
        this.commonUIService.openSnackBar(snack);

      }
    });
  }
}
