import { Pipe, LOCALE_ID, Inject } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'MangoCurrency'
})

export class MangoCurrencyPipe implements PipeTransform {
  localeId: string;
  constructor(@Inject(LOCALE_ID) public locale: string, private decimalPipe: DecimalPipe) {
    this.localeId = locale;
  }

  transform(value: any) {
    if ((value === undefined || value === null)) {
      return value;
    } else {
      if (this.locale === 'es') {
        value = this.decimalPipe.transform(value, '1.2-2');
        return value + ' ' + '€';
      } else {
        value = this.decimalPipe.transform(value, '1.2-2', 'en-US');
        const chars = value.toString().split('.');
        return this.replaceGTS(chars[0], '.') + ',' + chars[1] + ' ' + '€';
      }
    }
  }

  /**
   * Replace the generic thousand seperator. which is comma.
   * @param val Comma replacing values
   */
  replaceGTS(val, replacement) {
    return val.replace(/,/g, replacement);
  }
}
