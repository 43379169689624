import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig, MatDialogConfig } from '@angular/material';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { SnackBar, SnackBarType } from '../components/snackbar/entities/snackbar';
import { DialogPopupModel } from '../entities/DialogPopupModel';
import { AlertModel } from '../entities/alertModel';
import { AlertPopupComponent } from '../components/alert-popup/alert-popup.component';
import * as $ from "jquery";
import * as moment from 'moment';
import { Users } from 'src/app/auth/entities/user';

export interface DialogDataForModalView {
  data: DialogPopupModel;
}

export interface DialogDataForAlertView {
  data: AlertModel;
}

@Injectable({
  providedIn: 'root'
})

export class UIService {
  durationInSeconds: 5;
  _dateLabelsList: any = [];
  _postponedDateList: any = [];
  _reminderdateListList: any = [];
  maskCount: number = 0;

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar) {
  }

  openSnackBar(snack: SnackBar) {
    const classname = snack.type === SnackBarType.error ?
      'snackbar-error' : snack.type === SnackBarType.success ? 'snackbar-success' : 'snackbar-info';
    const duration = snack.duration;
    const horizontalPosition = snack.horizontalPosition;
    const data = {
      message: snack.message,
      icon: snack.icon
    };

    this.snackBar.openFromComponent(SnackbarComponent, {
      duration,
      horizontalPosition,
      panelClass: classname,
      data
    });
  }

  openDialog(component, data?) {
    const dialogRef = this.dialog.open(component, {
      data: { data },
      panelClass: 'form-dialog-modal',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
           
    });
  }

  setDataForModalDialog(id, status, type, modalData, projectId, companyId) {
    const viewData = new DialogPopupModel();
    viewData.id = id;
    viewData.status = status;
    viewData.type = type;
    viewData.modalData = modalData;
    viewData.projectId = projectId;
    viewData.companyId = companyId;
    return viewData;
  }

  openAlert(data) {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { data },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openMask(appmaskText = "") {
    this.maskCount++;
    $('#app_mask').fadeIn();
    $('#app_mask_text').text(appmaskText);
  }
  closeMask() {
    if (this.maskCount > 0) this.maskCount--;
    if (this.maskCount == 0) $('#app_mask').fadeOut();
  }
  public goToWebsite(link) {
    let url = link.includes("://") ? link : "https://" + link;
    window.open(url, '_system');
    return false;
  }
  public loadTaskDateLabels() {
    this._dateLabelsList = [
      { id: 1, date: `${this.getDateValueForTask('Today')}`, label: '*', hint: 'Today' },
      { id: 2, date: `${this.getDateValueForTask('Tomorrow')}`, label: '1d', hint: 'Tomorrow' },
      { id: 3, date: `${this.getDateValueForTask('Day after tomorrow')}`, label: '2d', hint: 'Day after tomorrow' },
      { id: 4, date: `${this.getDateValueForTask('Monday')}`, label: 'Mo', hint: 'Monday' },
      { id: 5, date: `${this.getDateValueForTask('Tuesday')}`, label: 'Tu', hint: 'Tuesday' },
      { id: 6, date: `${this.getDateValueForTask('Wednesday')}`, label: 'We', hint: 'Wednesday' },
      { id: 7, date: `${this.getDateValueForTask('Thursday')}`, label: 'Th', hint: 'Thursday' },
      { id: 8, date: `${this.getDateValueForTask('Friday')}`, label: 'Fr', hint: 'Friday' },
      { id: 9, date: `${this.getDateValueForTask('One week')}`, label: '1w', hint: 'One week' },
      { id: 10, date: `${this.getDateValueForTask('Two weeks')}`, label: '2w', hint: 'Two weeks' },
      { id: 11, date: `${this.getDateValueForTask('Three weeks')}`, label: '3w', hint: 'Three weeks' },
      { id: 12, date: `${this.getDateValueForTask('One month')}`, label: '1m', hint: 'One month' },
      { id: 13, date: `${this.getDateValueForTask('Two months')}`, label: '2m', hint: 'Two months' },
      { id: 14, date: `${this.getDateValueForTask('Three months')}`, label: '3m', hint: 'Three months' },
      { id: 15, date: `${this.getDateValueForTask('Six months')}`, label: '6m', hint: 'Six months' },
    ]
  }

  public loadPostponeDateList() {
    this._postponedDateList = [
      { id: 1, date: `${this.getDateValueForTask('Tomorrow')}`, label: 'Tomorrow', hint: 'Tomorrow' },
      { id: 2, date: `${this.getDateValueForTask('Day after tomorrow')}`, label: 'In 2 days', hint: 'Day after tomorrow' },
      { id: 3, date: `${this.getDateValueForTask('One week')}`, label: '1 week', hint: 'One week' },
      { id: 4, date: `${this.getDateValueForTask('Two weeks')}`, label: '2 weeks', hint: 'Two weeks' },
      { id: 5, date: `${this.getDateValueForTask('One month')}`, label: '1 month', hint: 'One month' },
      { id: 6, date: `${this.getDateValueForTask('Two months')}`, label: '2 months', hint: 'Two months' },
      { id: 7, date: `${this.getDateValueForTask('Three months')}`, label: '3 months', hint: 'Three months' },
      { id: 8, date: `${this.getDateValueForTask('Six months')}`, label: '6 months', hint: 'Six months' },
    ]
  }

  public getDateValueForTask(text) {
    let today = moment();
    let date: any;
    switch (text) {
      case "Today":
        date = moment(today);
        break;
      case "Tomorrow":
        date = moment(today).add(1, 'days');
        break;
      case "Day after tomorrow":
        date = moment(today).add(2, 'days');
        break;
      case "Monday":
        date = moment(today).day() === 1 || today.day() > 1 ? moment(today).day(8) : moment(today).day(1);
        break;
      case "Tuesday":
        date = moment(today).day() === 2 || today.day() > 2 ? moment(today).day(9) : moment(today).day(2);
        break;
      case "Wednesday":
        date = moment(today).day() === 3 || today.day() > 3 ? moment(today).day(10) : moment(today).day(3);
        break;
      case "Thursday":
        date = moment(today).day() === 4 || today.day() > 4 ? moment(today).day(11) : moment(today).day(4);
        break;
      case "Friday":
        date = moment(today).day() === 5 || today.day() > 5 ? moment(today).day(12) : moment(today).day(5);
        break;
      case "One week":
        date = moment(today).add(7, 'days');
        break;
      case "Two weeks":
        date = moment(today).add(14, 'days');
        break;
      case "Three weeks":
        date = moment(today).add(21, 'days');
        break;
      case "One month":
        date = moment(today).add(1, 'months');
        break;
      case "Two months":
        date = moment(today).add(2, 'months');
        break;
      case "Three months":
        date = moment(today).add(3, 'months');
        break;
      case "Six months":
        date = moment(today).add(6, 'months');
        break;
    }
    //Check if first date starts on weekends
    if (date.isValid()) {
      if (date.day() === 6) { //Saturday
        //Move date to current week friday
        date = date.day(5);
      } else if (date.day() === 0) { //Sunday
        //Move date to next week monday
        date = date.day(1);
      }
      date = date.format('DD/MM/YYYY');
    }

    return date;
  }
  delay = (function () {
    let timer: any = 0;
    return function (callback: any, ms: any) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();
  public getInitials(name) {
    let initial = null;
    if (name != null) {
      var matches = name.match(/\b(\w)/g);
      initial = matches.join('');
    }
    return initial;
  }


  public loadReminderDateList() {
    this._reminderdateListList = [
      { id: 1, date: `${this.getDateValueForReminder('Tomorrow')}`, label: 'Tomorrow', hint: 'Tomorrow' },
      { id: 2, date: `${this.getDateValueForReminder('2 days')}`, label: '2 days', hint: '2 days' },
      { id: 3, date: `${this.getDateValueForReminder('3 days')}`, label: '3 days', hint: '3 days' },
      { id: 4, date: `${this.getDateValueForReminder('One week')}`, label: 'One week', hint: 'One week' },
      { id: 5, date: `${this.getDateValueForReminder('Two weeks')}`, label: 'Two weeks', hint: 'Two weeks' },
      { id: 6, date: `${this.getDateValueForReminder('One month')}`, label: '1 month', hint: 'One month' },
      { id: 7, date: `${this.getDateValueForReminder('Two months')}`, label: '2 months', hint: 'Two months' },
      { id: 8, date: `${this.getDateValueForReminder('Three months')}`, label: '3 months', hint: 'Three months' },
      { id: 9, date: `${this.getDateValueForReminder('Six months')}`, label: '6 months', hint: 'Six months' }
    ]
  }

  public getDateValueForReminder(text) {
    let today = moment();
    let date: any;
    switch (text) {
      case "Tomorrow":
        date = moment(today).add(1, 'days');
        break;
      case "2 days":
        date = moment(today).add(2, 'days');
        break;
      case "3 days":
        date = moment(today).add(3, 'days');
        break;
      case "One week":
        date = moment(today).add(7, 'days');
        break;
      case "Two weeks":
        date = moment(today).add(14, 'days');
        break;
      case "One month":
        date = moment(today).add(1, 'months');
        break;
      case "Two months":
        date = moment(today).add(2, 'months');
        break;
      case "Three months":
        date = moment(today).add(3, 'months');
        break;
      case "Six months":
        date = moment(today).add(6, 'months');
        break;
    }
    //Check if first date starts on weekends
    if (date.isValid()) {
      if (date.day() === 6) { //Saturday
        //Move date to next week monday
        date = date.day(1);
        date = moment(date).add(7, 'days');
      } else if (date.day() === 0) {
        date = date.day(1);
        //date = moment(date).add(7, 'days');
      }

      date = date.format('DD/MM/YYYY');
    }
    return date;
  }
}
