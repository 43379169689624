export class EstimationDocument {
  id: number;
  estimationPhaseId: number;
  generatedFileName: string;
  fileName: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  googleDriveId: string;
  googleDriveUrl: string;
}
