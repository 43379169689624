export class ActionClient {
  id: number;
  actionTypeId: number;
  clientId: number;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: any;
  type: string;
  who: string;
  actionDate: Date;
  actionName: string;
  comment: string;
  userId: number;
  answer: string;
  author: string;
  initials: any;
  actionUserImageUrl: string;
  doneTaskId?: number;
  nextTaskTypeId?:number;
  nextTaskDate?: any;
  nextTaskUserId?:number;
  nextTaskTime?: any;
  nextTaskDescription: string;
  nextTaskDateStr: string;
  nextTaskTimeStr?:any;
  loggedUserId:any;
  actionTypeName: string;
  actionType: any;
  text: string;
  characterLength: any;
  showMore: any = false;
}
