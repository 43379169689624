import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatInputModule } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';
import { LeaveComponent } from './leave.component';
import { AddLeaveComponent } from './components/add-leave/add-leave.component';
import { CancelLeaveComponent } from './components/cancel-leave/cancel-leave.component';
import { FilterLeaveComponent } from './components/filter/filter-leave.component';
import { TimelineComponent } from '../shared/components/timeline/timeline.component';

@NgModule({
  declarations: [
    LeaveComponent,
    AddLeaveComponent,
    FilterLeaveComponent,
    CancelLeaveComponent,
    TimelineComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    AngularMaterialModule,
    FullCalendarModule,
    NgxDropzoneModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [
    AddLeaveComponent,
    FilterLeaveComponent,
    CancelLeaveComponent,
  ],
})
export class LeaveModule {}
