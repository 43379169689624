import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { InventoryRepair } from 'src/app/inventory/entities/inventoryRepair';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-add-inventory-repair',
  templateUrl: './add-inventory-repair.component.html',
  styleUrls: ['./add-inventory-repair.component.scss']
})
export class AddInventoryRepairComponent implements OnInit {
  repairFormGroup: FormGroup;
  inventoryId: number = 0;
  inventoryRepairModel: InventoryRepair;
  inventoryRepairModelForSave: InventoryRepair;

  constructor(public dialogRef: MatDialogRef<AddInventoryRepairComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private _formBuilder: FormBuilder,
    public commonDropdownService: CommonDropdownService,
    private formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private inventoryService: InventoryService,
    private commonUIService: UIService) {
    this.inventoryRepairModel = new InventoryRepair();
    this.inventoryRepairModelForSave = new InventoryRepair();
  }

  ngOnInit() {
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
    this.initFormGroup();
  }


  initFormGroup() {
    this.repairFormGroup = this._formBuilder.group({
      sendDate: ['', [Validators.required]],
      providerId: [''],
      details: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      repairDate: [''],
      cost: [''],
      currency: [''],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  submit(model: InventoryRepair) {
    if (this.repairFormGroup.invalid) {
      return;
    }
    this.inventoryRepairModelForSave = Object.assign({}, model);
    this.inventoryRepairModelForSave.sendDateStr = model.sendDate ? this.formatDatepicker.ConvertDateFormatForSave(model.sendDate) : model.sendDate;
    this.inventoryRepairModelForSave.repairDateStr = model.repairDate ? this.formatDatepicker.ConvertDateFormatForSave(model.repairDate) : model.repairDate;
    this.saveInventoryRepair()
  }

  saveInventoryRepair() {
    this.commonUIService.openMask();
    if (this.modalViewData) {
      this.inventoryRepairModelForSave.inventoryId = this.modalViewData.data.id;
      this.inventoryService.saveInventoryRepair(this.inventoryRepairModelForSave).subscribe(res => {
        if (res && res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.inventoryService.refreshInventoryRepair.emit(true);
          this.closeDialog();
          this.commonUIService.closeMask();
        }
        else {
          const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.commonUIService.closeMask();
        }
      });
    }
  }
}
