import { Component, OnInit } from '@angular/core';
import API from '../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';
import { UserModal } from 'src/app/client/entities/user';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  userList: UserModal[] = [];
  constructor(private _apiService: APIService, ) {
    this.userList = [];
  }

  ngOnInit() {
  }
}
