import { Component, OnInit, ElementRef, Inject, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { HrmService } from '../../service/hrm.service';
import { Employee } from '../../entities/empoyee';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { of, Subject } from 'rxjs';
import { Users } from 'src/app/auth/entities/user';
import { Observable } from 'rxjs-compat';

@Component({
  selector: 'add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
  host: {
    "(window:paste)": "handlePaste( $event )"
  },
})

export class AddEmployeeComponent implements OnInit {
  @ViewChild("managerSearch",{ static: true }) managerSearch:ElementRef;

  employee: Employee;
  employeeFormGroup: FormGroup;
  lengthNumber: number = 12;
  employeeForSave: Employee;
  isDisabledEmployee = true;
  userRoleMultiSelectList: string;
  companyMultiSelectList: string[];
  imageDataViewForEdit: any;
  imageFront: any;
  imageFileNameFront: any;
  imageName: any;
  firstOpen: boolean = true;
  _userModal: Users;
  empNoInDb:string;
  emailInDb:string;
  NicInDb:string;
  list = [];
  listbackup = [];
  isFutureDate:boolean

  public _subCompanyList: any = [];
  private _unsubscribeAll: Subject<any>;
  
  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public currentconpanyData: DialogDataForModalView) {
    this.employee = new Employee();
    this.employeeForSave = new Employee();
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.commonDropdownService.LoadDesignationList();
    this.commonDropdownService.LoadDepartmentList();
    this.commonDropdownService.LoadRolesList();
    this.commonDropdownService.LoadCompantList();
    this.commonDropdownService.LoadEmployeeTypesList();
    this.commonDropdownService.LoadEmployeeList();
    this.initEmployeeDetailForm();
    this._userModal = new Users();
    this.specialMehodsForEdit();
  }

  searchManagers()
  {
    const searchInput=this.managerSearch.nativeElement.value ?
      this.managerSearch.nativeElement.value.toLowerCase():'';

      this.commonDropdownService._employeeListBkp =this.commonDropdownService._employeeList.filter(m=>{
      if(m.firstName!=null || m.lastName!=null)
      {
        const viewValue : string=m.firstName.toLowerCase() + m.lastName.toLowerCase();
        
        return viewValue.indexOf(searchInput) > -1;
      }
    })
  }

  specialMehodsForEdit()
  {
    if (this.currentconpanyData.data.id > 0 && this.hrmService.isEmployeeEdit) {
      this.employeeFormGroup.get('userRoleMultiSelectList').clearValidators();
      this.employeeFormGroup.get('userRoleMultiSelectList').updateValueAndValidity();
      this.employeeFormGroup.get('companyId').clearValidators();
      this.employeeFormGroup.get('companyId').updateValueAndValidity();
      this._userModal.employeesTypeId=this.currentconpanyData.data.modalData.employeesType.id;
      this._userModal.initRepManager=this.currentconpanyData.data.modalData.initRepManager;
      this._userModal.gender=this.currentconpanyData.data.modalData.gender;
      this.empNoInDb=this.currentconpanyData.data.modalData.employeeNumber;
      this.emailInDb=this.currentconpanyData.data.modalData.email;
      this.NicInDb=this.currentconpanyData.data.modalData.nic;
      
    }
  }

  initEmployeeDetailForm() {
    this.employeeFormGroup = this.fb.group({
      id: [''],
      firstName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      lastName: ['', [this.validationService.noWhitespaceValidator]],
      employeeNumber: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      nic: [''],
      dob: ['',[Validators.required]],
      designation: [''],
      gender: [''],
      department: [''],
      jointDate: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone1: ['',[Validators.required,Validators.pattern("^[0-9]*$"),
      Validators.minLength(9), Validators.maxLength(10)]],
      address: [''],
      phone2: ['',[this.validationService.checkLimit(6,15)]],
      relationShip: [''],
      emergencyContactPersonName: [''],
      isProbationCompleted: [false],
      isContractCompleted: [false],
      userRoleMultiSelectList: ['', [Validators.required]],
      companyId: ['', [Validators.required]],
      companyMultiSelectList: [''],
      seeAllBranches: [''],
      employeesTypeId : ['', [Validators.required]],
      initRepManager : ['', [Validators.required]],
      isOutsourceAccess: [false],
    });
    if (this.currentconpanyData.data.modalData != null || this.currentconpanyData.data.modalData != undefined) {
      this.setValuesForEdit();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onKey(numb) {
    if (numb.charAt(0) == "+") {

      this.lengthNumber = 15
    }
    else {
      this.lengthNumber = 15;
    }
  }

  setValuesForEdit() {
    this.employee = this.currentconpanyData.data.modalData;
    this.imageDataViewForEdit = this.employee.prolfileImage;
  }

  changeListenerFront(event: any): void {
    let files: any = event.target.files;
    this.employee.fileList = files;
    this.readThisFront(event.target);
  }

  readThisFront(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.imageFront = myReader.result;
      this.imageFileNameFront = file.name;
      this.imageDataViewForEdit = this.imageFront;
    }
    myReader.readAsDataURL(file);
  }

  async submit(employeeModel) {
    this.isDisabledEmployee = false;
    if (this.employeeFormGroup.invalid || this.employeeFormGroup.pending) {
      this.isDisabledEmployee = true;
      return;
    }
    if (this.currentconpanyData.data.id > 0 && this.hrmService.isEmployeeEdit) {
      let employeeObj = Object.assign({}, employeeModel);
      employeeObj.dobStr = employeeObj.dob ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.dob) : employeeObj.dob;
      employeeObj.jointDateStr = employeeObj.jointDate ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.jointDate) : employeeObj.jointDate;
      this.updateEmployee(employeeObj);
    } else {
      let employeeObj = Object.assign({}, employeeModel);
      employeeObj.dobStr = employeeObj.dob ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.dob) : employeeObj.dob;
      employeeObj.jointDateStr = employeeObj.jointDate ? this.formatDatepicker.ConvertDateFormatForSave(employeeObj.jointDate) : employeeObj.jointDate;
      this.addEmployee(employeeObj);
    }
  }

  updateEmployee(employeeObj) {
    this.bindToModel(employeeObj);
    this.hrmService.updateEmployee(employeeObj.id, this.employeeForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEmployee = true;
          if (this.employee.fileList && res.data.profileFileName) {
            this.uploadEmployeeProfileImage(res);
          }
          else {
            this.hrmService.setRefreshemployee(employeeObj);
            const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
            this.commonUIService.openSnackBar(snack);
            let savedEmployee: any = res.data;
            this.closeDialog();
            this.employee.showEditSave = false;
            this.employee.isEdit = true;
            this.employeeFormGroup.disable();
            this.hrmService.refershListEmloyee.emit(savedEmployee);
            this.hrmService.refreshEmployee.emit(savedEmployee);
            this.hrmService.selectedEmployee.emit(savedEmployee);
          }
        } else {
          this.isDisabledEmployee = true;
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); this.isDisabledEmployee = true;});
  }

  addEmployee(employeeObj) {
    this.bindToModel(employeeObj);
    this.hrmService.addEmployee(this.employeeForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEmployee = true;
          if (this.employee.fileList && res.data.profileFileName) {
            this.uploadEmployeeProfileImage(res);
          }
          else {
            const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
            this.commonUIService.openSnackBar(snack);
            this.closeDialog();
            let savedEmployee: any = res.data;
            savedEmployee.isEdit = true;
            savedEmployee.showEditSave = false;
            this.hrmService.refershListEmloyee.emit(savedEmployee);
            this.hrmService.refreshEmployee.emit(savedEmployee);
            this.hrmService.selectedEmployee.emit(savedEmployee);
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEmployee = true;
        }
      },
      () => { console.error(); this.closeDialog(); this.isDisabledEmployee = true;});
  }

  bindToModel(employeeObj) {
    this.employeeForSave = Object.assign({}, employeeObj);
    this.employeeForSave.profileFileName = this.imageFileNameFront ? this.imageFileNameFront : this.employeeForSave.profileFileName;
    this.employeeForSave.profilePathForView = this.imageFront;
    this.setSeeAllBranches();
    this.employeeForSave.companyMultiSelectList=this.companyMultiSelectList;
    this.employeeForSave.userRoleMultiSelectList=this.userRoleMultiSelectList;
    this.employeeForSave.seeAllBranches=this._userModal.seeAllBranches;
    this.employeeForSave.companyId=this._userModal.companyId;
    this.employeeForSave.employeesTypeId=this._userModal.employeesTypeId;
    this.employeeForSave.initRepManager=this._userModal.initRepManager;
    this.employeeForSave.gender=this._userModal.gender;

    if (this.currentconpanyData.data.companyId !== null) {
      this.employeeForSave.companyId = this.currentconpanyData.data.companyId ? this.currentconpanyData.data.companyId : 0;
    }
  }

  setSeeAllBranches()
  {
    if (this._userModal.seeAllBranches) {
      this.companyMultiSelectList = [];
      for (let c of this.commonDropdownService._companyList) {
        this.companyMultiSelectList.push(c.id);
      }
    };
  }

  uploadEmployeeProfileImage(employee) {
    const formData: FormData = new FormData();
    formData.append('file', this.employee.fileList[0], employee.data.logo);
    formData.append('folderName', 'Employee');
    formData.append('id', employee.data.id.toString());
    formData.append('path', 'Profile');
    let savedEmployee: any = employee.data;
    this.hrmService.uploadEmployeeProfileImage(formData).subscribe(
      res => {
        if (+res == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          savedEmployee.isEdit = true;
          savedEmployee.showEditSave = false;
          this.hrmService.refershListEmloyee.emit(savedEmployee);
          this.hrmService.refreshEmployee.emit(savedEmployee);
          this.hrmService.selectedEmployee.emit(savedEmployee);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on profile image upload', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.hrmService.refershListEmloyee.emit(savedEmployee);
          this.hrmService.refreshEmployee.emit(savedEmployee);
          this.hrmService.selectedEmployee.emit(savedEmployee);
        }
      },
      error=> { console.log(error); this.closeDialog();})
  }

  changeCompany() {
    if (this._userModal.companyId) {
      this.hrmService.getAllCompanyData()
        .subscribe(response => {
          if (response && response.data) {
            this._subCompanyList = response.data;
            let index1 = this._subCompanyList.findIndex(s => s.id == this._userModal.companyId);
            if (index1 !== -1) {
              this._subCompanyList.splice(index1, 1);
            }
          }
        }, err => {
          console.error(err)
        }, () => { });
    }
  }

  seelAllBranchesCheckEvent(event) {
    this._userModal.seeAllBranches = event.checked;
  }

  inputValidation(event,input)
  {
    if(this.currentconpanyData.data.id > 0 && this.hrmService.isEmployeeEdit)
    { 
      this.hrmService.employeeNumberValidation(event.target.value,input).subscribe(res=>{
        if(input=='EMPNO'){
          if(this.empNoInDb!=event.target.value)
          {
            if(res.data)
            {
              this.employeeFormGroup.controls.employeeNumber.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.employeeNumber.setErrors(null);
              this.employeeFormGroup.controls.employeeNumber.updateValueAndValidity();
            }
          }
        }else if(input=='NIC'){
          if(this.NicInDb!=event.target.value)
          {
            if(res.data)
            {
              this.employeeFormGroup.controls.nic.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.nic.setErrors(null);
              this.employeeFormGroup.controls.nic.updateValueAndValidity();
            }
          }
        }else if(input=='EMAIL'){
          if(this.emailInDb!=event.target.value)
          {
            if(res.data)
            {
              this.employeeFormGroup.controls.email.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.email.setErrors(null);
              this.employeeFormGroup.controls.email.updateValueAndValidity();
            }
          }
        }
      });
    }else{
        if(event.target.value==null)
        return;
        this.hrmService.employeeNumberValidation(event.target.value,input).subscribe(res=>{
          if(input=='EMPNO'){
            if(res.data)
            {
              this.employeeFormGroup.controls.employeeNumber.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.employeeNumber.setErrors(null);
              this.employeeFormGroup.controls.employeeNumber.updateValueAndValidity();
            }
          }else if(input=='NIC'){
            if(res.data)
            {
              this.employeeFormGroup.controls.nic.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.nic.setErrors(null);
              this.employeeFormGroup.controls.nic.updateValueAndValidity();
            }
          }else if(input=='EMAIL'){
            if(res.data)
            {
              this.employeeFormGroup.controls.email.setErrors({valueExistsInDB:true});
            }else{
              this.employeeFormGroup.controls.email.setErrors(null);
              this.employeeFormGroup.controls.email.updateValueAndValidity();
            }
          }
        });
    }
    if(input=='DOB') {
      let currentDate = new Date();
      let DOB = new Date(event.target.value);

      if(this.isFutureDate = DOB>currentDate){
        this.employeeFormGroup.controls.dob.setErrors({futureDate:true});
      }else{
        this.employeeFormGroup.controls.dob.setErrors(null);
      }
      this.employeeFormGroup.controls.email.updateValueAndValidity();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
