import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APIService } from 'src/app/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryMaintenanceService {

  public subjectCommonConditionRefresh = new Subject<any>();
  public subjectLaptopCPURefresh = new Subject<any>();
  public subjectLaptopKeyboardTypeRefresh = new Subject<any>();
  public subjectLaptopMouseTypeRefresh = new Subject<any>();
  public subjectLaptopOsTypeRefresh = new Subject<any>();

  public subjectLaptopScreenResTypeRefresh = new Subject<any>();
  public subjectLaptopScreenSizeTypeRefresh = new Subject<any>();
  public subjectMiscellaneousTypeRefresh = new Subject<any>();
  public subjectLaptopStorageTypeRefresh = new Subject<any>();

  public subjectMobileDeviceOsTypeRefresh = new Subject<any>();
  public subjectMobileDeviceTypeRefresh = new Subject<any>();
  public subjectMonitorOsRefresh = new Subject<any>();
  public subjectMonitorResolutionRefresh = new Subject<any>();
  public subjectMonitorScreenSizeRefresh = new Subject<any>();
  public subjectMonitorTypeRefresh = new Subject<any>();


  constructor(private apiservice: APIService) { }

  async commonConditionRefresh(isRefresh: any) {
    this.subjectCommonConditionRefresh.next(isRefresh);
  }

  async laptopCPURefresh(isRefresh: any) {
    this.subjectLaptopCPURefresh.next(isRefresh);
  }

  async laptopKeyboardTypeRefresh(isRefresh: any) {
    this.subjectLaptopKeyboardTypeRefresh.next(isRefresh);
  }

  async laptopMouseTypeRefresh(isRefresh: any) {
    this.subjectLaptopMouseTypeRefresh.next(isRefresh);
  }

  async laptopOsTypeRefresh(isRefresh: any) {
    this.subjectLaptopOsTypeRefresh.next(isRefresh);
  }

  async laptopScreenResTypeRefresh(isRefresh: any) {
    this.subjectLaptopScreenResTypeRefresh.next(isRefresh);
  }

  async laptopScreenSizeTypeRefresh(isRefresh: any) {
    this.subjectLaptopScreenSizeTypeRefresh.next(isRefresh);
  }

  async laptopStorageTypeRefresh(isRefresh: any) {
    this.subjectLaptopStorageTypeRefresh.next(isRefresh);
  }

  async miscellaneousTypeRefresh(isRefresh: any) {
    this.subjectMiscellaneousTypeRefresh.next(isRefresh);
  }

  async mobileDeviceTypeRefresh(isRefresh: any) {
    this.subjectMobileDeviceTypeRefresh.next(isRefresh);
  }

  async mobileDeviceOsTypeRefresh(isRefresh: any) {
    this.subjectMobileDeviceOsTypeRefresh.next(isRefresh);
  }

  async monitorOsRefresh(isRefresh: any) {
    this.subjectMonitorOsRefresh.next(isRefresh);
  }

  async monitorResolutionRefresh(isRefresh: any) {
    this.subjectMonitorResolutionRefresh.next(isRefresh);
  }

  async monitorScreenSizeRefresh(isRefresh: any) {
    this.subjectMonitorScreenSizeRefresh.next(isRefresh);
  }

  async monitorTypeRefresh(isRefresh: any) {
    this.subjectMonitorTypeRefresh.next(isRefresh);
  }

  updateInventoryMetadata(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("inventoryMetaData.updateInventoryMetadata", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  saveInventoryMetadata(obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("inventoryMetaData.saveInventoryMetadata", null);
    return this.apiservice.post(url, obj, true);
  }

  deleteInventoryMetadata(id: string) {
    let url = this.apiservice.getEndPoint("inventoryMetaData.deleteInventoryMetadata",null);
    return this.apiservice.delete(url, id);
  }

}
