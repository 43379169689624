import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { ClientProductComponent } from './client-product.component';
import { ViewClientProductComponent } from './view-client-product/view-client-product.component';
import { AddEditClientProductComponent } from './view-client-product/add-edit-client-product/add-edit-client-product.component';
import { AddEditClientPackComponent } from './view-client-product/add-edit-client-pack/add-edit-client-pack.component';
import { AddEditProductComponent } from './view-client-product/add-edit-product/add-edit-product.component';
import { AddEditProductPackComponent } from './view-client-product/add-edit-product-pack/add-edit-product-pack.component';
import { NgxMaskModule } from 'ngx-mask';
import { ViewProductListComponent } from './view-client-product/view-product-list/view-product-list.component';

@NgModule({
  declarations: [
    ClientProductComponent,
    ViewClientProductComponent,
    AddEditClientProductComponent,
    AddEditClientPackComponent,
    AddEditProductComponent,
    AddEditProductPackComponent,
    ViewProductListComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule, NgxMaskModule.forRoot()],
  entryComponents: [ClientProductComponent, AddEditClientProductComponent, AddEditClientProductComponent,
    AddEditClientPackComponent,
    AddEditProductComponent,
    AddEditProductPackComponent,
    ViewProductListComponent]
})

export class ClientProductModule {
}
