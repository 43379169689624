import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../service/maintenance.service';
import { ServiceTypeModel } from 'src/app/shared/entities/service-type';
import { UIService } from 'src/app/shared/services/ui.service';
import { AddEditServiceTypeComponent } from './add-edit-service-type/add-edit-service-type.component';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-service-type',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.scss']
})
export class ServiceTypeComponent implements OnInit {
  serviceTypeList: ServiceTypeModel[] = [];
  isLoading: boolean = false;
  selectedServiceTypeId: number;

  constructor(private maintanaceService: MaintenanceService,
    private uiService: UIService,
    private alertService: AlertService) {
    this.serviceTypeList = [];
    this.subscribeEvents();
  }

  ngOnInit() {
    this.getServiceTypeList(true);
  }

  private getServiceTypeList(isShowLoader: boolean) {
    this.isLoading = isShowLoader;
    this.maintanaceService.getServiceTypeList().subscribe((res) => {
      if (res && res.data) {
        this.serviceTypeList = res.data;
      }
      this.isLoading = false;
    })
  }
  addServiceType() {
    this.uiService.openDialog(AddEditServiceTypeComponent, null)
  }
  private subscribeEvents() {
    this.maintanaceService.refreshServiceType.subscribe((res) => {
      if (res) {
        this.getServiceTypeList(true);
      }
    });
    this.alertService.referenceDelete.subscribe(data => {
      if (data && data === "Delete ServiceType") {
        this.deleteServiceType(this.selectedServiceTypeId);
      }
    });
  }

  public editActiontype(item) {
    const actiontype = Object.assign({}, item);
    const dialogViewData = this.uiService.setDataForModalDialog(null, null, null, actiontype, null, null);
    this.uiService.openDialog(AddEditServiceTypeComponent, dialogViewData);
  }

  openAlertServiceTypeDelete(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ServiceType";
    this.selectedServiceTypeId = id;
    this.uiService.openAlert(deleteData);
  }

  deleteServiceType(id) {
    this.maintanaceService.DeleteServicetype(id).subscribe(res => {
      if (res.isSuccsess) {
        let snack = new SnackBar(SnackBarType.success, "Successfully deleted", "", 2000);
        this.uiService.openSnackBar(snack);
        this.getServiceTypeList(true);
      }
      else {
        let snack = new SnackBar(SnackBarType.error, "Error on delete", "", 5000);
        this.uiService.openSnackBar(snack);
      }
    })
  }

  activeDeactive(event, item) {
    item.status = event.checked;
    this.maintanaceService.UpdateServicetype(item.id, item).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uiService.openSnackBar(snack);
          this.getServiceTypeList(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uiService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
