import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AngularMaterialModule } from '../angular-material.module';
import {  AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { LoginService } from './login/login.service';
import { SharedModule } from '../shared/shared.module';
import { PermitsComponent } from './permits/permits.component';
import { UsersComponent } from './users/users.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { ViewUserComponent } from './users/view-user/view-user.component';
import { AddEditUserComponent } from './users/view-user/add-edit-user/add-edit-user.component';
import { ModuleComponent } from './module/module.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatChipsModule } from '@angular/material';


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("960420412673-n0nrl9icodo69p0pbofi8m3i0a1ijvv1.apps.googleusercontent.com")
  }
]);

export function provideConfig() {
  return config;
}


@NgModule({
  imports: [
    AuthRoutingModule,
    AngularMaterialModule,
    SharedModule,
    AngularEditorModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    LoginService
  ],
  declarations: [
    LoginComponent,
    PermitsComponent,
    UsersComponent,
    ViewUserComponent,
    AddEditUserComponent,
    NoPermissionComponent,
    ModuleComponent
  ],
  exports: [
  ],
  entryComponents: [
    AddEditUserComponent
  ]
})
export class AuthModule {

}
