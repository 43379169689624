import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatInputModule } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';
import { ApprovalsComponent } from './approvals.component';
import { HrmLeaveFilterComponent } from './hrm-leave-filter/hrm-leave-filter.component';
import { HrmLeaveRejectComponent } from './hrm-leave-reject/hrm-leave-reject.component';
import { HrmLeaveApprovalComponent } from './hrm-leave-approval/hrm-leave-approval.component';
import { FloatingButtonComponent } from '../shared/components/floating-button/floating-button.component';

@NgModule({
  declarations: [
    ApprovalsComponent,
    HrmLeaveFilterComponent,
    HrmLeaveRejectComponent,
    HrmLeaveApprovalComponent,
    FloatingButtonComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    AngularMaterialModule,
    FullCalendarModule,
    NgxDropzoneModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [
    HrmLeaveFilterComponent,
    HrmLeaveRejectComponent
  ],
  providers:[
    HrmLeaveRejectComponent
  ]
})
export class ApprovalsModule {}
