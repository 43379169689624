import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../angular-material.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AlertPopupComponent } from '../shared/components/alert-popup/alert-popup.component';
import { TaskComponent } from './task.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { FilterTaskComponent } from './components/filter-task/filter-task.component';
import { ClientModule } from '../client/client.module';
import { MatPaginatorModule } from '@angular/material';

@NgModule({
  declarations: [
    TaskComponent,
    TaskListComponent,
    FilterTaskComponent
    ],
  imports: [
    AngularMaterialModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    SharedModule,
    ClientModule    
  ],

  exports: [TaskComponent],

  entryComponents: [
    AlertPopupComponent,
    FilterTaskComponent
  ]
})

export class TaskModule { }
