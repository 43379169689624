import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { ERMViewComponent } from './components/erm-detail/erm-view/erm-view.component';
import { ERMComponent } from './erm.component';
import { ERMDetailComponent } from './components/erm-detail/erm-detail.component';
import { MatInputModule } from '@angular/material';
import { NgxMaskModule } from 'ngx-mask';
import { ERMCenterViewComponent } from './components/erm-detail/erm-center-view/erm-center-view.component';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  declarations: [
    ERMComponent,
    ERMDetailComponent,
    ERMComponent,
    ERMViewComponent,
    ERMCenterViewComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    AngularMaterialModule,
    MatInputModule,
    FullCalendarModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [],
})
export class ERMModule {}
