export class InventoryRepair
{
    id:number;
    inventoryId:number;
    providerId?: number;
    sendDate?: any;
    details: string;
    repairDate?:any;
    cost?:any;
    currency:string;

    sendDateStr:string;
    repairDateStr:string;
    providerName:string;
    inventory:any;
}