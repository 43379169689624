import { EstimationPhase } from './estimationPhase';

export class Estimation {
  id: number;
  flag: boolean;
  projectId: number;
  title: string;
  description: string;
  date: any;
  dateStr: string;
  state: any;
  type: any;
  estimationHours: any;
  maxEstimationHours: any;
  budgetHours: any;
  maxBudgetHours: any;
  estimationHoursStr: string;
  maxEstimationHoursStr: string;
  budgetHoursStr: string;
  maxBudgetHoursStr: string;
  googleFolderId: string;
  totalBudget: any;
  totalMaxBudget: any;
  currency: number;
  clientId: number;
  clientName: string;
  projectName: string;
  userImageUrl: string;
  userName: string;
  projectStatusId: number;
  insertUser: string;
  updateUser: string;
  loggedUserId: number;

  estimationPhase: EstimationPhase;
}
