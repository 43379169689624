export class ProductPack {
  id: any;
  productId: number;
  packName: string;
  subscriptionType: any;
  oneTimePrice: any;
  subscriptionPrice: any;
  active: boolean;

  productName: string;

  constructor() {
    this.active = false;
  }
}
