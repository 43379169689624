import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, GoogleLoginProvider } from 'angularx-social-login';

@Injectable({ providedIn: 'root' })
export class AuthAppService {
  public googleUserSubscription: any;
  _LoggedUser: any;
  _permissionIds: Array<string>;
  @Output() getNavigationPath: EventEmitter<any> = new EventEmitter<any>();
  @Output() listAllEmployees: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router, private authService: AuthService) {
  }

  setSessionToken(token) {
    localStorage.setItem('token', token);
  }

  async getSessionToken() {
    return await localStorage.getItem('token');
  }

  async authenticated() {
    return await this.getSessionToken();
  }
  public getLoggedInUser() {
    let jsonString = localStorage.getItem('appUser');
    this._LoggedUser = jsonString ? JSON.parse(jsonString) : "";
    if (this._LoggedUser.imageUrl == undefined) {
      this._LoggedUser.imageUrl = "assets/images/profile.jpg";
    }
    this.getLoggedInPermission();
    return this._LoggedUser;
  }

  public getLoggedInPermission() {
    this._permissionIds = localStorage.getItem('permissionIds') != null ? JSON.parse(localStorage.getItem('permissionIds')) : [];
    return this._permissionIds;
  }

}
