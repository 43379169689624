import { Injectable } from '@angular/core';
import { AuthService, GoogleLoginProvider } from 'angularx-social-login';
import { APIService } from 'src/app/shared/services/api.service';
import { LoginModel } from '../entities/login';
import { UIService } from 'src/app/shared/services/ui.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Users } from '../entities/user';
import { ClientService } from 'src/app/client/services/client.service';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { SharedHeaderService } from 'src/app/shared/services/shared.header.services';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  _user: Users;
  authorizedSubject = new Subject<boolean>();
  login: LoginModel[] = [];
  googleUserSubscription: any;
  constructor(
    private authService: AuthService,
    private apiService: APIService,
    private uiService: UIService,
    private router: Router,
    private ClientService: ClientService,
    private commonUIService: UIService,
    private authAppService: AuthAppService,
    private sharedHeaderService: SharedHeaderService
  ) {
  }
  /**
   * Sign With Google
   */
  signWithGoogle() {
    this.commonUIService.openMask();
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialLogin => {
      let loginModel: LoginModel = new LoginModel(socialLogin.id, socialLogin.firstName, socialLogin.lastName, socialLogin.email, socialLogin.photoUrl, socialLogin.authToken, socialLogin.idToken);
      let url = this.apiService.getEndPoint("user.loginWithGoogleUser", []);
      this.apiService.post(url, loginModel, true).subscribe(res => {
        if (res.statusCode == 200) {
          this._user = res.data;
          this._user.imageUrl = socialLogin.photoUrl;
          this.setSessionAppUser(JSON.stringify(this._user));
          this.setLoggedUserPermission(this._user);
          let company = this._user.company ? this._user.company : null;
          this.sharedHeaderService.selectedCompanyId = company ? company.id : undefined;
          this.sharedHeaderService.selectedCompanyName = company ? company.name : undefined;
          this.setLoggedUserCopmany(JSON.stringify(company));
          this.authAppService.setSessionToken(this._user.authToken);
          const snack = new SnackBar(SnackBarType.success, "Authorized", "", 5000);
          this.uiService.openSnackBar(snack);
          this.authorizedSubject.next(true);
          this.sharedHeaderService.isMainBranch = true;
          return true;
        }
        else if (res.statusCode === 401) {
          const snack = new SnackBar(SnackBarType.info, res.message, "", 7000);
          this.uiService.openSnackBar(snack);
          this.commonUIService.closeMask();
          return false;
        }
        else {
          const snack = new SnackBar(SnackBarType.error, "Unauthorized", "", 5000);
          this.uiService.openSnackBar(snack);
          this.commonUIService.closeMask();
          return false;

        }
      }, err => {
        console.log(err);
        var snack = new SnackBar(SnackBarType.error, "Unauthorized", "", 5000);
        this.uiService.openSnackBar(snack);
        this.commonUIService.closeMask();
        return false;
      }
      );
    });
  }

  async logout() {
    this.unsubscribeGoogleUser();
  }

  async unsubscribeGoogleUser() {
    this.authService.signOut();
    localStorage.clear();
    sessionStorage.clear();
    var cookies = document.cookie;
    for (var i = 0; i < cookies.split(";").length; ++i) {
      var myCookie = cookies[i];
      var pos = myCookie.indexOf("=");
      var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    this.sharedHeaderService.isMainBranch = null;
    this.sharedHeaderService.selectedCompanyId = null;
    this.sharedHeaderService.selectedCompanyName = null;
    var snack = new SnackBar(SnackBarType.success, "Logout success", "", 5000);
    this.uiService.openSnackBar(snack);
    this.router.navigate(['/']);
  }

  setSessionAppUser(appUsers) {
    localStorage.setItem('appUser', appUsers);
  }
  setLoggedUserCopmany(company) {
    localStorage.setItem('company', company);
  }

  async getSessionAppUser() {
    return localStorage.getItem('appUser');
  }

  userLogin(formData) {
    let url = this.apiService.getEndPoint("user.loginUser", null);
    return this.apiService.post(url, formData);
  }

  public setLoggedUserPermission(user) {
    const _permissionIds = [];
    if (user && user.role && user.role.rolePermission && user.role.rolePermission.length > 0) {
      for (var i = 0; i < user.role.rolePermission.length; i++) {
        if (user.role != null)
          _permissionIds.push(user.role.rolePermission[i].permissionId.toString());
      }
    }
    if (user && user.userRole.length > 0) {
      user.userRole.forEach(ur => {
        for (var i = 0; i < ur.role.rolePermission.length; i++) {
          _permissionIds.push(ur.role.rolePermission[i].permissionId.toString());
        }
      });
    }
    localStorage.setItem('permissionIds', JSON.stringify(_permissionIds));
  }

}

export class SnackbarComponent { }
