import { Injectable } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { Company } from '../entities/company';

@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  public subjectCompanyRefresh = new Subject<any>();

  constructor(private apiservice: APIService) { }

  deleteCompany(id: string) {
    let url = this.apiservice.getEndPoint("company.deleteCompany", null);
    return this.apiservice.delete(url, id);
  }

  async companyRefresh(isCompanyRefesh: boolean) {
    this.subjectCompanyRefresh.next(isCompanyRefesh);
  }

  saveCompanyData(companyObj: Company) {
    let url = this.apiservice.getEndPoint("company.addCompany", null);
    return this.apiservice.post(url, companyObj, true);
  }

  updateCompany(id: any, obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("company.updateCompany", [["{companyID}", id]]);
    return this.apiservice.put(url, obj, true);
  }
}
