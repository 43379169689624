import { ApprovalsService } from './../service/approvals.service';
import { Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';

@Component({
  selector: 'app-hrm-leave-reject',
  templateUrl: './hrm-leave-reject.component.html',
  styleUrls: ['./hrm-leave-reject.component.scss']
})
export class HrmLeaveRejectComponent implements OnInit {
  reasonForm :FormGroup;
  constructor(
    public commonDropdownService: CommonDropdownService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    private approvalsService : ApprovalsService
  ) { }

  ngOnInit() {
  this.initForm();
  }

  initForm()
  {
    this.reasonForm= this.fb.group({
      reason:['',Validators.required]
    })
  }

  submit()
  {
    if(this.reasonForm.valid)
    {
      this.approvalsService.setReason(this.reasonForm.value)
      this.dialog.closeAll();
      
    }
  }
}
