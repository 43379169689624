import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const bSubForAlertEvents = new BehaviorSubject<string>(null);

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  @Output() referenceDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() referenceClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() referenceConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() referenceConfirmForSimm: EventEmitter<any> = new EventEmitter<any>();
  @Output() referenceRefreshSimm: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteHolidays: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

}
