export class FilterEmployeeModel {
  designation: string;
  department: string;
  jointDate?: any;
  toDate?: any;
  fromDate?: any;
  searchText: string;
  page:number;
  pageSize: number;
  pageItems: number;
  count: number;
}
