export class ProvidersModel{
  id: number;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  province: string;
  countryId: number;
  phone: string;
  nif: string;

  type: string;
  countryName: string;
}
