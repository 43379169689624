import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { CompanyComponent } from 'src/app/company/company.component';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { ViewCompanyComponent } from './view-company/view-company.component';
import { AddEditCompanyComponent } from './view-company/add-edit-company/add-edit-company.component';

@NgModule({
  declarations: [
    CompanyComponent,
    ViewCompanyComponent,
    AddEditCompanyComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule],
  entryComponents: [CompanyComponent, AddEditCompanyComponent]
})

export class CompanyModule {

}
