import { Injectable } from '@angular/core';
import { Client } from '../entities/client';
import { ClientService } from './client.service';
import API from './../../shared/services/api.routes.json';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class ClientViewService {
  selectedClient: Client;
  isLoading_clientDocument: boolean = false;
  isLoading_ClientProductDetail:boolean=false;
  showAddDocumentOut: boolean = false;
  isLoading_clientDescriptionAndTechList:boolean=false;

  constructor(public clientService: ClientService, ) { }

  async loadClientDocumentList(clientId) {  
    this.isLoading_clientDocument = true;
    this.clientService.loadClientDocumentList(clientId).subscribe(
      res => {
        if (res) {
          let _docList = res.data;
          if (_docList.length > 0) {
            _docList.forEach(document => {
              //Need for future developments
              //element.clientDocumentModelList.forEach(document => {
                if (document.fileName) {
                  document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
                  document.link = API.baseUrl + API.clientDocument.getClientDocument + document.id + '/' + document.generatedFileName;
                  if (document.url) {
                    if (document.extension === 'pdf')
                      document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                    if (document.extension === 'png')
                      document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                    if (document.extension === 'jpg')
                      document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                    if (document.extension === 'jpeg')
                      document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                    if (document.extension === 'docx')
                      document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                    if (document.extension === 'xlsx' || document.extension === 'xls')
                      document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                  }
                }
              //});
            });
          }         
          this.clientService._clientDocumentList = _docList;
          this.selectedClient.isClientDocumentList = this.clientService._clientDocumentList.length > 0;
          this.isLoading_clientDocument = false;
          this.showAddDocumentOut = this.clientService._clientDocumentList.length > 0;
        }
      },
      () => { console.error(); });
    this.isLoading_clientDocument = false;
  }
}
