import { Component, OnInit, OnChanges } from '@angular/core';
import { MatDialogConfig, PageEvent } from '@angular/material';
import { AddclientComponent } from '../add-client/add-client.component';
import { FilterclientComponent } from '../filter-client/filter-client.component';
import { ClientService } from '../services/client.service';
import { Client } from '../entities/client';
import * as $ from "jquery";
import { AlertService } from 'src/app/shared/services/alert.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { SharedClientService } from '../services/shared.client.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { MaintenanceService } from 'src/app/maintenance/service/maintenance.service';
import { APIService } from 'src/app/shared/services/api.service';
import { Users } from 'src/app/auth/entities/user';
import { ClientTask } from '../entities/clientTask';
import { LoginService } from 'src/app/auth/login/login.service';
import { SharedHeaderService } from 'src/app/shared/services/shared.header.services';
import { TaskService } from '../services/task.service';
import { MoreFilterModel } from '../entities/moreFilterModel';
import { Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})

export class ClientlistComponent implements OnInit {

  //region Variables
  clientList: Client[] = [];
  clientListTemp: Client[] = [];
  clientFilteredList: Client[] = [];
  clientFilterByTask: Client[] = [];
  clientFilterWithoutTask: Client[] = [];
  projectTaskList: any[] = [];

  firstLoad = false;
  idSelectedClient: number = 0;
  isLoading: boolean = true;
  selectedStatus: any = 1;
  searchInputField: string = null;
  client: Client;
  clientId: any;
  hideStatus: boolean = false;
  selectedStatusIndex: number;
  selectedClientStatus: string = '';
  searchText: string = '';
  _taskList: ClientTask[] = [];
  currentCompanyId: number;
  currentCompanyName: string;
  currentUsers: Users;
  filterObject: MoreFilterModel;
  selectedClientUpdateData: any;
  urlData: any;
  loggedUserCompany: any;
  yesterdayDate: any;
  projectTaskDate: any;

  //pagination properties
  lowValue: number = 0;
  highValue: number = 15;
  pageIndex: number = 0;

  //custom pagination
  totalCount: number = 0;

  constructor(
    public clientService: ClientService,
    public commonUIService: UIService,
    public alertService: AlertService,
    private maintenanceService: MaintenanceService,
    public commonDropdownService: CommonDropdownService,
    private sharedClientService: SharedClientService,
    private _apiService: APIService,
    private loginService: LoginService,
    private sharedHeaderService: SharedHeaderService,
    private taskService: TaskService,
    public router: Router) {
    this.clientList = [];
    this.clientListTemp = [];
    this.clientFilterByTask = [];
    this.clientFilterWithoutTask = [];
    this.clientFilteredList = [];
    this.currentCompanyId = this.sharedHeaderService.selectedCompanyId;
    this.currentCompanyName = this.sharedHeaderService.selectedCompanyName;
    this.hideStatus = false;
    this.filterObject = this.clientService.moreFilterModel;
  }
  //endregion

  ngOnInit() {
    //Don't remove this comments. Need for future developments
    //this.filterObject.page = 1;
    //this.filterObject.pageSize = 15;
    //this.filterObject.pageItems = 15;
    this.urlData = this.router.url;
    let today = new Date();
    this.yesterdayDate = new Date(today.setDate(today.getDate() - 1)).toISOString();
    this.getCurrentUser().then();
    this.commonDropdownService.LoadClientActiveStatusList().subscribe(async res => {
      this.commonDropdownService._activeClientStatusList = res;
      if (res) {
        this.firstLoad = true;
        //Don't remove this comments. Need for future developments
        //this.loggedUserCompany = await this.sharedHeaderService.getLoggedUserCopmany();
        //if (this.loggedUserCompany) {
        //  this.sharedHeaderService.selectedCompanyId = this.loggedUserCompany.id;
        //  this.sharedHeaderService.selectedCompanyName = this.loggedUserCompany.name;
        //  this.sharedHeaderService.isMainBranch = this.loggedUserCompany.name.toLowerCase().trim().replace(" ", "") === "qualitapps";
        //}
        this.currentCompanyId = this.sharedHeaderService.selectedCompanyId;
        this.currentCompanyName = this.sharedHeaderService.selectedCompanyName;
        this.filterObject.companyId = this.currentCompanyId;
        this.filterObject.clientStatus = 1;
        //Don't remove this comments. Need for future developments
        //this.filterObject.page = 1;
        //this.filterObject.pageSize = 15;
        //this.filterObject.pageItems = 15;
        this.selectedClientUpdateData = null;
        this.getClientList(this.filterObject, null);
      }
    });
    this.subscribeEvents();
    this.resetFilter();
    this.loadProjectTasks();
  }

  async currentUser() {
    this.loggedUserCompany = await this.sharedHeaderService.getLoggedUserCopmany();
  }

  public getPaginatorDataForClient(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  function() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
  }

  subscribeEvents() {
    this.sharedClientService.passCompanyIdToClient.subscribe(data => {
      if (!data) { return; }
      this.currentCompanyId = data.companyId;
      this.idSelectedClient = 0;
      this.clientService.isSelectedClient = false;
      this.filterObject.companyId = this.currentCompanyId > 0 && data.isLoad && this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
      this.selectedClientUpdateData = null;
      this.getClientList(this.filterObject, null);
    });

    this.clientService.moreFilterSelectEmitter.subscribe(res => {
      if (res) {
        this.hideStatus = true;
        this.idSelectedClient = 0;
        this.lowValue = 0;
        this.highValue = 15;
        this.clientService.isSelectedClient = false;
        if (res.hasMoreFilter) {
          this.filterObject = Object.assign({}, res);
        }
        else if (!res.hasMoreFilter && this.filterObject.text) {
          this.filterObject = new MoreFilterModel();
        }
        else { this.filterObject = new MoreFilterModel(); }
        this.filterObject.text = null;
        this.searchInputField = null;
        this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
        this.filterObject.clientStatus = null;
        this.selectedClientUpdateData = null;
        this.getClientList(this.filterObject, null);
      }
      else {
        this.hideStatus = false;
        this.filterObject = new MoreFilterModel();
        this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
        this.filterObject.clientStatus = 1;
        this.selectedStatusIndex = 0;
        this.searchInputField = null;
        this.selectedClientUpdateData = null;
        this.idSelectedClient = 0;
        this.clientService.isSelectedClient = false;
        this.getClientList(this.filterObject, null);
      }
    });

    this.clientService.refreshClient.subscribe(data => {
      if (data) {     
        this.resetFilter();
        this.filterObject.clientStatus = Number(data.clientStatusValue);
        this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
        if (data.isEdit) {
          this.selectedStatusIndex = (Number(data.clientStatusValue) === 2 ? 1 : (Number(data.clientStatusValue) === 3 ? 2 : (Number(data.clientStatusValue) === 0 ? 3 : 0)));
          if (data.isStatusChange) {
            this.selectedClientUpdateData = data;
            this.getClientList(this.filterObject, data);
            return;
          }
          this.selectedClientUpdateData = null;
          this.getClientList(this.filterObject, null);
          this.idSelectedClient = data.id;
          this.clientService.isSelectedClient = true;
          data.isSelectedItem = true;
          this.clientService.selectedClient.emit(data);
        }
        else if (data.isStatusChange) {
          this.selectedStatusIndex = (Number(data.clientStatusValue) === 2 ? 1 : (Number(data.clientStatusValue) === 3 ? 2 : (Number(data.clientStatusValue) === 0 ? 3 : 0)));
          this.selectedClientUpdateData = data;
          this.getClientList(this.filterObject, data);
        }
        else {
          this.selectedStatusIndex = (Number(data.clientStatusValue) === 2 ? 1 : (Number(data.clientStatusValue) === 3 ? 2 : (Number(data.clientStatusValue) === 0 ? 3 : 0)));
          this.selectedClientUpdateData = null;
          this.getClientList(this.filterObject, data);
        }
      }
    });

    this.clientService.refreshDeletedClient.subscribe(data => {
      if (!data) { return; }
      this.selectedStatusIndex = 0;
      this.selectedClientUpdateData = null;
      this.filterObject.clientStatus = null;
      this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
      this.getClientList(this.filterObject, null);
    });

    this.clientService.refreshClientTask.subscribe(data => {
      if (!data) { return; }
      this.loadToDoClientTaskList(this.idSelectedClient).then();
    });

    this.sharedClientService.taskUpdatedEvent.subscribe(tasks => {
      let selectedClient = this.clientList.filter((c) => c.id == this.idSelectedClient)[0];
      selectedClient.clientTasks = tasks;
    });

    this.sharedClientService.clientStatusChangeEvent.subscribe(client => {
      if (this.searchInputField) {
        let selectedClient = this.clientList.filter((c) => c.id == this.idSelectedClient)[0];
        selectedClient.clientStatus.clientState = client.clientStatus.clientState;
        selectedClient.clientStatus.statusStyles = this.getClientStatusStyle(client.clientStatus);
      } else {
        this.filterObject.clientStatus = client.clientStatusGlobal;
        if (this.filterObject.clientStatus == 1)
          this.selectedStatusIndex = 0;
        if (this.filterObject.clientStatus == 2)
          this.selectedStatusIndex = 1;
        if (this.filterObject.clientStatus == 3)
          this.selectedStatusIndex = 2;
        if (this.filterObject.clientStatus == 0)
          this.selectedStatusIndex = 3;
        this.getClientList(this.filterObject, this.selectedClientUpdateData);
        //Don't remove this comments. Need for future developments
        //const index: number = this.clientList.findIndex(e => e.id === client.id);
        //if (index !== -1) {
        //  this.clientList.splice(index, 1);
        //}
      }
    });
  }

  private getClientList(filterModel: MoreFilterModel, addEditClient) {
    this.selectedStatus = filterModel.clientStatus;
    this.isLoading = true;
    filterModel.companyId = this.sharedHeaderService.isMainBranch ? null : filterModel.companyId;
    this.clientService.getClientList(filterModel).subscribe(res => {
      if (res && res.data) {
        //TODO SET COUNT
        this.totalCount = 24;
        if (this.firstLoad) {
          this.firstLoad = false;
        }
        this.currentCompanyName = this.sharedHeaderService.selectedCompanyName;
        this.clientListTemp = res.data;
        this.clientList = this.setClientListData(this.clientListTemp, false);
        if (this.clientList.length > 0) {
          this.clientList.forEach(c => {
            if (c.isTask == false && c.projects.length > 0 && c.projects[0].tasks.length > 0) {
              let projectTaskList = c.projects[0].tasks.sort((a, b) => new Date(a.taskDate).getTime() - new Date(b.taskDate).getTime());
              if (projectTaskList.length > 0)
                c.projectTaskDate = projectTaskList[0].taskDate;
            } else
              c.projectTaskDate = null;
          });
        }
        this.isLoading = false;
        if (addEditClient) {
          this.idSelectedClient = addEditClient.id;
          this.clientService.isSelectedClient = true;
          addEditClient.isSelectedItem = true;
          this.clientService.selectedClient.emit(addEditClient);
        }
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading = false;
      }
    },
      () => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading = false;
      });
  }

  private setClientListData(clientList, isFilter) {
    clientList.forEach((client: any) => {
      client.initials = client.legelName ? this.getInitials(client.legelName) : null;
      if (client.clientStatus) {
        client.clientStatus.statusStyles = this.getClientStatusStyle(client.clientStatus);
      }
      if (client.logo) {
        client.logoUrl = this.clientService.getClientLogo(client.id);
      }
      if (client.clientTasks.length > 0) {
        let taskList = client.clientTasks.length > 0 ? client.clientTasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime()) : client.clientTasks;
        if (taskList.length > 0)
          client.isTask = true;
        else
          client.isTask = false;
        client.clientTasks = this.getClientTask(client);
        client.clientTasks.forEach(r => {
          r.initials = r.taskUserName ? this.getInitials(r.taskUserName) : null;
        });
        if (isFilter) { this.clientFilterByTask.push(client); }
      }
      else {
        client.isTask = false;
        if (isFilter) this.clientFilterWithoutTask.push(client);
      }
    });
    return clientList;
  }

  private getInitials(name) {
    let initial = null;
    if (name != null) {
      let matches = name.match(/\b(\w)/g);
      initial = matches.join('');
    }
    return initial;
  }

  private getClientTask(client) {
    this._taskList = client.clientTasks.length > 0 ? client.clientTasks.filter(x => x.status == 0).sort((a, b) => new Date(b.taskDate).getTime() - new Date(a.taskDate).getTime()) : client.clientTasks;
    if (this._taskList && this._taskList.length > 0) {
      this.clientService.clientTaskCount = this._taskList.length;
    } else {
      this.clientService.clientTaskCount = null;
    }
    return this._taskList;
  }

  private getClientStatusStyle(clientStatus) {
    clientStatus.statusStyles = {
      'background': clientStatus.color,
      'color': '#ffffff'
    };
    return clientStatus.statusStyles;
  }

  async loadToDoClientTaskList(id) {
    this.clientService.loadToDoClientTaskList(id).subscribe(
      res => {
        if (res) {
          this._taskList = res.data;
          this._taskList.forEach(r => {
            r.initials = r.taskUserName ? this.getInitials(r.taskUserName) : null;
          });
          this.setClientTask(id);
        }
      },
      () => { console.error(); });
  }

  private setClientTask(clientId) {
    this.clientList.forEach((client: any) => {
      client.isTask = true;
      if (client.id == clientId) {
        client.clientTasks = this._taskList.sort((a, b) => new Date(a.taskDate).getTime() - new Date(b.taskDate).getTime());
      }
    });
  }

  clientListFilter(event, isTabChanged: boolean = false) {
    this.lowValue = 0;
    this.highValue = 15;
    //Don't remove this comments. Need for future developments
    //this.filterObject.page = 1;
    //this.filterObject.pageSize = 15;
    //this.filterObject.pageItems = 15;
    this.selectedClientUpdateData = !isTabChanged && this.selectedClientUpdateData ? this.selectedClientUpdateData : null;
    this.selectedStatusIndex = !isTabChanged && this.selectedClientUpdateData ? this.selectedStatusIndex : event.index;
    if (this.firstLoad && this.selectedStatusIndex === 0) {
      this.firstLoad = false;
      return;
    }
    this.idSelectedClient = 0;
    this.clientService.isSelectedClient = false;
    this.filterObject.clientStatus = event.tab._viewContainerRef._data.renderElement ? Number(event.tab._viewContainerRef._data.renderElement.id) : 1;
    this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
    this.getClientList(this.filterObject, this.selectedClientUpdateData);
  }

  public selectClient(client) {      
    this.selectedClientStatus = client.clientStatus.status;
    const except = $(event.target).hasClass('except');
    if (!except) {
      if (client.id) {
        this.commonUIService.openMask()
        this.idSelectedClient = client.id;
        client.isSelectedItem = true;
        client.url = this.urlData;
        this.clientService.showMore = false;
        this.clientService._clientId = client.id;
        this.clientService.isDifferentClientSelected = true; 
        this.clientService.selectedClient.emit(client);
      }
    }
  }

  setClientColor(client: Client) {
    let delayedTasks = client.clientTasks.some(m => m.taskDate < this.yesterdayDate);
    return delayedTasks ? 'red' : '';
  }

  setProjectTaskColor(client: Client) {
    let delayedProjectTasks = null;
    delayedProjectTasks = client.projects[0].tasks.some(m => m.taskDate < this.yesterdayDate);
    return delayedProjectTasks ? 'red' : '';
  }

  SearchClientByText(event) {
    this.lowValue = 0;
    this.highValue = 15;
    //Don't remove this comments. Need for future developments
    //this.filterObject.page = 1;
    //this.filterObject.pageSize = 15;
    //this.filterObject.pageItems = 15;
    this.filterObject.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
    this.filterObject.clientStatus = event.target && event.target.value || this.filterObject.hasMoreFilter ? null : 1;
    this.filterObject.text = event.target && event.target.value ? event.target.value : null;
    this.selectedStatusIndex = event.target && event.target.value || this.filterObject.hasMoreFilter ? null : 0;
    this.searchText = event.target.value;
    this.hideStatus = event.target && event.target.value || this.filterObject.hasMoreFilter;
    this.idSelectedClient = 0;
    this.clientService.isSelectedClient = false;
    this.selectedClientUpdateData = null;
    this.commonUIService.delay(() => { this.getClientList(this.filterObject, null); }, 1000);
  }

  openAddEditClientPopup() {
    this.clientService.isClientEdit = false;
    let clientData = new Client();
    clientData.clientStatusValue = "2";
    let today = moment();
    let date = moment(today);
    clientData.entrydate = date;
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, this.currentCompanyName, clientData, null, this.currentCompanyId);
    this.commonUIService.openDialog(AddclientComponent, dialogViewData)
  }

  openFilterClientPopup() {
    this.commonUIService.openDialog(FilterclientComponent, null)
  }

  async getCurrentUser() {
    let jsonString = await this.loginService.getSessionAppUser();
    this.currentUsers = jsonString ? JSON.parse(jsonString) : "";
    if (this.currentUsers.imageUrl == undefined) {
      this.currentUsers.imageUrl = "assets/images/profile.jpg";
    }
    this.currentCompanyId = !this.sharedHeaderService.selectedCompanyId ? this.currentUsers.company.id : this.sharedHeaderService.selectedCompanyId;
    this.currentCompanyName = !this.sharedHeaderService.selectedCompanyName ? this.currentUsers.company.name : this.sharedHeaderService.selectedCompanyName;
  }

  resetFilter() {
    this.clientService.moreFilterModel = new MoreFilterModel();
    this.filterObject = new MoreFilterModel();
    this.searchInputField = "";
    localStorage.removeItem("filterStorageModel");
    this.clientService.isMoreFilterActive = false;
    this.hideStatus = false;
    this.taskService.isDelayedTask = false;
    this.idSelectedClient = 0;
    this.clientService.isSelectedClient = false;
  }

  loadProjectTasks() {
    this.clientService.loadProjectTasks().subscribe(
      res => {
        if (res && res.data) {
          this.projectTaskList = res.data;
        }
      },
      () => { console.error(); });
  }

  onChangePageItems(event) {
    this.filterObject.page = 1;
    this.filterObject.pageSize = event;
    this.getClientList(this.filterObject, null);
  }

  viewPreviousPage(event) {
    if (this.filterObject.page <= 1) {
      this.filterObject.page = this.filterObject.page - 1;
      this.filterObject.pageSize = this.filterObject.pageSize / 2;
      this.getClientList(this.filterObject, null);
    }
  }

  viewNextPage(event) {
    if (this.filterObject.page >= 1) {
      this.filterObject.page = this.filterObject.page + 1;
      this.filterObject.pageSize = this.filterObject.pageSize * 2;
      this.getClientList(this.filterObject, null);
    }
  }
}

