import { InventoryMetadataType } from "../enum/inventoryMetadataType";

export class InventoryMetaData{
    id: number;
    name: string;
    type: InventoryMetadataType;
    insertDate: Date;
    insertUser: string;
    updateDate: Date;
    status: boolean;
}