export class EstimationOption {
  id: number;
  estimationPhaseId: number;
  state: any;
  option: string;
  hours: any;
  maxHours: any;
  hourFee: number;
  budget: number;
  maxBudget: number;
  hoursStr: string;
  maxHoursStr: string;  
  insertUser: string;
  updateUser: string;

  constructor() {
  }
}
