export class currencyModel {
  id: number;
  name: string;
  code: string;
  insertDate: Date;
  insertUser: string;
  updateuser: string;
  updateDate: Date;
  status: boolean;
  isExistId:boolean;
}
