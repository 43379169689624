export class departmentModel {
  id: number;
  name: string;
  insertDate: string;
  insertUser: string;
  updateDate: Date;
  updateUser: Date;
  status: boolean;
  active?: boolean;
  isExistId:boolean;
}
