import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject, Observable } from 'rxjs';
import API from '../../shared/services/api.routes.json';
import { RestResponse } from 'src/app/shared/entities/rest-response';

@Injectable({
  providedIn: 'root'
})

export class MaintenanceService {

  ischeckbox: boolean=false;
  
  public subjectTechnolgyRefersh = new Subject<any>();
  public subjectClientStatusRefersh = new Subject<any>();
  public subjectprojectStatusRefresh = new Subject<any>();
  public subjectTaskTypeRefersh = new Subject<any>();
  public subjectActionTypeRefresh = new Subject<any>();
  public subjectRegionTypeRefresh = new Subject<any>();
  public subjectDocumentTypeRefresh = new Subject<any>();
  public subjectPositionTypeRefresh = new Subject<any>();
  public subjectreferalSourcerefresh = new Subject<any>();
  public subjectSourcerefresh = new Subject<any>();
  public subjectCountryRefresh = new Subject<any>();
  public subjectCurrencyRefresh = new Subject<any>();
  public subjectDepartmentTypeRefresh = new Subject<any>();
  public subjectDesignationTypeRefresh = new Subject<any>();

  @Output() refreshServiceType: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiservice: APIService) { }

  SaveTechnology(obj: any) {
    let url = this.apiservice.getEndPoint("technologies.technology", null);
    return this.apiservice.post(url, obj, true);
  }

  updateTechnology(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("technologies.updateTechnology", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteTechnology(id: string) {
    let url = this.apiservice.getEndPoint("technologies.deleteTechnology", null);
    return this.apiservice.delete(url, id);
  }

  deleteClientStatus(id) {
    console.log("Client status working");
    let url = this.apiservice.getEndPoint("clientStatus.deleteClientStatus", null);
    console.log("url", url);
    return this.apiservice.delete(url, id);
  }

  async ClientStatusRefersh(isRefresh) {
    this.subjectClientStatusRefersh.next(isRefresh);
  }

  async technologyRefersh(isRefresh: any) {
    this.subjectTechnolgyRefersh.next(isRefresh);
  }

  async projectStatusRefresh(isStatusRefesh) {
    this.subjectprojectStatusRefresh.next(isStatusRefesh);
  }

  async referalRefresh(isStatusRefesh) {
    this.subjectSourcerefresh.next(isStatusRefesh);
  }

  async referalSourceRefresh(isReferalRefresh) {
    this.subjectreferalSourcerefresh.next(isReferalRefresh);
  }

  deleteActionType(id: string) {
    let url = this.apiservice.getEndPoint("actionType.deleteActionType", null);
    return this.apiservice.delete(url, id);
  }

  deleteProjectStatus(id) {
    let url = this.apiservice.getEndPoint("projectStatus.deleteProject", null);
    return this.apiservice.delete(url, id);
  }

  async TaskTypeRefersh(isRefresh) {
    this.subjectTaskTypeRefersh.next(isRefresh);
  }

  deleteTaskType(id) {
    let url = this.apiservice.getEndPoint("taskType.deleteTaskType", null);
    return this.apiservice.delete(url, id);
  }

  saveActionType(obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("actionType.addActionType", null);
    return this.apiservice.post(url, obj, true);
  }

  async actionTypeRefresh(isActionTypeRefesh: boolean) {
    this.subjectActionTypeRefresh.next(isActionTypeRefesh);
  }

  updateActionType(id: any, obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("actionType.updateActionType", [["{actionTypeID}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  SaveClientStatus(obj: any) {
    let url = this.apiservice.getEndPoint("clientStatus.clientStatus", null);
    return this.apiservice.post(url, obj, true);
  }

  updateClientStatus(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("clientStatus.updateClientStatus", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  SaveProjectStatus(obj: any) {
    let url = this.apiservice.getEndPoint("projectStatus.addProjectStatus", null);
    return this.apiservice.post(url, obj, true);
  }

  updateProjectStatus(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("projectStatus.updateProjectStatus", [["{projectStatusID}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  SaveTaskType(obj: any) {
    let url = this.apiservice.getEndPoint("taskType.taskType", null);
    return this.apiservice.post(url, obj, true);
  }

  updateTaskType(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("taskType.updateTaskType", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteRegionConform(id) {
    let url = this.apiservice.getEndPoint("regionType.deleteRegionType", null);
    return this.apiservice.delete(url, id);
  }

  saveRegionType(obj: any) {
    let url = this.apiservice.getEndPoint("regionType.saveRegionType", null);
    return this.apiservice.post(url, obj, true);
  }

  RegionTypeRefresh(regionTypeRefresh: boolean) {
    this.subjectRegionTypeRefresh.next(regionTypeRefresh);
  }

  updateRegionType(obj: any) {
    let url = this.apiservice.getEndPoint("regionType.updateRegionType", [["{id}", obj.id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteDocument(id) {
    let url = this.apiservice.getEndPoint("documentType.deleteDocumentType", null);
    return this.apiservice.delete(url, id);
  }

  saveDocumentType(obj: any) {
    let url = this.apiservice.getEndPoint("documentType.saveDocumentType", null);
    return this.apiservice.post(url, obj, true);
  }

  DocumentTypeRefresh(documentTypeRefresh: boolean) {
    this.subjectDocumentTypeRefresh.next(documentTypeRefresh);
  }

  PositionTypeRefresh(PositionTypeRefresh: boolean) {
    this.subjectPositionTypeRefresh.next(PositionTypeRefresh);
  }

  UpdateDocumentType(obj: any) {
    let url = this.apiservice.getEndPoint("documentType.updateDocumentType", [["{id}", obj.id]]);
    return this.apiservice.put(url, obj, true);
  }

  deletePosition(id) {
    let url = this.apiservice.getEndPoint("position.deletePosition", null);
    return this.apiservice.delete(url, id);
  }

  SavePosition(obj: any) {

    let url = this.apiservice.getEndPoint("position.position", null);
    return this.apiservice.post(url, obj, true);

  }

  updatePostion(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("position.updatePosition", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  SaveReferalSource(obj: any) {
    let url = this.apiservice.getEndPoint("referralSource.AddReferalSource", null);
    return this.apiservice.post(url, obj, true);
  }

  updateReferalSource(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("referralSource.UpdateReferalSource", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteReferalSource(id) {
    let url = this.apiservice.getEndPoint("referralSource.deletereferralSource", null);
    return this.apiservice.delete(url, id);
  }
  getServiceTypeList():Observable<RestResponse> {
    let url =`${API.serviceType.listAll}`;
    return this.apiservice.get(url);
  }
  SaveServicetype(obj: any) {
    let url = `${API.serviceType.addServiceType}`;
    return this.apiservice.post(url, obj, true);
  }

  UpdateServicetype(id: number, obj: any) {
    let url = `${API.serviceType.updateServiceType}`;
    url=url.replace('{id}',id.toString());
    return this.apiservice.put(url, obj, true);
  }
  DeleteServicetype(id: number) {
    let url = `${API.serviceType.serviceType}`;
    return this.apiservice.delete(url,id.toString());
  }

  deleteCountry(id) {
    let url = this.apiservice.getEndPoint("country.deleteCountry", null);
    return this.apiservice.delete(url, id);
  }

  saveCountry(obj: any) {
    let url = this.apiservice.getEndPoint("country.saveCountry", null);
    return this.apiservice.post(url, obj, true);
  }

  CountryRefresh(countryRefresh: boolean) {
    this.subjectCountryRefresh.next(countryRefresh);
  }

  UpdateCountry(obj: any) {
    let url = this.apiservice.getEndPoint("country.updateCountry", [["{id}", obj.id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteCurrency(id) {
    let url = this.apiservice.getEndPoint("currency.deleteCurrency", null);
    return this.apiservice.delete(url, id);
  }

  saveCurrency(obj: any) {
    let url = this.apiservice.getEndPoint("currency.saveCurrency", null);
    return this.apiservice.post(url, obj, true);
  }

  CurrencyRefresh(currencyRefresh: boolean) {
    this.subjectCurrencyRefresh.next(currencyRefresh);
  }

  UpdateCurrency(obj: any) {
    let url = this.apiservice.getEndPoint("currency.updateCurrency", [["{id}", obj.id]]);
    return this.apiservice.put(url, obj, true);
  }

  
  DesignationTypeRefresh(DesignationTypeRefresh: boolean) {
    this.subjectDesignationTypeRefresh.next(DesignationTypeRefresh);
  }


  deleteDepartment(id) {
    let url = this.apiservice.getEndPoint("department.deleteDepartment", null);
    return this.apiservice.delete(url, id);
  }

  deleteDesignation(id) {
    let url = this.apiservice.getEndPoint("designation.deleteDesignation", null);
    return this.apiservice.delete(url, id);
  }

  DepartmentTypeRefresh(DepartmentTypeRefresh: boolean) {
    this.subjectDepartmentTypeRefresh.next(DepartmentTypeRefresh);
  }

  SaveDepartment(obj: any) {
    let url = this.apiservice.getEndPoint("department.department", null);
    return this.apiservice.post(url, obj, true);
  }

  SaveDesignation(obj: any) {
    let url = this.apiservice.getEndPoint("designation.designation", null);
    return this.apiservice.post(url, obj, true);
  }

  updateDepartment(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("department.updateDepartment", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  updateDesignation(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("designation.updateDesignation", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }
}
