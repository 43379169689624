import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { ClientComponent } from '../client/client.component';
import { MaintenanceComponent } from '../maintenance/maintenance.component';
import { LinkedInComponent } from 'src/app/linkedin/linkedin.component';
import { Users } from '../auth/entities/user';
import { UsersComponent } from '../auth/users/users.component';
import { PermitsComponent } from '../auth/permits/permits.component';
import { InventoryMainComponent } from '../inventory/components/inventory-main/inventory-main.component';
import { NoPermissionComponent } from '../auth/no-permission/no-permission.component';
import { CompanyComponent } from '../company/company.component';
import { AuthGuard } from '../shared/auth-guard';
import { HRMComponent } from '../hrm/hrm.component';
import { TaskComponent } from '../task/task.component';
import { EstimationComponent } from '../estimation/estimation.component';
import { CRMDocumentTypeComponent } from '../crm-document-type/crm-document-type.component';
import { ViewLinkedInTempleteComponent } from '../linkedin-templete/components/view-linkedin-templete/view-linkedin-templete.component';
import { LinkedInTempleteComponent } from '../linkedin-templete/linkedin-templete.component';
import { ClientProductComponent } from '../client-product/client-product.component';
import { InventoryMaintenanceComponent } from '../inventory-maintenance/inventory-maintenance.component';
import { HrmMaintenanceComponent } from '../hrm-maintenance/hrm-maintenance.component';
import { ERMComponent } from '../erm/erm.component';
import { LeaveComponent } from '../leave/leave.component';
import { ApprovalsComponent } from '../approvals/approvals.component';
import { AddHolidayComponent } from '../hrm-maintenance/components/popups/add-holiday/add-holiday.component';
import { HrmLeaveApprovalComponent } from '../approvals/hrm-leave-approval/hrm-leave-approval.component';
import { YearEndProcessComponent } from '../year-end-process/year-end-process.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'client',
        component: ClientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory-maintenance',
        component: InventoryMaintenanceComponent,
      },
      {
        path: 'add-holiday',
        component: AddHolidayComponent,
      },
      {
        path: 'hrm-maintenance',
        component: HrmMaintenanceComponent,
      },
      {
        path: 'linkedin',
        component: LinkedInComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'permits',
        component: PermitsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'inventory',
        component: InventoryMainComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'no-permission',
        component: NoPermissionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'hrm',
        component: HRMComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'erm',
        component: ERMComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'leave',
        component: LeaveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'approvals',
        component: ApprovalsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'task',
        component: TaskComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'estimation',
        component: EstimationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crm-document-type',
        component: CRMDocumentTypeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'linkedin-templete',
        component: LinkedInTempleteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client-product',
        component: ClientProductComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'hrm-leave-approval-component',
        component: HrmLeaveApprovalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'year-end-process',
        component: YearEndProcessComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule]
})

export class AdminRoutingModule {
}
