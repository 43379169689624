import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { EmployeeLeave } from '../entities/empoyeeLeave';

import API from '../../shared/services/api.routes.json';
import { LeaveFullDetails } from 'src/app/leave/models/leave-fulldetails';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ErmService {

  @Output() refreshEmployee: EventEmitter<{ employeeObject: any }> = new EventEmitter<{ employeeObject: any }>();
  @Output() refreshEmployeeInsurance: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshEmloyeeList: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() refershListEmloyee: EventEmitter<{ id: any }> = new EventEmitter<{ id: any }>();
  @Output() selectedEmployee: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshLeaveList: EventEmitter<any> = new EventEmitter<any>();
  private addLeaveSuccess = new Subject<any>();

  isEmployeeSelected: boolean = false;
  isCompletedOneYear: boolean = false;
  isEmployeeEdit: boolean = true;
  isShowInsuarenceCard: boolean = false;
  isShowLeaveCard: boolean = true;

  constructor(
    private apiservice: APIService,
    public commonUIService: UIService
  ) { }

  public setAddleave() {
    this.addLeaveSuccess.next('success');
  }
  public triggerSuccessStatus() {
    return this.addLeaveSuccess.asObservable();
  }
  getEmpInsuranceById(employeeId) {
    let url = this.apiservice.getEndPoint("employeeInsurance.getEmpInsuranceById", [["{employeeId}", employeeId]])
    return this.apiservice.get(url);
  }

  getEmployeeLeaveTypeListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveTypeListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveEventListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveEventListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveDeatail(leaveId) {
    let url = `${API.employeeLeave.getEmployeeLeaveDeatail}`;
    url = url.replace('{leaveId}', leaveId);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveCard(empId, year) {
    let url = `${API.employeeLeave.getLeaveAllcation}`;
    url = url.replace('{empId}', empId);
    url = url.replace('{year}', year);
    return this.apiservice.get(url);
  }

  getEmployeeLeaveAllocation(year) {
    let url = `${API.employeeLeave.getAllLeaveAllcation}`;
    url = url.replace('{year}', year);
    return this.apiservice.get(url);
  }

  calculateYearEndProcess() {
    let url = `${API.employeeLeave.calculateYearEndProcess}`;
    return this.apiservice.get(url);
  }

  getEmployeeLeaveHistoryByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeLeave.getEmployeeLeaveHistoryByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeById(employeeId) {
    let url = this.apiservice.getEndPoint("employee.getEmployeeById", [["{id}", employeeId]]);
    return this.apiservice.get(url);
  }

  getEmployeeByEmail(employeeEmail) {
    let url = this.apiservice.getEndPoint("employee.getEmployeeByEmail", [["{email}", employeeEmail]]);
    return this.apiservice.get(url);
  }

  getEmployeeAttendanceEventListByEmployeeId(employeeId) {
    let url = this.apiservice.getEndPoint("employeeAttendance.getEmployeeAttendanceEventListByEmployeeId", [["{employeeId}", employeeId]]);
    return this.apiservice.get(url);
  }
  saveEmployeeLeave(employeeLeaveObj: EmployeeLeave) {
    let url = this.apiservice.getEndPoint("employeeLeave.saveEmployeeLeave", null);
    return this.apiservice.post(url, employeeLeaveObj, true);
  }
  saveEmployeeLeaveDetails(employeeLeaveObj: LeaveFullDetails) {
    let url = `${API.employeeLeave.saveEmployeeLeaveDetails}`;
    return this.apiservice.post(url, employeeLeaveObj, true);
  }
  getHolidaysForCurrentYear(employeeId: any, isLeaves: any, isHolidays: any, leaveStatus: any) {
    let url = `${API.employeeLeave.getHolidaysForCurrentYear}`;
    url = url.replace('{employeeId}', employeeId);
    url = url.replace('{isLeaves}', isLeaves);
    url = url.replace('{isHolidays}', isHolidays);
    url = url.replace('{leaveStatus}', leaveStatus);
    return this.apiservice.get(url);
  }
  getAllLeaveStatus() {
    let url = `${API.employeeLeave.getAllLeaveStatus}`;
    return this.apiservice.get(url);
  }
} 
