import { RolePermissionModel } from '../../auth/entities/rolePermission';
import { roleModel } from '../../auth/entities/role';
import { TypeOfDocument } from './type-of-document';

export class DocumentPermits {
  id: number;
  typeOfDocumentId: number;
  roleId: number;
  editable: boolean; 
  insertDate: Date;
  insertUser: string;
  updateDate: Date;
  updateUser: string;
  status: boolean;
  visible: boolean;
  typeOfDocumentName: string;
  roleName: string;

  role: roleModel;
  typeOfDocument: TypeOfDocument;
}
