export class FilterClientProjectTaskModel {
  filterId?: any;
  userId?: number;
  isCheckLoggingUser: boolean;
  uptoToday: boolean;
  taskDescription?: string;
  toDate?: any;
  fromDate?: any;
  taskTypeId?: number;
  fromDateStr: any;
  toDateStr: any;
  companyId?: number;
}
