import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { ClientTask } from '../../../entities/clientTask';
import { ProjectTask } from 'src/app/shared/entities/projectTask';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { TaskService } from 'src/app/client/services/task.service';

@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.scss']
})

export class AddEditTaskComponent implements OnInit {

  taskFormGroup: FormGroup;
  _taskType: string;
  _taskModal: any;
  _taskModalForSave: any;
  isEdit: boolean = false;
  isDisabledTask = false;
  minDate: Date = new Date();
  _taskCreationType: string = "";
  _selectedTaskTypeId: any;
  _selectedTaskTypeName: string;
  isTypeRequired: boolean = false;
  private _unsubscribeAll: Subject<any>;
  @ViewChild('description', { static: false }) descriptionField: ElementRef;

  constructor(private _formBuilder: FormBuilder,
    private clientService: ClientService,
    public commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public taskService: TaskService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.initTaskFormGroup();
    this.commonDropdownService.LoadUserList();
    this.commonUIService.loadTaskDateLabels();
    this.setClientTaskDataForEditView();
    this.taskService.LoadTaskTypeListForIcon();
  }

  setClientTaskDataForEditView(): void {
    if (this.modalViewData.data) {
      this._taskType = this.modalViewData.data.type;
      this._taskModal = Object.assign({}, this.modalViewData.data.modalData);
      this._selectedTaskTypeId = this._taskModal.taskTypeId;
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = false;
        this._taskCreationType = this._taskModal ? this._taskModal.text : null;
        this._selectedTaskTypeName = this._taskModal.taskTypeName ? this._taskModal.taskTypeName : null;
        this.taskService.selectedDateId = 0;
      } else {
        this.isEdit = true;
        if (this._taskModal.taskDate) {
          let today = new Date().toISOString();
          if (this._taskModal.taskDate > today) {
            this.minDate = new Date();
          } else {
            this.minDate = this._taskModal.taskDate;
          }
        } else
          this.minDate = new Date();
        this.taskService.selectedDateId = 0;
        this._selectedTaskTypeName = this._taskModal.taskType.taskTypeName;
      }
    }
  }

  // Reactive Form
  initTaskFormGroup() {
    this.taskFormGroup = this._formBuilder.group({
      taskDate: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      status: [''],
      taskTypeId: ['', [Validators.required]],
      taskTime: [''],
      description: [''],
      taskTypeName: ['', [Validators.required]]
    });
  }

  async submit(taskModal) {
    if (this.taskFormGroup.invalid) {
      this.isDisabledTask = false;
      this.isTypeRequired = !!(this.taskFormGroup.get('taskTypeId').errors && this.taskFormGroup.get('taskTypeId').errors.required && this.taskFormGroup.get('taskTypeName').errors.required);
      return;
    }
    const taskModalObj = Object.assign({}, taskModal);
    taskModalObj.taskDateStr = taskModalObj.taskDate ? this.formatDatepicker.ConvertDateFormatForSave(taskModalObj.taskDate) : taskModalObj.taskDate;
    if (this.isEdit) {
      this.updateClientTaskData(taskModal.id, taskModalObj);
    } else {
      this.SaveClientTask(taskModalObj).then();
    }
  }

  updateClientTaskData(taskModalId, taskModalObj) {
    this.BindToModel(taskModalObj);
    this.clientService.updateClientTask(taskModalId, this._taskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.clientService.refreshClientTask.emit(res.data);
          this.clientService.clientProjectTaskUpdate.emit(res.data);
        }
      },
      error=> { console.log(error); this.closeDialog();})
  }

  async SaveClientTask(taskModalObj) {
    this.isDisabledTask = true;
    this.BindToModel(taskModalObj);
    this.clientService.SaveTask(this._taskModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.clientService.isLoading_task = true;
          this.clientService.refreshClientTask.emit(res.data);
          this.clientService.clientProjectTaskUpdate.emit(res.data);        
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  BindToModel(taskModalObj) {
    if (this._taskType === 'clientTask') {
      this._taskModalForSave = new ClientTask();
      this._taskModalForSave.clientId = this.isEdit ? taskModalObj.clientId : this.modalViewData.data.id;
      this._taskModalForSave.status = 0;
    }
    else if (this._taskType === 'projectTask') {
      this._taskModalForSave = new ProjectTask();
      this._taskModalForSave.projectId = this.isEdit ? taskModalObj.projectId : this.modalViewData.data.id;
      this._taskModalForSave.status = false;
    }
    this._taskModalForSave.taskName = null;
    this._taskModalForSave.taskDate = taskModalObj.taskDate;
    this._taskModalForSave.taskDateStr = taskModalObj.taskDateStr;
    this._taskModalForSave.userId = taskModalObj.userId;
    this._taskModalForSave.taskTypeId = taskModalObj.taskTypeId;
    this._taskModalForSave.taskType = null;
    this._taskModalForSave.description = taskModalObj.description;
    this._taskModalForSave.taskTimeStr = taskModalObj.taskTimeStr;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  setDateValueToPicker(item) {
    this._taskModal.taskDate = this.taskService.setDateValueToPicker(item);
  }

  selectTaskType(taskType) {
    this._taskModal.taskTypeId = taskType.id;
    this._selectedTaskTypeId = taskType.id;
    this._selectedTaskTypeName = taskType.taskTypeName;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.descriptionField.nativeElement.focus();
    }, 100);
  }
}
