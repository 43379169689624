import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { UIService } from 'src/app/shared/services/ui.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms'
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ClientModule } from 'src/app/client/client.module';
import { UserModal } from 'src/app/client/entities/user';
import { APIService } from 'src/app/shared/services/api.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'
  ],
})

export class LoginComponent implements OnInit {

  clientForm: FormGroup;
  clientDetail: UserModal;

  ngOnInit(): void {
    this.formvalidate();
  }

  constructor(public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private apiService: APIService,
    private commonUIService: UIService,
    private authAppService: AuthAppService
  ) {
    this.clientDetail = new UserModal();
  }
  formvalidate() {

    this.clientForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],

    })
  }
  /**
   * Sign with google in component
   */
  signInWithGoogle() {
    this.commonUIService.openMask();
    this.loginService.signWithGoogle();
    this.loginService.authorizedSubject.pipe(delay(2000)).subscribe((data) => {
      if (data == true) {
        this.router.navigate(["/module"]);
        this.commonUIService.closeMask();
      }
    });
  }

  signInWithManualUser() {
    this.clientDetail.userName = this.clientForm.value.username;
    this.clientDetail.password = this.clientForm.value.password;

    let url = this.apiService.getEndPoint("user.loginUser", []);
    this.apiService.post(url, this.clientDetail, true).subscribe(res => {
      if (res.data) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 5000);
        this.router.navigate(["/client"]);
        this.commonUIService.openSnackBar(snack);
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error login", "", 7000);
        this.commonUIService.openSnackBar(snack);
      }
    })
  }
}











