import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { LinkedInComponent } from 'src/app/linkedin/linkedin.component';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { ViewLinkedInComponent } from './components/view-linkedin/view-linkedin.component';
import { AddEditLinkedInComponent } from './components/view-linkedin/add-edit-linkedin/add-edit-linkedin.component';
import { LinkedinReasonPopupComponent } from './components/view-linkedin/linkedin-reason-popup/linkedin-reason-popup.component';
import { SendEmailComponent } from './components/view-linkedin/email-popup/email-popup.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    LinkedInComponent,
    ViewLinkedInComponent,
    AddEditLinkedInComponent,
    LinkedinReasonPopupComponent,
    SendEmailComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule, AngularEditorModule],
  entryComponents: [LinkedInComponent, AddEditLinkedInComponent, LinkedinReasonPopupComponent, SendEmailComponent]
})

export class LinkedInModule {
}
