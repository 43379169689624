import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { docoumnetTypeModel } from 'src/app/shared/entities/documentTypeModel';
import { APIService } from 'src/app/shared/services/api.service';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-add-edit-documenttype',
  templateUrl: './add-edit-documenttype.component.html',
  styleUrls: ['./add-edit-documenttype.component.scss']
})

export class AddEditDocumenttypeComponent implements OnInit {
  _documentType: docoumnetTypeModel;
  documentFromGroup: FormGroup;
  isEdit: boolean = false;
  isDisabledDocumentType = false;

  constructor(private _formBuilder: FormBuilder,
    private apiservice: APIService,
    private validationService: ValidationService,
    private maintaneService: MaintenanceService,
    public dialogRef: MatDialogRef<AddEditDocumenttypeComponent>,
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public DocumentTypeTypeData: DialogDataForModalView) { }

  ngOnInit() {
    this.formvalidate();
    this._documentType = new docoumnetTypeModel();
    this.editRegionView();

  }

  formvalidate() {
    this.documentFromGroup = this._formBuilder.group({
      documenttype: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  editRegionView() {
    if (this.DocumentTypeTypeData.data) {
      this._documentType = Object.assign({}, this.DocumentTypeTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(data) {
    if (this.documentFromGroup.invalid) {
      return
    }
    else
    {
        if(!this.isEdit)
        {
          this.isDisabledDocumentType = true;
          this.maintaneService.saveDocumentType(data).subscribe(data=>
            {
              if(data.message=="Successfully saved")
              {
                this.maintaneService.DocumentTypeRefresh(true);
                this.closeDialog();
                let snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
                this.uiService.openSnackBar(snack);
              }
                else{
                this.closeDialog();
                let snack = new SnackBar(SnackBarType.success, data.message, "", 5000);
                this.uiService.openSnackBar(snack);
              }

        });
      }
      if (this.isEdit) {
        this.maintaneService.UpdateDocumentType(data).subscribe(data => {
          if (data.message == "Update Sucess") {
            this.maintaneService.DocumentTypeRefresh(true);
            this.closeDialog();
            let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
            this.uiService.openSnackBar(snack);
          }
          else {
            this.closeDialog();
            let snack = new SnackBar(SnackBarType.success, data.message, "", 5000);
            this.uiService.openSnackBar(snack);
          }

        });
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
