import { ApprovalsService } from './../service/approvals.service';
import { APIService } from './../../shared/services/api.service';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hrm-leave-approval',
  templateUrl: './hrm-leave-approval.component.html',
  styleUrls: ['./hrm-leave-approval.component.scss']
})
export class HrmLeaveApprovalComponent implements OnInit {

  constructor(private _dialog :MatDialog,private approvalsService: ApprovalsService) { }

  ngOnInit() {
  }

  approveLeave()
  {
    this.approvalsService.setApprove('Approved');
    this._dialog.closeAll();
  }


  cancelPopup()
  {
    this._dialog.closeAll();
  }

}
