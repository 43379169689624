import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { EmployeeDocument } from '../../../../entities/empoyeeDocument';
import { Employee } from '../../../../entities/empoyee';
import { HrmService } from '../../../../service/hrm.service';
import { EmpBankDetails } from 'src/app/hrm/entities/EmpBankDetails';
declare const JSON;

@Component({
  selector: 'bank-account-document-view',
  templateUrl: './bank-account-document-view.component.html',
  styleUrls: ['./bank-account-document-view.component.scss']
})
export class BankAccountDocumentViewComponent implements OnInit {

  bankDetailFormGroup: FormGroup;
  _empBankDetails: EmpBankDetails;
  isDisabledAction = false;
  isEdit: boolean = false;
  _empBankDetailsForSave: EmpBankDetails;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<BankAccountDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService) {

    this._empBankDetails = new EmpBankDetails()
  }

  ngOnInit() {
    this.formvalidate();
    this._empBankDetailsForSave = new EmpBankDetails();
    this.LoadEmpBankDetailsByEmpId();

  }

  formvalidate() {
    this.bankDetailFormGroup = this._formBuilder.group({
      accountNumber: ['', [Validators.required]],
      beneficiaryName: ['',  [Validators.required]],
      bank: ['', [Validators.required]],
      branch: ['', [Validators.required]],
    });
  }

  LoadEmpBankDetailsByEmpId(){
    this.hrmService.GetBankDetailByEmpId(this.modalViewData.data.id).subscribe(
      res => {
        if(res.isSuccsess && res.data != null){
          this._empBankDetails = res.data;
        }

      }
    )
  }
  async submit(bankDetailObj) {
    if (this.bankDetailFormGroup.invalid) {
      this.isDisabledAction = false;
      return;
    }
    if (this.isEdit) {
      // this.updateEmpBankDetails(taskModal.id, bankDetailObj);
    } else {
      this.SavEmpBankDetails(bankDetailObj);
    }
  }

  SavEmpBankDetails(bankDetailObj){
    this.BindToModel(bankDetailObj)
     this.hrmService.saveEmployeeBankDetails(this._empBankDetailsForSave).subscribe(
       res => {
         if(res.isSuccsess){
          const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
         }
         else{
          const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
         }
  });
}

BindToModel(bankObj) {
  this._empBankDetailsForSave = new EmpBankDetails();

  if(bankObj.id != undefined){
    this._empBankDetailsForSave.id = bankObj.id;
  }
    this._empBankDetailsForSave.employeeId = this.modalViewData.data.id;
  this._empBankDetailsForSave.accountNumber = bankObj.accountNumber;
  this._empBankDetailsForSave.beneficiaryName = bankObj.beneficiaryName;
  this._empBankDetailsForSave.bank = bankObj.bank;
  this._empBankDetailsForSave.branch = bankObj.branch;
 // this._empBankDetailsForSave.status = 1;
}

closeDialog(): void {
  this.dialogRef.close();
}

}
