export class InventoryIncidenceModel {
  inventoryId: number;
  date: any;
  incidence: string;
  dateResolution: any;
  resolution: string;
  inventory: any;

  dateStr: string;
  dateResolutionStr: string;
}

