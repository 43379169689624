import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { ClientService } from 'src/app/client/services/client.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { AddEditInventoryComponent } from '../add-edit-inventory/add-edit-inventory.component';
import { InventoryModel } from '../../entities/inventoryModel';
import { InventoryService } from '../../service/inventoryService';
import { SimmModels } from '../../entities/simm';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../../shared/services/api.routes.json';
import { empty, Subscription } from 'rxjs';
import { debug } from 'util';
import { ProvidersModel } from '../../entities/providers';
import { FilterLaptopDetailModel } from '../../entities/filterLaptopDetailModel';
import { FilterLaptopDetailComponent } from './filter-laptop-details/filter-laptop-details.component';
import moment from 'moment';
import { PageEvent } from '@angular/material';
@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit {
  searchInputField: string = null;
  inventorylist: InventoryModel[] = [];
  simmList: SimmModels[] = [];
  emptylist: boolean = false;
  currentId: number;
  exitSimm: boolean = false;
  exitInventory: boolean = false;
  exitLapTop: boolean = false;
  private subscriber: Subscription;
  isLoading: boolean = false;
  simmCurrentId: number;
  providerCurrentId: number;
  isChecked: boolean = false;
  providerList: ProvidersModel[] = [];
  exitProvider: boolean = false;
  selectedTabIndex: number = 0;
  state: any;
  brand: string;
  cpuType: string;
  currentStorageType: string;
  ramType: string;
  modelInvent: InventoryModel;
  laptopDetailForExcelExport: FilterLaptopDetailModel;
  todayDate: any;
  tabId: number = 0;
  kpiList: any[] = [];
  _tabId = undefined;
  pageL = undefined;
  inventoryTypeGlobal: number;
  filterText: string = 'All';

  //pagination properties
  lowValue: number = 0;
  highValue: number = 15;
  pageIndex: number = 0;

  constructor(public commonDropdownService: CommonDropdownService,
    public inventoryService: InventoryService,
    public commonUIservice: UIService,
    public _apiService: APIService) {
    this.inventorylist = [];
    this.simmList = [];
    this.providerList = [];
    this.modelInvent = new InventoryModel();
    this.laptopDetailForExcelExport = new FilterLaptopDetailModel();
    this.inventoryService.isMoreFilterActive = false;
    localStorage.removeItem("filterStorageModel");
  }

  ngOnInit() {
    this.tabId = this.inventoryService.slectedInventoryIndex;
    this.subscribeLoadInventoryDetals();
    this.commonDropdownService.LoadInventoryList();
    let tid = this.tabId ? this.tabId : 0;
    this.loadinventorylist(null, tid, null);
    this.loadAddRefeshInventor();
    this.refreshSimmUpdate();
    this.refreshProvidersUpdate();
    this.subscribeLaptopMoreFilter();
    this.subscribeLaptopClearFilter();
    this.subscribeLoadKPIStatistics();
    this.todayDate = new Date().toISOString();
    this.loadKPIStatistics(0);
  }


  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }


  // public getPaginatorData(event: any) {
  //   this.inventoryService.inventoryFilterModel.page = 1;
  //   this.inventoryService.inventoryFilterModel.pageSize = event;

  // }

  // public viewPreviousPage(event: PageEvent) {
  //   if (this.inventoryService.inventoryFilterModel.page !== 1) {
  //     this.inventoryService.inventoryFilterModel.page--;
  //   }
  // }

  // public viewNextPage(event: PageEvent) {
  //   if (this.inventoryService.highValue !== this.inventoryService.totalCount) {
  //     this.inventoryService.inventoryFilterModel.page++;
  //   }
  // }

  loadKPIStatistics(tabId) {
    if (tabId == undefined) {
      this.tabId = 0;
    }
    this._apiService.get(API.Inventory.getKPIStatistics).subscribe(response => {
      if (response) {
        if (response.data) {
          this.kpiList.length = 0;
          if (this.tabId == 0) {
            this.kpiList.push(response.data[0]);
          }
          //else if (this.tabId == 1) {
          //  this.kpiList.push(response.data[1]);
          //} else if (this.tabId == 2) {
          //  this.kpiList.push(response.data[3]);
          //}
        }
      }
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  loadAddRefeshInventor() {
    this.inventoryService.refershListInventor.subscribe(res => {
      this.loadinventorylist(res.id, this.inventoryService.slectedInventoryIndex, null);
    });
  }

  refreshSimmUpdate() {
    this.inventoryService.refreshSimmUpdate.subscribe(res => {
      this.loadinventorylist(null, 4, res.simmId);
    })
  }

  refreshProvidersUpdate() {
    this.inventoryService.refreshProvidersUpdate.subscribe(res => {
      this.loadinventorylist(null, 5, res.providerId);
    })
  }

  loadinventorylist(id, index, simProviderIds) {
    this.lowValue = 0;
    this.highValue = 15;

    this.searchInputField = "";
    this.inventoryTypeGlobal = index;
    let type = index;
    this.currentId = id;
    this.simmList = null;
    this.providerList = null;
    this.isLoading = true;
    if (type == 4) {
      this._apiService.get(API.Simm.ListAll).subscribe(res => {
        if (res && res.data) {
          this.exitSimm = true;
          this.emptylist = true;
          this.exitInventory = true;
          this.exitLapTop = false;
          this.inventorylist = res.data;
          this.isLoading = false;
          this.simmCurrentId = simProviderIds;
        }
        else {
          this.exitSimm = true;
          this.exitProvider = true;
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }
      })
    } else if (type == 5) {
      this._apiService.get(API.provider.listAll).subscribe(res => {
        if (res && res.data) {
          this.exitProvider = true;
          this.exitInventory = false;
          this.emptylist = true;
          this.exitLapTop = false;
          this.inventorylist = res.data;
          this.isLoading = false;
          this.providerCurrentId = simProviderIds;
        }
        else {
          this.exitSimm = true;
          this.exitProvider = true;
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }
      })
    }
    else {
      this.inventoryService.getInventoryList(type).subscribe(res => {
        if (res && res.data) {
          this.exitSimm = false;
          this.exitInventory = true;
          this.exitProvider = false;
          this.inventorylist = res.data;
          
          this.inventorylist.sort(function(a,b){
            return a.state - b.state;
          })
          this.emptylist = true;
          for (let x = 0; x < this.inventorylist.length; x++) {
            if (this.inventorylist[x].entryDate) {
              let _todayDate = new Date().toISOString().split("T")[0];
              let _entryDate = this.inventorylist[x].entryDate.split("T")[0];
              let month = moment(_todayDate).diff(moment(_entryDate), 'month', true);

              if(month < 1){
                let days = moment(_todayDate).diff(moment(_entryDate), 'day', true);
                this.inventorylist[x].inventoryDays = days;
              }

              if (month >= 12) {
                let _year = (month - (month % 12)) / 12;
                this.inventorylist[x].inventoryYear = _year;
                let _month = (month % 12).toString();
                this.inventorylist[x].inventoryMonth = parseInt(_month);
              }
            }
            if (this.inventorylist[x].type == "LAPTOP") {
              this.exitLapTop = true;
              if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
              else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
              else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
              else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
            }
            else if (this.inventorylist[x].type == 'PHONE' || this.inventorylist[x].type == 'MOBILE' || this.inventorylist[x].type == 'PAD') {
              if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
              else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
              else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
              else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
            }
            else if (this.inventorylist[x].type == 'MONITOR' || this.inventorylist[x].type == 'TV') {
              if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
              else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
              else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
              else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
            }
            else {
              this.inventorylist[x].statestr = null;
            }
          }
          this.isLoading = false;
        }
        else {
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }
      });
    }
  }

  SearchInventoryByType(event) {
    if (event.target.value) {
      if (this.inventoryTypeGlobal == 4) {
        var x = null;
        var y;
        if (isNaN(event.target.value)) {
          x = event.target.value;
        }
        else {
          y = event.target.value;
          y = parseInt(y);
        }
        this.inventoryService.getBySimmNumberUser(x, y).subscribe(res => {
          if (res && res.data) {
            this.inventorylist = res.data;
            this.emptylist = true;
          } else {
            this.emptylist = false;
            this.inventorylist = null;
          }
        });
      } else {
        var name = event.target.value;
        this.inventoryService.getInventoryLaptopListBySearch(this.inventoryTypeGlobal, name).subscribe(res => {
          if (res && res.data) {
            this.inventorylist = res.data;
            for (let x = 0; x < this.inventorylist.length; x++) {
              let item = this.inventorylist[x];
              if (item.type == "LAPTOP" || item.type == 'PHONE' || item.type == 'MOBILE' || item.type == 'PAD' || item.type == 'MONITOR' || item.type == 'TV') {
                if (item.state == 0) { item.statestr = "Available" }
                else if (item.state == 1) { item.statestr = "In use" }
                else if (item.state == 2) { item.statestr = "Damaged" }
                else if (item.state == 3) { item.statestr = "Lost" }
              }
              else {
                item.statestr = null;
              }
            }
            this.emptylist = true;
          } else {
            this.emptylist = false;
            this.inventorylist = null;
            this.isLoading = false;
          }
        });
      }
    }
    else if (event.target.value == "") {
      this.loadinventorylist(null, this.inventoryTypeGlobal, null);
    }
  }

  inventorListFilter(data) {
    this.selectedTabIndex = this.inventoryService.slectedInventoryIndex;
    this.inventoryService.isInventorySelected = false;
    this.loadinventorylist(null, this.selectedTabIndex, null);
    this.inventoryService.refreshInvetoryType.emit(data.textLabel);
    if (data.textLabel == "SIMM Card") {
      this.exitSimm = true;
      this.exitLapTop = false;
    }
    else {
      this.exitSimm = false;
      this.exitLapTop = false;
    }
    if (data.textLabel == "Providers")
      this.exitProvider = true;
    else
      this.exitProvider = false;
  }

  selectInventory(list, event) {
    if (list.type) {
      this.simmCurrentId = null;
      this.providerCurrentId = null;
      this.currentId = list.id;
      this.inventoryService.refreshInventor.emit({ list: list, tabName: "" });
    }
    else {
      if (this.selectedTabIndex == 5) {
        this.currentId = null;
        this.simmCurrentId = null;
        this.providerCurrentId = list.id;

        for (let x = 0; x < list.length; x++) {
          list[x].type = "Providers";
        }

        this.inventoryService.refreshInventor.emit({ list: list, tabName: "Providers" });
      } else {
        this.currentId = null;
        this.providerCurrentId = null;
        this.simmCurrentId = list.id;

        for (let x = 0; x < list.length; x++) {
          list[x].type = "SIMM Card";
        }

        this.inventoryService.refreshInventor.emit({ list: list, tabName: "SIMM Card" });
      }
    }
  }

  openAddInventoryView() {
    this.currentId = null;
    this.inventoryService.addInventoryEmitter.emit({ isEdit: false, selectedTabIndex: this.selectedTabIndex });
  }

  FilterSimmByUser(event) {
    this.isChecked = event.checked;
    this._apiService.get(API.Simm.ListAll).subscribe(res => {
      if (res && res.data) {
        this.exitSimm = true;
        this.exitLapTop = false;
        this.emptylist = true;
        if (this.isChecked == true) {
          this.filterText = 'Available';
          this.inventorylist = res.data.filter(x => x.user === '' || x.user === null || x.user === undefined);
          if (this.inventorylist.length == 0)
            this.inventorylist = null;
        } else {
          this.filterText = 'All';
          this.inventorylist = res.data;
        }
        this.isLoading = false;
      }
      else { this.emptylist = false; }
    })
  }

  openAddProviderSimDetails(event) {
    if (this.selectedTabIndex == 4) {
      this.currentId = null;
      this.providerCurrentId = null;
      this.simmCurrentId = null;
      this.inventoryService.refreshInventor.emit({ list: null, tabName: "SIMM Card" });
    } else {
      this.currentId = null;
      this.simmCurrentId = null;
      this.providerCurrentId = null;
      this.inventoryService.refreshInventor.emit({ list: null, tabName: "Providers" });
    }
  }

  openFilterLaptopPopup() {
    this.commonUIservice.openDialog(FilterLaptopDetailComponent, null)
  }

  subscribeLoadInventoryDetals() {
    this.inventoryService.loadInventoryDetails.subscribe(data => {
      if (data) {
        localStorage.removeItem("filterStorageModel");
        this.inventoryService.slectedInventoryIndex = data.selectedTab;
        this.inventorListFilter(this.inventoryService.slectedInventoryIndex);
      }
    });
    localStorage.setItem('pageLoad', JSON.stringify(true));
  }

  subscribeLaptopMoreFilter() {
    this.inventoryService.refreshLaptopList.subscribe(res => {
      if (res.length > 0) {
        this.inventorylist = res;
        this.inventoryService.isInventorySelected = false;
        for (let x = 0; x < this.inventorylist.length; x++) {
          if (this.inventorylist[x].type == "LAPTOP") {
            if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
            else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
            else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
            else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
          }
          else if (this.inventorylist[x].type == 'PHONE' || this.inventorylist[x].type == 'MOBILE' || this.inventorylist[x].type == 'PAD') {
            if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
            else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
            else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
            else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
          }
          else if (this.inventorylist[x].type == 'MONITOR' || this.inventorylist[x].type == 'TV') {
            if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
            else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
            else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
            else if (this.inventorylist[x].state == 3) { this.inventorylist[x].statestr = "Lost" }
          }
          else {
            this.inventorylist[x].statestr = null;
          }
        }
      } else {
        this.inventoryService.isInventorySelected = false;
        this.inventorylist = null;
      }
    })
  }

  subscribeLaptopClearFilter() {
    this.inventoryService.clearLaptopList.subscribe(res => {
      if (res) {
        localStorage.removeItem("filterStorageModel");
        this.inventoryService.isMoreFilterActive = false;
        this.inventoryService.isInventorySelected = false;
        this.loadinventorylist(null, 0, null);
      }
    })
  }

  subscribeLoadKPIStatistics() {
    this.inventoryService.refreshLoadKPIStatistics.subscribe(res => {
      this.tabId = res;
      this.loadKPIStatistics(this.tabId);
    })
  }
}
