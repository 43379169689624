import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { HrmService } from '../../service/hrm.service';
import { Employee } from '../../entities/empoyee';

@Component({
  selector: 'app-hrm-detail',
  templateUrl: './hrm-detail.component.html',
  styleUrls: ['./hrm-detail.component.scss']
})

export class HRMDetailComponent implements OnInit {

  employeeModel: Employee;

  constructor(
    public commonUIService: UIService,
    public commonDropdownService: CommonDropdownService,
    public cdRef: ChangeDetectorRef,
    public hrmService: HrmService) {
    this.employeeModel = new Employee();
  }

  ngOnInit() {
    this.subscribeRefreshEmployee();
  }

  subscribeRefreshEmployee() {
    this.hrmService.refreshEmployee.subscribe(res => {
      if (res.employeeObject) {
        this.hrmService.isEmployeeSelected = true;
        this.employeeModel = res.employeeObject;
        this.employeeModel.isEdit = true;
      }
    });
  }
}
