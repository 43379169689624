export class LinkedIn {
  id: any;
  entryDate: any;
  name: string;
  companyId: any;
  city: string;
  state: string;
  reminderDate: any;
  reminderDateStr:string;
  email: string;
  comments: string;
  userId: number;
  status?: boolean;
  company: string;
  isLinkedIn?: boolean = false;
  phoneNumber: number;
  statusId? : number;
  countryId: any;
  country: string;
  language: string;

  userName: string;
  companyName: string;
  linkedInUserImageUrl: string;
  color: string;
  statusNavigation:any;
  user: any;
  updatedCatagory: string;
  reminderDateView: any;
  toggle: any;
  initials: any;
  isReminderDropdown: boolean;
  characterLength: any;
  showMore: any;
  page: number;
  pageSize: number;
  pageItems: number;
}
