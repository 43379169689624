export class EmployeeLeave {
  id: number;
  employeeId: number;
  startDate: any;
  endDate: any;
  isFullDayLeave: boolean;
  comment: string;
  absenceReasonText: string;
  startTime: string;
  endTime: string;
  leaveTypeId: any;
  leaveTypeText: string;
  insertDate: Date;
  insertUser: string;
  updateDate: Date;
  updateUser: string;
  status: number;
  employeeName: string
  startDateStr: string;
  endDateStr: string;
  startTimeStr: string;
  endTimeStr: string;
  constructor() {
    this.isFullDayLeave = false;
  }
}
