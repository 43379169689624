import { Component, OnInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { InventoryModel } from '../../entities/inventoryModel';
import { ClientService } from 'src/app/client/services/client.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../../shared/services/api.routes.json';
import { state } from '@angular/animations';
import { InventoryUserModel } from '../../entities/inventoryUser';
import { InventoryService } from '../../service/inventoryService';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.scss']
})
export class InventoryDetailComponent implements OnInit, OnChanges {

  inventoryDetailForm: FormGroup;
  title: string = "";
  inventoryModel: InventoryModel;
  inventoryUserModel: InventoryUserModel;
  currentId: number;
  selectInventoryId: number = 0;
  inventoryType: any;
  simmId: number;
  providerId: number;

  constructor(public fb: FormBuilder, public inventoryService: InventoryService, private apiService: APIService,
    private commonDropdownService: CommonDropdownService,
    private cdRef: ChangeDetectorRef) {
    this.subscribeEventEmitters();
    this.inventoryModel = new InventoryModel();
    this.inventoryModel.isEdit = false;
    this.inventoryService.isInventorySelected = false;
  }

  ngOnInit() {
    this.initInventoryDetailForm();
    this.commonDropdownService.LoadInventoryStateDropdown();
    //this.commonDropdownService.LoadInventoryCpuTypDropdown();
    //this.commonDropdownService.LoadInventoryMouseTypDropdown();
    this.getListId();
    this.inventoryModel = new InventoryModel();
    this.inventoryUserModel = new InventoryUserModel();
  }

  ngOnChanges() { }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  subscribeEventEmitters() {
    this.inventoryService.addInventoryEmitter.subscribe(res => {
      if (res) {
        this.inventoryDetailForm.enable();
        this.inventoryModel = new InventoryModel();
        this.inventoryService.isInventorySelected = true;
        this.inventoryType = res.selectedTabIndex === 0 ? "LAPTOP" : (res.selectedTabIndex === 1 ? "PHONE" : (res.selectedTabIndex === 2 ? "MONITOR" : ""));
        this.inventoryModel.state = 0;
        this.inventoryModel.isEdit = res.isEdit ? res.isEdit : false;
        this.inventoryModel.selectedIndex = res.selectedTabIndex ? res.selectedTabIndex : 0;
        this.inventoryModel.type = this.inventoryType;
      }
    })
  }

  initInventoryDetailForm() {
    this.inventoryDetailForm = this.fb.group({
      id: [''],
      code: ['', [Validators.required]],
      name: [''],
      state: [''],
      serialNumber: [''],
      brand: [''],
      model: [''],
      purchase: [''],
      cpu: [''],
      originalStorage: [''],
      currentStorage: [''],
      ramType: [''],
      currentRAM: [''],
      mouseModel: [''],
      mouseDate: [''],
      os: [''],
      comments: [''],
      cputype: [''],
      mouseType: [''],
      currentStorageType: [''],
      originalSotargeType: [''],
      screensize: [''],
      currentUser: [''],
      type: ['', [Validators.required]],
      screenSize: [''],
      resoultion: [''],
      version: [''],
      originalRAM: [''],
      date: ['', [Validators.required]],
      cost: [''],
      providerId: [''],
      currency: [''],
      condition: [''],
      specialNote: [''],
      keyboardModel: [''],
      keyboardDate: [''],
      keyboardType: [''],
      entryDate: [''],
      cputypeId: [null],
      originalSotargeTypeId: [null],
      currentStorageTypeId: [null],
      resolutionId: [null],
      ramtypeId: [null],
      osTypeId: [null],
      mouseTypeId: [null],
      keyboardTypeId: [null],
      screenSizeTypeId: [null],
      mobileTypeId: [null],
      mobileOsTypeId: [null],
      monitorOsTypeId: [null],
      monitorTypeId: [null],
      monitorScreenSizeId: [null],
      monitorScreenResolutionId: [null],
      miscellaneousTypeId:[null]
    })
  }

  getListId() {
    this.inventoryService.refreshInventor.subscribe(res => {
      this.inventoryService.isInventorySelected = true;
      this.inventoryDetailForm.disable();
      if (res.list) {
        if (res.list.state == 1) {
          this.inventoryModel.checkIsInuse = true;
        } else {
          this.inventoryModel.checkIsInuse = false;
        }
        if (res.list.type) {
          this.currentId = res.list.id;
          this.inventoryType = res.list.type;
          this.setValueForDisplay(this.currentId);
        } else if (res.tabName == "SIMM Card") {
          this.simmId = res.list.id;
          this.inventoryType = "SIMM Card";
        } else if (res.tabName == "Providers") {
          this.providerId = res.list.id;
          this.inventoryType = "Providers";
        }
      }
      else {
        if (res.tabName == "SIMM Card") {
          this.simmId = 0;
          this.inventoryType = "SIMM Card";
        } else if (res.tabName == "Providers") {
          this.providerId = 0;
          this.inventoryType = "Providers";
        } else { }
      }
    });
  }

  setValueForDisplay(currentId) {
    this.inventoryService.InventoryGetById(currentId).subscribe(res => {
      this.inventoryModel = res.data[0];
      this.inventoryModel.isEdit = true;
      this.inventoryModel.showEditSave = false;
      if (this.inventoryModel.state == 1) {
        this.inventoryModel.checkIsInuse = true;
      } else {
        this.inventoryModel.checkIsInuse = false;
      }
      if (this.inventoryModel.screenSize) {
        this.inventoryModel.screenSize = this.inventoryModel.screenSize.toString();
      }
      this.inventoryModel.screenSizeString = this.inventoryModel.screenSize ? this.inventoryModel.screenSize.toString() : this.inventoryModel.screenSize;
      this.inventoryUserModel = res.data[0].inventoryUser;
      this.selectInventoryId = res.data[0].id;
    });
  }
}
