import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { LinkedInComponent } from 'src/app/linkedin/linkedin.component';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { InventoryMainComponent } from './components/inventory-main/inventory-main.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';
import { InventoryDetailComponent } from './components/inventory-detail/inventory-detail.component';
import { AddEditInventoryComponent } from './components/add-edit-inventory/add-edit-inventory.component';
import { AddEditLaptopComponent } from './components/add-edit-inventory/add-edit-laptop/add-edit-laptop.component';
import { LaptopDetailComponent } from './components/inventory-detail/laptop-detail/laptop-detail.component';
import { InvoicesComponent } from './components/inventory-detail/invoices/invoices.component';
import { AttachInvoiceComponent } from './components/inventory-detail/invoices/attach-invoice/attach-invoice.component';
import { InventoryUserComponent } from './components/inventory-detail/inventory-user/inventory-user.component';
import { InventoryIncidenceComponent } from './components/inventory-detail/inventory-incidence/inventory-incidence.component';
import { MobileDetailComponent } from './components/inventory-detail/mobile-detail/mobile-detail.component';
import { MonitorDetailComponent } from './components/inventory-detail/monitor-detail/monitor-detail.component';
import { SimmDetailComponent } from './components/inventory-detail/simm-detail/simm-detail.component';
import { ProvidersComponent } from './components/inventory-detail/providers/providers.component';
import { InventoryRepairComponent } from './components/inventory-detail/inventory-repair/inventory-repair.component';
import { AddInventoryRepairComponent } from './components/inventory-detail/inventory-repair/add-inventory-repair/add-inventory-repair.component';
import { AddInventoryIncidenceComponent } from './components/inventory-detail/inventory-incidence/add-inventory-incidence/add-inventory-incidence.component';
import { MiscellaneousDetailComponent } from './components/inventory-detail/miscellaneous-detail/miscellaneous-detail.component';
import { InventoryMainDetailListComponent } from './components/inventory-main-detail-list/inventory-main-detail-list.component';
import { AddInventoryUserComponent } from './components/inventory-detail/inventory-user/add-inventory-user/add-inventory-user.component';
import { FilterLaptopDetailComponent } from './components/inventory-list/filter-laptop-details/filter-laptop-details.component';
import { FurnitureDetailComponent } from './components/furniture-detail/furniture-detail.component';
import { AddFurnitureComponent } from './components/furniture-detail/add-furniture/add-furniture.component';
import { NgxMaskModule } from 'ngx-mask'
import { AddSimmUserComponent } from './components/inventory-detail/simm-detail/add-simm-user/add-simm-user.component';
import { EditInvoiceComponent } from './components/inventory-detail/invoices/edit-invoice/edit-invoice.component';

@NgModule({
  declarations: [
    InventoryMainComponent,
    InventoryListComponent,
    InventoryDetailComponent,
    AddEditInventoryComponent,
    AddEditLaptopComponent,
    LaptopDetailComponent,
    InvoicesComponent,
    AttachInvoiceComponent,
    InventoryUserComponent,
    InventoryIncidenceComponent,
    MobileDetailComponent,
    MonitorDetailComponent,
    SimmDetailComponent,
    ProvidersComponent,
    InventoryRepairComponent,
    AddInventoryRepairComponent,
    AddInventoryIncidenceComponent,
    InventoryMainDetailListComponent,
    MiscellaneousDetailComponent,
    AddInventoryUserComponent,
    FilterLaptopDetailComponent,
    FurnitureDetailComponent,
    AddFurnitureComponent,
    AddSimmUserComponent,
    EditInvoiceComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule, NgxMaskModule.forRoot()],
  entryComponents: [AddEditInventoryComponent, AttachInvoiceComponent, AddInventoryRepairComponent, AddInventoryIncidenceComponent, AddInventoryUserComponent, FilterLaptopDetailComponent, AddFurnitureComponent, AddSimmUserComponent,EditInvoiceComponent]
})

export class InventoryModule {

}
