import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from 'src/app/client/services/client.service';
import { FormGroup } from '@angular/forms';
import { InventoryUserModel } from 'src/app/inventory/entities/inventoryUser';
import { InventoryService } from 'src/app/inventory/service/inventoryService';

@Component({
  selector: 'app-inventory-user',
  templateUrl: './inventory-user.component.html',
  styleUrls: ['./inventory-user.component.scss']
})
export class InventoryUserComponent implements OnInit {

  listUser: InventoryUserModel[] = [];
  inventoryUserEmty: boolean = false;
  @Input() selectInventory: any;

  constructor(public inventoryService: InventoryService) {
    this.listUser = [];
  }

  ngOnInit() {
    this.subscribeRefreshInventoryUser();
  }

  ngOnChanges() {
    if (this.selectInventory != 0) { this.loadUser(this.selectInventory); }
  }

  loadUser(id) {
    this.inventoryUserEmty = false;
    this.inventoryService.InventoryGetById(id).subscribe(res => {
      this.listUser = res.data[0].inventoryUser.sort((a, b) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime());
      if (res.data[0].inventoryUser.length > 0) { this.inventoryUserEmty = true; }
    })
  }

  subscribeRefreshInventoryUser() {
    this.inventoryService.refreshInventoryUser.subscribe(res => {
      if (res) this.loadUser(this.selectInventory);
    })
  }
}
