import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import API from './api.routes.json';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  private readonly _baseUrl: string;
  routes = {};

  constructor(private http: HttpClient) {
    this._baseUrl = API.baseUrl;
  }

  /**
   * Get url of the end point
   * @param urlName : url name should to be and string, endpoint navigation break by comma separator Ex: "client.getclientsbyclientStatus"
   * @param replacements : replacements should to be and array of array, one array should include the key and value. key is the replacement key
   */
  getEndPoint(urlName: string, replacements: any[]) {  
    const urlBreakArray = urlName.split('.');
    let endPointUrl: any = API;
    urlBreakArray.forEach(element => {
      endPointUrl = endPointUrl[element];
    });

    if (replacements) {
      replacements.forEach(element => {
        endPointUrl = endPointUrl.replace(element[0], element[1]);
      });
    }
    return endPointUrl;
  }

  get(url: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }
    const uri = this._baseUrl + url;
    return this.http
      .get(uri, options)
      .pipe(map(response => response), catchError(err => of(err)));
  }

  post(url: string, data?: any, mapJson: boolean = true) {
    const options = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json'
      })
    };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (mapJson) {
      return this.http.post(this._baseUrl + url, data, options)
        .pipe(map(response => <any>response), catchError(() => of('error')));
    }
    else {
      return this.http.post(this._baseUrl + url, data, options);
    }
  }

  delete(url: string, id: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    return this.http.delete(this._baseUrl + url + '/' + id, options)
      .pipe(map(response => <any>(response)), catchError(() => of('error')));
  }

  put(url: string, data?: any, mapJson: boolean = true) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (mapJson) {
      return this.http.put(this._baseUrl + url, JSON.stringify(data), options)
        .pipe(map(response => <any>response), catchError(err => of(err)));
    }
    else {
      return this.http.put(this._baseUrl + url, data, options);
    }
  }

  upload(url: string, formData: FormData, mapJson: boolean = true) {    
    let headers: HttpHeaders = new HttpHeaders();

    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    const params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
      headers: headers
    };

    if (mapJson) {
      return this.http.post(this._baseUrl + url, formData, options)
        .pipe(map(response => <any>response), catchError(() => of('error')));
    }
    else {
      return this.http.post(this._baseUrl + url, formData, options);
    }
  }
  
  patch(url: string, data?: any, mapJson: boolean = true) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (url.charAt(0) !== '/') {
      url = '/' + url;
    }

    if (mapJson) {
      return this.http.patch(this._baseUrl + url, data, options)
        .pipe(map(response => <any>response), catchError(() => of('error')));
    }
    else {
      return this.http.patch(this._baseUrl + url, data, options);
    }
  }
}
