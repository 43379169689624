import { Component } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ApprovalsService } from 'src/app/approvals/service/approvals.service';

interface Leave {
  value: string;
  viewValue: string;
}
interface Duration {
  value: string;
  viewValue: string;
}
interface Coverage {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'cancel-leave',
  templateUrl: './cancel-leave.component.html',
  styleUrls: ['./cancel-leave.component.scss'],
  host: {
    '(window:paste)': 'handlePaste( $event )',
  },
})
export class CancelLeaveComponent {
  selectedValue: string;
  selectedDuration: string;

  leaves: Leave[] = [
    { value: 'casual', viewValue: 'Casual' },
    { value: 'annual', viewValue: 'Annual' },
    { value: 'sick', viewValue: 'Sick' },
    { value: 'statutory', viewValue: 'Statutory' },
    { value: 'maternity', viewValue: 'Maternity' },
  ];

  durations: Duration[] = [
    { value: 'full-day', viewValue: 'Full Day' },
    { value: 'half-day', viewValue: 'Half Day' },
  ];

  coverages: Coverage[] = [
    { value: 'first-person', viewValue: 'First Person' },
    { value: 'second-person', viewValue: 'Second Person' },
  ];
  reasonForm: FormGroup;
  constructor(
    public commonDropdownService: CommonDropdownService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    public dialogRef: MatDialogRef<CancelLeaveComponent>,
    private approvalsService: ApprovalsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    })
  }

  submit() {
    if (this.reasonForm.valid) {
      this.approvalsService.setCancelEvent(this.reasonForm.get('reason').value);
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
