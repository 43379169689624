export class ProjectAction {
  id: number;
  actionTypeId: number;
  projectId: number;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  type: string;
  who: string;
  actionDate: any;
  actionName: string;
  comment: string;
  userId: number;
  actionType: any;
  answer: string;
  author: string;
  initials: any;
  actionUserImageUrl: string;
  text: string;
  actionTypeName: string;
  doneTaskId?: number;
  nextTaskTypeId?:number;
  nextTaskDate?: any;
  nextTaskUserId?:number;
  nextTaskTime?: any;
  nextTaskDescription: string;
  nextTaskDateStr: string;
  nextTaskTimeStr?:any;
  loggedUserId: any;
}
