export class ActionTypeModel {
  id: number;
  actionType1: string;
  status: boolean;
  active?: boolean;
  isExistId: boolean;
  icon: string;
  isHeaderType: boolean;
  order: number;
}
