import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'erm',
  templateUrl: './erm.component.html',
  styleUrls: ['./erm.component.scss']
})

export class ERMComponent {

}
