import { Component, OnInit } from '@angular/core';
import API from '../../../shared/services/api.routes.json';
import { Subscription, Subject } from 'rxjs';
import { Users } from '../../../auth/entities/user';
import { LoginService } from '../../../auth/login/login.service';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { UIService } from '../../../shared/services/ui.service';
import { APIService } from '../../../shared/services/api.service';
import { AlertService } from '../../../shared/services/alert.service';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { AlertModel } from '../../../shared/entities/alertModel';
import { SharedHeaderService } from 'src/app/shared/services/shared.header.services';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { LinkdinModel } from 'src/app/shared/entities/linkedinModel';
import { LinkedInService } from '../../../linkedin/service/linkedin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../shared/services/validation.service';
import { LinkedIn } from '../../../linkedin/entities/linkedin';
import { AuthAppService } from '../../../shared/services/auth-app.service';
import { Router } from '@angular/router';
import { Template } from '../../entities/template';
import { TemplateCategory } from '../../entities/templateCategory';
import { TemplateSubcategory } from '../../entities/templateSubcategory';
import { AddEditTempleteSubcategoryComponent } from '../add-edit-templete-subcategory/add-edit-templete-subcategory.component';
import { AddEditTempleteCategoryComponent } from '../add-edit-templete-category/add-edit-templete-category.component';
import { AddEditTempleteComponent } from '../add-edit-templete/add-edit-templete.component';

@Component({
  selector: 'app-view-linkedin-templete',
  templateUrl: './view-linkedin-templete.component.html',
  styleUrls: ['./view-linkedin-templete.component.scss']
})
export class ViewLinkedInTempleteComponent implements OnInit {
  templeteFormGroup: FormGroup;
  _templeteCategoryList: TemplateCategory[] = [];
  template: Template;
  _idSelectedTemplate: number;
  _idSelectedTemplateCategory: number;
  _idSelectedTemplateSubcategory: number;
  private subscriber: Subscription;
  permissionsList: any[] = [];
  selectedCategoryId: number = null;
  selectedSubcategoryId: number = null;

  constructor(private commonUIService: UIService,
    private linkedInService: LinkedInService,
    private _apiService: APIService,
    private loginServic: LoginService,
    private alertService: AlertService,
    private _formBuilder: FormBuilder,
    private sharedHeaderService: SharedHeaderService,
    private authAppService: AuthAppService,
    public commonDropdownService: CommonDropdownService,
    private router: Router,
    private validationService: ValidationService,
    private formatDatepicker: FormatMatDatepicker) {
    this.template = new Template();
  }

  ngOnInit() {
    this.loadTempleteCategoryList();
    this.subscribeAlertConfirmEvents();
    this.subscribeRefreshCategoryList();
    this.initFormGroup();
    this.permissionsList = this.authAppService.getLoggedInPermission();
    this.template.isEdit = false;
    this.template.isDelete = false;
    this.template.isSave = false;
    this.template.isAdd = false;
  }

  initFormGroup() {
    this.templeteFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      espsubject: [''],
      esptext: [''],
      catsubject: [''],
      cattext: [''],
      engsubject: [''],
      engtext: [''],
      main: ['']
    })
    this.templeteFormGroup.reset();
    if (this.templeteFormGroup) {
      this.templeteFormGroup.get('name').markAsUntouched();
    }
  }

  loadTempleteCategoryList() {
    this.linkedInService.getTempleteCategoryList().subscribe(res => {
      if (res && res.data) {
        this._templeteCategoryList = res.data;
        this._templeteCategoryList.forEach(c => {
          if (c.templateSubcategory.length > 0) {
            c.templateSubcategory.forEach(sc => {
              sc.flag = false;
            });
          }
          c.flag = false;
        });
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIService.openSnackBar(snack);
        this._templeteCategoryList = [];
      }
    },
      err => { console.error(err); }, () => { });
  }

  templeteMainCheckEvent(event) {
    this.template.main = event.checked;
  }

  toggleTemplateCategoryList(templeteCategory: TemplateCategory) {
    templeteCategory.flag = !templeteCategory.flag;
  }

  toggleTemplateSubcategoryList(templateSubcategory: TemplateSubcategory) {
    templateSubcategory.flag = !templateSubcategory.flag;
  }

  openAddTempleteSubcategoryPopup(templeteCategory) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, templeteCategory, null, null);
    this.commonUIService.openDialog(AddEditTempleteSubcategoryComponent, dialogViewData);
  }

  openAddTempletePopup(templeteSubcategory) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, templeteSubcategory, null, null);
    this.commonUIService.openDialog(AddEditTempleteComponent, dialogViewData);
  }

  openAddTempleteCategoryPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, null, null, null);
    this.commonUIService.openDialog(AddEditTempleteCategoryComponent, dialogViewData);
  }

  openEditTempleteSubcategoryPopup(templeteSubcategory) {
    let _templeteSubcategory = Object.assign({}, templeteSubcategory);
    const dialogViewData = this.commonUIService.setDataForModalDialog(_templeteSubcategory.id, null, null, _templeteSubcategory, null, null);
    this.commonUIService.openDialog(AddEditTempleteSubcategoryComponent, dialogViewData);
  }

  openEditTempleteCategoryPopup(templeteCategory) {
    let _templeteCategory = Object.assign({}, templeteCategory);
    const dialogViewData = this.commonUIService.setDataForModalDialog(_templeteCategory.id, null, null, _templeteCategory, null, null);
    this.commonUIService.openDialog(AddEditTempleteCategoryComponent, dialogViewData);
  }

  openTempletePopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, null, null, null);
    this.commonUIService.openDialog(AddEditTempleteComponent, dialogViewData);
  }

  selectTemplete(category, subcategory, template) {
    if (template.id) {
      if (category)
        this.selectedCategoryId = category.id;
      if (subcategory)
        this.selectedSubcategoryId = subcategory.id;

      this.templeteFormGroup.reset();
      if (this.templeteFormGroup) {
        this.templeteFormGroup.get('name').markAsUntouched();
      }
      this.linkedInService.getTempleteById(template.id).subscribe(res => {
        if (res && res.data) {
          this.template = Object.assign({}, res.data);
          this.template.isEdit = true;
          this.template.isShow = true;
          this.template.isDelete = true;
          this.template.isAdd = true;
          this.templeteFormGroup.disable();
        }
      })
    } else {
      this.selectedCategoryId = null;
      this.selectedSubcategoryId = null;
    }
  }

  openAddTempleteView(template) {
    this.templeteFormGroup.reset();
    if (this.templeteFormGroup) {
      this.templeteFormGroup.get('name').markAsUntouched();
    }
    this.templeteFormGroup.enable();
    this.template = new Template();
    this.template.isEdit = false;
    this.template.isShow = true;
    this.template.isSave = true;
  }

  openEditTempleteView(template) {
    if (template.id > 0) {
      this.templeteFormGroup.enable();
      this.template = Object.assign({}, template);
      this.template.isEdit = false;
      this.template.isSave = true;
      this.template.isAdd = true;
    }
  }

  openDeleteTemplatePopup(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete LinkedIn Templete";
    this._idSelectedTemplate = id;
    this.commonUIService.openAlert(deleteData);
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete LinkedIn Templete" && this._idSelectedTemplate) {
          this.deleteTemplate(this._idSelectedTemplate);
        }
        if (data === "Delete Templete Subcategory" && this._idSelectedTemplateSubcategory) {
          this.deleteTemplateSubcategory(this._idSelectedTemplateSubcategory);
        }
        if (data === "Delete Templete Category" && this._idSelectedTemplateCategory) {
          this.deleteTemplateCategory(this._idSelectedTemplateCategory);
        }
      });
  }

  deleteTemplate(templeteId) {
    this.linkedInService.deleteTemplete(templeteId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadTempleteCategoryList();
          this.template.isShow = false;
          this.template.isAdd = false;
          this.template.isEdit = false;
          this.template.isDelete = false;
        }
      });
  }

  deleteTemplateCategory(id) {
    this.linkedInService.deleteTempleteCategory(id).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadTempleteCategoryList();
        }
      });
  }

  deleteTemplateSubcategory(id) {
    this.linkedInService.deleteTempleteSubcategory(id).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadTempleteCategoryList();
        }
      });
  }

  saveTemplate(model: Template) {
    if (model) {
      this.templeteFormGroup.get('name').markAsTouched();
      let templateModel = Object.assign({}, model);
      templateModel.categoryId = this.selectedCategoryId;
      templateModel.subcategoryId = this.selectedSubcategoryId;
      if (this.templeteFormGroup.invalid || templateModel.name == undefined || templateModel.name == null) {
        this.templeteFormGroup.enable();
        return
      } else {
        if (templateModel.id > 0) {
          this.updateTemplate(templateModel.id, templateModel)
        } else {
          this.addTemplete(templateModel)
        }
      }
    }
  }

  addTemplete(templateObj) {
    this.linkedInService.saveTempleteData(templateObj).subscribe(
      (res) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.selectedCategoryId = res.data.categoryId;
          this.selectedSubcategoryId = res.data.subcategoryId;
          this.loadTempleteCategoryList();
          this.selectTemplete(null, null, res.data);
          this.templeteFormGroup.disable();
          this.template.isEdit = true;
          this.template.isSave = false;
          this.template.isAdd = true;
          this.template.isDelete = true;
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save", "", 5000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  updateTemplate(id, obj) {
    this.linkedInService.updateTemplete(id, obj).subscribe(
      (res) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadTempleteCategoryList();
          this.selectedCategoryId = res.data.categoryId;
          this.selectedSubcategoryId = res.data.subcategoryId;
          this.selectTemplete(null, null, res.data);
          this.templeteFormGroup.disable();
          this.template.isEdit = true;
          this.template.isSave = false;
          this.template.isAdd = true;
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  viewLinkedIn() {
    if (this.permissionsList.includes("2800")) {
      this.router.navigate(["/linkedin"]);
    }
    else {
      this.router.navigate(["/no-permission"]);
    }
  }

  subscribeRefreshCategoryList() {
    this.linkedInService.refreshCategoryList.subscribe(data => {
      if (!data) { return; }
      this.loadTempleteCategoryList();
    });
  }

  openDeleteTempleteCategoryPopup(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Templete Category";
    this._idSelectedTemplateCategory = id;
    this.commonUIService.openAlert(deleteData);
  }

  openDeleteTempleteSubcategoryPopup(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Templete Subcategory";
    this._idSelectedTemplateSubcategory = id;
    this.commonUIService.openAlert(deleteData);
  }
}
