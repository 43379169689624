import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'estimation',
    templateUrl: './estimation.component.html',
    styleUrls: ['./estimation.component.scss']
})

  export class EstimationComponent {
  }
