import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { ClientService } from 'src/app/client/services/client.service';
import API from '../../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { Subscription, Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Users } from '../../entities/user';
import { UserService } from '../service/user.service';
import { AddEditUserComponent } from './add-edit-user/add-edit-user.component';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {
  _userList: Users[] = [];
  userList: Users[] = [];
  _idSelectedUser: number;
  today_date: string;
  selectedValue: string;
  filterAciveDeactive = false;

  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  constructor(private commonUIService: UIService,
    private userService: UserService,
    private _apiService: APIService,
    private alertService: AlertService,
    private commonDropdownService: CommonDropdownService, ) {
    this._userList = [];
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    const today_date = new Date().toISOString();
    this.loadUserList();
    this.subscribeAlertConfirmEvents();
    this.commonDropdownService.LoadCompantList();    
    this.subscribeRefreshUserList();
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete User" && this._idSelectedUser) {
          this.deleteUser(this._idSelectedUser);
          console.log(this._idSelectedUser);
        }
      });
  }

  openAddUserPopup() {
    this.commonUIService.openDialog(AddEditUserComponent, null);
  }

  filterActive() {
    if (this.filterAciveDeactive == false)
      this.filterAciveDeactive = true;
    else if (this.filterAciveDeactive == true) {
      this.filterAciveDeactive = false;
      this.loadUserList();
      this.selectedValue = undefined;
    }
  }

  private loadUserList() {

    this.commonUIService.openMask();

    //NOTE : When fetching the users the URL parameter 'true' is passed to get Active users only
    this._apiService.get(`${API.user.getAllUsers}/true`).subscribe(response => {
      if (response) {
        if (response.data) {        
          let userData = response.data;
          this._userList = userData.sort((a, b) => a.name.localeCompare(b.name));
          if (this._userList.length > 0) {
            this._userList.forEach(ele => {
              ele.mainBranch = ele.companyName;
              let _companyNames = [];
              if (ele.companyUser.length > 0) {
                ele.companyUser.forEach(cu => {
                  _companyNames.push(cu.companyName);
                });
                _companyNames = _companyNames.filter((x, i, a) => a.indexOf(x) == i);
                ele.companyNames = _companyNames.join(', ');
              } else {
                ele.companyNames = _companyNames.join(', ');
              }
            });
            this._userList.forEach(ele => {
              let _userRoles = [];
              if (ele.userRole.length > 0) {
                ele.userRole.forEach(ur => {
                  _userRoles.push(ur.userRoleName);
                });             
                _userRoles = _userRoles.filter((x, i, a) => a.indexOf(x) == i);
                if (ele.roleName)
                  _userRoles.push(ele.roleName);
                ele.userRoles = _userRoles.join(', ');
              } else {
                if (ele.roleName)
                  _userRoles.push(ele.roleName);
                ele.userRoles = _userRoles.join(', ');;
              }
            });
          }
          this.commonUIService.closeMask();
        }       
      }
      else{
        this.commonUIService.closeMask();
      }      
    },
      err => {
        this.commonUIService.closeMask();
        console.error(err);
      }, () => {
      });
  }

  editUser(user) {
    const userModel = Object.assign({}, user);
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, userModel, null, null);
    this.commonUIService.openDialog(AddEditUserComponent, dialogViewData);
  }

  openAlertDeleteUser(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete User";
    this._idSelectedUser = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteUser(userId) {
    this.userService.deleteUser(userId).subscribe(
      res => {      
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 5000);
          this.commonUIService.openSnackBar(snack);
          this.loadUserList();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  refreshUpdateUser(): void {
    this.subscriber.unsubscribe();
  }

  subscribeRefreshUserList() {
    this.userService.refershUpdateUser.subscribe(data => {   
      if (!data) { return; }
      this.loadUserList();
    });
  }
}
