import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { startWith, map } from 'rxjs/operators';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';

@Component({
  selector: 'app-miscellaneous-detail',
  templateUrl: './miscellaneous-detail.component.html',
  styleUrls: ['./miscellaneous-detail.component.scss']
})
export class MiscellaneousDetailComponent implements OnInit, OnChanges {
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  @Input() inventoryDetailFormGroup: FormGroup;
  @Input() inventoryModel: InventoryModel;

  constructor(public commonDropdownService: CommonDropdownService, public commonUIService: UIService,
    public formatDatepicker: FormatMatDatepicker,
    public inventoryService: InventoryService) {
    this.inventoryModel = new InventoryModel();
    this.inventoryModel.isEdit = false;
    this.inventoryModel.state = 0;
  }

  ngOnInit() {
    this.filteredOptions = this.inventoryDetailFormGroup.get('name').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.commonDropdownService.LoadInventoryDropdownList();
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
    //this.commonDropdownService.LoadInventoryMiscellaneousTypeList();
    this.commonDropdownService.LoadUserList();
    this.inventoryDetailFormGroup.reset();
    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }

  ngOnChanges() {
    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase());
  }

  public editMiscellaneous() {
    if (!this.inventoryModel.showEditSave) {
      this.inventoryModel.showEditSave = true;
    }
    if (this.inventoryDetailFormGroup.disabled) {
      this.inventoryDetailFormGroup.enable();
    }
  }

  saveMiscellaneous(model) {

    if (this.inventoryModel.isEdit == false) {
      let type = this.commonDropdownService._inventoryMiscellaneousTypeList.find(x => x.id === model.miscellaneousTypeId)
      this.inventoryDetailFormGroup.get('type').markAsTouched();
      this.inventoryDetailFormGroup.get('code').markAsTouched();
      this.inventoryDetailFormGroup.get('date').markAsTouched();
      this.inventoryDetailFormGroup.get('type').patchValue(type.name);

      var typeValue = this.inventoryDetailFormGroup.get('type').value;
      var codeValue = this.inventoryDetailFormGroup.get('code').value;
      var dateValue = this.inventoryDetailFormGroup.get('date').value;
      if (this.inventoryDetailFormGroup.invalid || typeValue == undefined || typeValue == null || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
        this.inventoryDetailFormGroup.enable();
        return
      }
      else {
        model.type = type.name;
        let invetoryObj = Object.assign({}, model);
        invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
        invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
        if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
          this.inventoryService.submitUpdateInventory(invetoryObj, 3, false);
        }
        else {
          this.inventoryService.submitSaveInventory(invetoryObj, 3);
        }
      }
    } else {
      let type = this.commonDropdownService._inventoryMiscellaneousTypeList.find(x => x.id === model.miscellaneousTypeId)
       
      if (!!this.inventoryDetailFormGroup && this.inventoryDetailFormGroup.dirty) {
        this.inventoryDetailFormGroup.get('type').markAsTouched();
        this.inventoryDetailFormGroup.get('code').markAsTouched();
        this.inventoryDetailFormGroup.get('date').markAsTouched();
        this.inventoryDetailFormGroup.get('type').patchValue(type.name);

        var typeValue = this.inventoryDetailFormGroup.get('type').value;
        var codeValue = this.inventoryDetailFormGroup.get('code').value;
        var dateValue = this.inventoryDetailFormGroup.get('date').value;
        if (this.inventoryDetailFormGroup.invalid || typeValue == undefined || typeValue == null || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
          this.inventoryDetailFormGroup.enable();
          return
        }
        else {
          model.type = type.name;
          let invetoryObj = Object.assign({}, model);
          invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
          invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
          if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
            this.inventoryService.submitUpdateInventory(invetoryObj, 3, false);
          }
          else {
            this.inventoryService.submitSaveInventory(invetoryObj, 3);
          }
        }
      } else {
        const snack = new SnackBar(SnackBarType.success, 'No data entered for update', '', 4000);
        this.commonUIService.openSnackBar(snack);
        return;
      }
    }
  }
}
