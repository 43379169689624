import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../angular-material.module';
import { ClientComponent } from './client.component';
import { ClientlistComponent } from './client-list/client-list.component';
import { ClientdetailComponent } from './client-detail/client-detail.component';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from '../shared/pipes/date-format.pipe';
import { MangoCurrencyPipe } from '../shared/pipes/currency.pipe';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddclientComponent } from './add-client/add-client.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormControl, FormBuilder } from '@angular/forms';
import { AppModule } from '../app.module';
import { AlertPopupComponent } from '../shared/components/alert-popup/alert-popup.component';
import { AddEditContactComponent } from './client-detail/client-view-data/add-edit-contact/add-edit-contact.component';
import { AddEditActionComponent } from './client-detail/client-view-data/add-edit-action/add-edit-action.component';
import { AddEditProjectComponent } from './client-detail/add-edit-project/add-edit-project.component';
import { AddEditTaskComponent } from './client-detail/client-view-data/add-edit-task/add-edit-task.component';
import { AddEditProjectTaskComponent } from './client-detail/project-view-data/add-edit-project-task/add-edit-project-task.component';
import { AddEditProjectActionComponent } from './client-detail/project-view-data/add-edit-project-action/add-edit-project-action.component';
import { AddEditClientDocumentComponent } from 'src/app/client/client-detail/client-view-data/add-edit-client-document/add-edit-client-document.component';
import { ClientViewDataComponent } from './client-detail/client-view-data/client-view-data.component';
import { ProjectViewDataComponent } from './client-detail/project-view-data/project-view-data.component';
import { FilterclientComponent } from './filter-client/filter-client.component';
import { AddEditProjectDocumentComponent } from './client-detail/project-view-data/add-edit-project-document/add-edit-project-document.component';
import { TreeviewModule } from 'ngx-treeview';
import { AddEstimationComponent } from './client-detail/project-view-data/add-estimation/add-estimation.component';
import { AddEstimationPhaseComponent } from './client-detail/project-view-data/add-estimation-phase/add-estimation-phase.component';
import { NgxMaskModule } from 'ngx-mask';
import { AddEstimationOptionComponent } from './client-detail/project-view-data/add-estimation-option/add-estimation-option.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    ClientComponent,
    ClientdetailComponent,
    ClientViewDataComponent,
    ClientlistComponent,
    ProjectViewDataComponent,
    AddclientComponent,
    FilterclientComponent,
    AddEditContactComponent,
    AddEditTaskComponent,
    AddEditActionComponent,
    AddEditProjectComponent,
    AddEditProjectTaskComponent,
    AddEditProjectActionComponent,
    AddEditClientDocumentComponent,
    AddEditProjectDocumentComponent,
    AddEstimationComponent,
    AddEstimationPhaseComponent,
    AddEstimationOptionComponent
  ],
  imports: [
    AngularMaterialModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    TreeviewModule.forRoot(),
    NgxMaskModule.forRoot(),
    SlickCarouselModule
  ],
  exports: [ClientComponent, ClientdetailComponent],
  entryComponents: [
    AlertPopupComponent,
    AddclientComponent,
    FilterclientComponent,
    AddEditContactComponent,
    AddEditTaskComponent,
    AddEditActionComponent,
    AddEditProjectComponent,
    AddEditProjectTaskComponent,
    AddEditProjectActionComponent,
    AddEditClientDocumentComponent,
    AddEditProjectDocumentComponent,
    AddEstimationComponent,
    AddEstimationPhaseComponent,
    AddEstimationOptionComponent
  ]
})

export class ClientModule { }
