
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { departmentModel } from 'src/app/shared/entities/departmentModel';
import { MaintenanceService } from '../../service/maintenance.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-add-edit-department',
  templateUrl: './add-edit-department.component.html',
  styleUrls: ['./add-edit-department.component.scss']
})
export class AddEditDepartmentComponent implements OnInit {

  departmentFormGroup: FormGroup;
  isEdit: boolean;
  departmenObj: departmentModel;
  isDisabledDepartment = false;
  

  constructor(    
    private maintatanceService: MaintenanceService,
    private _formBuilder: FormBuilder,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditDepartmentComponent>,
    @Inject(MAT_DIALOG_DATA) public departmentData: DialogDataForModalView, 
    ) {
      this.departmenObj = new departmentModel();
     }

  ngOnInit() {
    this.formvalidate();
    this.checkDataForEdit();
  }

  formvalidate() {
    this.departmentFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }
  
  checkDataForEdit() {
    if (this.departmentData.data) {
      this.departmenObj = Object.assign({}, this.departmentData.data).modalData;
      this.isEdit = true;
    }
  }

  submit(model) {
    if (this.departmentFormGroup.invalid) {
      this.isDisabledDepartment = false;
      return;
    }
    else if (this.isEdit) {
      this.maintatanceService.updateDepartment(model.id, model).subscribe(res => {
        if (res.isSuccsess) {
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintatanceService.DepartmentTypeRefresh(true);
          this.closeDialog();
        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }
    else {
      this.isDisabledDepartment = true;
      model.status  = true;
      this.maintatanceService.SaveDepartment(model).subscribe(res => {
        if(res.isSuccsess)
        {
          let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.DepartmentTypeRefresh(true);

        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}