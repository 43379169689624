import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertPopupComponent } from '../shared/components/alert-popup/alert-popup.component';
import { AlertModel } from '../shared/entities/alertModel';
import { AddHolidayComponent } from './components/popups/add-holiday/add-holiday.component';

interface Holiday {
  holidayName: string;
  holidayType: string;
  date: string;
  year: string;
}

@Component({
  selector: 'app-hrm-maintenance',
  templateUrl: './hrm-maintenance.component.html',
  styleUrls: ['./hrm-maintenance.component.scss'],
})
export class HrmMaintenanceComponent implements OnInit {
  _alertModel: any = 'Sample';
  _idSelectedUser: number;
  constructor(public commonUIService: UIService) {}
  OpenAddHolidayPopup() {
    this.commonUIService.openDialog(AddHolidayComponent);
  }
  openAlertDeleteUser(id) {
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure want to delete this record?';
    deleteData.id = id;
    deleteData.reference = 'Delete User';
    this._idSelectedUser = id;
    this.commonUIService.openAlert(deleteData);
  }

  ngOnInit() {}

}
