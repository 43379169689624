import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { HrmService } from '../../../../service/hrm.service';
import { AddEditEmployeeSalaryComponent } from './add-edit-employee-salary/add-edit-employee-salary.component';
import { AlertModel } from '../../../../../shared/entities/alertModel';
import { AlertService } from '../../../../../shared/services/alert.service';
import { Subscription } from 'rxjs';
import { SnackBar, SnackBarType } from '../../../../../shared/components/snackbar/entities/snackbar';
declare const JSON;

@Component({
  selector: 'salary-document-view',
  templateUrl: './salary-document-view.component.html',
  styleUrls: ['./salary-document-view.component.scss']
})
export class SalaryDocumentViewComponent implements OnInit {
  // config 
  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  subscriber: Subscription;
  files: File[] = [];
  employeeSalaryList: any = [];
  isLoadingSalaryList: boolean = false;
  eventValue: any;
  selectedCompanyDocumentGroupId: number = 0;
  idCurrentEmployeeDocumentId: number = 0;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    public hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<SalaryDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService) {
  }

  ngOnInit() {
    if (this.modalViewData.data.id)
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    this.subscribeAlertConfirmEvents();
    this.subscribeRefreshSalaryEmployeeDocumentList();
  }

  async loadEmployeeDocumentList(employeeId) {
    this.hrmService.isLoading_Salary = true;
    this.hrmService.getEmployeeDocumentListByEmployeeIdAndDocumentTypeId(employeeId, 10).subscribe(
      res => {
        if (res) {
          let _employeeSalaryList = res.data;
          if (_employeeSalaryList != null) {
            _employeeSalaryList.forEach(element => {
              if (element.fileName) {
                element.extension = element.fileName.split('.')[(element.fileName.split('.')).length - 1];
              }
              element.link = API.baseUrl + API.employeeDocument.getEmployeeDocument + element.id + '/' + element.generatedFileName;
              if (element.url) {
                let l = element.url.toString()
                if (element.extension === 'pdf')
                  l = l.includes('data:application/pdf;base64,') ? l : 'data:application/pdf;base64,' + l;
                if (element.extension === 'png')
                  l = l.includes('data:image/png;base64,') ? l : 'data:image/png;base64,' + l;
                if (element.extension === 'jpg')
                  l = l.includes('data:image/jpg;base64,') ? l : 'data:image/jpg;base64,' + l;
                if (element.extension === 'jpeg')
                  l = l.includes('data:image/jpeg;base64,') ? l : 'data:image/jpeg;base64,' + l;
                if (element.extension === 'docx')
                  l = l.includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                if (element.extension === 'xlsx' || element.extension === 'xls')
                  l = l.includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
              }
            });
            this.hrmService.isLoading_Salary = false;
          }
          this.employeeSalaryList = null;
          this.employeeSalaryList = _employeeSalaryList;
          this.isLoadingSalaryList = this.employeeSalaryList == null ? true : false;
          if (this.isLoadingSalaryList == true)
            this.hrmService.isLoading_Salary = false;        
        }
      },
      err => { console.error(); this.hrmService.isLoading_Salary = true; });
  }

  openAddEmployeeDocumentPopup(documentTypeId: number) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.modalViewData.data.id, null, documentTypeId, null, null, null);
    this.commonUIService.openDialog(AddEditEmployeeSalaryComponent, dialogViewData);
  }

  openDeleteSalaryDocumentAlert(employeeDocumentId) {
    this.idCurrentEmployeeDocumentId = employeeDocumentId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = employeeDocumentId;
    deleteData.type = 'deleteSalaryEmployeeDocument';
    deleteData.reference = 'Delete Salary Employee Document';
    this.commonUIService.openAlert(deleteData);
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Salary Employee Document') {
        this.deleteEmployeeSalaryDocument(this.idCurrentEmployeeDocumentId);
      }
    });
  }

  deleteEmployeeSalaryDocument(clientDocumentId) {
    this.hrmService.deleteEmployeeDocument(clientDocumentId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmployeeDocumentList(this.modalViewData.data.id);
        }
      }
    )
  }

  subscribeRefreshSalaryEmployeeDocumentList() {
    this.hrmService.refreshSalaryEmployeeDocumentList.subscribe(data => {
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
