import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { MaintenanceComponent } from './maintenance.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AddEditTechnologiesComponent } from './technologies/add-edit-technologies/add-edit-technologies.component';
import { RegionComponent } from './region/region.component';
import { AddEditRegionComponent } from './region/add-edit-region/add-edit-region.component';
import { DocumentTypeComponent } from './document-type/document-type.component';
import { AngularMaterialModule } from '../angular-material.module';
import { PositionComponent } from './position/position.component';
import { AddEditReferalsourceComponent } from './referal-source/add-edit-referalsource/add-edit-referalsource.component';
import { AddEditActiontypeComponent } from './action-type/add-edit-actiontype/add-edit-actiontype.component';
import { ActionTypeComponent } from './action-type/action-type.component';
import { AddEditClientStatusComponent } from './client-status/add-edit-client-status/add-edit-client-status.component';
import { ClientStatusComponent } from './client-status/client-status.component';
import { ProjectStatusComponent } from './project-status/project-status.component';
import { TaskComponent } from './task/task.component';
import { AddEditDocumenttypeComponent } from './document-type/add-edit-documenttype/add-edit-documenttype.component';
import { AddEditProjectstatusComponent } from './project-status/add-edit-projectstatus/add-edit-projectstatus.component';
import { AddEditTaskTypeComponent } from './task/add-edit-task-type/add-edit-task-type.component';
import { CommonModule } from '@angular/common';
import { ReferalSourceComponent } from './referal-source/referal-source.component';
import { AddEditpositionComponent } from './position/add-editposition/add-editposition.component';
import { ServiceTypeComponent } from './service-type/service-type.component';
import { AddEditServiceTypeComponent } from './service-type/add-edit-service-type/add-edit-service-type.component';
import { CountryComponent } from './country/country.component';
import { CurrencyComponent } from './currency/currency.component';
import { AddEditCountryComponent } from './country/add-edit-country/add-edit-country.component';
import { AddEditCurrencyComponent } from './currency/add-edit-currency/add-edit-currency.component';
import { DepartmentComponent } from './department/department.component';
import { DesignationComponent } from './designation/designation.component';
import { AddEditDepartmentComponent } from './department/add-edit-department/add-edit-department.component';
import { AddEditDesignationComponent } from './designation/add-edit-designation/add-edit-designation.component';

@NgModule({
  declarations: [
    MaintenanceComponent,
    ActionTypeComponent,
    ClientStatusComponent,
    DocumentTypeComponent,
    PositionComponent,
    ProjectStatusComponent,
    ReferalSourceComponent,
    RegionComponent,
    TaskComponent,
    TechnologiesComponent,
    CountryComponent,
    CurrencyComponent,
    AddEditActiontypeComponent,
    AddEditRegionComponent,
    AddEditClientStatusComponent,
    AddEditDocumenttypeComponent,
    AddEditpositionComponent,
    AddEditProjectstatusComponent,
    AddEditReferalsourceComponent,
    AddEditTaskTypeComponent,
    AddEditTechnologiesComponent,
    ServiceTypeComponent,
    AddEditServiceTypeComponent,
    AddEditCountryComponent,
    AddEditCurrencyComponent,
    DepartmentComponent,
    DesignationComponent,
    AddEditDepartmentComponent,
    AddEditDesignationComponent
  ],
  imports: [
    RouterModule,
    SharedModule,
    ColorPickerModule,
    AngularMaterialModule,
    CommonModule,
    BrowserModule
  ],
  entryComponents: [
    MaintenanceComponent,
    AddEditActiontypeComponent,
    AddEditRegionComponent,
    AddEditClientStatusComponent,
    AddEditDocumenttypeComponent,
    AddEditpositionComponent,
    AddEditProjectstatusComponent,
    AddEditReferalsourceComponent,
    AddEditTaskTypeComponent,
    AddEditTechnologiesComponent,
    AddEditServiceTypeComponent,
    AddEditCountryComponent,
    AddEditCurrencyComponent,
    AddEditDepartmentComponent,
    AddEditDesignationComponent

  ]
})

export class MaintenanceModule {

}
