import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { ClientService } from 'src/app/client/services/client.service';
import API from '../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { Subscription, Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SharedHeaderService } from '../../shared/services/shared.header.services';
import { LoginService } from '../../auth/login/login.service';
import { Users } from '../../auth/entities/user';
import { AddEditCRMDocumentTypeComponent } from './add-edit-crm-document-type/add-edit-crm-document-type.component';
import { TypeOfDocument } from '../entities/type-of-document';
import { CRMDocumentTypeService } from '../service/crm-document-type.service';
import { roleModel } from '../../auth/entities/role';
import { DocumentPermits } from '../entities/document-permits';

@Component({
  selector: 'view-crm-document-type',
  templateUrl: './view-crm-document-type.component.html',
  styleUrls: ['./view-crm-document-type.component.scss']
})
export class ViewCRMDocumentTypeComponent implements OnInit {

  _documentTypeList: TypeOfDocument[] = [];
  documentTypeList: TypeOfDocument[] = [];
  documentPermitsList: TypeOfDocument[] = [];
  _idSelectedCRMDocumentType: number;
  today_date: string;
  selectedValue: string;
  roleList: roleModel[] = [];
  typeOfDocumentModel: TypeOfDocument;
  _saveDocumentType: TypeOfDocument;
  _saveDocumentPermits: DocumentPermits;
  docName: string = null;
  docPermits: any;
  docPermitsList: any[] = [];
  selectedTypeOfDocumentId: number = 0;
  selectDoc: boolean = false;
  isLadingDocumentPermits: boolean = false;

  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  constructor(private commonUIService: UIService,
    private loginServic: LoginService,
    public sharedHeaderService: SharedHeaderService,
    private _apiService: APIService,
    private _typeOfDocumentService: CRMDocumentTypeService,
    private uIService: UIService,
    private alertService: AlertService) {
    this.documentTypeList = [];
    this.documentPermitsList = [];
    this.roleList = [];
    this._unsubscribeAll = new Subject();
    this.typeOfDocumentModel = new TypeOfDocument();
    this._saveDocumentType = new TypeOfDocument();
    this._saveDocumentPermits = new DocumentPermits();
  }

  ngOnInit() {
    const today_date = new Date().toISOString();
    this.loadTypeOfDocumentList();
    this.loadDocumentPermitsList();
    this.loadRoleList();
    this.subscribeTypeOfDocumentRefresh();
  }

  openAddDocumentTypesPopup() {
    this.commonUIService.openDialog(AddEditCRMDocumentTypeComponent, null);
  }

  openEditDocumentTypesPopup(documentType) {
    const documentTypeModel = Object.assign({}, documentType);
    const dialogViewData = this.commonUIService.setDataForModalDialog(documentTypeModel.id, null, null, documentTypeModel, null, null);
    this.commonUIService.openDialog(AddEditCRMDocumentTypeComponent, dialogViewData);
  }

  private loadTypeOfDocumentList() {
    this._apiService.get(API.typeOfDocument.listAll).subscribe(res => {
      if (res) {
        if (res && res.data) {
          this._documentTypeList = res.data;
          this.documentTypeList = this._documentTypeList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  loadRoleList() {
    this._apiService.get(API.role.listAll).subscribe(response => {
      if (response && response.data) {
        this.roleList = response.data;
      }
    });
  }

  private loadDocumentPermitsList() {
    this._apiService.get(API.documentPermits.listAll).subscribe(res => {
      if (res) {
        if (res && res.data) {
          this.documentPermitsList = res.data;
        }
      }
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  subscribeTypeOfDocumentRefresh() {
    this._typeOfDocumentService.crmDocumentTypeRefresh.subscribe(data => {
      if (!data) { return; }
      this.loadTypeOfDocumentList();
    })
  }

  selectTypeOfdocument(dt) {
    if (dt.id) {
      this.selectDoc = true;
      this.selectedTypeOfDocumentId = dt.id;
      this.loadDocumentPermitsByTypeOfDocumentId(dt.id);
    }
  }

  changeBoolFields(typeOfDocument, text, value, event) {
    if (typeOfDocument != null) {
      this._saveDocumentType = Object.assign({}, typeOfDocument);
      if (text == 'client') {
        this._saveDocumentType.client = event.checked;
        if (this._saveDocumentType.client == false) {
          this._saveDocumentType.clientMain = event.checked;
          this._saveDocumentType.clientRequest = event.checked;
        }
      } else if (text == 'clientMain') {
        this._saveDocumentType.clientMain = event.checked;
      }
      else if (text == 'clientRequest') {
        this._saveDocumentType.clientRequest = event.checked;
      }
      else if (text == 'project') {
        this._saveDocumentType.project = event.checked;
      }
      else if (text == 'hr') {
        this._saveDocumentType.hr = event.checked;
      }
      this.updateTypeOfDocument(this._saveDocumentType.id, this._saveDocumentType);
    }
  }

  updateTypeOfDocument(id: any, obj) {
    this._typeOfDocumentService.editTypeOfDocumentData(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Type of document successfully updated", "", 5000);
          this.uIService.openSnackBar(snack);
          this.loadTypeOfDocumentList();
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update type of document", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  updateVisible(documentPermit, event) {
    if (documentPermit != null && event.checked == true) {
      documentPermit.typeOfDocumentId = this.selectedTypeOfDocumentId;
      documentPermit.visible = event.checked;
      this.addDocumentPermits(documentPermit);
    }
    if (documentPermit != null && event.checked == false) {
      this.deleteDocumentPermits(documentPermit.id);
    }
  }

  updateEditable(documentPermit, event) {
    if (documentPermit != null) {
      if (documentPermit.id == 0) {
        documentPermit.editable = event.checked;
        documentPermit.visible = true;
        this.addDocumentPermits(documentPermit);
      } else {
        documentPermit.editable = event.checked;
        this.updateDocumentPermits(documentPermit.id, documentPermit);
      }
    }
  }

  addDocumentPermits(documentPermits) {
    this.isLadingDocumentPermits = true;
    this._typeOfDocumentService.addDocumentPermits(documentPermits).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Document permits successfully saved", "", 5000);
          this.uIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on save document permits", "", 5000);
          this.uIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
      },
      () => { console.error(); });
  }

  updateDocumentPermits(id: any, obj) {
    this.isLadingDocumentPermits = true;
    this._typeOfDocumentService.updateDocumentPermits(id, obj).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Document permits successfully updated", "", 5000);
          this.uIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update document permits", "", 5000);
          this.uIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
      },
      () => { console.error(); });
  }

  deleteDocumentPermits(id: any) {
    this.isLadingDocumentPermits = true;
    this._typeOfDocumentService.deleteDocumentPermits(id).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Document permit successfully deleted', '', 5000);
          this.commonUIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
        else {
          const snack = new SnackBar(SnackBarType.success, "Error on document permit delete", "", 5000);
          this.uIService.openSnackBar(snack);
          if (this.selectDoc)
            this.loadDocumentPermitsByTypeOfDocumentId(this.selectedTypeOfDocumentId);
        }
      },
      () => { console.error(); });
  }


  loadDocumentPermitsByTypeOfDocumentId(id) {
    if (id) {
      this._typeOfDocumentService.getDocumentPermitsByTypeOfDocumentId(id).subscribe(res => {
        if (res && res.data) {
          this.docPermits = res.data;
          if (this.docPermits && this.docPermits.documentPermitsList != null) {
            this.docPermitsList = this.docPermits.documentPermitsList;
            this.isLadingDocumentPermits = false;
          }
        }
      },
        () => { console.error(); this.commonUIService.closeMask(); this.isLadingDocumentPermits = false; });
    }
  }
}
