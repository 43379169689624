export class AlertModel {
  title: string;
  msg: string;
  id: number;
  reference: string;
  type: string;
  alertType:string;
  isCancel: boolean;

  selectedTabIndex: any;
}
