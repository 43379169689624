import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { LinkedInComponent } from 'src/app/linkedin/linkedin.component';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { ViewLinkedInTempleteComponent } from './components/view-linkedin-templete/view-linkedin-templete.component';
import { LinkedInTempleteComponent } from './linkedin-templete.component';
import { AddEditTempleteComponent } from './components/add-edit-templete/add-edit-templete.component';
import { AddEditTempleteCategoryComponent } from './components/add-edit-templete-category/add-edit-templete-category.component';
import { AddEditTempleteSubcategoryComponent } from './components/add-edit-templete-subcategory/add-edit-templete-subcategory.component';

@NgModule({
  declarations: [
    LinkedInTempleteComponent,
    ViewLinkedInTempleteComponent,
    AddEditTempleteComponent,
    AddEditTempleteCategoryComponent,
    AddEditTempleteSubcategoryComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule],
  entryComponents: [LinkedInTempleteComponent,
    AddEditTempleteComponent,
    AddEditTempleteCategoryComponent,
    AddEditTempleteSubcategoryComponent]
})

export class LinkedInTempleteModule {
}
