import {EventEmitter, Injectable, Output} from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
_taskTypeListForIcons: any = [];
selectedDateId : number = 0;
isDelayedTask: boolean = false;

isSelectedClient: boolean = false;

@Output() selectedClient: EventEmitter<any> = new EventEmitter<any>();

  constructor(public commonDropdownService: CommonDropdownService) {
  }

  LoadTaskTypeListForIcon() {
    this.commonDropdownService.LoadTaskTypeListForHeader().subscribe(data => {
      if (data) {
        this._taskTypeListForIcons = data;
        if (this._taskTypeListForIcons.length > 0) {
          this._taskTypeListForIcons.forEach(ele => {
            ele.taskTypeName = ele.taskTypeName.trim();
          });
        }
      }
    });
  }

  setDateValueToPicker(item){
    this.selectedDateId = item.id;
    const [day, month, year] = item.date.split("/");
    return new Date(year, month - 1, day);
  }
}
