import { Component, OnInit, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker, FormatTimeForDisplay } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
import { Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { isNumber } from 'util';
import { ProjectViewService } from '../../../services/project-view.service';
import { Estimation } from 'src/app/client/entities/estimation';
import { ClientViewService } from '../../../services/client-view.service';
import moment from 'moment';

@Component({
  selector: 'add-estimation',
  templateUrl: './add-estimation.component.html',
  styleUrls: ['./add-estimation.component.scss']
})

export class AddEstimationComponent implements OnInit {

  isDisabledEstimation = true;
  estimationFormGroup: FormGroup;
  isEdit: boolean = false;
  private _unsubscribeAll: Subject<any>;
  estimationModal: Estimation;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private clientService: ClientService,
    private projectViewService: ProjectViewService,
    public clientViewService: ClientViewService,
    private _formBuilder: FormBuilder,
    public router: Router,
    private formatTimeForDisplay: FormatTimeForDisplay,
    public dialogRef: MatDialogRef<AddEstimationComponent>,
    @Inject(MAT_DIALOG_DATA) public currentconpanyData: DialogDataForModalView,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._unsubscribeAll = new Subject();
    this.estimationModal = new Estimation();
  }

  ngOnInit() {
    this.initEstimationFormGroup();
    this.setEstimationDataForEditView();
  }

  initEstimationFormGroup() {
    this.estimationFormGroup = this._formBuilder.group({
      title: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      description: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      type: ['', [Validators.required]],
      state: [''],
      estimationHours: [''],
      maxEstimationHours: [''],
      budgetHours: [''],
      maxBudgetHours: [''],
      totalBudget: [''],
      totalMaxBudget: [''],
      date: ['']
    });
  }

  setEstimationDataForEditView(): void {
    if (this.modalViewData.data) {
      this.estimationModal = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.id)) {
        if (this.modalViewData.data.modalData.state != null)
          this.estimationModal.state = this.modalViewData.data.modalData.state.toString();
        if (this.modalViewData.data.modalData.type != null)
          this.estimationModal.type = this.modalViewData.data.modalData.type.toString();
        this.isEdit = true;
      } else {
        let today = moment();
        let date = moment(today);
        this.estimationModal.date = date;
        this.estimationModal.state = "1";
        this.isEdit = false;
      }
    }
  }

  async submit(estimation) {
    this.isDisabledEstimation = false;
    let estimationObj = Object.assign({}, estimation);
    estimationObj.projectId = this.modalViewData.data.projectId;
    estimationObj.insertUser = this.modalViewData.data.modalData.loggedUser;
    estimationObj.updateUser = this.modalViewData.data.modalData.loggedUser;
    estimationObj.clientId = this.clientViewService.selectedClient.id;
    estimationObj.dateStr = estimationObj.date ? this.formatDatepicker.ConvertDateFormatForSave(estimationObj.date) : estimationObj.date;
    if (this.estimationFormGroup.invalid) {
      this.isDisabledEstimation = true;
      return;
    }
    if (this.isEdit) {
      this.updateEstimationData(estimationObj.id, estimationObj);
    }
    else {
      this.saveEstimationData(estimationObj);
    }
  }

  saveEstimationData(estimationObj) {
    this.clientService.addEstimation(estimationObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEstimation = true;
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.projectViewService.refreshEstimationList.emit(res.data);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEstimation = true;
        }
      },error=>{ 
        console.log(error); this.closeDialog();
      });
  }

  updateEstimationData(estimationId, estimationObj) {
    this.clientService.updateEstimation(estimationId, estimationObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEstimation = true;
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          let savedEstimation: any = res.data;
          savedEstimation.isEdit = true;
          this.projectViewService.refreshEstimationList.emit(res.data);
          let url = this.router.url;
          if (url == "/estimation") {
            //Don't remove this comments. Need for future developments
            //this.clientService.refreshEstimation.emit(savedEstimation);
          } else if (url == "/task") {
            //Don't remove this comments. Need for future developments
            //if (this.clientService.selectedEstimationAndProjectObject)
            //this.clientService.selectedEstimation.emit(this.clientService.selectedEstimationAndProjectObject);
            //this.clientService.refreshUpdateEstimation.emit(true);
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on update', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEstimation = true;
        }
      },
      error=> { console.log(error); this.closeDialog();})
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
