export class ProjectDocuments {
  id : number;
  projectId: number;
  initialDate: any;
  documentTypeId: number;
  documentName: string;
  fileName: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  extension:string;
  link: string;
  url: string;
  file: any;
  fileList: any;
  dateStr: any;
  typeOfDocumentId: number;
}
