import {SafeResourceUrl} from '@angular/platform-browser';
import { ClientProduct } from '../../client-product/entities/clientProduct';

export class Client {
  id : number;
  legelName : string;
  addressLine1: string;
  city: string;
  postalCode: string;
  country: string;
  entrydate: any;
  position : string;
  clientStatusId: any;
  businessDescription: string;
  website: string;
  logo : string;
  logoUrl : string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser : string;
  region: string;
  state: number;
  phoneNumber: string;
  sector: string ;
  serviceTypeId?: number;
  technologies: string;
  status : boolean;
  referralSourceId: number;
  hourlyRate?: any;
  type:any;
  clientContacts:any;
  clientTasks:any;
  actionClients:any;
  referralSource:any;
  company:any;
  clientStatus:any;
  province:string;
  isSelectedItem: boolean;
  isTaskList: boolean = false;
  isContactList: boolean = false;
  isActionList: boolean = false;
  isClientDocumentList: boolean = false;
  clientStatusValue: string;
  technologyList: any = [];
  technologyListForView: any = [];
  clientDocumentList: any = [];
  logoFamily: string;
  taskName:any;
  taskDate:Date;
  userName:string;
  user:any;
  companyId: number;
  referee : string;
  closeReasonId ?:any;
  closeReason: any;
  closeReasonText:string;
  email: string;
  companySize: string;
  isStatusChange: boolean;
  companyName: any;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  safeUrl: SafeResourceUrl;
  initials: any;
  isTask: any;
  projects: any;
  clientDocument: any = [];

  projectTaskDate: any;
  clientProduct: ClientProduct[];  
}
