export enum LeaveStatus
{
    Pending = 1,
    ApprovedPM = 2,
    ApprovedHR = 3,
    RejectedPM = 4,
    RejectedHR = 5,
    CancelByUser = 6,
    Approved = 7,
}
