import { Component, OnInit } from '@angular/core';
import { ApprovalsService } from 'src/app/approvals/service/approvals.service';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent implements OnInit {
  public buttonState: boolean = false
  changeValue: string;

  buttonClickChange(){
  this.approvalsService.showHideFloating('show_hide');
   if (this.buttonState) {
    this.buttonState = false
   }
   else {
    this.buttonState = true
   }
}

  constructor(private approvalsService:ApprovalsService) { }

  ngOnInit() {
  }
}
