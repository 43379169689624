import { Component, OnInit } from '@angular/core';
import { docoumnetTypeModel } from 'src/app/shared/entities/documentTypeModel';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AddEditDocumenttypeComponent } from './add-edit-documenttype/add-edit-documenttype.component';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.scss']
})

export class DocumentTypeComponent implements OnInit {
  _DocumentTypeList: docoumnetTypeModel[] = [];
  documentTypeList: docoumnetTypeModel[] = [];
  updateDocument: docoumnetTypeModel;
  _idSelectedDocumentType;
  isLoading: boolean = false;

  constructor(private uIService: UIService,
    private _apiService: APIService,
    private alertService: AlertService,
    private maintananceService: MaintenanceService) {
    this._DocumentTypeList = [];
  }

  ngOnInit() {
    this.updateDocument = new docoumnetTypeModel();
    this.LoadDocumnetType();
    this.subscribeDocumentTypeList();
    this.maintananceService.subjectDocumentTypeRefresh.subscribe(isRefresh => {
      if (isRefresh) {
        this.LoadDocumnetType();
      }
    });
  }

  subscribeDocumentTypeList() {
    this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete DocumnetType") {
        this.deleteDocument(this._idSelectedDocumentType);
      }
    });

  }

  addDocumentType() {
    this.uIService.openDialog(AddEditDocumenttypeComponent, null);
  }

  deleteDocumentType(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete DocumnetType";
    this._idSelectedDocumentType = id;
    this.uIService.openAlert(deleteData);
  }

  editDocumentType(data) {
    const setdataDocument = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataDocument, null, null);
    this.uIService.openDialog(AddEditDocumenttypeComponent, dialogViewData);

  }

  LoadDocumnetType() {
    this.isLoading = true;
    this._apiService.get(API.documentType.listAll).subscribe(documentdata => {
      if (documentdata) {
        if (documentdata.data) {
          this.documentTypeList = documentdata.data;
          this._DocumentTypeList = this.documentTypeList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
      this.isLoading = false;
    });

  }

  deleteDocument(id) {
    this.maintananceService.deleteDocument(id).subscribe(data => {
      if (data.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
        this.uIService.openSnackBar(snack);
        if (data.data) this.LoadDocumnetType()
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }
    });
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintananceService.UpdateDocumentType(model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this.maintananceService.DocumentTypeRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
}
