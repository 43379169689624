export class InventoryUserModel {
  id: number;
  inventoryId: number;
  user: string;
  fromDate: any;
  toDate: any;
  inventory: any = [];
  fromDateStr: string;
  toDateStr: string;
}
