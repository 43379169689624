export class ClientDocument {
  id : number;
  clientId: number;
  date: any;
  documentTypeId: number;
  documentName: string;
  generatedFileName: string;
  fileName: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  documentTypeName: string;
  dateStr: any;
  main: boolean;
  requested: boolean;
  typeOfDocumentId: number;
  googleDriveId: string;
  googleDriveUrl: string;
}
