import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedClientService {

  @Output() passCompanyIdToClient: EventEmitter<{ companyId: any, isLoad: any }> = new EventEmitter<{ companyId: any, isLoad: any }>();
  @Output() passCompanyIdToTask: EventEmitter<{ companyId: any, isLoad: any }> = new EventEmitter<{ companyId: any, isLoad: any }>();
  @Output() refreshCompanySelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() taskUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clientStatusChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientForUpdate: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

}
