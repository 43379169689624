import { APIService } from './../../shared/services/api.service';
import { ApprovalsService } from './../service/approvals.service';
import { Subject } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import API from '../../shared/services/api.routes.json'
@Component({
  selector: 'app-hrm-leave-filter',
  templateUrl: './hrm-leave-filter.component.html',
  styleUrls: ['./hrm-leave-filter.component.scss']
})
export class HrmLeaveFilterComponent implements OnInit {
  @ViewChild("managerSearch",{ static: true }) managerSearch:ElementRef
  filterForm : FormGroup;
  leaveTypeList:any;
  public _employeeListBkp: any = [];
  public _employeeList: any = [];
  constructor(  
    public commonDropdownService: CommonDropdownService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private approvalsService : ApprovalsService,
    public _apiService: APIService
    
    ) { }

  ngOnInit() {
    this.initForm();
    this.fetchLeaveType();
    this.LoadEmployeeList()
    this.commonDropdownService.LoadEmployeeList();
  }

  initForm()
  {
    this.filterForm = this.fb.group({
      empId : [''],
      leaveTypeId : [''],
      duration : [''],
      fromDate : ['',Validators.required],
      toDate : ['',Validators.required],
      status : [''],
      isHR : [''],
      authPreson : [''],
    })
  }

  public LoadEmployeeList() {
    this._apiService.get(API.employee.getEmployeeList)
      .subscribe(response => {
        this._employeeList = response.data;
        this._employeeListBkp=this._employeeList;
      }, err => {
        console.error(err)
      }, () => { });
  }

  searchManagers()
  {
    const searchInput=this.managerSearch.nativeElement.value ?
      this.managerSearch.nativeElement.value.toLowerCase():'';

      this.commonDropdownService._employeeListBkp =this.commonDropdownService._employeeList.filter(m=>{
      if(m.firstName!=null || m.lastName!=null)
      {
        const viewValue : string=m.firstName.toLowerCase() + m.lastName.toLowerCase();
        
        return viewValue.indexOf(searchInput) > -1;
      }
    })
  }

  fetchLeaveType()
  {
    this.approvalsService.getLeaveType().subscribe(res=>{
      this.leaveTypeList=res.data;
    },erroe=>{
      console.log(erroe)
    })
  }

  validateDateRange()
  {
    let fromDate =  new Date(this.filterForm.get('fromDate').value)
    let toDate =  new Date(this.filterForm.get('toDate').value)

    if(!this.validateDates(fromDate,toDate)){
      this.filterForm.get('toDate').setErrors({'incorrect': true});
    }else{
      this.filterForm.get('toDate').setErrors(null);
    }
  }

  filterDialog()
  {
    if(this.filterForm.valid) {
      this.approvalsService.setFilter(this.filterForm.value)
      this.dialog.closeAll();
    }
  }

  validateDates(startDate: Date, endDate: Date): boolean {
    return endDate > startDate;
  }
  
  clearDialog()
  {
    this.filterForm.reset();
  }

  cancelDialog()
  {
    this.dialog.closeAll();
  }

}


