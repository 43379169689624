import { ClientPack } from './clientPack';
import { Client } from '../../client/entities/client';
import { Product } from './product';

export class ClientProduct {
  id: number;
  clientId: number;
  productId: number;
  state: number;
  proposedDate: any;
  rejectDate: any;
  confirmDate: any;
  comments: string;
  main: boolean;
  active: boolean;

  proposedDateStr: any;
  rejectDateStr: any;
  confirmDateStr: any;

  productName: string;
  showMore: any = false;

  client: Client;
  product: Product;
  clientPack: ClientPack[];
}
