export class EstimationPhase {
  id: number;
  estimationId: number;
  state: number;
  type: any;
  userType: number;
  userId: number;
  contactId: number;
  date: any;
  dateStr: string;
  concept: string;
  hours: any;
  maxHours: any;
  hoursStr: string;
  maxHoursStr: string;
  hourFee: any;
  budget: any;
  maxBudget: any;
  insertUser: string;
  updateUser: string;
  loggedUserId: number;
  contactName: string;

  constructor() {
  }
}
