import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FilterEmployeeModel } from '../../entities/filter-employee-model';
import { HrmService } from '../../service/hrm.service';
import { MatDialogRef } from '@angular/material';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-filter-employee',
  templateUrl: './filter-employee.component.html',
  styleUrls: ['./filter-employee.component.scss']
})
export class FilterEmployeeComponent implements OnInit {
  employeeFilterFormGroup: FormGroup;
  //filterEmployeeModel: FilterEmployeeModel;

  constructor(
    private _formBuilder: FormBuilder,
    public commonDropdownService: CommonDropdownService,
    public hrmService: HrmService,
    public dialogRef: MatDialogRef<FilterEmployeeComponent>,
    private formatDatepicker: FormatMatDatepicker,
    private uIService: UIService
  ) {
    this.hrmService.filterEmployeeModel = new FilterEmployeeModel();
    this.hrmService.filterEmployeeModel.page = 1;
    this.hrmService.filterEmployeeModel.pageSize= 15
  }

  ngOnInit() {
    this.initDropdownValues();
    this.formvalidate();
    this.setFilterModelValue();

    if (this.hrmService.filterEmployeeModel.department == undefined)
      this.hrmService.filterEmployeeModel.department = "Select";

    if (this.hrmService.filterEmployeeModel.designation == undefined)
      this.hrmService.filterEmployeeModel.designation = "Select";
  }

  formvalidate() {
    this.employeeFilterFormGroup = this._formBuilder.group({
      department: [''],
      designation: [''],
      toDate: [''],
      fromDate: [''],
    })
  }
  initDropdownValues() {
    this.commonDropdownService.LoadDepartmentList();
    this.commonDropdownService.LoadDesignationList();
  }

  filterEmployee(model) {
    if (model.department == "Select" && model.designation == "Select" && model.toDate == null && model.fromDate == null) {
      this.hrmService.isMoreFilterActive = false;
      const snack = new SnackBar(SnackBarType.info, 'Apply atleast one filter', '', 2000);
      this.uIService.openSnackBar(snack);
    }
    else {
      this.hrmService.isMoreFilterActive = true;
      model.FromDateStr = model.fromDate ? this.formatDatepicker.ConvertDateFormatForSave(model.fromDate) : null;
      model.ToDateStr = model.toDate ? this.formatDatepicker.ConvertDateFormatForSave(model.toDate) : null;
      localStorage.setItem('filterStorageModel', JSON.stringify(model));
      this.hrmService.filterEmployee(model).subscribe(
        res => {
          if (res) {
            if (res.data) {
              this.hrmService.refreshEmloyeeList.emit(res.data);
              this.closeDialog();
            }
          }
        },
        err => {
        });
    }
  }

  setFilterModelValue() {
    var value = localStorage.getItem('filterStorageModel');
    if (value) {
      var obj = JSON.parse(value);
      this.hrmService.filterEmployeeModel.department = obj && obj.department ? obj.department : 0;
      this.hrmService.filterEmployeeModel.designation = obj && obj.designation ? obj.designation : 0;
      this.hrmService.filterEmployeeModel.toDate = obj && obj.toDate ? obj.toDate : null;
      this.hrmService.filterEmployeeModel.fromDate = obj && obj.fromDate ? obj.fromDate : null;
    }
  }

  filterClosed() {
    const value = localStorage.getItem('filterStorageModel');
    if (!value)
      this.closeDialog();
    else {
      localStorage.removeItem("filterStorageModel");
      this.hrmService.clearFilter.emit(true);
      this.hrmService.isMoreFilterActive = false;
      this.closeDialog();
    }   
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
