import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { SnackBarType, SnackBar } from 'src/app/shared/components/snackbar/entities/snackbar';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { EmployeeDocument } from '../../../../../entities/empoyeeDocument';
import { Employee } from '../../../../../entities/empoyee';
import { HrmService } from '../../../../../service/hrm.service';

@Component({
  selector: 'add-edit-employee-salary',
  templateUrl: './add-edit-employee-salary.component.html',
  styleUrls: ['./add-edit-employee-salary.component.scss']
})
export class AddEditEmployeeSalaryComponent implements OnInit, OnDestroy {

  employeeDocumentFormGroup: FormGroup;
  _employeeDocumentModal: EmployeeDocument;
  _employeeDocumentForSave: EmployeeDocument;
  selectedEmployee: Employee;
  documentTypeList: any = [];
  isChecked = true;
  toggle = true;
  isDocumentRequired = false;
  private _unsubscribeAll: Subject<any>;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditEmployeeSalaryComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService) {
    this._unsubscribeAll = new Subject();
    this._employeeDocumentModal = new EmployeeDocument();
    this._employeeDocumentForSave = new EmployeeDocument();
  }

  ngOnInit() {
    this.initEmployeeDocumentFormGroup();
  }

  initEmployeeDocumentFormGroup() {
    this.employeeDocumentFormGroup = this._formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChooseEmployeeDocument(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this._employeeDocumentModal.fileList = files;
      this.toggle = true;
      this.isDocumentRequired = false;
    }
  }

  async submit(employeeDocumentModal: EmployeeDocument) {
    this.hrmService.isLoading_Salary = true;
    let valid = this.employeeDocumentFormGroup.get('file').touched && this.employeeDocumentFormGroup.get('file').valid;
    this.isDocumentRequired = !valid;
    this.toggle = valid;
    if (this.employeeDocumentFormGroup.invalid) {
      return;
    }
    this._employeeDocumentForSave = Object.assign({}, employeeDocumentModal);
    this._employeeDocumentForSave.dateStr = employeeDocumentModal.date ? this.formatDatepicker.ConvertDateFormatForSave(employeeDocumentModal.date) : employeeDocumentModal.date;
    this.onSaveEmployeeDocument();
  }

  async onSaveEmployeeDocument() {
    if (this._employeeDocumentForSave) {
      if (this.modalViewData.data.id) {
        this._employeeDocumentForSave.documentTypeId = 7;
        this._employeeDocumentForSave.documentTypeId = this.modalViewData.data.type;
        this._employeeDocumentForSave.employeeId = this.modalViewData.data.id;
        if (this._employeeDocumentForSave.fileList.length > 0) {
          this._employeeDocumentForSave.fileName = (this._employeeDocumentForSave.fileList[0]).name;
          this._employeeDocumentForSave.extension = ((this._employeeDocumentForSave.fileList[0]).name).split('.')[(((this._employeeDocumentForSave.fileList[0]).name).split('.')).length - 1];
          this.hrmService.saveEmployeeDocument(this._employeeDocumentForSave).subscribe(res => {
            this.hrmService.newDocument.emit(res);
            if (res != null && res.data) {
              const formData: FormData = new FormData();
              formData.append('file', this._employeeDocumentModal.fileList[0], res.data.generatedFileName);
              formData.append('employeeId', res.data.employeeId.toString());
              formData.append('fileId', res.data.id.toString());
              formData.append('letterTypeId', "0");             
              this.uploadEmployeeDocument(formData);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadEmployeeDocument(formData: FormData) {
    this.hrmService.uploadEmployeeDocument(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.hrmService.refreshSalaryEmployeeDocumentList.emit(this.modalViewData.data.id);
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      error=> { console.log(error); this.closeDialog();})
  }

  closeDialogBox() {
    this.hrmService.cancelDocument.emit(true);
  }
}
