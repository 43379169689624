import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedHeaderService {

  @Output() refreshCompanyDropdownList: EventEmitter<any> = new EventEmitter<any>();
  selectedCompanyId : number;
  selectedCompanyName : string;
  isMainBranch:boolean ;
  mainBranchId: number;

  constructor() {
  } 
  
  async getLoggedUserCopmany() {
    let jsonString = localStorage.getItem('company');
    return jsonString ? JSON.parse(jsonString) : "";
  }
}
