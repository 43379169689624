import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { FilterTaskComponent } from '../filter-task/filter-task.component';
import { ClientService } from '../../../client/services/client.service';
import { SnackBar, SnackBarType } from '../../../shared/components/snackbar/entities/snackbar';
import { FilterClientProjectTaskModel } from '../../entities/filterClientProjectTaskModel';
import { AuthAppService } from '../../../shared/services/auth-app.service';
import { TaskService } from 'src/app/client/services/task.service';
import { Router } from '@angular/router';
import { SharedClientService } from '../../../client/services/shared.client.service';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { Users } from '../../../auth/entities/user';
import { LoginService } from '../../../auth/login/login.service';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})

export class TaskListComponent implements OnInit {
  isLoading: boolean = false;
  clientAndProjectTaskList: any[] = [];
  idSelectedClient: number = 0;
  filterClientProjectTaskModel: FilterClientProjectTaskModel = new FilterClientProjectTaskModel();
  loggedInUser: any;
  selectedClientStatus: string = '';
  urlData: any;
  taskcount: any = 0;
  currentCompanyId: number;
  currentCompanyName: string;
  currentUsers: Users;
  companyId?: number;
  loggedUserCompany: any;
  yesterdayDate: any;

  //pagination properties
  lowValue: number = 0;
  highValue: number = 15;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIservice: UIService,
    public _apiService: APIService,
    public clientService: ClientService,
    public taskService: TaskService,
    private authAppService: AuthAppService,
    private sharedClientService: SharedClientService,
    private sharedHeaderService: SharedHeaderService,
    private loginService: LoginService,
    public router: Router) {
    this.filterClientProjectTaskModel = new FilterClientProjectTaskModel;
    this.loggedInUser = this.authAppService.getLoggedInUser();
    this.clientService.isMoreFilterActiveForTask = false;
    this.currentCompanyId = this.sharedHeaderService.selectedCompanyId;
    this.currentCompanyName = this.sharedHeaderService.selectedCompanyName;
  }

  ngOnInit() {
    this.getClientAndProjectTaskList();
    this.subscribeTaskFilterClear();
    this.subscribeUpdateClient();
    this.subscribeRefreshClientAndProjectTaskList();
    this.subscribeRefreshClientAndProjectTaskListForTaskUpdate();
    this.urlData = this.router.url;
    this.resetFilter();
    this.subscribeCompanyId();
    let today = new Date();
    this.yesterdayDate = new Date(today.setDate(today.getDate() - 1)).toISOString();
  }

  async getCurrentUser() {
    let jsonString = await this.loginService.getSessionAppUser();
    this.currentUsers = jsonString ? JSON.parse(jsonString) : "";
    if (!this.currentUsers.imageUrl) {
      this.currentUsers.imageUrl = "assets/images/profile.jpg";
    }
    this.currentCompanyId = !this.sharedHeaderService.selectedCompanyId ? this.currentUsers.company.id : this.sharedHeaderService.selectedCompanyId;
    this.currentCompanyName = !this.sharedHeaderService.selectedCompanyName ? this.currentUsers.company.name : this.sharedHeaderService.selectedCompanyName;
  }

  public getPaginatorDataForTask(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  async getClientAndProjectTaskList() {
    this.isLoading = true;
    if (this.filterClientProjectTaskModel.taskDescription == "" || this.filterClientProjectTaskModel.taskDescription == undefined)
      this.filterClientProjectTaskModel.taskDescription = null;

    if (this.filterClientProjectTaskModel.isCheckLoggingUser == true) {
      if (this.loggedInUser) {
        this.filterClientProjectTaskModel.userId = this.loggedInUser.id;
      }
    } else
      this.filterClientProjectTaskModel.userId = 0;
    this.filterClientProjectTaskModel.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
    this.clientService.filterClientAndProjectTasks(this.filterClientProjectTaskModel).subscribe(res => {
      if (res && res.data) {
        this.clientAndProjectTaskList = res.data;
        this.clientAndProjectTaskList.forEach(r => {
          var myStr = r.taskUserName;
          var matches = myStr.match(/\b(\w)/g);
          var x = matches.join('');
          r.initials = x;
        });
        this.isLoading = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      });
  }

  openFilterClient() {
    this.commonUIservice.openDialog(FilterTaskComponent, null);
  }

  filterClientAndProjectTasks(event) {
    this.lowValue = 0;
    this.highValue = 15;
    this.isLoading = true;
    if (this.filterClientProjectTaskModel.taskDescription == "" || this.filterClientProjectTaskModel.taskDescription == undefined)
      this.filterClientProjectTaskModel.taskDescription = null;

    if (this.filterClientProjectTaskModel.isCheckLoggingUser == true) {
      if (this.loggedInUser) {
        this.filterClientProjectTaskModel.userId = this.loggedInUser.id;
      }
    } else
      this.filterClientProjectTaskModel.userId = 0;
    this.filterClientProjectTaskModel.companyId = this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
    this.clientService.filterClientAndProjectTasks(this.filterClientProjectTaskModel).subscribe(res => {
      if (res && res.data) {
        this.clientAndProjectTaskList = res.data;
        this.clientAndProjectTaskList.forEach(r => {
          var myStr = r.taskUserName;
          var matches = myStr.match(/\b(\w)/g);
          var x = matches.join('');
          r.initials = x;
        });
        this.isLoading = false;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      }
    },
      err => {
        console.error();
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIservice.openSnackBar(snack);
        this.isLoading = false;
      });
  }

  subscribeRefreshClientAndProjectTaskList() {
    this.clientService.refreshClientAndProjectTaskList.subscribe(res => {
      if (res) {
        this.lowValue = 0;
        this.highValue = 15;
        this.filterClientProjectTaskModel.taskDescription = null;
        this.filterClientProjectTaskModel.isCheckLoggingUser = false;
        this.filterClientProjectTaskModel.uptoToday = false;
        this.clientAndProjectTaskList = res;
      } else {
        this.clientAndProjectTaskList = null;
      }
    })
  }

  subscribeTaskFilterClear() {
    this.clientService.clearTaskFilter.subscribe(res => {
      if (res == true) {
        this.getClientAndProjectTaskList();
        this.clientService.isMoreFilterActiveForTask = false;
      }
    });
  }

  public selectClient(task) {
    const except = $(event.target).hasClass('except');
    if (!except) {
      if (task.clientId) {
        this.commonUIservice.openMask();
        this.idSelectedClient = task.id;
        task.isSelectedItem = true;
        task.url = this.urlData;
        let _clientAndProjectTaskList = this.clientAndProjectTaskList.filter(x => x.clientId == task.clientId && x.projectId == 0);
        this.taskcount = _clientAndProjectTaskList.length;
        task.taskcount = this.taskcount;
        this.clientService.selectedClientAndProjectObject = task;
        this.clientService.selectedClient.emit(task);
      }
    }
  }

  subscribeRefreshClientAndProjectTaskListForTaskUpdate() {
    this.clientService.clientProjectTaskUpdate.subscribe(res => {
      this.getClientAndProjectTaskList();
      if (res != null)
        this.idSelectedClient = res.id;
    })
  }

  subscribeUpdateClient() {
    this.clientService.refreshUpdateClient.subscribe(res => {
      if (res == true) {
        this.getClientAndProjectTaskList();
      }
    });
  }

  resetFilter() {
    this.filterClientProjectTaskModel.isCheckLoggingUser = false;
    this.filterClientProjectTaskModel.taskDescription = null;
    this.filterClientProjectTaskModel.uptoToday = false;
    this.filterClientProjectTaskModel.userId = 0;
    this.clientService.isMoreFilterActiveForTask = false;
    this.idSelectedClient = 0;
    this.clientService.isSelectedClient = false;
    localStorage.removeItem("filterStorageModel");
  }

  subscribeCompanyId() {
    this.sharedClientService.passCompanyIdToClient.subscribe(data => {
      if (!data) { return; }
      this.currentCompanyId = data.companyId;
      this.idSelectedClient = 0;
      this.clientService.isSelectedClient = false;
      this.companyId = this.currentCompanyId > 0 && data.isLoad && this.sharedHeaderService.isMainBranch ? null : this.currentCompanyId;
      this.getClientAndProjectTaskList();
    })
  }
}

