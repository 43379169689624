import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';
import { APIService } from 'src/app/shared/services/api.service';
import { HrmMetaDataEnum } from 'src/app/shared/enum/hrmMetadataType';
import { EmployeeLeave } from 'src/app/erm/entities/empoyeeLeave';
import { Users } from 'src/app/auth/entities/user';
import { Employee } from 'src/app/erm/entities/empoyee';
import { ErmService } from 'src/app/erm/service/erm.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { LeaveStatus } from 'src/app/shared/enum/ermMetadataType';
import { LeaveDetail } from '../../models/leave-detail';
import { LeaveFullDetails } from '../../models/leave-fulldetails';
import moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HrmService } from 'src/app/hrm/service/hrm.service';
interface Duration {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'add-leave',
  templateUrl: './add-leave.component.html',
  styleUrls: ['./add-leave.component.scss'],
  host: {
    '(window:paste)': 'handlePaste( $event )',
  },
})
export class AddLeaveComponent implements OnInit {
  @ViewChild("coveringPersonSearch", { static: true }) coveringPersonSearch: ElementRef
  selectedValue: string;
  selectedDuration: number = 1;
  selectedLeaveType: string;
  leaveTypeList: HrmMetaData[];
  _employeeLeaveModal: EmployeeLeave;
  employeeLeaveFormGroup: FormGroup;
  checkToDate: boolean = false;
  currentusers: Users;
  loginEmployee: Employee;
  today;
  endYeardate;
  _userModal: Users;
  isWeekendStartDate: boolean = false;
  isWeekendEndDate: boolean = false;
  isWeekendfromDate: boolean = false;
  istwoDays: boolean = false;
  isWeekDayRange: boolean = false;
  rows: any[] = [];
  isHalfDayConShow: boolean = true;
  isAnualEligibility: boolean = false;
  isautherEligibility: boolean = false;
  isLeaveEligibility: boolean = false;

  _startDate;
  _endDate;
  _fromDate;
  _leaveTypeId;
  _leaveTypeText;

  durations: Duration[] = [
    { value: '1', viewValue: 'Full Day' },
    { value: '2', viewValue: 'Half Day' },
  ];

  leaveDetailsList: LeaveDetail[];
  leaveDetails: LeaveDetail;
  leaveFullDetails: LeaveFullDetails;
  leaveType: any;
  anualLeaveDayCount = 0;
  leaveDataCardData: any;
  employeeListWithouLoginUser:any;
  loggedUser:any;
  entitledLeaveDetails : any;
  entitledLeaveDetailsSelected : any;
  appliedLeaveDetails : any;

  constructor(private _apiService: APIService,
    public commonDropdownService: CommonDropdownService,
    public dialog: MatDialog,
    private ermService: ErmService,
    private commonUIService: UIService,
    private _formBuilder: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    public hrmService: HrmService,
    public dialogRef: MatDialogRef<AddLeaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._employeeLeaveModal = new EmployeeLeave();
    this._userModal = new Users();
    this.loginEmployee = new Employee();
    this.currentuser();
    this.rows = [];
    this.today = moment().format('YYYY-MM-DD');
    this.endYeardate = moment({ year: (new Date()).getFullYear(), month: 12, day: 31 });
    this.loggedUser= JSON.parse(localStorage.getItem('appUser'));

  }

  ngOnInit() {
    this.leaveDetailsList = [];
    this.initEmployeeLeaveFormGroup();
    this.getLeaveTypeDetails();
    this.getAlreadyAppliedLeaves();
    this.commonDropdownService.LoadEmployeeList();
    this.leaveDataCardData = this.data;
    this.employeeListWithouLoginUser =   this.commonDropdownService._employeeListBkp.filter(item => item.id !== this.loggedUser.empId);
  }

  initEmployeeLeaveFormGroup() {
    
    this.employeeLeaveFormGroup = this._formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      fromDate: ['', [Validators.required]],
      duration: [''],
      leaveTypeId: ['', [Validators.required]],
      comment: [''],
      startTime: [],
      endTime: [],
      absenceReasonText: [''],
      CoveringPersonId: ['', [Validators.required]],
    });
    this.employeeLeaveFormGroup.patchValue({
      duration: '1'
    });
  }

  getLeaveTypeDetails() {
    let urlEntitledLeave = this._apiService.getEndPoint("employeeLeave.getEmployeeLeaveTypeListByEmployeeId", [["{employeeId}", this.loggedUser.empId]]);
    
    this._apiService.get(urlEntitledLeave).subscribe(response => {
      if (response) {
        if (response.data) {
          this.entitledLeaveDetails = response.data;

           this.UpdateLeaveTypeDetailsWithComment();    
        }
      }
    });
    
  }

  UpdateLeaveTypeDetailsWithComment()
  {
    let url = this._apiService.getEndPoint("hrmMetaData.getHrmMetadataByType", [["{type}", HrmMetaDataEnum.Leave], ["{isOnlyActive}", true]]);
    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          response.data.forEach(r => {

            this.entitledLeaveDetailsSelected=this.entitledLeaveDetails.find(leave=> leave.leaveTypeId===r.id);
            
            if (this.entitledLeaveDetailsSelected.employeeLeaveCategoriesModelList[0].remaining == 0) 
            {
              r.limitExceeded = 'disabled';
              r.description = r.description + ' (Exceeded)';
            }                     

          });
          this.leaveTypeList = response.data;        
        }
      }
    });
  }

  searchCoveringPerson() {
    const searchInput = this.coveringPersonSearch.nativeElement.value ?
      this.coveringPersonSearch.nativeElement.value.toLowerCase() : '';

    this.employeeListWithouLoginUser = this.commonDropdownService._employeeList.filter(m => {
      if (m.firstName != null || m.lastName != null) {
        const viewValue: string = m.firstName.toLowerCase() + m.lastName.toLowerCase();
        return viewValue.indexOf(searchInput) > -1;
      }
    });
    this.employeeListWithouLoginUser =   this.employeeListWithouLoginUser.filter(item => item.id !== this.loggedUser.empId);
  }

  leaveTypeChange(row) {
    this.isHalfDayConShow = this.employeeLeaveFormGroup.get('duration').value == "1" ? true : false;
    this.removeObjectWithId(this.rows, row.id)
    let indexForId = this.rows.length + 1
    this.rows.push({
      id: indexForId,
      name: '',
      isFullDay: this.isHalfDayConShow
    })
    if (this.leaveDetailsList != undefined) {
      this.removeObjectWithId(this.leaveDetailsList, row.id);
    }
    this.leaveDetailsSetup(indexForId, this.isHalfDayConShow, this.isHalfDayConShow == true ? 0 : this.employeeLeaveFormGroup.get('startTime').value, this.employeeLeaveFormGroup.get('startDate').value);
  }

  leaveTimeChange(row) {
    this.removeObjectWithId(this.rows, row.id)
    let indexForId = this.rows.length + 1
    this.rows.push({
      id: indexForId,
      name: '',
      isFullDay: this.isHalfDayConShow
    })

    if (this.leaveDetailsList != undefined) {
      this.removeObjectWithId(this.leaveDetailsList, row.id);
    }
    this.leaveDetailsSetup(indexForId, this.isHalfDayConShow, this.isHalfDayConShow == true ? 0 : this.employeeLeaveFormGroup.get('startTime').value, this.employeeLeaveFormGroup.get('startDate').value);

    if (this.leaveDetailsList != undefined) {
      this.validationFuntion();
    }
  }

  leaveDurationChange(row) {
    this.removeObjectWithId(this.rows, row.id)
    let indexForId = this.rows.length + 1
    this.rows.push({
      id: indexForId,
      name: '',
      isFullDay: this.isHalfDayConShow
    })
    if (this.leaveDetailsList != undefined) {
      this.removeObjectWithId(this.leaveDetailsList, row.id);
    }
    this.leaveDetailsSetup(indexForId, this.isHalfDayConShow, this.employeeLeaveFormGroup.get('startTime').value, this.employeeLeaveFormGroup.get('startDate').value);
  }

  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  deleteRow(row) {
    this.removeObjectWithId(this.rows, row.id);

    if (this.leaveDetailsList != undefined) {
      this.removeObjectWithId(this.leaveDetailsList, row.id);
    }
    this.validationFuntion();
  }

  validationFuntion() {
    this._fromDate = new Date(this.employeeLeaveFormGroup.get('fromDate').value);
    this._endDate = new Date(this.employeeLeaveFormGroup.get('endDate').value);
    this._startDate = new Date(this.employeeLeaveFormGroup.get('startDate').value);

    this.anualLeaveDayCount = this.daysBetween(this._fromDate, this._endDate);

    //#calculateAnualLeaveDayCount
    var leaveallocation = this.leaveDataCardData.data.data.find(a => a.leaveTypeId === this._leaveTypeId);
    if (leaveallocation != undefined) {
      var selectedMonth = this.data.data.nextSelectionMonth.toLowerCase();
      var balanceLeave = leaveallocation[selectedMonth] - leaveallocation.usedLeave;
      this.isLeaveEligibility = false;
    } else { this.isLeaveEligibility = true }
    //#calculateAnualLeaveDayCount

    //#calculateoutherLeaveDayCount
    var casualLeaveCount = 0;
    let fulldayCasualLeave = this.leaveDetailsList;
    for (var day of fulldayCasualLeave) {
      if (day.IsFulDay == true) { casualLeaveCount = casualLeaveCount + 1 }
      if (day.IsFulDay == false) { casualLeaveCount = casualLeaveCount + 0.5 }
    }
    //#calculateoutherLeaveDayCount

    if (!isNaN(this._fromDate)) {
      if (this._fromDate.getDay() % 6 == 0) { this.isWeekendfromDate = true } else { this.isWeekendfromDate = false }
    } else { this.isWeekendfromDate = false; this.isWeekendStartDate = false }

    if (!isNaN(this._endDate)) {
      if (this._endDate.getDay() % 6 == 0) { this.isWeekendEndDate = true } else { this.isWeekendEndDate = false }
    } else { this.isWeekendEndDate = false; this.isWeekendStartDate = false }

    if (!isNaN(this._startDate)) {
      if (this._startDate.getDay() % 6 == 0) { this.isWeekendStartDate = true } else { this.isWeekendStartDate = false }
    } else { this.isWeekendStartDate = false; this.isWeekendEndDate = false; this.isWeekendfromDate = false }

    if (this._leaveTypeId != undefined) {
      if (this.leaveType.description == "Annual Leave" && (this.anualLeaveDayCount + 1) < 3 && (!isNaN(this._endDate)) && (!isNaN(this._fromDate))) { this.istwoDays = true } else { this.istwoDays = false }
    }

    if (this._leaveTypeId != undefined) {
      if (this.leaveType.description == "Annual Leave" && (this.anualLeaveDayCount) > balanceLeave && (!isNaN(this._endDate)) && (!isNaN(this._fromDate))) { this.isAnualEligibility = true } else { this.isAnualEligibility = false }
    }

    if (this._leaveTypeId != undefined) {
      if (this.leaveType.description != "Annual Leave" && (casualLeaveCount) > balanceLeave) { this.isautherEligibility = true } else { this.isautherEligibility = false }
    }
  }

  daysBetween(startDate, endDate) {
    var diff = Math.floor((Date.parse(endDate) - Date.parse(startDate)) / 86400000);
    diff = diff + 1;
    var weedDaysCount = 0;

    var newStartDate = new Date(startDate);

    while (newStartDate <= endDate) {

      if (newStartDate.getDay() % 6 != 0) {
        weedDaysCount = weedDaysCount + 1;
      }
      newStartDate.setDate(newStartDate.getDate() + 1);
    }
    return weedDaysCount;
  }

  validationErrors() {
    if (
      this.isWeekendStartDate ||
      this.isWeekendEndDate ||
      this.isWeekendfromDate ||
      this.istwoDays ||
      this.isAnualEligibility ||
      this.isautherEligibility ||
      this.isLeaveEligibility) {
      return true;
    } else {
      return false;
    }
  }

getAlreadyAppliedLeaves()
{
  this.hrmService.getEmployeeLeaveByEmployeeId(this.loggedUser.empId).subscribe(res => {
    if (res && res.data) {
      
      this.appliedLeaveDetails = res.data;   
    }
  });
}


submit(employeeLeaveModal: EmployeeLeave) {

    if (this.employeeLeaveFormGroup.valid && this.leaveDetailsList.length > 0 && this.leaveType.description != "Annual Leave" && !this.validationErrors()) {
      if (this.loginEmployee.id) {
        employeeLeaveModal.status = LeaveStatus.Pending;
        employeeLeaveModal.employeeId = this.loginEmployee.id;

        //#calculateCasualLeaveDayCount
        var casualLeaveCount = 0;
        let fulldayCasualLeave = this.leaveDetailsList;
        for (var day of fulldayCasualLeave) {
          if (day.IsFulDay == true) { casualLeaveCount = casualLeaveCount + 1 }
          if (day.IsFulDay == false) { casualLeaveCount = casualLeaveCount + 0.5 }
        }
        employeeLeaveModal.noOfDays = casualLeaveCount;
        //#EndcalculateCasualLeaveDayCount

        this.leaveModelConversion(employeeLeaveModal);

        //Check duplicates
        var formStartDate = this.leaveFullDetails.LeaveDetail[0].LeaveDate; 

        var stopForEach='false';

        this.appliedLeaveDetails.forEach(r => {
        
          if(stopForEach =='false')
          {
            var startDate = new Date(r.startDate);
            var endDate = new Date(r.endDate);          
          
            if((formStartDate >= startDate  && formStartDate <= endDate) )
            {    
              const snack = new SnackBar(SnackBarType.error, 'Leave already applied for this Date', '', 4000);
              this.commonUIService.openSnackBar(snack);      
              stopForEach = 'true';
            }           
          }

          });  

        if (stopForEach == 'true')
        {
          stopForEach = 'false'
          return;
        }
        //Check duplicates

        this.ermService.saveEmployeeLeaveDetails(this.leaveFullDetails).subscribe(
          res => {
            if (res.isSuccsess) {
              this.ermService.setAddleave();
              const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
              this.closeDialog();
            } else {
              const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
            }
          },
          err => {
            console.error(); this.closeDialog();
          });
      }
    }
    if ((this.employeeLeaveFormGroup.valid && this.leaveType.description == "Annual Leave" || this.leaveType.description =="Maternity Leave") && !this.validationErrors()) {

      if (this.loginEmployee.id) {
        employeeLeaveModal.status = LeaveStatus.Pending;
        employeeLeaveModal.employeeId = this.loginEmployee.id;
        employeeLeaveModal.noOfDays = this.anualLeaveDayCount;
        employeeLeaveModal.startDate = this.employeeLeaveFormGroup.get('fromDate').value;

        //#setLeaveDetails
        var weedDaysCount = 0;
        var newStartDate = new Date(employeeLeaveModal.startDate);

        while (newStartDate <= employeeLeaveModal.endDate) {

          if (newStartDate.getDay() % 6 != 0) {
            this.leaveDetailsSetup(weedDaysCount, true, this.isHalfDayConShow == true ? 0 : this.employeeLeaveFormGroup.get('startTime').value, newStartDate);
          }
          newStartDate.setDate(newStartDate.getDate() + 1);
        }
        //#endLeaveDetails
        this.leaveModelConversion(employeeLeaveModal);

        this.ermService.saveEmployeeLeaveDetails(this.leaveFullDetails).subscribe(
          res => {
            if (res.isSuccsess) {
              this.ermService.setAddleave();
              const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
              this.closeDialog();
            } else {
              const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
              this.commonUIService.openSnackBar(snack);
            }
          },
          err => {
            console.error(); this.closeDialog();
          });
      }
    }
  }

  leaveModelConversion(oldModel: EmployeeLeave) {
    let leaveList: LeaveFullDetails = {
      id: 0,
      coveringPersonId: oldModel.CoveringPersonId,
      absenceReasonText: oldModel.absenceReasonText,
      comment: oldModel.comment,
      employeeId: oldModel.employeeId,
      insertDate: this.today,
      insertUser: this.loginEmployee.firstName,
      leaveTypeId: oldModel.leaveTypeId,
      LeaveStatusId: 1,
      isFullDayLeave: oldModel.isFullDayLeave,
      LeaveDetail: this.leaveDetailsList,
      NoOfDays: oldModel.noOfDays,
      startDate: oldModel.startDate,
      endDate: oldModel.endDate,

      endTime: undefined,
      updateDate: undefined,
      startTime: '',
      leaveTypeText: '',
      updateUser: '',
      startDateStr: '',
      endDateStr: '',
      startTimeStr: '',
      endTimeStr: '',
      employeeName: '',
      status: 0
    }
    this.leaveFullDetails = leaveList;
  }

  resetLeaveTable() {
    this._leaveTypeId = this._employeeLeaveModal.leaveTypeId;
    this.leaveType = this.leaveTypeList.find(x => x.id === this._leaveTypeId);
    if(this.leaveType.description == "Annual Leave" || this.leaveType.description == "Maternity Leave")
    {
      this._leaveTypeText=true;
    }else if(this.leaveType.description=="Casual Leave" || this.leaveType.description=="Substitute Leave")
    {
      this._leaveTypeText=false;
    }else
    {
      this._leaveTypeText=false;
    }

    // this._leaveTypeText = this.leaveType.description == "Annual Leave" || "Maternity Leave" ? true : false;
    // this._leaveTypeText = this.leaveType.description == "Casual Leave" ? false : true;
    // this._leaveTypeText = this.leaveType.description == "Substitute Leave" ? false : true;

    if (this.leaveType.description == "Annual Leave") {
      this.employeeLeaveFormGroup.controls.startDate.disable();
      this.employeeLeaveFormGroup.controls.startDate.setValue(null);

      this.employeeLeaveFormGroup.controls.fromDate.enable();
      this.employeeLeaveFormGroup.controls.endDate.enable();

      this.resetErrors();
    }else if(this.leaveType.description == "Maternity Leave"){
      this.employeeLeaveFormGroup.controls.startDate.disable();
      this.employeeLeaveFormGroup.controls.startDate.setValue(null);

      this.employeeLeaveFormGroup.controls.fromDate.enable();
      this.employeeLeaveFormGroup.controls.endDate.enable();
      this.resetErrors();
    }
    else if(this.leaveType.description == "Sick Leave"){
      this.employeeLeaveFormGroup.controls.fromDate.disable();
      this.employeeLeaveFormGroup.controls.endDate.disable();
      this.employeeLeaveFormGroup.controls.fromDate.setValue(null);
      this.employeeLeaveFormGroup.controls.endDate.setValue(null);

      this.employeeLeaveFormGroup.controls.startDate.enable();
      this.resetErrors();
    }
    else {
      this.employeeLeaveFormGroup.controls.fromDate.disable();
      this.employeeLeaveFormGroup.controls.endDate.disable();
      this.employeeLeaveFormGroup.controls.fromDate.setValue(null);
      this.employeeLeaveFormGroup.controls.endDate.setValue(null);

      this.employeeLeaveFormGroup.controls.startDate.enable();

      this.resetErrors();

    }

    this.rows.splice(0);
  }

  resetErrors() {
    this.isWeekendfromDate = false;
    this.isWeekendEndDate = false;
    this.isWeekendStartDate = false;
    this.istwoDays = false;
    this.isWeekDayRange = false;
    this.isAnualEligibility = false;
    this.isautherEligibility = false;
    this.isLeaveEligibility = false;
  }

  currentuser() {
    let loggedUser = JSON.parse(localStorage.getItem('appUser'));
    this.currentusers = loggedUser;
    this.ermService.getEmployeeByEmail(this.currentusers.email).subscribe(res => {
      this.loginEmployee = res.data;
    });
  }

  leaveDetailsSetup(index, isfulDay, leaveSlot, leaveDate) {
    let category: LeaveDetail = {
      id: index,
      IsFulDay: isfulDay,
      LeaveDate: new Date(leaveDate),
      LeaveSlot: leaveSlot
    };
    this.leaveDetailsList.push(category);
  }

  addRow() {
    let indexForId = this.rows.length + 1
    this.rows.push({
      id: indexForId,
      name: '',
      isFullDay: this.isHalfDayConShow
    })
    this.leaveDetailsSetup(indexForId, this.isHalfDayConShow, this.isHalfDayConShow == true ? 0 : this.employeeLeaveFormGroup.get('startTime').value, this.employeeLeaveFormGroup.get('startDate').value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
