import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
@Injectable({
  providedIn: 'root'
})
export class HrmMaintenanceService {

  public subjectAnnualRefresh = new Subject<any>();
  public subjectCasualRefresh = new Subject<any>();
  public subjectHospitalizationRefresh = new Subject<any>();
  public subjectOPDRefresh = new Subject<any>();
  public subjectPayableRefresh = new Subject<any>();
  public subjectSickRefresh = new Subject<any>();

  constructor(private apiservice: APIService) { }

  async AnnualRefresh(isRefresh: any) {
    this.subjectAnnualRefresh.next(isRefresh);
  }

  async CasualRefresh(isRefresh: any) {
    this.subjectCasualRefresh.next(isRefresh);
  }

  async HospitalizationRefresh(isRefresh: any) {
    this.subjectHospitalizationRefresh.next(isRefresh);
  }

  async OPDRefresh(isRefresh: any) {
    this.subjectOPDRefresh.next(isRefresh);
  }

  async PayableRefresh(isRefresh: any) {
    this.subjectPayableRefresh.next(isRefresh);
  }

  async SickRefresh(isRefresh: any) {
    this.subjectSickRefresh.next(isRefresh);
  }

  updateHrmMetadata(id: any, obj: any) {
    let url = this.apiservice.getEndPoint("hrmMetaData.updateHrmMetadata", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  saveHrmMetadata(obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("hrmMetaData.saveHrmMetadata", null);
    return this.apiservice.post(url, obj, true);
  }

  deleteHrmMetadata(id: string) {
    let url = this.apiservice.getEndPoint("hrmMetaData.deleteHrmMetadata",null);
    return this.apiservice.delete(url, id);
  }

  getAllHolidayInfoByYear(year:any){
    let url = `${API.holiday.getAllHolidayInfoByYear}`;
    url = url.replace('{year}', year);
    return this.apiservice.get(url);
  }

  getAllHolidaystypes(){
    let url = `${API.holiday.getAllHolidaystypes}`;
    return this.apiservice.get(url);
  }

  editHolidayInfo(obj:any){
    let url = `${API.holiday.editHolidayInfo}`;
    return this.apiservice.put(url,obj);
  }

  saveHolidayInfo(obj:any){
    let url = `${API.holiday.saveHolidayInfo}`;
    return this.apiservice.post(url,obj);
  }

  deleteHolidays(id:any){
    let url = `${API.holiday.deleteHolidays}`;
    url = url.replace('{id}', id);
    return this.apiservice.delete(url,id);
  }


}
