import { NativeDateAdapter, MatDateFormats } from '@angular/material';
import * as moment from 'moment';

export class AppDateAdapter extends NativeDateAdapter {
  formatt(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

export class FormatMatDatepicker {
  ConvertDateFormatForSave(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }
  }

  ConvertDateFormatForSaveCalenderEndDate(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }
  }

  addDaysToDate(date,noOfDays:number){
    if (date  && noOfDays) {
      return moment(date).add(noOfDays, 'days').format('DD/MM/YYYY HH:mm:ss');
    }
  }

  addMonthsToDate(date,noOfMonths:number){
    if (date && noOfMonths) {
      return moment(date).add(noOfMonths, 'months').format('DD/MM/YYYY HH:mm:ss');
    }
  }

  addYearsToDate(date,noOfYears:number){
    if (date && noOfYears) {
      return moment(date).add(noOfYears, 'years').format('DD/MM/YYYY HH:mm:ss');
    }
  }
};

export class FormatTimeForDisplay {
  ConvertTimeFormatForDisplay(timeObjs) {
    if (timeObjs) {   
      let momentVal = moment(timeObjs, 'hh:mm:ss');    
      var min = momentVal.minutes() < 10 ? "0" + momentVal.minutes() : momentVal.minutes();
      var hour = momentVal.hours() < 10 ? "0" + momentVal.hours() : momentVal.hours();
      return hour + ':' + min;
    }
  }
}
