import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ModuleComponent } from './module/module.component';
import {AuthGuard, AuthRedirect} from '../shared/auth-guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [AuthRedirect] },
  { path: 'module', component: ModuleComponent, canActivate: [AuthGuard] }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {

}
