export class ReferalSourceModel
{
         id :number;
         parentId :number;
         refSourceName:string;
         insertDate:Date;
         insertUser :string;
         updateDate :Date;
         updateUser :string;
         status:boolean;
         parentName :string;
         active?: boolean;
         isExistId:boolean;
}