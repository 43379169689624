import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YearEndProcessComponent } from './year-end-process.component';
import { AngularMaterialModule } from '../angular-material.module';

@NgModule({
  declarations: [YearEndProcessComponent],
  imports: [CommonModule, AngularMaterialModule]
})
export class YearEndProcessModule { }
