export class ClientTask {
  id: number;
  clientId: number;
  taskName: string;
  description: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: any;
  taskDate: any;
  taskUser: string;
  userId:number;
  taskTypeId:number;
  taskType: any;
  user:any;
  taskTime: any;
  taskUserImageUrl: string;
  taskUserName: string;
  taskDateStr: string;
  taskTimeStr: any;
  initials: any;

  constructor() {
    this.status = true;
  }
}
