export class Company {
  id: number;
  name: string;
  abbreviation : string;
  main: boolean;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;

  isDisable: boolean;
}
