import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { AddEditRegionComponent } from './add-edit-region/add-edit-region.component';
import { regionModel } from 'src/app/shared/entities/regionModel';
import { APIService } from 'src/app/shared/services/api.service';
import API from '../../shared/services/api.routes.json';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MaintenanceService } from '../service/maintenance.service';
import { Subscription } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})

export class RegionComponent implements OnInit {

  _RegionList: regionModel[] = [];
  regionList: regionModel[] = [];
  updateRegion: regionModel;
  _idSelectedRegionType: number;
  private subscriber: Subscription;
  isLoading: boolean = false;

  constructor(private uIService: UIService, private _apiService: APIService, private alertService: AlertService,
    private maintananceService: MaintenanceService, ) {
    this._RegionList = [];
  }

  ngOnInit() {
    this.updateRegion = new regionModel();
    this.LoadRegionList();
    this.SubscribeRegionList();
    this.maintananceService.subjectRegionTypeRefresh.subscribe(istrueRefresh => {

      if (istrueRefresh) {
        this.LoadRegionList()
      }
    });
  }

  addRegion() {
    this.uIService.openDialog(AddEditRegionComponent, null);
  }

  SubscribeRegionList() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data == "Delete RegionType") {
        this.deleteRegionConform(this._idSelectedRegionType);
      }
    });
  }

  LoadRegionList() {
    this.isLoading = true;
    this._apiService.get(API.regionType.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this.regionList = response.data;
          this._RegionList = this.regionList.sort((a, b) => a.regionName.localeCompare(b.regionName));
        }
      }
      this.isLoading = false;
    });
  }

  deleteRegion(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete RegionType";
    this._idSelectedRegionType = id;
    this.uIService.openAlert(deleteData);

  }

  deleteRegionConform(id) {
    this.maintananceService.deleteRegionConform(id).subscribe(res => {
      if (res.isSuccsess) {
        var snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
        this.uIService.openSnackBar(snack);
        this.LoadRegionList();
      }
      else {
        var snack = new SnackBar(SnackBarType.error, "Error", "", 2000);
        this.uIService.openSnackBar(snack);
      }

    });
  }
  activeDeactive(event, model) {
    model.active = event.checked;
    this.maintananceService.updateRegionType(model).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintananceService.RegionTypeRefresh(true);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }
  editRegion(data) {
    const setdataRegion = Object.assign({}, data);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, setdataRegion, null, null);
    this.uIService.openDialog(AddEditRegionComponent, dialogViewData);
  }
}
