import { Injectable, EventEmitter, Output } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { Users } from '../../entities/user';
import API from '../../../shared/services/api.routes.json';
@Injectable({
  providedIn: 'root'
})

export class UserService {

  public subjectUserRefresh = new Subject<any>();
  @Output() refershUpdateUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiservice: APIService) { }

  deleteUser(id: string) {
    let url = this.apiservice.getEndPoint("user.deleteUser", null);
    return this.apiservice.delete(url, id);
  }

  async userRefresh(isUserRefesh: boolean) {
    this.subjectUserRefresh.next(isUserRefesh);
  }

  saveUserData(userObj: Users) {
    let url = this.apiservice.getEndPoint("user.addUser", null);
    return this.apiservice.post(url, userObj, true);
  }

  updateUser(id: any, obj: { id: any; }) {
    let url = this.apiservice.getEndPoint("user.updateUser", [["{userID}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  addUserBranches(userBranchesList) {
    let url = this.apiservice.getEndPoint("companyUser.addCompanyUser", null);
    return this.apiservice.post(url, userBranchesList, true);
  }

  updateUserBranches(companyUserId, userBranchesList) {
    let url = this.apiservice.getEndPoint("companyUser.updateCompanyUser", [["{companyUserId}", 0]]);
    return this.apiservice.put(url, userBranchesList, true);
  }

  addUserRoles(userRolesList) {
    let url = this.apiservice.getEndPoint("userRole.addUserRole", null);
    return this.apiservice.post(url, userRolesList, true);
  }

  updateUserRoles(userRoleId, userRoleList) {
    let url = this.apiservice.getEndPoint("userRole.updateUserRole", [["{userRoleId}", 0]]);
    return this.apiservice.put(url, userRoleList, true);
  }

  getUserById(id) {
    let url = this.apiservice.getEndPoint("user.getById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  getUserByEmail(email) {
    let url = `${API.user.getUserByEmail}`;
    url = url.replace('{email}', email);
    return this.apiservice.get(url);
  }
}
