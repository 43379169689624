import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { AddEditCompanyComponent } from 'src/app/company/view-company/add-edit-company/add-edit-company.component';
import { ClientService } from 'src/app/client/services/client.service';
import API from '../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { Company } from 'src/app/company/entities/company';
import { Subscription, Subject } from 'rxjs';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CompanyService } from 'src/app/company/service/company.service';
import { SharedHeaderService } from '../../shared/services/shared.header.services';
import { LoginService } from '../../auth/login/login.service';
import { Users } from '../../auth/entities/user';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss']
})
export class ViewCompanyComponent implements OnInit {
  _companyList: Company[] = [];
  companyList: Company[] = [];
  _idSelectedCompany: number;
  today_date: string;
  selectedValue: string;
  filterAciveDeactive = false;
  currentCompanyId: number;
  _currentCompanyId: number;
  currentusers: Users;

  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  constructor(private commonUIService: UIService,
    private companyService: CompanyService,
    private loginServic: LoginService,
    public sharedHeaderService: SharedHeaderService,
    private _apiService: APIService,
    private alertService: AlertService) {
    this._companyList = [];
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    const today_date = new Date().toISOString();
    this.getCurrentUser();
    this.LoadCompanyList();
    this.subscribeAlertConfirmEvents();
    this.companyService.subjectCompanyRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadCompanyList();
      }
    });
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete Company" && this._idSelectedCompany) {
          this.deleteCompany(this._idSelectedCompany);
          console.log(this._idSelectedCompany);
        }
      });
  }

  openAddCompanyPopup() {
    this.commonUIService.openDialog(AddEditCompanyComponent, null);
  }

  filterActive() {
    if (this.filterAciveDeactive == false)
      this.filterAciveDeactive = true;
    else if (this.filterAciveDeactive == true) {
      this.filterAciveDeactive = false;
      this.LoadCompanyList();
      this.selectedValue = undefined;
    }
  }

  private LoadCompanyList() {
    this._apiService.get(API.company.listAll).subscribe(response => {
      if (response) {
        if (response.data) {
          this.companyList = response.data;
          this._companyList = this.companyList.sort((a, b) => a.name.localeCompare(b.name));
        }
      }
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  editCompany(company) {
    const companyModel = Object.assign({}, company);
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, companyModel, null, null);
    this.commonUIService.openDialog(AddEditCompanyComponent, dialogViewData);
  }

  openAlertDeleteCompany(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete Company";
    this._idSelectedCompany = id;
    this.commonUIService.openAlert(deleteData);
  }

  deleteCompany(companyId) {
    this.companyService.deleteCompany(companyId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 5000);
          this.commonUIService.openSnackBar(snack);
          this.sharedHeaderService.refreshCompanyDropdownList.emit(true);
          if (res.data) this.LoadCompanyList();
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  async getCurrentUser() {
    let jsonString = await this.loginServic.getSessionAppUser();
    this.currentusers = jsonString ? JSON.parse(jsonString) : "";
    if (this.currentusers.imageUrl == undefined) {
      this.currentusers.imageUrl = "assets/images/profile.jpg";
    }
    this.currentCompanyId = this.currentusers.company.id;
    this._currentCompanyId = this.currentusers.company.id;
  }
}
