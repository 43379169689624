export class InventoryModel {
  id: number;
  type: string;
  code: string;
  name: string;
  location: string;
  state: any;
  serialNumber: string;
  brand: string;
  model: string;
  purchase: any;
  cpuType: string;
  cpu: string;
  originalSotargeType: string;
  originalStorage: string;
  currentStorageType: string;
  currentStorage: string;
  ramType: string;
  originalRAM: number;
  currentRAM: number;
  vgCard: string;
  vgRam: number;
  mouseType: string;
  mouseModel: string;
  mouseDate: any;
  keyboardType: string;
  keyboardModel: string;
  keyboardDate: any;
  os: string;
  currentUser: string;
  comments: string;
  statestr: any;
  emptylist: boolean = false;
  inventoryUser: any;
  inventoryIncidence: any;
  resolution: String;
  screenSize: any;
  simm: any = [];
  user: string;
  number: number;
  providerId: number;
  date: any;
  cost: number;
  providerName: string;
  currency: string;
  condition:string;
  userFromDate: any;
  userToDate:any ; 
  companyId:number; 
  specialNote: string;       
  isMouseAvailable: boolean;
  isKeyboardAvailable: boolean;
  entryDate: any;

  dateStr: string;
  screenSizeString: string;
  purchaseStr: string;
  mouseDateStr: string;
  keyboardDateStr: string;
  entryDateStr: string;

  city: string;
  country: string;
  isEdit: boolean;
  selectedIndex: number;
  showEditSave: boolean;
  checkIsInuse: boolean;
  inventoryYear: any;
  inventoryMonth: any;

  cputypeId: number;
  originalSotargeTypeId: number;
  currentStorageTypeId: number;
  resolutionId: number;
  ramtypeId: number;
  osTypeId: number;
  mouseTypeId: number;
  keyboardTypeId: number;
  screenSizeTypeId: number;
  mobileTypeId: number;
  mobileOsTypeId: number;
  monitorOsTypeId: number;
  monitorTypeId: number;
  monitorScreenSizeId: number;
  monitorScreenResolutionId: number;
    miscellaneousTypeId: number;
    inventoryDays: any;
}
