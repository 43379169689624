import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxDropzoneModule } from 'ngx-dropzone'; import { MatInputModule } from '@angular/material';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EstimationComponent } from './estimation.component';
import { EstimationListComponent } from './components/estimation-list/estimation-list.component';
import { ClientModule } from '../client/client.module';

@NgModule({
  declarations: [
    EstimationComponent,
    EstimationListComponent
  ],

  imports: [RouterModule, SharedModule, AngularMaterialModule, FullCalendarModule, NgxDropzoneModule, MatInputModule, ClientModule],

  entryComponents: []
})

export class EstimationModule {
}
