import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';

import {
   MatButtonModule,
   MatToolbarModule,
   MatIconModule,
   MatBadgeModule,
   MatSidenavModule,
   MatListModule,
   MatGridListModule,
   MatFormFieldModule,
   MatInputModule,
   MatSelectModule,
   MatRadioModule,
   MatDatepickerModule,
   MatNativeDateModule,
   MatChipsModule,
   MatSlideToggleModule,
   MatCheckboxModule,
   MatTooltipModule,
   MatTableModule,
   MatPaginatorModule,
   MatCardModule,
   MatSnackBarModule,
   MatMenuModule,
   MatTabsModule,
   MatDialogModule,
   MatAutocompleteModule,
   MatProgressSpinnerModule,
   MatProgressBarModule
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddclientComponent } from './client/add-client/add-client.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
   imports: [
      CommonModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatGridListModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatCardModule,
      FlexLayoutModule,
      MatSnackBarModule,
      MatMenuModule,
      MatTabsModule,
      MatDialogModule,
      ColorPickerModule,
      MatFormFieldModule,
      NgxMatSelectSearchModule,
      ReactiveFormsModule,
      MatProgressSpinnerModule,
      MatSlideToggleModule,
      MatCheckboxModule,
      MatSelectModule,
      MatProgressBarModule
   ],
   exports: [
      CommonModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatBadgeModule,
      MatListModule,
      MatGridListModule,
      MatInputModule,
      MatFormFieldModule,
      MatSelectModule,
      MatRadioModule,
      MatDatepickerModule,
      MatChipsModule,
      MatTooltipModule,
      MatTableModule,
      MatPaginatorModule,
      MatCardModule,
      MatSlideToggleModule,
      MatCheckboxModule,
      FlexLayoutModule ,
      MatSnackBarModule,
      MatMenuModule,
      MatTabsModule,
      MatDialogModule,
      ReactiveFormsModule,
      MatAutocompleteModule,
      MatInputModule,
      MatButtonModule,
      ColorPickerModule,
      NgxMatSelectSearchModule,
      MatProgressSpinnerModule,
      
	
   ],
   declarations : [],
   providers: [
      MatDatepickerModule,
      NgbActiveModal
   ],
   entryComponents:[],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AngularMaterialModule { }
