import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ApprovalsService } from 'src/app/approvals/service/approvals.service';
interface Leave {
  value: string;
  viewValue: string;
}
interface Duration {
  value: string;
  viewValue: string;
}
interface Status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'filter-leave',
  templateUrl: './filter-leave.component.html',
  styleUrls: ['./filter-leave.component.scss'],
  host: {
    '(window:paste)': 'handlePaste( $event )',
  },
})
export class FilterLeaveComponent implements OnInit {
  filterForm: FormGroup;
  selectedValue: string;
  leaveTypeList: any;

  leaves: Leave[] = [
    { value: 'casual', viewValue: 'Casual' },
    { value: 'annual', viewValue: 'Annual' },
  ];
  durations: Duration[] = [
    { value: 'full-day', viewValue: 'Full Day' },
    { value: 'half-day', viewValue: 'Half Day' },
  ];
  statuses: Status[] = [
    { value: 'approved', viewValue: 'Approved' },
    { value: 'rejected', viewValue: 'Rejected' },
  ];
  constructor(
    public commonDropdownService: CommonDropdownService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private approvalsService: ApprovalsService,

    public dialogRef: MatDialogRef<FilterLeaveComponent>
  ) { }

  ngOnInit() {
    this.initForm();
    this.fetchLeaveType();
  }
  initForm() {
    this.filterForm = this.fb.group({
      leaveTypeId: [''],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: [''],
    })
  }
  validateDateRange() {
    let fromDate = new Date(this.filterForm.get('fromDate').value)
    let toDate = new Date(this.filterForm.get('toDate').value)

    if (!this.validateDates(fromDate, toDate)) {
      this.filterForm.get('toDate').setErrors({ 'incorrect': true });
    } else {
      this.filterForm.get('toDate').setErrors(null);
    }
  }

  validateDates(startDate: Date, endDate: Date): boolean {
    return endDate > startDate;
  }

  fetchLeaveType() {
    this.approvalsService.getLeaveType().subscribe(res => {
      this.leaveTypeList = res.data;
    }, erroe => {
      console.log(erroe)
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  clearDialog() {
    this.filterForm.reset();
  }
  cancelDialog() {
    this.dialog.closeAll();
  }
}
