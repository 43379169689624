export enum InventoryMetadataType {
    laptopStorage = 1,
    laptopScreenSize = 2,
    laptopScreenResolution = 3,
    laptopCPU = 4,
    laptopOS = 5,
    laptopMouseType = 6,
    laptopKeyboardType = 7,
    mobileDeviceType = 8,
    mobileDeviceOS = 9,
    monitorType = 10,
    monitorScreenSize = 11,
    monitorResolution = 12,
    monitorOS = 13,
    miscellaneousType = 14,
    commonCondition = 15
}