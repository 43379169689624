export class LinkedInFilterModel {
  companyId?: number;
  sortField: string;
  sortType: string;
  searchText: string;
  state: string;
  user: string;
  userId?: number;
  page: number;
  pageSize: number;
  pageItems: number;
}
