import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { debug } from 'util';

@Component({
  selector: 'app-add-edit-laptop',
  templateUrl: './add-edit-laptop.component.html',
  styleUrls: ['./add-edit-laptop.component.scss']
})
export class AddEditLaptopComponent implements OnInit {

  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  @Input() inventoryForm: FormGroup;
  @Input() inventoryModel: InventoryModel;

  constructor(public commonDropdownService: CommonDropdownService) {
  }

  ngOnInit() {
    this.filteredOptions = this.inventoryForm.get('name').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
    );
  }

  ngOnChanges() {
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
