import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { HRMListComponent } from './components/hrm-list/hrm-list.component';
import { HRMViewComponent } from './components/hrm-detail/hrm-view/hrm-view.component';
import { HRMComponent } from './hrm.component';
import { HRMDetailComponent } from './components/hrm-detail/hrm-detail.component';
import { FilterEmployeeComponent } from './components/filter-employee/filter-employee.component';
import { AddEmployeeComponent } from './components/add-employee/add-employee.component';
import { PersonalDocumentViewComponent } from './components/hrm-detail/hrm-view/personal-document-view/personal-document-view.component';
import { CompanyDocumentViewComponent } from './components/hrm-detail/hrm-view/company-document-view/company-document-view.component';
import { SalaryDocumentViewComponent } from './components/hrm-detail/hrm-view/salary-document-view/salary-document-view.component';
import { BankAccountDocumentViewComponent } from './components/hrm-detail/hrm-view/bank-account-document-view/bank-account-document-view.component';
import { LetterDocumentViewComponent } from './components/hrm-detail/hrm-view/letter-document-view/letter-document-view.component';
import { HRMCenterViewComponent } from './components/hrm-detail/hrm-center-view/hrm-center-view.component';
import { HRMCalenderComponent } from './components/hrm-detail/hrm-center-view/hrm-calender/hrm-calender.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AddEditEmployeeLetterComponent } from './components/hrm-detail/hrm-view/letter-document-view/add-edit-employee-letter/add-edit-employee-letter.component';
import { AddEditEmployeeDocumentComponent } from './components/hrm-detail/hrm-view/company-document-view/add-edit-employee-document/add-edit-employee-document.component';
import { HRMInsuarenceComponent } from './components/hrm-detail/hrm-center-view/hrm-insuarence/hrm-insuarence.component';
import { AddEditEmployeeSalaryComponent } from './components/hrm-detail/hrm-view/salary-document-view/add-edit-employee-salary/add-edit-employee-salary.component';
import { AddHrmInsuranceComponent } from './components/hrm-detail/hrm-center-view/hrm-insuarence/add-hrm-insurance/add-hrm-insurance.component';
import { AddEditEmployeeLeaveComponent } from './components/hrm-detail/hrm-center-view/hrm-calender/add-edit-employee-leave/add-edit-employee-leave.component';
import { MatInputModule } from '@angular/material';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AddPersonalDocumentComponent } from './components/hrm-detail/hrm-view/personal-document-view/add-personal-document/add-personal-document.component'; 
import { HRMAttendanceCalenderComponent } from './components/hrm-detail/hrm-center-view/hrm-attendance-calender/hrm-attendance-calender.component';
import { NgxMaskModule } from 'ngx-mask';
FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    HRMComponent,
    HRMListComponent,
    HRMDetailComponent,
    HRMViewComponent,
    FilterEmployeeComponent,
    AddEditEmployeeDocumentComponent,
    AddEmployeeComponent,
    HRMCenterViewComponent,
    PersonalDocumentViewComponent,
    CompanyDocumentViewComponent,
    SalaryDocumentViewComponent,
    BankAccountDocumentViewComponent,
    LetterDocumentViewComponent,
    AddEmployeeComponent,
    AddEditEmployeeLetterComponent,
    HRMCalenderComponent,
    HRMInsuarenceComponent,
    AddEditEmployeeSalaryComponent,
    AddEditEmployeeLeaveComponent,
    AddHrmInsuranceComponent,
    AddPersonalDocumentComponent,
    HRMAttendanceCalenderComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    AngularMaterialModule,
    FullCalendarModule,
    NgxDropzoneModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [
    FilterEmployeeComponent,
    AddEditEmployeeDocumentComponent,
    AddEmployeeComponent,
    PersonalDocumentViewComponent,
    CompanyDocumentViewComponent,
    SalaryDocumentViewComponent,
    BankAccountDocumentViewComponent,
    LetterDocumentViewComponent,
    AddEmployeeComponent,
    AddEditEmployeeLetterComponent,
    HRMCalenderComponent,
    HRMInsuarenceComponent,
    AddEditEmployeeSalaryComponent,
    AddEditEmployeeLeaveComponent,
    AddHrmInsuranceComponent,
    AddPersonalDocumentComponent,
    HRMAttendanceCalenderComponent,
  ],
})
export class HRMModule {}
