import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { countryModel } from 'src/app/shared/entities/countryModel';
import { APIService } from 'src/app/shared/services/api.service';
import { MaintenanceService } from '../../service/maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'app-add-edit-country',
  templateUrl: './add-edit-country.component.html',
  styleUrls: ['./add-edit-country.component.scss']
})

export class AddEditCountryComponent implements OnInit {
  _country: countryModel;
  countryFromGroup: FormGroup;
  isEdit: boolean = false;
  isDisabledCountry = false;

  constructor(private _formBuilder: FormBuilder,
    private apiservice: APIService,
    private validationService: ValidationService,
    private maintaneService: MaintenanceService,
    public dialogRef: MatDialogRef<AddEditCountryComponent>,
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public CountryTypeData: DialogDataForModalView) { }

  ngOnInit() {
    this.formvalidate();
    this._country = new countryModel();
    this.editRegionView();
  }

  formvalidate() {
    this.countryFromGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }

  editRegionView() {
    if (this.CountryTypeData.data) {
      this._country = Object.assign({}, this.CountryTypeData.data).modalData;
      this.isEdit = true;
    }
  }

  async submit(data) {
    if (this.countryFromGroup.invalid) {
      return
    }
    else {
      if (!this.isEdit) {
        this.isDisabledCountry = true;
        this.maintaneService.saveCountry(data).subscribe(data => {
          if (data.message == "Successfully saved") {
            var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
            this.uiService.openSnackBar(snack);
            this.closeDialog();
            this.maintaneService.CountryRefresh(true);
          }
          else {
            var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
            this.uiService.openSnackBar(snack);
            this.closeDialog();
          }
        });
      }
      if (this.isEdit) {
        this.maintaneService.UpdateCountry(data).subscribe(data => {
          if (data.message == "Update Sucess") {
            var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
            this.uiService.openSnackBar(snack);
            this.maintaneService.CountryRefresh(true);
            this.closeDialog();
          }
          else {
            var snack = new SnackBar(SnackBarType.success, data.message, "", 2000);
            this.uiService.openSnackBar(snack);
            this.closeDialog();
          }
        });
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
