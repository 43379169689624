export class TypeOfDocument {
  id: number;
  name: string;
  code: string; 
  insertDate: Date;
  insertUser: string;
  updateDate: Date;
  updateUser: string;
  status: boolean;
  client: boolean;
  clientMain: boolean;
  clientRequest: boolean;
  project: boolean;
  hr: boolean;
}
