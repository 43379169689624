import { HrmMetaDataEnum } from "../enum/hrmMetadataType";

export class HrmMetaData{
    id: number;
    value: string;
    type: HrmMetaDataEnum;
    insertDate: Date;
    insertUser: string;
    updateDate: Date;
    status: boolean;
    description: string;
    color: string;
    limitExceeded: string;
}