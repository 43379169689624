import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from 'src/app/client/services/client.service';
import { InventoryIncidenceModel } from 'src/app/inventory/entities/inventoryIncidence';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { AddInventoryIncidenceComponent } from './add-inventory-incidence/add-inventory-incidence.component';
import { UIService } from '../../../../shared/services/ui.service';



@Component({
  selector: 'app-inventory-incidence',
  templateUrl: './inventory-incidence.component.html',
  styleUrls: ['./inventory-incidence.component.scss']
})
export class InventoryIncidenceComponent implements OnInit {
  @Input() selectInventory:any;
  listIncidence:InventoryIncidenceModel[]=[];
  inventoryIncidence:boolean=false;

  constructor(public inventoryService: InventoryService, private commonUIService: UIService) {
    this.listIncidence=[];
   
   }

  ngOnInit() {
    this.subscribeEventEmiiter();
  }
  ngOnChanges()
  {
    if(this.selectInventory!=0){this.loadIncidenceList(this.selectInventory);}
  }

  loadIncidenceList(id)
  {
    this.inventoryIncidence=false;
    this.inventoryService.InventoryGetById(id).subscribe(res => {
    this.listIncidence=res.data[0].inventoryIncidence.sort((a,b) => new Date(b.date ).getTime() - new Date(a.date).getTime());
    if(res.data[0].inventoryIncidence.length>0){this.inventoryIncidence=true;}
     
    })
  }

  subscribeEventEmiiter() {
    this.inventoryService.refreshInventoryIncidence.subscribe(res => {
      if (res) this.loadIncidenceList(this.selectInventory);
    })
  }

  openAddIncidencePopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.selectInventory, null, null, null, null, null);
    this.commonUIService.openDialog(AddInventoryIncidenceComponent, dialogViewData);
  } 
}
