import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { LinkedInService } from 'src/app/linkedin/service/linkedin.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { SharedLinkedInService } from 'src/app/linkedin/service/shared.linkedin.service';
import { LinkedinEmailModel } from '../../../entities/linkedinEmailModel';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { LoginService } from '../../../../auth/login/login.service';
import { Users } from '../../../../auth/entities/user';
import API from '../../../../shared/services/api.routes.json';

@Component({
  selector: 'email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss']
})

export class SendEmailComponent implements OnInit {

  _linkedInTemplateList: any[] = [];
  _linkedInNotMainTemplateList: any[] = [];
  subTemplate:any = "";
  language: string = 'ESP';
  email = new LinkedinEmailModel();
  emailFormGroup: FormGroup;
  buttonDisable: boolean = true;
  currentUsers: Users;
  emailSignature: string = null;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '12',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['subscript', 'superscript'],
      ['fontSize', 'customClasses', 'insertImage', 'insertVideo',]
    ]
  };

  constructor(
    private sharedLinkedInService: SharedLinkedInService,
    private formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    private linkedInService: LinkedInService,
    public commonUIService: UIService,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public modelData: DialogDataForModalView,
    public dialogRef: MatDialogRef<SendEmailComponent>) { }

  ngOnInit() {
    this.loadLinkedInTemplatesList();
    this.initFormGroup();
    this.getCurrentUser().then();
    let d = this.modelData.data.modalData;
    if (d.language)
      this.language = d.language;
    if (d.email)
      this.email.to = d.email;
  }

  initFormGroup() {
    this.emailFormGroup = this._formBuilder.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],
      subject: [''],
      message: ['']
    })
  }

  async getCurrentUser() {
    let jsonString = await this.loginService.getSessionAppUser();
    this.currentUsers = jsonString ? JSON.parse(jsonString) : "";
    if (this.currentUsers.imageUrl == undefined) {
      this.currentUsers.imageUrl = "assets/images/profile.jpg";
    }
    this.email.from = this.currentUsers.email;
    this.emailSignature = this.currentUsers.emailSignature;
  }

  loadLinkedInTemplatesList() {
    this.linkedInService.getAllTempleteList().subscribe(res => {
      if (res && res.data) {
        this._linkedInTemplateList = [];
        this._linkedInTemplateList = res.data.filter(x => x.category.category != null
          && x.category.category == this.modelData.data.modalData.category
          && x.main == true);
        this._linkedInNotMainTemplateList = [];
        this._linkedInNotMainTemplateList = res.data.filter(x => x.category.category != null
          && x.category.category == this.modelData.data.modalData.category && (x.main == null || x.main == false));
      }
      else {
        const snack = new SnackBar(SnackBarType.error, 'Error on list', '', 5000);
        this.commonUIService.openSnackBar(snack);
        this._linkedInTemplateList = [];
      }
    },
      err => { console.error(err); }, () => { });
  }

  autoFill(t: any) {
    if (this.language == 'ESP') {
      this.email.subject = t.espsubject;
      this.email.message = t.esptext;
    }
    if (this.language == 'CAT') {
      this.email.subject = t.catsubject;
      this.email.message = t.cattext;
    }
    if (this.language == 'ENG') {
      this.email.subject = t.engsubject;
      this.email.message = t.engtext;
    }
  }

  submit(email) {
    this.buttonDisable = false;
    if (this.emailFormGroup.invalid) {
      this.buttonDisable = true;
      return;
    } else {
      this.sendEmail();
    }
  }

  sendEmail() {
    this.linkedInService.sendEmail(this.email).subscribe(res => {
      if (res) {
        if (res.statusCode == 401) {
          this.commonUIService.openMask("Please finish and close the authorization page");
          var cs = this.commonUIService;
          var _this = this;
          var win = window.open(
            API.baseUrl + "/gauth/GmailAuth?userid=" + this.email.from.split("@")[0],
            "Gmail Authentication");
          var timer = setInterval(function () {
            if (win.closed) {
              clearInterval(timer);
              cs.closeMask();
              _this.sendEmail();
            }
          }, 500);
        }
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, "Email sent successfully", "", 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
        }
        else if (res.statusCode != 401) {
          snack = new SnackBar(SnackBarType.success, "Error on email sending", "", 2000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    },
      err => { console.error(err); });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


  onChooseAttachDocument(event: any): void {
    if (event) {
      let files: any = event.target.files;
      this.email.fileList = files;
    }
  }
}
