import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { LinkedIn } from '../entities/linkedin';
import { LinkedInFilterModel } from '../entities/linkedInFilterModel';
import { LinkedInTemplates } from '../entities/linkedInTemplates';
import { LinkedinEmailModel } from '../entities/linkedinEmailModel';
import { Template } from '../../linkedin-templete/entities/template';
import { TemplateCategory } from '../../linkedin-templete/entities/templateCategory';
import { TemplateSubcategory } from '../../linkedin-templete/entities/templateSubcategory';

@Injectable({
  providedIn: 'root'
})

export class LinkedInService {
  filterObject: LinkedInFilterModel = new LinkedInFilterModel();

  public subjectLinkedInRefresh = new Subject<any>();
  @Output() linkedInCloseReasonEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() linkedReasonEmitForAdd: EventEmitter<any> = new EventEmitter<any>();
  isLinkedInAdd: boolean = false;
  commonLinkedList: LinkedIn[] = [];
  @Output() refreshCategoryList: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiservice: APIService) {
    this.filterObject.sortField = !this.filterObject.sortField ? "name" : this.filterObject.sortField;
    this.filterObject.sortType = !this.filterObject.sortType ? "asc" : this.filterObject.sortType;
  }

  deleteLinkedIn(id: string) {
    let url = this.apiservice.getEndPoint("linkedIn.deleteLinkedIn", null);
    return this.apiservice.delete(url, id);
  }

  async linkedInRefresh(isLinkedInRefesh: boolean) {
    this.subjectLinkedInRefresh.next(isLinkedInRefesh);
  }

  saveLinkedInData(linkedInObj: LinkedIn) {
    let url = this.apiservice.getEndPoint("linkedIn.addLinkedIn", null);
    return this.apiservice.post(url, linkedInObj, true);
  }

  searchLinkdin(id, companyId) {
    let url = this.apiservice.getEndPoint("linkedIn.getLinkedInByState", [["{State}", id], ["{CompanyId}", companyId]]);
    return this.apiservice.get(url);
  }


  getLinkedInList(filterObject: LinkedInFilterModel) {
    let url = this.apiservice.getEndPoint("linkedIn.getLinkedInList", null);
    return this.apiservice.post(url, filterObject, true);
  }

  updateLinkedIn(obj: LinkedIn) {
    let url = this.apiservice.getEndPoint("linkedIn.updateLinkedIn", null);
    return this.apiservice.put(url, obj, true);
  }

  updateLinkedInObj(id, obj) {
    let url = this.apiservice.getEndPoint("linkedIn.updateLinkedInObj", [["{linkedInID}", id]]);
    return this.apiservice.put(url, obj, true);
  }
  setDateValueToPicker(item) {
    const [day, month, year] = item.date.split("/");
    let date1 = new Date(year, month - 1, day);
    return date1;
  }

  saveLinkedInTempletesData(linkedInTempleteObj: LinkedInTemplates) {
    let url = this.apiservice.getEndPoint("linkedInTemplete.addLinkedInTemplete", null);
    return this.apiservice.post(url, linkedInTempleteObj, true);
  }

  getLinkedInTempletesList() {
    let url = this.apiservice.getEndPoint("templete.listAll", null);
    return this.apiservice.get(url);
  }

  updateLinkedInTempletes(id, obj: LinkedInTemplates) {
    let url = this.apiservice.getEndPoint("linkedInTemplete.updateLinkedInTemplete", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getLinkedInTempleteById(id) {
    let url = this.apiservice.getEndPoint("linkedInTemplete.getLinkedInTempleteById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteLinkedInTemplete(id: string) {
    let url = this.apiservice.getEndPoint("linkedInTemplete.deleteLinkedInTemplete", null);
    return this.apiservice.delete(url, id);
  }

  saveTempleteData(templeteObj: Template) {
    let url = this.apiservice.getEndPoint("templete.addTemplete", null);
    return this.apiservice.post(url, templeteObj, true);
  }

  getTempleteList() {
    let url = this.apiservice.getEndPoint("templete.listAll", null);
    return this.apiservice.get(url);
  }

  updateTemplete(id, obj: Template) {
    let url = this.apiservice.getEndPoint("templete.updateTemplete", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getTempleteById(id) {
    let url = this.apiservice.getEndPoint("templete.getTempleteById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteTemplete(id: string) {
    let url = this.apiservice.getEndPoint("templete.deleteTemplete", null);
    return this.apiservice.delete(url, id);
  }

  sendEmail(emailModel: LinkedinEmailModel) {
    let url = this.apiservice.getEndPoint("linkedIn.sendLinkedInEmail", null);
    return this.apiservice.post(url, emailModel, true);
  }

  saveTempleteCategoryData(templeteCategoryObj: TemplateCategory) {
    let url = this.apiservice.getEndPoint("templeteCategory.addTempleteCategory", null);
    return this.apiservice.post(url, templeteCategoryObj, true);
  }

  getTempleteCategoryList() {
    let url = this.apiservice.getEndPoint("templeteCategory.listAll", null);
    return this.apiservice.get(url);
  }

  updateTempleteCategory(id, obj: TemplateCategory) {
    let url = this.apiservice.getEndPoint("templeteCategory.updateTempleteCategory", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getTempleteCategoryById(id) {
    let url = this.apiservice.getEndPoint("templeteCategory.getTempleteCategoryById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteTempleteCategory(id: string) {
    let url = this.apiservice.getEndPoint("templeteCategory.deleteTempleteCategory", null);
    return this.apiservice.delete(url, id);
  }

  saveTempleteSubcategoryData(templeteSubcategoryObj: TemplateSubcategory) {
    let url = this.apiservice.getEndPoint("templeteSubcategory.addTempleteSubcategory", null);
    return this.apiservice.post(url, templeteSubcategoryObj, true);
  }

  getTempleteSubcategoryList() {
    let url = this.apiservice.getEndPoint("templeteSubcategory.listAll", null);
    return this.apiservice.get(url);
  }

  updateTempleteSubcategory(id, obj: TemplateSubcategory) {
    let url = this.apiservice.getEndPoint("templeteSubcategory.updateTempleteSubcategory", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getTempleteSubcategoryById(id) {
    let url = this.apiservice.getEndPoint("templeteSubcategory.getTempleteSubcategoryById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteTempleteSubcategory(id: string) {
    let url = this.apiservice.getEndPoint("templeteSubcategory.deleteTempleteSubcategory", null);
    return this.apiservice.delete(url, id);
  }

  getAllTempleteList() {
    let url = this.apiservice.getEndPoint("templete.listAllTempletes", null);
    return this.apiservice.get(url);
  }
}
