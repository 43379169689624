export class SnackBar{
    message: string;
    icon: string;
    duration: number;
    type: SnackBarType;
    horizontalPosition: any;

    constructor(type, message, icon, duration, horizontalPosition?){
        this.type = type;
        this.message = message;
        this.icon = icon;
        this.duration = duration;
        this.horizontalPosition = horizontalPosition == null ? "right" : horizontalPosition
    }
}


export enum SnackBarType{
    success = 1,
    error = 2,
    info = 3
}

