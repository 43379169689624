import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  @Output() cancelDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() newDocument: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProjectDocument: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
}
