export class ClientContact {
  id: number;
  clientId: number;
  phoneNumber: string;
  contactPhoneNumber: string;
  email: string;
  whatsapp: string;
  skype: string;
  linkdin: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  additionalPhoneNumber:string;
  status: any;
  name: string;
  position: string;
  positionId: number;
  phoneString: string;
  language: string;
}
