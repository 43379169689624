import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InventoryModel } from '../../entities/inventoryModel';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { InventoryService } from '../../service/inventoryService';

@Component({
  selector: 'app-add-edit-inventory',
  templateUrl: './add-edit-inventory.component.html',
  styleUrls: ['./add-edit-inventory.component.scss']
})
export class AddEditInventoryComponent implements OnInit {
  inventoryForm: FormGroup;
  title: string = "";
  inventoryModel: InventoryModel;
  isDisabledInventory = false;

  constructor(private dialogRef: MatDialogRef<AddEditInventoryComponent>,
    private formatDatepicker: FormatMatDatepicker,
    public fb: FormBuilder, private commonDropdownService: CommonDropdownService,
    public inventoryService: InventoryService,
    public commonUIService: UIService) {
    this.inventoryModel = new InventoryModel();
  }

  ngOnInit() {
    this.title = "Add Laptop"
    this.initInventoryForm();
    this.commonDropdownService.LoadInventoryStateDropdown();
  }

  initInventoryForm() {
    this.inventoryForm = this.fb.group({
      code: ['', [Validators.required]],
      name: ['', Validators.required],
      state: ['', Validators.required],
      serialNumber: [''],
      brand: [''],
      model: [''],
      purchase: [''],
      cpuType: [''],
      cpu: [''],
      originalSotargeType: [''],
      originalStorage: [''],
      currentStorageType: [''],
      currentStorage: [''],
      ramType: [''],
      vgRam: [''],
      mouseType: [''],
      mouseModel: [''],
      mouseDate: [''],
      os: [''],
      comments: [''],
      resoultion: [''],
      screenSize: [''],
      date: [''],
      cost: [''],
      providerId: [''],
      currency: ['']
    })
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  submit(model: InventoryModel) {
    if (this.inventoryForm.invalid) {
      this.inventoryForm.get('code').markAsTouched();
      this.inventoryForm.get('name').markAsTouched();
      this.inventoryForm.get('state').markAsTouched();
      return
    }
    else {  
      if (model.state) {
        let x = parseInt(model.state);
        model.state = x;
      }
      if (model.screenSize) { model.screenSize = parseInt(model.screenSize) }
      this.isDisabledInventory = true;
      this.inventoryService.saveInventory(model).subscribe(res => {
        if (res.isSuccsess) {
          this.isDisabledInventory = false;
          const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        if (res.message == "Successfully saved") {
          this.isDisabledInventory = false;
          this.inventoryService.refershListInventor.emit({id: null, index: 0});
          this.closeDialog();
        }
      });
    }
  }
}
