export class FilterLaptopDetailModel {
  state: any;
  brand: string;
  cpuType: string;
  ramType: string;
  currentStorageType: string;
  page:number;
  pageSize: number;
  pageItems: number;
  count: number;
}
