import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/login/login.service';
import { Users } from 'src/app/auth/entities/user';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import moment from 'moment';
import { SharedHeaderService } from '../../../shared/services/shared.header.services';
import { isNumber } from 'util';
import { FormatMatDatepicker } from '../../../shared/format-datepicker';
import { ProductService } from '../../service/product.service';
import { Product } from '../../entities/product';

@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.scss']
})

export class AddEditProductComponent implements OnInit {

  isEdit: boolean = false;
  productFormGroup: FormGroup;
  _productModal: Product;
  isDisabledProduct: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    public commonUIService: UIService,
    private uIService: UIService,
    private route: Router,
    private loginServic: LoginService,
    private validationSerivce: ValidationService,
    public sharedHeaderService: SharedHeaderService,
    private formatDatepicker: FormatMatDatepicker,
    public productService: ProductService,
    private commonDropdownService: CommonDropdownService,
    public dialogRef: MatDialogRef<AddEditProductComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView) {
    this._productModal = new Product();
  }

  ngOnInit() {

    this.initFormGroup();
    this.setProductDataForEditView();
  }

  initFormGroup() {
    this.productFormGroup = this._formBuilder.group({
      productName: [''],
      main: [''],
      active: ['']
    });
  }

  setProductDataForEditView(): void {
    if (this.modalViewData.data) {
      this._productModal = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
      } else {
        this.isEdit = false;
      }
    }
  }

  async submit(product) {
    if (this.productFormGroup.invalid) {
      this.isDisabledProduct = false;
      return;
    }
    let productObj = Object.assign({}, product);
    if (this.isEdit) {
      this.updateProduct(product.id, productObj);
    } else {
      this.addProduct(productObj);
    }
  }

  addProduct(productObj) {  
    this.isDisabledProduct = true;
    this.productService.saveProductData(productObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.closeDialog();
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.productService.refreshProduct.emit(res.data);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateProduct(productId, productObj) {
    this.productService.updateProduct(productId, productObj).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.productService.refreshProduct.emit(res.data);
          this.productService.updatedProduct=res.data;
          this.closeDialog();
        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  activeCheckEvent(event) {
    this._productModal.active = event.checked;
  }

  mainCheckEvent(event) {
    this._productModal.main = event.checked;
  }
}
