export enum HrmMetaDataEnum
{
    Leave = 1,
    Insurance = 2,
}

export enum MainLeaveTypes //When comparing main leave type with db data user .replace(/\s/g, "") and .toLower() functions
{
    CasualLeave = 'casualleave',
    AnnualLeave =  'annualleave',
    SickLeave = 'sickleave'
}