import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SharedLinkedInService {

  linkedInCompanyId: number = null;

  @Output() passCompanyIdToLinkedIn: EventEmitter<{ companyId: any, isLoad: any }> = new EventEmitter<{ companyId: any, isLoad: any }>();

  constructor() {
  }
}
