import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { departmentModel } from 'src/app/shared/entities/departmentModel';
import { MaintenanceService } from '../../service/maintenance.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { designationModel } from 'src/app/shared/entities/designationModel';

@Component({
  selector: 'app-add-edit-designation',
  templateUrl: './add-edit-designation.component.html',
  styleUrls: ['./add-edit-designation.component.scss']
})
export class AddEditDesignationComponent implements OnInit {

  designationFormGroup: FormGroup;
  isEdit: boolean;
  designationObj: designationModel;
  isDisabledDesignation = false;

  constructor(
    private maintatanceService: MaintenanceService,
    private _formBuilder: FormBuilder,
    private uIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditDesignationComponent>,
    @Inject(MAT_DIALOG_DATA) public designationData: DialogDataForModalView, 
  ) {
    this.designationObj = new departmentModel();
   }

  ngOnInit() {
    this.formvalidate();
    this.checkDataForEdit();
  }
  formvalidate() {
    this.designationFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, this.validationService.noWhitespaceValidator]]
    })
  }
  checkDataForEdit() {
    if (this.designationData.data) {
      this.designationObj = Object.assign({}, this.designationData.data).modalData;
      this.isEdit = true;
    }
  }

  submit(model) {
    if (this.designationFormGroup.invalid) {
      this.isDisabledDesignation = false;
      return;
    }
    else if (this.isEdit) {
      this.maintatanceService.updateDesignation(model.id, model).subscribe(res => {
        if (res.isSuccsess) {
          let snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
          this.maintatanceService.DesignationTypeRefresh(true);
          this.closeDialog();
        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }
    else {
      this.isDisabledDesignation = true;
      model.status  = true;
      this.maintatanceService.SaveDesignation(model).subscribe(res => {
        if(res.isSuccsess)
        {
          let snack = new SnackBar(SnackBarType.success, "Successfully saved", "", 2000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
          this.maintatanceService.DesignationTypeRefresh(true);

        }
        else {
          let snack = new SnackBar(SnackBarType.success, res.message, "", 5000);
          this.uIService.openSnackBar(snack);
          this.closeDialog();
        }
      });
    }

  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}