import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { Product } from '../entities/product';
import { ProductPack } from '../entities/productPack';
import { ClientProduct } from '../entities/clientProduct';
import { ClientPack } from '../entities/clientPack';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  @Output() refreshProduct: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshProductPack: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshClientProductDetails: EventEmitter<any> = new EventEmitter<any>();
  updatedProduct: any;
  productList: Product[] = [];
  activeProductList: Product[] = [];
  inActiveProductList: Product[] = [];
  productPackList: ProductPack[] = [];
  activeProductPackList: ProductPack[] = [];
  inActiveProductPackList: ProductPack[] = [];
  isActiveProduct: boolean = true;
  isActiveProductPack: boolean = true;
  selectedClientProduct: number;
  selectedClientPackId:number;

  constructor(private apiservice: APIService) { }

  saveProductData(productObj: Product) {
    let url = this.apiservice.getEndPoint("product.addProduct", null);
    return this.apiservice.post(url, productObj, true);
  }

  getProductList(isNotDeletedOnly:boolean=false) {
    let url = this.apiservice.getEndPoint('product.listAll', null);
    url = `${url}?isNotDeletedOnly=${isNotDeletedOnly}`;
    return this.apiservice.get(url);
  }

  updateProduct(id, obj: Product) {
    let url = this.apiservice.getEndPoint("product.updateProduct", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getProductById(id) {
    let url = this.apiservice.getEndPoint("product.getProductById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteProduct(id: string) {
    let url = this.apiservice.getEndPoint("product.deleteProduct", null);
    return this.apiservice.delete(url, id);
  }

  deleteProductPack(id: string) {
    let url = this.apiservice.getEndPoint("productPack.deleteProductPack", null);
    return this.apiservice.delete(url, id);
  }

  saveProductPackData(productPackObj: ProductPack) {
    if (productPackObj.subscriptionType != null)
      productPackObj.subscriptionType = +productPackObj.subscriptionType;
    if (productPackObj.oneTimePrice != null)
      productPackObj.oneTimePrice = parseFloat(productPackObj.oneTimePrice);
    if (productPackObj.subscriptionPrice != null)
      productPackObj.subscriptionPrice = parseFloat(productPackObj.subscriptionPrice);
    let url = this.apiservice.getEndPoint("productPack.addProductPack", null);
    return this.apiservice.post(url, productPackObj, true);
  }

  getProductPackList() {
    let url = this.apiservice.getEndPoint("productPack.listAll", null);
    return this.apiservice.get(url);
  }

  updateProductPack(id, obj: ProductPack) {
    if (obj.subscriptionType != null)
      obj.subscriptionType = +obj.subscriptionType;
    if (obj.oneTimePrice != null)
      obj.oneTimePrice = parseFloat(obj.oneTimePrice);
    if (obj.subscriptionPrice != null)
      obj.subscriptionPrice = parseFloat(obj.subscriptionPrice);
    let url = this.apiservice.getEndPoint("productPack.updateProductPack", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getProductPackById(id) {
    let url = this.apiservice.getEndPoint("productPack.getProductPackById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  saveClientProductData(clientProductObj: ClientProduct) {
    let url = this.apiservice.getEndPoint("clientProduct.addClientProduct", null);
    return this.apiservice.post(url, clientProductObj, true);
  }

  getClientProductList() {
    let url = this.apiservice.getEndPoint("clientProduct.listAll", null);
    return this.apiservice.get(url);
  }

  updateClientProduct(id, obj: ClientProduct) {
    let url = this.apiservice.getEndPoint("clientProduct.updateClientProduct", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getClientProductById(id) {
    let url = this.apiservice.getEndPoint("clientProduct.getClientProductById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteClientProduct(id: string) {
    let url = this.apiservice.getEndPoint("clientProduct.deleteClientProduct", null);
    return this.apiservice.delete(url, id);
  }

  saveClientPackData(clientPackObj: ClientPack) {
    let url = this.apiservice.getEndPoint("clientPack.addClientPack", null);
    return this.apiservice.post(url, clientPackObj, true);
  }

  getClientPackList() {
    let url = this.apiservice.getEndPoint("clientPack.listAll", null);
    return this.apiservice.get(url);
  }

  updateClientPack(id, obj: ClientPack) {
    let url = this.apiservice.getEndPoint("clientPack.updateClientPack", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getClientPackById(id) {
    let url = this.apiservice.getEndPoint("clientPack.getClientPackById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  deleteClientPack(id: string) {
    let url = this.apiservice.getEndPoint("clientPack.deleteClientPack", null);
    return this.apiservice.delete(url, id);
  }

  /*NOTE:Refer the harcoded subscription types at :
   1.) src/app/client-product/view-client-product/add-edit-client-pack/add-edit-client-pack.html file's drop down for 'Types of Subscription'
   */
  getSubscriptionTypeNameById(ppObj: ProductPack) {
    let paramStr = ppObj.subscriptionType != null ? ppObj.subscriptionType.toString().toLowerCase().trim() : '';
    let result = '';
    switch (paramStr) {
      case "0":
        result = 'One time';
        break;
      case "1":
        result = 'Monthly';
        break;
      case "3":
        result = 'Quarterly';
        break;
      case "6":
        result = 'Semi-yearly';
        break;
      case "12":
        result = 'Yearly';
        break;
    }
    return result;
  }
}
