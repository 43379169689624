export class InvoiceModel
{
    id : number
    inventoryId:number;
    date:Date;
    documentType:string;
    documentName:string;
    generatedFileName:string;
    fileName:string;
    insertDate:Date;
    insertUser:string;
    updateDate:Date;
    updateUser:string;
    status:boolean;
    fileList:any;
    dateStr:any;
    extension: string;
    file:any;

    existInventoryId: number;
}

