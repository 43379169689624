export class MoreFilterModel {
  city?: string;
  province?: string;
  refferralSourceId?: number;
  userId?: number;
  fromDate?: any;
  toDate?: any;
  myTaskCheck: boolean;
  taskDelay: boolean;
  delayedDate?: any;
  currentTaskUserId?: number;
  companyId?: number;
  text: string;
  clientStatus?: number;
  delayedDateStr: string;
  fromDateStr: string;
  toDateStr: string;
  hasMoreFilter: boolean;

  page: number;
  pageSize: number;
  pageItems: number;
}
