import { SafeResourceUrl } from '@angular/platform-browser';

export class EmpLetters {
  id: number;
  employeeId: number;
  date: any;
  letterName: string;
  letterTypeId: number;
  path: string;
  generatedFileName: string;
  fileName: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  safeUrl: SafeResourceUrl;
  documentType: string;
  dateStr: any; 
 }
