export class docoumnetTypeModel {
  id: number;
  name: string;
  section: number;
  status: boolean;
  insertDate: Date;
  insertUser: string;
  updateuser: string;
  updateDate: Date;
  projectDocuments: any;
  active?: boolean;
  isExistId:boolean;
}
