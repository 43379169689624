import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm-document-type',
  templateUrl: './crm-document-type.component.html',
  styleUrls: ['./crm-document-type.component.scss']
})

export class CRMDocumentTypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
