import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { isNumber } from 'util';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { APIService } from 'src/app/shared/services/api.service';
import API from 'src/app/shared/services/api.routes.json';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { HrmService } from '../../../../service/hrm.service';
import { AddEditEmployeeDocumentComponent } from './add-edit-employee-document/add-edit-employee-document.component';
import { AlertModel } from '../../../../../shared/entities/alertModel';
import { AlertService } from '../../../../../shared/services/alert.service';
import { SnackBar, SnackBarType } from '../../../../../shared/components/snackbar/entities/snackbar';
declare const JSON;

@Component({
  selector: 'company-document-view',
  templateUrl: './company-document-view.component.html',
  styleUrls: ['./company-document-view.component.scss']
})
export class CompanyDocumentViewComponent implements OnInit {
  // config 
  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  @ViewChild('matTabGroup', { static: false }) matTabGroup: any;
  subscriber: Subscription;
  files: File[] = [];
  employeeDocumentGroupList: any = [];
  isLoadingJobOfferDocument: boolean = false;
  isLoadingServiceAgreement: boolean = false;
  isLoadingPropertyDocument: boolean = false;
  isLoadingNDADocument: boolean = false;
  isLoadingMonthsConfirmationDocument: boolean = false;
  eventValue: any;
  selectedCompanyDocumentGroupId: number = 0;
  jobOfferDocumentList: any = [];
  serviceAgreementList: any = [];
  propertyDocumentList: any = [];
  ndaList: any = [];
  monthsConfirmationList: any = [];
  idCurrentEmployeeDocumentId: number = 0;
  isLoading: boolean = true;
  isLoading_JobOfferDocument: boolean = false;
  isLoading_ServiceAgreement: boolean = false;
  isLoading_PropertyDocument: boolean = false;
  isLoading_NDA: boolean = false;
  isLoading_MonthsConfirmation: boolean = false;

  constructor(private _apiService: APIService,
    private _formBuilder: FormBuilder,
    private hrmService: HrmService,
    private commonUIService: UIService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<CompanyDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    public alertService: AlertService) {
  }

  ngOnInit() {
    if (this.modalViewData.data.id)
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    this.subscribeAlertConfirmEvents();
    this.subscribeRefreshCompanyEmployeeDocumentList();
    this.subscribeCancelDocument();
  }

  async loadEmployeeDocumentList(employeeId) {
    this.hrmService.getDocumentListByEmployeeIdAndDocumentTypeId(employeeId, 7).subscribe(
      res => {
        if (res) {
          let _companyDocumentList = res.data;
          if (_companyDocumentList != null) {
            _companyDocumentList.forEach(element => {
              element.employeeDocumentModelList.forEach(companyDocument => {
                if (companyDocument.fileName) {
                  companyDocument.extension = companyDocument.fileName.split('.')[(companyDocument.fileName.split('.')).length - 1];
                }
                companyDocument.link = API.baseUrl + API.employeeDocument.getEmployeeDocument + companyDocument.id + '/' + companyDocument.generatedFileName;
                if (companyDocument.url) {
                  let c = companyDocument.url.toString();
                  if (companyDocument.extension === 'pdf')
                    c = c.includes('data:application/pdf;base64,') ? c : 'data:application/pdf;base64,' + c;
                  if (companyDocument.extension === 'png')
                    c = c.includes('data:image/png;base64,') ? c : 'data:image/png;base64,' + c;
                  if (companyDocument.extension === 'jpg')
                    c = c.includes('data:image/jpg;base64,') ? c : 'data:image/jpg;base64,' + c;
                  if (companyDocument.extension === 'jpeg')
                    c = c.includes('data:image/jpeg;base64,') ? c : 'data:image/jpeg;base64,' + c;
                  if (companyDocument.extension === 'docx')
                    c = c.includes('https://docs.google.com/gview?url=') ? c : 'https://docs.google.com/gview?url=' + companyDocument.link + '&embedded=true';
                  if (companyDocument.extension === 'xlsx' || companyDocument.extension === 'xls')
                    c = c.includes('https://docs.google.com/gview?url=') ? c : 'https://docs.google.com/gview?url=' + companyDocument.link + '&embedded=true';
                }
              });
            });
            this.isLoading = false;
          }
          this.employeeDocumentGroupList = [];
          this.jobOfferDocumentList = [];
          this.serviceAgreementList = [];
          this.propertyDocumentList = [];
          this.ndaList = [];
          this.monthsConfirmationList = [];
          this.employeeDocumentGroupList = _companyDocumentList;
          this.jobOfferDocumentList.push(this.employeeDocumentGroupList[0]);
          this.serviceAgreementList.push(this.employeeDocumentGroupList[1]);
          this.propertyDocumentList.push(this.employeeDocumentGroupList[2]);
          this.ndaList.push(this.employeeDocumentGroupList[3]);
          this.monthsConfirmationList.push(this.employeeDocumentGroupList[4]);
          this.isLoading_JobOfferDocument = false;
          this.isLoading_ServiceAgreement = false;
          this.isLoading_PropertyDocument = false;
          this.isLoading_NDA = false;
          this.isLoading_MonthsConfirmation = false;
          this.isLoadingJobOfferDocument = this.jobOfferDocumentList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingServiceAgreement = this.serviceAgreementList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingPropertyDocument = this.propertyDocumentList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingNDADocument = this.ndaList[0].employeeDocumentModelList.length > 0 ? true : false;
          this.isLoadingMonthsConfirmationDocument = this.monthsConfirmationList[0].employeeDocumentModelList.length > 0 ? true : false;
        }
      },
      err => {
        console.error(); this.isLoading = true;
      });
  }

  openAddEmployeeDocumentPopup(documentGroupId: number) {
    if (documentGroupId == 1) {
      this.isLoadingJobOfferDocument = true;
      this.isLoading_JobOfferDocument = true;
    } else if (documentGroupId == 2) {
      this.isLoadingServiceAgreement = true;
      this.isLoading_ServiceAgreement = true;
    } else if (documentGroupId == 3) {
      this.isLoadingPropertyDocument = true;
      this.isLoading_PropertyDocument = true;
    } else if (documentGroupId == 4) {
      this.isLoadingNDADocument = true;
      this.isLoading_NDA = true;
    } else if (documentGroupId == 5) {
      this.isLoadingMonthsConfirmationDocument = true;
      this.isLoading_MonthsConfirmation = true;
    }
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.modalViewData.data.id, null, documentGroupId, null, null, null);
    this.commonUIService.openDialog(AddEditEmployeeDocumentComponent, dialogViewData);
  }

  openDeleteCompanyDocumentAlert(employeeDocumentId) {
    this.idCurrentEmployeeDocumentId = employeeDocumentId;
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = employeeDocumentId;
    deleteData.type = 'deleteCompanyEmployeeDocument';
    deleteData.reference = 'Delete Company Employee Document';
    this.commonUIService.openAlert(deleteData);
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete.subscribe(data => {
      if (data === 'Delete Company Employee Document') {
        this.deleteCompanyEmployeeDocument(this.idCurrentEmployeeDocumentId);
      }
    });
  }

  deleteCompanyEmployeeDocument(employeeDocumentId) {
    this.hrmService.deleteEmployeeDocument(employeeDocumentId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully deleted', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.loadEmployeeDocumentList(this.modalViewData.data.id);
        }
      }
    )
  }

  subscribeRefreshCompanyEmployeeDocumentList() {
    this.hrmService.refreshCompanyEmployeeDocumentList.subscribe(data => {
      if (data.documentGroupId == 1) {
        this.isLoading_JobOfferDocument = false;
      } else if (data.documentGroupId == 2) {
        this.isLoading_ServiceAgreement = false;
      } else if (data.documentGroupId == 3) {
        this.isLoading_PropertyDocument = false;
      } else if (data.documentGroupId == 4) {
        this.isLoading_NDA = false;
      } else if (data.documentGroupId == 5) {
        this.isLoading_MonthsConfirmation = false;
      }
      this.loadEmployeeDocumentList(this.modalViewData.data.id);
    });
  }

  subscribeCancelDocument() {
    this.hrmService.cancelDocument.subscribe(data => {
      if (data == 1) {
        this.isLoadingJobOfferDocument = false;
        this.isLoading_JobOfferDocument = false;
      } else if (data == 2) {
        this.isLoadingServiceAgreement = false;
        this.isLoading_ServiceAgreement = false;
      } else if (data == 3) {
        this.isLoadingPropertyDocument = false;
        this.isLoading_PropertyDocument = false;
      } else if (data == 4) {
        this.isLoadingNDADocument = false;
        this.isLoading_NDA = false;
      } else if (data == 5) {
        this.isLoadingMonthsConfirmationDocument = false;
        this.isLoading_MonthsConfirmation = false;
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
