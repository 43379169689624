import { Component, OnInit, Input, Inject,SimpleChanges  } from '@angular/core';
import { Employee } from '../../../entities/empoyee';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import { ErmService } from '../../../service/erm.service';
import {Tooltip } from 'bootstrap';
declare var $: any;

@Component({
  selector: 'app-erm-view',
  templateUrl: './erm-view.component.html',
  styleUrls: ['./erm-view.component.scss'],
  host: {
    '(window:paste)': 'handlePaste( $event )',
  },
})
export class ERMViewComponent implements OnInit {
  selectedLeave: string;
  isSpinnerShow: boolean = false;
  isLeave:boolean=true;
  isHoliday:boolean=true;
  isShowLeaveStatus:boolean=true;
  leaveStatusId=0;
  leaveStatusList=[];
  @Input() employeeModel: Employee;

  constructor(public ermService: ErmService) {}

  calendarEvents = [];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    firstDay: 1,
    events: this.calendarEvents,
    eventDidMount: function(info) {
      var tooltip = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
    headerToolbar: {
      left: 'prev,next today',
      center: '',
      right: 'title'
    }
  };

  handleDateClick(arg) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.employeeModel.firstChange) {
      this.refreshCalender();
    }
  }

  ngOnInit() {
    this.getAllLeaveStatus();
  }

  getAllLeaveStatus()
  {
    this.ermService.getAllLeaveStatus().subscribe(res=>{
      if(res.data){
        this.leaveStatusList=res.data;
      }
    },error => {
      console.log(error)
    });
  }

  refreshCalender()
  {
    this.isSpinnerShow=true;
    this.ermService
    .getHolidaysForCurrentYear(this.employeeModel.id,this.isLeave,this.isHoliday,this.leaveStatusId).subscribe((res) => {
      this.isSpinnerShow = false;
      if (res.data) {
        this.calendarOptions.events = res.data;
      }else{
        this.calendarOptions.events=[];
      }
    },error => {
      console.log(error)
    });
  }

  checkBoxChange()
  {
    this.refreshCalender();
    if(!this.isLeave){
      this.isShowLeaveStatus=false;
    }else{
      this.isShowLeaveStatus=true;
    }
  }

  leaveStatushange()
  {
    this.refreshCalender();
  }

}
