import { Component, OnInit, Input, Inject } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService} from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ErmService } from '../../../service/erm.service';
import { MatDialog} from '@angular/material';
import { FormatMatDatepicker, FormatTimeForDisplay } from '../../../../shared/format-datepicker';
import { Employee } from '../../../entities/empoyee';
import moment from 'moment';
import { EmpInsurance } from '../../../entities/EmpInsurance';
import API from 'src/app/shared/services/api.routes.json';
import { Subscription } from 'rxjs';
import { HrmMetaData } from 'src/app/shared/entities/hrmMetadataModel';

@Component({
  selector: 'erm-center-view',
  templateUrl: './erm-center-view.component.html',
  styleUrls: ['./erm-center-view.component.scss']
})
export class ERMCenterViewComponent implements OnInit {

  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  @Input() employeeModel: Employee;
  employeeObject: Employee;
  leaveList: any[] = [];
  _empInsuranceList: EmpInsurance[] = [];
  employeeLeaveTypeList: any = [];
  subEmployeeLeaveTypeList: any = [];
  isLoading = false;
  hours_s: any = null;
  minutes_s: any = null;
  seconds_s: any = null;
  a: any = null;
  title: string = null;
  viewLeave: boolean = false;
  viewCalender: boolean = false;
  viewAttendanceCalendar: boolean = false;
  isShowInsuarence: boolean = false;
  isShowLeave: boolean = false;
  totalClaim: any;
  total: any;
  entitleAmount: any;
  getClaim: boolean = false;
  firstDay: any;
  lastDay: any;
  todayDate = new Date();
  subscriber: Subscription;
  employeeLeaveId: number = null;
  isClickLeaveButton: boolean = true;
  isClickCalenderButton: boolean = false;
  isClickAttendanceButton: boolean = false;
  uitlizedHospitalizationAmount: number = null;
  uitlizedOPDAmount: number = null;
  payableInsuarenceAmount: number = null;
  empInsuranceForSave: EmpInsurance;
  isLoadingInsuranceList = false;
  currentMonth: number = null;
  currentDate = new Date();
  employeeInsuranceList: EmpInsurance[] = [];
  fileList: any;
  showUpload: boolean = false;
  today: any;
  insuranceTypeList: HrmMetaData[];
  subInsuranceTypeList: any = [];
  leaveTypeList: HrmMetaData[];

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public ermService: ErmService,
    public dialog: MatDialog,
    public formatDatepicker: FormatMatDatepicker,
    private formatTimeForDisplay: FormatTimeForDisplay,)
    { this.empInsuranceForSave = new EmpInsurance(); }

  ngOnInit() {
    this.isLoading = false;
  }

  ngOnChanges() {
    if (this.ermService.isShowLeaveCard == true && this.ermService.isShowInsuarenceCard == false) {
      this.isShowLeave = true;
      this.isShowInsuarence = false;
    }
    if (this.ermService.isShowLeaveCard == false && this.ermService.isShowInsuarenceCard == true) {
      this.isShowInsuarence = true;
      this.isShowLeave = false;
    }
    if (this.employeeModel.completedOneYear == true) {
      this.ermService.isCompletedOneYear = true;
    } else {
      this.ermService.isCompletedOneYear = false;
    }
    this.employeeObject = this.employeeModel;
    this.getInsuranceTypeDetails()
    this.loadEmployeeLeaveByEmployeeId();
    this.loadEmpInsuranceList();
    this.getEmployeeLeaveTypeListByEmployeeId();
    this.subscribeEmployeeInsuranceDetails();
    this.viewLeave = true;
    this.isLoading = true;
    this.title = "Leave list";
    this.uitlizedHospitalizationAmount = null;
    this.uitlizedOPDAmount = null;
    const startOfMonth = moment().startOf('month').format('dd MMMM yyyy');
    const endOfMonth = moment().endOf('month').format('dd MMMM yyyy');
    this.firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
    this.firstDay = this.firstDay.toISOString('MMM d, y');
    this.lastDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0);
    this.currentMonth = this.todayDate.getMonth() + 1;
    this.subscribeRefreshLeaves();
    this.today = new Date().toISOString();
  }

  getInsuranceTypeDetails() {
    let url = this._apiService.getEndPoint("hrmMetaData.list", [["{isOnlyActive}", true]]);

    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.insuranceTypeList = response.data.insurance;
          let payable = new HrmMetaData();
          payable.description = 'Payable';
          payable.color = '#000000'
          this.insuranceTypeList.push(payable);

          this.subInsuranceTypeList = [];
          let tempList = [];

          if (this.insuranceTypeList.length > 3) {
            for (var i = 0; i < this.insuranceTypeList.length; i++) {
              tempList.push(this.insuranceTypeList[i]);
              if ((i + 1) % 3 === 0) {
                this.subInsuranceTypeList.push(tempList);
                tempList = [];
              }

              if (this.insuranceTypeList.length === (i + 1) && tempList.length > 0) {
                this.subInsuranceTypeList.push(tempList);
              }
            }
          } else {
            this.subInsuranceTypeList.push(this.insuranceTypeList);
          }


          this.leaveTypeList = response.data.leave;
        }
      }
    });
  }

  loadEmployeeLeaveByEmployeeId() {
    if (this.employeeModel.id > 0) {
      this.ermService.getEmployeeLeaveByEmployeeId(this.employeeModel.id).subscribe(res => {
        if (res && res.data) {
          this.leaveList = res.data;
          this.formatLeaveTimeForDisplay(this.leaveList);
          this.firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
          this.lastDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0);
          this.leaveList.forEach(element => {
            if (element.startDate && element.endDate) {
              const oneDay = 24 * 60 * 60 * 1000;
              var _startDate = new Date(element.startDate);
              var _endDate = new Date(element.endDate);
              const diffDays = Math.round(Math.abs((+_startDate - +_endDate) / oneDay));
              element.leaveCount = diffDays;
            }
          });
          this.isLoading = false;
        } else {
          this.leaveList = [];
          this.isLoading = false;
        }
      });
    } else {
      this.leaveList = [];
      this.isLoading = false;
    }
  }

  private formatLeaveTimeForDisplay(list) {
    if (list.length > 0)
      list.forEach(ele => {
        ele.startTimeStr = ele.startTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.startTime) : null;
        ele.endTimeStr = ele.endTime ? this.formatTimeForDisplay.ConvertTimeFormatForDisplay(ele.endTime) : null;
      });
  }

  getEmployeeLeaveTypeListByEmployeeId() {
    if (this.employeeModel.id > 0) {
      this.ermService.getEmployeeLeaveTypeListByEmployeeId(this.employeeModel.id).subscribe(
        res => {
          if (res) {
            this.subEmployeeLeaveTypeList = [];
            this.employeeLeaveTypeList = res.data;
            let tempList = [];

            if (this.employeeLeaveTypeList.length > 3) {
              for (var i = 0; i < this.employeeLeaveTypeList.length; i++) {
                tempList.push(this.employeeLeaveTypeList[i]);
                if ((i + 1) % 3 === 0) {
                  this.subEmployeeLeaveTypeList.push(tempList);
                  tempList = [];
                }

                if (this.employeeLeaveTypeList.length === (i + 1) && tempList.length > 0) {
                  this.subEmployeeLeaveTypeList.push(tempList);
                }
              }
            } else {
              this.subEmployeeLeaveTypeList.push(this.employeeLeaveTypeList);
            }
          } else
            this.employeeLeaveTypeList = [];
        },
        err => { console.error(); });
    } else {
      this.employeeLeaveTypeList = [];
    }
  }

  viewLeaveList() {
    this.title = "Leave list";
    this.viewLeave = true;
    this.viewCalender = false;
    this.viewAttendanceCalendar = false;
    this.loadEmployeeLeaveByEmployeeId();
    this.getEmployeeLeaveTypeListByEmployeeId();
    this.isClickLeaveButton = true;
    this.isClickCalenderButton = false;
    this.isClickAttendanceButton = false;
    this.showUpload = false;
  }
  
  getInsuranceTotalByType(typeId: number): any {
    let totalOfThisCategory = 0;
    this.employeeInsuranceList.forEach((item) => {
      if (item.insuranceTypeId === typeId) {
        totalOfThisCategory = totalOfThisCategory + item.insuranceAmount;
      }
    });
    return totalOfThisCategory;
  }

  loadEmpInsuranceList() {
    this.isLoadingInsuranceList = true;
    if (this.employeeModel.id > 0) {
      this.ermService.getEmpInsuranceById(this.employeeModel.id).subscribe(
        res => {
          if (res) {

            this.uitlizedHospitalizationAmount = null;
            this.uitlizedOPDAmount = null;
            this.payableInsuarenceAmount = null;
            this._empInsuranceList = res.data;
            if (this._empInsuranceList.length > 0) {
              this._empInsuranceList.forEach(e => {
                if (e.insuranceTypeId == 1)
                  this.uitlizedHospitalizationAmount = e.uitlizedHospitalizationAmount ? e.uitlizedHospitalizationAmount : null;
                else if (e.insuranceTypeId == 2)
                  this.uitlizedOPDAmount = e.uitlizedOPDAmount ? e.uitlizedOPDAmount : null;

                this.payableInsuarenceAmount = e.payableInsuarenceAmount;
              });
            }
            this.isLoadingInsuranceList = false;
            let insuranceList = this._empInsuranceList;
            if (insuranceList.length > 0) {
              this.employeeObject.totalClaim = 0;
              insuranceList.forEach(element => {
                if (element.fileName != null) {
                  element.extension = element.fileName.split('.')[(element.fileName.split('.')).length - 1];
                  element.link = API.baseUrl + API.clientDocument.getClientDocument + element.id + '/' + element.generatedFileName;
                  if (element.url) {
                    let l = element.url.toString();
                    if (element.extension === 'pdf')
                      l = l.toString().includes('data:application/pdf;base64,') ? l : 'data:application/pdf;base64,' + l;
                    if (element.extension === 'png')
                      l = l.toString().includes('data:image/png;base64,') ? l : 'data:image/png;base64,' + l;
                    if (element.extension === 'jpg')
                      l = l.toString().includes('data:image/jpg;base64,') ? l : 'data:image/jpg;base64,' + l;
                    if (element.extension === 'jpeg')
                      l = l.toString().includes('data:image/jpeg;base64,') ? l : 'data:image/jpeg;base64,' + l;
                    if (element.extension === 'docx')
                      l = l.toString().includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                    if (element.extension === 'xlsx' || element.extension === 'xls')
                      l = l.toString().includes('https://docs.google.com/gview?url=') ? l : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                  }
                }
                let amount = element.insuranceAmount;
                this.employeeObject.totalClaim = amount + this.employeeObject.totalClaim;
              });
              this.employeeObject.entitleAmount = 50000;
              if (this.employeeObject.totalClaim > 0) {
                this.employeeObject.total = this.employeeObject.entitleAmount - this.employeeObject.totalClaim;
              }
              this.employeeInsuranceList = insuranceList;
              this._empInsuranceList = this.employeeInsuranceList;
              this._empInsuranceList = this._empInsuranceList.length > 0 ? this._empInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this._empInsuranceList;

            } else {
              this.employeeInsuranceList = [];
              this._empInsuranceList = [];
            }
            if (this.entitleAmount > this.totalClaim) {
              this.total = this.entitleAmount - this.totalClaim;
            } else {
              this.getClaim = true;
            }
          }
        },
        err => { console.error(); });
    } else {
      this.leaveList = [];
      this.isLoadingInsuranceList = false;
    }
  }

  subscribeEmployeeInsuranceDetails() {
    this.ermService.refreshEmployeeInsurance.subscribe(data => {
      if (!data) { return; }
      this.loadEmpInsuranceList();
    });
  }

  changeCurrentMonth(key) {
    if (key) {
      if (key == '-') {
        this.currentMonth = this.currentMonth - 1;
        this.currentDate.setMonth(this.currentDate.getMonth() - 1);
        this.firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this._empInsuranceList = this.employeeInsuranceList.length > 0 ? this.employeeInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this.employeeInsuranceList;
      } else if (key == '+') {
        this.currentMonth = this.currentMonth + 1;
        this.currentDate.setMonth(this.currentDate.getMonth() + 1);
        this.firstDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        this.lastDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0);
        this._empInsuranceList = this.employeeInsuranceList.length > 0 ? this.employeeInsuranceList.filter(x => new Date(x.date).getMonth() + 1 == this.currentMonth).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : this.employeeInsuranceList;
      }
    }
  }
 
  subscribeRefreshLeaves() {
    this.ermService.refreshLeaveList.subscribe(data => {
      if (!data) { return; }
      this.loadEmployeeLeaveByEmployeeId();
      this.getEmployeeLeaveTypeListByEmployeeId();
    });
  }
}
