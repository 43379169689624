import { Injectable, EventEmitter, Output } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { Subject } from 'rxjs';
import { TypeOfDocument } from '../entities/type-of-document';
import { DocumentPermits } from '../entities/document-permits';

@Injectable({
  providedIn: 'root'
})

export class CRMDocumentTypeService {

  @Output() crmDocumentTypeRefresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiservice: APIService) { }  

  getTypeOfDocumentById(id) {
    let url = this.apiservice.getEndPoint("typeOfDocument.getTypeOfDocumentById", [["{id}", id]]);
    return this.apiservice.get(url);
  }

  addTypeOfDocumentData(typeOfDocumentObj: TypeOfDocument) {
    let url = this.apiservice.getEndPoint("typeOfDocument.addTypeOfDocument", null);
    return this.apiservice.post(url, typeOfDocumentObj, true);
  }

  editTypeOfDocumentData(id: any, obj) {
    let url = this.apiservice.getEndPoint("typeOfDocument.editTypeOfDocument", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  deleteDocumentPermits(id) {
    let url = this.apiservice.getEndPoint("documentPermits.documentPermits", [["{id}", id]]);
    return this.apiservice.delete(url, id);
  }

  addDocumentPermits(documentPermitsObj: DocumentPermits) {
    let url = this.apiservice.getEndPoint("documentPermits.addDocumentPermits", null);
    return this.apiservice.post(url, documentPermitsObj, true);
  }

  updateDocumentPermits(id: any, obj) {
    let url = this.apiservice.getEndPoint("documentPermits.editDocumentPermits", [["{id}", id]]);
    return this.apiservice.put(url, obj, true);
  }

  getDocumentPermitsByTypeOfDocumentId(typeOfDocumentId) {
    let url = this.apiservice.getEndPoint("documentPermits.getDocumentPermitsByTypeOfDocumentId", [["{typeOfDocumentId}", typeOfDocumentId]]);
    return this.apiservice.get(url);
  }
} 
