import { TemplateCategory } from './templateCategory';
import { Template } from './template';

export class TemplateSubcategory {
  id: number;
  categoryId: number;
  subcategory: string;

  flag: boolean = false;

  templateCategory: TemplateCategory;
  template: Template[];
}
