import { TemplateSubcategory } from './templateSubcategory';
import { Template } from './template';

export class TemplateCategory {
  id: any;
  category: string;

  flag: boolean = false;

  template: Template[];
  templateSubcategory: TemplateSubcategory[];
}
