import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { AlertPopupComponent } from 'src/app/shared/components/alert-popup/alert-popup.component';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AddHolidayComponent } from '../popups/add-holiday/add-holiday.component';
import { HrmMaintenanceService } from '../../service/hrm-maintenance.service';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SnackBar,SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  alertModel: any = 'Sample';
  idSelectedHoliday: number;
  holidayList:any;
  yearsList=[];
  selectedYear:number;
  isSpinner:boolean=true;
  private subscriber: Subscription;

  constructor(
    public commonUIService: UIService,
    public hrmMaintenanceService: HrmMaintenanceService,
    public dialog: MatDialog,
    private alertService: AlertService,
    )
    {


    }
  

  ngOnInit() {
    this.setYearsList();
    let currentYear = new Date().getFullYear();
    this.fetchHolidaysByYear(currentYear)
    this.subscribeDeletetConfirmEvents();
    this.selectedYear= new Date().getFullYear();
  }

  setYearsList()
  {
    let currentYear = new Date().getFullYear();
    const year={
      year:currentYear
    }
    this.yearsList.push(year)
    for(let i=0;i<1;i++)
    {
      const year2={
        year:currentYear+1
      }
     this.yearsList.push(year2)
    }
  }

  yearChange()
  {
    this.fetchHolidaysByYear(this.selectedYear)
  }

  openAddHolidayPopup(data?:any) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, data, null, null);
    this.openDialog(AddHolidayComponent,dialogViewData);
  }

  openDialog(component, data?) {
    const dialogRef = this.dialog.open(component, {
      data: { data },
      panelClass: 'form-dialog-modal',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      let currentYear = new Date().getFullYear();
      this.fetchHolidaysByYear(currentYear) 
    });
  }

  openAlertDeleteUser(item:any) {
    let deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure want to delete this record?';
    deleteData.id = item.id;
    deleteData.reference = 'Delete Holiday';
    this.idSelectedHoliday = item.id;
    this.openAlert(deleteData);
  }

  subscribeDeletetConfirmEvents() {
    this.subscriber = this.alertService.deleteHolidays
      .subscribe(data => {
        if (data.reference === "Delete Holiday") {
          this.hrmMaintenanceService.deleteHolidays(this.idSelectedHoliday).subscribe(res=>{
            if(res.data){
              if(res.isSuccsess){
                let currentYear = new Date().getFullYear();
                this.fetchHolidaysByYear(currentYear)
                this.deleteSuccess();
              }else{
                this.deleteFail();
              }
            }
          },error=>{
            console.log(error);
            this.deleteFail();
          })
        }
      });
  }

  openAlert(data) {
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      data: { data },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      let currentYear = new Date().getFullYear();
      this.fetchHolidaysByYear(currentYear) 
    });
  }

  fetchHolidaysByYear(year:any)
  {
    this.isSpinner=true;
    this.hrmMaintenanceService.getAllHolidayInfoByYear(year).subscribe(res=>{
      if(res.data)
      {
        this.holidayList=res.data;
        this.isSpinner=false;
      }
    },error=>{
      console.log(error)
      this.isSpinner=false;
    });
  }

  deleteSuccess()
  {
    const snack = new SnackBar(SnackBarType.success, 'Successfully deleted', '', 4000);
    this.commonUIService.openSnackBar(snack);
  }

  deleteFail()
  {
    const snack = new SnackBar(SnackBarType.error, 'Error on delete', '', 4000);
    this.commonUIService.openSnackBar(snack);
  }

}
