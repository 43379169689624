export class regionModel {
  id: number;
  regionName: string;
  status: boolean;
  insertDate: Date;
  insertUser: string;
  updateuser: string;
  updateDate: Date;
  active?: boolean;
  isExistId:boolean;
}
