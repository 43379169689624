import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { HrmService } from '../../../../service/hrm.service';
import { FormatMatDatepicker } from '../../../../../shared/format-datepicker';
import { ValidationService } from '../../../../../shared/services/validation.service';
import { Employee } from 'src/app/hrm/entities/empoyee';
import { AddHrmInsuranceComponent } from './add-hrm-insurance/add-hrm-insurance.component';
import { EmpInsurance } from 'src/app/hrm/entities/EmpInsurance';
import API from 'src/app/shared/services/api.routes.json';


@Component({
  selector: 'hrm-insuarence',
  templateUrl: './hrm-insuarence.component.html',
  styleUrls: ['./hrm-insuarence.component.scss']
})



export class HRMInsuarenceComponent implements OnInit {
  @Input() employeeObject: Employee;
  _empInsuranceList: EmpInsurance[] = [];
  totalClaim: any;
  total: any;
  entitleAmount: any;
  getClaim: boolean = false;


  public PDF = '../../assets/images/config/pdf.png';
  public PNG = '../../assets/images/config/png.png';
  public JPG = '../../assets/images/config/jpg.png';
  public JPEG = '../../assets/images/config/jpg.png';
  public XLS = '../../assets/images/config/xls.png';
  public DOC = '../../assets/images/config/doc.png';

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    public hrmService: HrmService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService) {

  }

  ngOnChanges() {
    this._empInsuranceList = this.employeeObject.employeeInsurance;
    let insuranceList = this._empInsuranceList;
    if (insuranceList.length > 0) {
      insuranceList.forEach(element => {
        if (element.fileName != null) {
          element.extension = element.fileName.split('.')[(element.fileName.split('.')).length - 1];
          element.link = API.baseUrl + API.clientDocument.getClientDocument + element.id + '/' + element.generatedFileName;
          if (element.url) {
            if (element.extension === 'pdf')
              element.url = element.url.toString().includes('data:application/pdf;base64,') ? element.url : 'data:application/pdf;base64,' + element.url;
            if (element.extension === 'png')
              element.url = element.url.toString().includes('data:image/png;base64,') ? element.url : 'data:image/png;base64,' + element.url;
            if (element.extension === 'jpg')
              element.url = element.url.toString().includes('data:image/jpg;base64,') ? element.url : 'data:image/jpg;base64,' + element.url;
            if (element.extension === 'jpeg')
              element.url = element.url.toString().includes('data:image/jpeg;base64,') ? element.url : 'data:image/jpeg;base64,' + element.url;
            if (element.extension === 'docx')
              element.url = element.url.toString().includes('https://docs.google.com/gview?url=') ? element.url : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
            if (element.extension === 'xlsx' || element.extension === 'xls')
              element.url = element.url.toString().includes('https://docs.google.com/gview?url=') ? element.url : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
          }
        }

      });
      this._empInsuranceList = insuranceList;
    } else {
      this._empInsuranceList = [];
    }
  }

  ngOnInit() {

    this.subscribeEmployeeInsuranceDetails();
 
  }


  openAddInsurancePopup(employeeId, emptotalClaim) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(employeeId, null, emptotalClaim, null, null, null);
    this.commonUIService.openDialog(AddHrmInsuranceComponent, dialogViewData);
  }

  subscribeEmployeeInsuranceDetails() {
    this.hrmService.refreshEmployeeInsurance.subscribe(data => {
      if (!data) { return; }
      this.loadEmpInsuranceList(this.employeeObject.id);
    });
  }

  async loadEmpInsuranceList(empId) {
    this.hrmService.getEmpInsuranceById(empId).subscribe(
      res => {
        if (res) {
          this._empInsuranceList = res.data;
          let insuranceList = this._empInsuranceList;
          if (insuranceList.length > 0) {
            this.employeeObject.totalClaim = 0;
            insuranceList.forEach(element => {
              if (element.fileName != null) {
                element.extension = element.fileName.split('.')[(element.fileName.split('.')).length - 1];
                element.link = API.baseUrl + API.clientDocument.getClientDocument + element.id + '/' + element.generatedFileName;
                if (element.url) {
                  if (element.extension === 'pdf')
                    element.url = element.url.toString().includes('data:application/pdf;base64,') ? element.url : 'data:application/pdf;base64,' + element.url;
                  if (element.extension === 'png')
                    element.url = element.url.toString().includes('data:image/png;base64,') ? element.url : 'data:image/png;base64,' + element.url;
                  if (element.extension === 'jpg')
                    element.url = element.url.toString().includes('data:image/jpg;base64,') ? element.url : 'data:image/jpg;base64,' + element.url;
                  if (element.extension === 'jpeg')
                    element.url = element.url.toString().includes('data:image/jpeg;base64,') ? element.url : 'data:image/jpeg;base64,' + element.url;
                  if (element.extension === 'docx')
                    element.url = element.url.toString().includes('https://docs.google.com/gview?url=') ? element.url : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                  if (element.extension === 'xlsx' || element.extension === 'xls')
                    element.url = element.url.toString().includes('https://docs.google.com/gview?url=') ? element.url : 'https://docs.google.com/gview?url=' + element.link + '&embedded=true';
                }
              }


              let amount = element.insuranceAmount;
              this.employeeObject.totalClaim = amount + this.employeeObject.totalClaim;
            });

            this.employeeObject.entitleAmount = 50000;
            if (this.employeeObject.totalClaim > 0) {
              this.employeeObject.total = this.employeeObject.entitleAmount - this.employeeObject.totalClaim;
            }
            this._empInsuranceList = insuranceList;

          } else {
            this._empInsuranceList = [];

          }
          if (this.entitleAmount > this.totalClaim) {
            this.total = this.entitleAmount - this.totalClaim;
          } else {
            this.getClaim = true;
          }
        }
      },
      err => { console.error(); });
  }
}
