import { SafeResourceUrl } from '@angular/platform-browser';

export class EmpInsurance {
  id: number;
  employeeId: number;
  date: any;
  description: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: any;
  fileName: string;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  safeUrl: SafeResourceUrl;
  dateStr: any;
  generatedFileName: any;
  googleDriveId: string;
  googleDriveUrl: string;
  insuranceAmount: number;
  entitleAmount: number;
  total: number;
  insuranceTypeId: any;
  uitlizedHospitalizationAmount: number;
  uitlizedOPDAmount: number;
  isPayable: any;
  payableInsuarenceAmount: number;
  totalClaimForHospitalization: number = 0;
  totalClaimForOPD: number = 0;
  insuranceList: EmpInsurance[];
}
