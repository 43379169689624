import { SafeResourceUrl } from '@angular/platform-browser';

export class Employee {
  id: number;
  firstName: string;
  lastName: string;
  employeeNumber: string;
  nic: string;
  dob: any;
  gender: string;
  address: string;
  city: string;
  country: string;
  phone1: string;
  phone2: string;
  email: string;
  designation: string;
  jointDate: any;
  department: string;
  profileFileName: string;
  generatedFileName: string;
  profilePath: string;
  companyId: number;
  insertDate: Date;
  insertUser: string;
  updateDate: Date;
  updateUser: string;
  status: number;
  profilePathForView: string;
  completedSixMonth: boolean;
  completedOneYear: boolean;
  entitleAmount: number;
  totalClaim: number = 0;
  total: number = 0;
  dobStr: string;
  jointDateStr: string;
  prolfileImage: string;
  emergencyContactPersonName: string;
  relationShip: string;
  isEdit: boolean;
  showEditSave: boolean;
  file: any;
  fileList: any;
  extension: string;
  url: string;
  link: string;
  safeUrl: SafeResourceUrl;
  initials: any;
  employeeInsurance: any;
  employeeAge: number;
  year: any;
  month: any;
  days: number;
  isProbationCompleted: boolean;
  isContractCompleted: boolean;
  userRoleMultiSelectList : string;
  companyMultiSelectList : any [];
  seeAllBranches:boolean;
  employeesTypeId: number;
  initRepManager:number;
  isOutsourceAccess:boolean =false;
} 
