export class FurnitureModel{
  floorId: number;
  categoryId: number;
  name: string;
  furnitureCount: number;
  id?: number; 
}

export class FloorFurnitureModel {
  floorId: number;
  furnitureId: number;
  furnitureCount: number;
}


export class FloorFurnitureModelName {
  floorId: number;
  furnitureId: number;
  furnitureName: string;
}
