export class EmpBankDetails {
 id:number;
 employeeId: number;
 accountNumber: string;
 beneficiaryName: string;
 bank: string;
 branch: string;
 insertDate: any;
 insertUser: string;
 updateDate: any;
 updateUser: string;
 status: any;
}
