import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { CancelLeaveComponent } from '../../app/leave/components/cancel-leave/cancel-leave.component';
import { FilterLeaveComponent } from '../../app/leave/components/filter/filter-leave.component';
import { AddLeaveComponent } from './components/add-leave/add-leave.component';
import { Employee } from '../erm/entities/empoyee';
import { ErmService } from '../erm/service/erm.service';
import { FormatTimeForDisplay } from '../shared/format-datepicker';
import { Users } from '../auth/entities/user';
import { APIService } from '../shared/services/api.service';
import { HrmMetaDataEnum } from '../shared/enum/hrmMetadataType';
import { ApprovalsService } from '../approvals/service/approvals.service';
import moment from 'moment';

@Component({
  selector: 'leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss'],
})
export class LeaveComponent implements OnInit {

  employeeModel: Employee;
  leaveList: any[] = [];
  isLoading = false;
  currentusers: Users;
  loginEmployee: Employee;
  isHR: any = true;
  leaveObj: any;
  selectedLeaveObj: any;
  idSelectedEmployee: number = 0;
  leaveCardList: any = [];
  defultLoard: boolean = false;
  nextSelectionDate: Date
  nextSelectionMonth: any
  nextSelectionYear: number
  leaveStatusLable;

  constructor(
    public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public ermService: ErmService,
    private _apiService: APIService,
    private approvalsService: ApprovalsService
  ) {
    this.loginEmployee = new Employee();
  }

  ngOnInit() {
    this.leaveCardList = [];
    this.currentuser();
    this.commonDropdownService.LoadEmployeeList();
    this.nextSelectionDate = new Date();
    this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
    this.nextSelectionYear = this.nextSelectionDate.getFullYear();
    this.setObservable();
  }

  setObservable() {

    this.ermService.triggerSuccessStatus().subscribe(res => {
      this.loadEmployeeLeaveByEmployee(this.loginEmployee.id);
      this.fetchLeaveInfo(this.loginEmployee.id)
    }, error => {
      console.log(error)
    });

    this.approvalsService.getCancelEvent().subscribe(reason => {
      this.approvalsService.cancelLeave(this.currentusers.id, this.selectedLeaveObj.id, reason).subscribe(rest => {
        this.loadEmployeeLeaveByEmployee(this.loginEmployee.id);
        this.fetchLeaveInfo(this.loginEmployee.id)
      })
    }, error => {
      console.log(error);
    })

  }

  openAddEmployeePopup() {
    this.commonUIService.openDialog(AddLeaveComponent, { data: this.leaveCardList, nextSelectionMonth: this.nextSelectionMonth });
  }

  openCancelEmployeePopup() {
    this.commonUIService.openDialog(CancelLeaveComponent);
  }

  openFilterPopup() {
    this.commonUIService.openDialog(FilterLeaveComponent);
  }

  loadEmployeeLeaveByEmployee(loginUserId) {
    if (loginUserId > 0) {
      this.ermService.getEmployeeLeaveHistoryByEmployeeId(loginUserId).subscribe(res => {
        if (res && res.data) {
          this.leaveList = res.data;
          this.isLoading = false;
        }
        else {
          this.leaveList = [];
          this.isLoading = false;
        }
      });
    } else {
      this.leaveList = [];
      this.isLoading = false;
    }
  }

  loadEmployeeLeaveCard(empId, year) {
    if (empId > 0) {
      this.ermService.getEmployeeLeaveCard(empId, year).subscribe(res => {
        if (res && res.data) {
          this.leaveCardList = [];
          this.leaveCardList = res.data;
        }
        else {
          this.leaveCardList = [];
        }
      });
    } else {
      this.leaveCardList = [];
    }
  }

  currentuser() {
    let loggedUser = JSON.parse(localStorage.getItem('appUser'));
    this.currentusers = loggedUser;
    this.ermService.getEmployeeByEmail(this.currentusers.email).subscribe(res => {
      this.loginEmployee = res.data;
      this.loadEmployeeLeaveByEmployee(this.loginEmployee.id);
      this.fetchLeaveInfo(this.loginEmployee.id);
      this.idSelectedEmployee = 0;
      this.loadEmployeeLeaveCard(this.loginEmployee.id, (new Date()).getFullYear());

    });
  }

  getStatusClass(statusId) {
    let classList = '';
    if (statusId == 2) { classList = 'pending'; }
    else if (statusId == 3) { classList = 'approved'; }
    else if (statusId == 1) { classList = 'pending'; }//use this class for pending
    else if (statusId == 4) { classList = 'rejected'; }
    else if (statusId == 5) { classList = 'rejected'; }//use this class for rejected
    else if (statusId == 6) { classList = 'canceled'; }//use this class for canceled
    return classList;
  }

  getStatus(statusId) {
    let classList = '';
    if (statusId == 2) { classList = 'pending'; }
    else if (statusId == 3) { classList = 'Approved'; }
    else if (statusId == 1) { classList = 'Pending'; }
    else if (statusId == 4) { classList = 'Rejected'; }
    else if (statusId == 5) { classList = 'Rejected'; }
    else if (statusId == 6) { classList = 'Canceled'; }
    return classList;
  }
  selectLeave(leaveId: any, leaveStatus: any) {
    this.selectedLeaveObj = this.leaveObj.find(leave => leave.id === leaveId);
    this.selectedLeaveObj.employeeLeaveHistory.sort((a, b) => b.id - a.id);

    this.idSelectedEmployee = leaveId;
    this.defultLoard = true;
    this.leaveStatusLable = leaveStatus;
  }
  leaveHistoryMapping(statusId: any) {
    if (statusId == null)
      return ''
    if (statusId == 1) {
      return 'Leave request submitted by'
    } else if (statusId == 2) {
      return 'Leave request approved by PM'
    } else if (statusId == 3) {
      return 'Leave request approved by HR'
    } else if (statusId == 4) {
      return 'Leave request rejected by PM'
    } else if (statusId == 5) {
      return 'Leave request rejected by HR'
    } else if (statusId == 6) {
      return 'Leave request canceled by user'
    }
  }
  statusMapping(statusId: any) {
    if (statusId == null)
      return 'Pending'
    if (statusId == 1 || statusId == 2) {
      return 'Pending'
    } else if (statusId == 3) {
      return 'Approved'
    } else if (statusId == 4 || statusId == 5) {
      return 'Rejected'
    } else {
      return 'Canceled'
    }
  }
  leaveHistoryClassMapping(statusId: any) {
    if (statusId == null)
      return ''
    if (statusId == 1) {
      return 'grey-status-circle'
    } else if (statusId == 2) {
      return 'green-status-circle'
    } else if (statusId == 3) {
      return 'green-status-circle'
    } else if (statusId == 4) {
      return 'red-status-circle'
    } else if (statusId == 5) {
      return 'red-status-circle'
    } else if (statusId == 6) {
      return 'red-status-circle'
    }
  }

  fetchLeaveInfo(employeeid) {
    this.approvalsService.getLeaveViewDetail(employeeid).subscribe(res => {
      if (res) {
        this.leaveList = res.data;
        this.leaveObj = res.data;
      }
    }, error => {
      console.log(error);
    })
  }

  monthSelectionChange(action) {
    var today = new Date();

    if (action == 'next') {
      this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() + 1);
      if (today.getFullYear() === this.nextSelectionDate.getFullYear()) {
        this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
        this.nextSelectionYear = this.nextSelectionDate.getFullYear();
      } else { this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() - 1); }
    }
    if (action == 'prev') {
      this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() - 1);
      if (today.getFullYear() === this.nextSelectionDate.getFullYear()) {
        this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
        this.nextSelectionYear = this.nextSelectionDate.getFullYear();
      }
      else { this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() + 1); }
    }
  }

  cancelLeaveStatus(employeeLeaveHistory: any): boolean {
    if (!employeeLeaveHistory || employeeLeaveHistory.length === 0) {
      return true;
    }

    const today = new Date();
    const compareTodate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    for (const history of employeeLeaveHistory.leaveDetails) {
      const leaveDate = new Date(history.leaveDate);
      const compareLeaveDate = new Date(leaveDate.getFullYear(), leaveDate.getMonth(), leaveDate.getDate());

      if (compareLeaveDate <= compareTodate) {
        return true;
      }
    }
    if (employeeLeaveHistory.leaveStatusId == 4 || employeeLeaveHistory.leaveStatusId == 5 || employeeLeaveHistory.leaveStatusId == 6) {
      return true;
    }
    return false;
  }
}
