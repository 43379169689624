import { HrmMetaDataEnum } from './../../shared/enum/hrmMetadataType';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import API from '../../shared/services/api.routes.json';
import { APIService } from 'src/app/shared/services/api.service';


@Injectable({
  providedIn: 'root'
})
export class ApprovalsService {
  private streamReason = new Subject<any>();
  private streamApproved = new Subject<any>();
  private submitFilter = new Subject<any>();
  private floatinButton = new Subject<any>();
  private streamlLeave = new Subject<any>();

  constructor(private apiservice: APIService) {

  }

  getLeaveForApprovals(isHR: any, authPreson: any, pageNumber: any, pageSize: any) {
    let url = `${API.employeeLeave.getLeaveForApprovals}`;
    url = url.replace('{isHR}', isHR);
    url = url.replace('{authPreson}', authPreson);
    url = url.replace('{pageNumber}', pageNumber);
    url = url.replace('{pageSize}', pageSize);
    return this.apiservice.get(url);
  }

  getLeaveViewDetail(employeeId: any) {
    let url = `${API.employeeLeave.getLeaveViewDetail}`;
    url = url.replace('{employeeId}', employeeId);
    return this.apiservice.get(url);
  }

  rejectLeave(isHR: any, authPreson: any, leaveId: any, reason: any) {
    let url = `${API.employeeLeave.rejectLeave}`;
    url = url.replace('{isHR}', isHR);
    url = url.replace('{authPreson}', authPreson);
    url = url.replace('{leaveId}', leaveId);
    url = url.replace('{reason}', reason);
    return this.apiservice.get(url);
  }

  cancelLeave(authPreson: any, leaveId: any, reason: any) {
    let url = `${API.employeeLeave.cancelLeave}`;
    url = url.replace('{authPreson}', authPreson);
    url = url.replace('{leaveId}', leaveId);
    url = url.replace('{reason}', reason);
    return this.apiservice.get(url);
  }

  approveLeave(isHR: any, authPreson: any, leaveId: any) {
    let url = `${API.employeeLeave.approveLeave}`;
    url = url.replace('{isHR}', isHR);
    url = url.replace('{authPreson}', authPreson);
    url = url.replace('{leaveId}', leaveId);
    return this.apiservice.get(url);
  }


  getLeaveType() {
    let url = `${API.hrmMetaData.getHrmMetadataByType}`;
    url = url.replace('{type}', HrmMetaDataEnum.Leave.toString());
    url = url.replace('{isOnlyActive}', 'true');
    return this.apiservice.get(url);
  }


  filterApprovals(object: any) {
    let url = `${API.employeeLeave.filterApprovals}`;
    return this.apiservice.post(url, object);
  }

  public setReason(data: any) {
    this.streamReason.next(data);
  }

  public returnReason() {
    return this.streamReason.asObservable();
  }

  public setApprove(data: any) {
    this.streamApproved.next(data);
  }

  public returnApprove() {
    return this.streamApproved.asObservable();
  }

  public setFilter(data: any) {
    this.submitFilter.next(data);
  }

  public getFilterData() {
    return this.submitFilter.asObservable();
  }

  public showHideFloating(data: any) {
    this.floatinButton.next(data)
  }

  public streamFloatingButton() {
    return this.floatinButton.asObservable();
  }

  public setCancelEvent(reason: any) {
    this.streamlLeave.next(reason)
  }

  public getCancelEvent() {
    return this.streamlLeave.asObservable();
  }

}
