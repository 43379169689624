import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { InventoryModel } from 'src/app/inventory/entities/inventoryModel';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { startWith, map } from 'rxjs/operators';
import { ClientService } from 'src/app/client/services/client.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { MatAutocomplete, MatDatepickerInputEvent, MatDialogRef, MatDialog } from '@angular/material';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { ValidationService } from '../../../../shared/services/validation.service';
import { AlertModel } from '../../../../shared/entities/alertModel';
import { AlertService } from '../../../../shared/services/alert.service';
import { AddInventoryUserComponent } from '../inventory-user/add-inventory-user/add-inventory-user.component';
import { AlertPopupComponent } from '../../../../shared/components/alert-popup/alert-popup.component';
import { Users } from '../../../../auth/entities/user';
import { APIService } from '../../../../shared/services/api.service';
import API from '../../../../shared/services/api.routes.json';

@Component({
  selector: 'app-laptop-detail',
  templateUrl: './laptop-detail.component.html',
  styleUrls: ['./laptop-detail.component.scss']
})

export class LaptopDetailComponent implements OnInit, OnChanges, OnDestroy {
  nameControl = new FormControl();
  nameOption: any[] = [];
  filteredOptions1: Observable<any[]>;
  events: string[] = [];
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  @Input() inventoryDetailFormGroup: FormGroup;
  @Input() inventoryModel: InventoryModel;
  subscriber: Subscription;
  isChecked: boolean = false;
  saveInventoryUser: boolean = false;
  selectedIndex: number = 1;
  subscriberForAlertConfirm: Subscription;

  constructor(public commonDropdownService: CommonDropdownService, public commonUIService: UIService,
    public formatDatepicker: FormatMatDatepicker,
    private _formBuilder: FormBuilder,
    public alertService: AlertService,
    private validationService: ValidationService,
    public inventoryService: InventoryService,
    private _apiService: APIService,
    public dialog: MatDialog) {
    this.inventoryModel = new InventoryModel();
    this.inventoryModel.isEdit = false;
    this.inventoryModel.state = 0;
    this.subscribeAlertConfirmEvents();
  }

  ngOnInit() {
    this.filteredOptions = this.inventoryDetailFormGroup.get('name').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.commonDropdownService.LoadInventoryDropdownList();
    this.commonDropdownService.LoadProviderList();
    this.commonDropdownService.LoadCurrencyList();
    this.commonDropdownService.LoadUserList();

    this.subscribeAlertCloseEvents();

    this._apiService.get(API.user.getAllUsers).subscribe(response => {
      if (response) {
        if (response.data) {
          let userData = response.data;
          userData.forEach(element => {
            this.nameOption.push({ name: element.name });
          });

          this.filteredOptions1 = this.nameControl.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.name),
              map(name => name ? this._filter1(name) : this.nameOption.slice())
            );
        }
      }
    });

    this.filteredOptions1 = this.nameControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter1(name) : this.nameOption.slice()));

    this.inventoryDetailFormGroup.reset();
    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }


  ngOnChanges() {
    if (this.inventoryDetailFormGroup) {
      this.inventoryDetailFormGroup.get('type').markAsUntouched();
      this.inventoryDetailFormGroup.get('code').markAsUntouched();
      this.inventoryDetailFormGroup.get('date').markAsUntouched();
    }
  }

  ngOnDestroy() {
    this.subscriberForAlertConfirm.unsubscribe();
  }

  displayFn(user: Users): string {
    return user && user.name ? user.name : '';
  }

  private _filter1(name: string): Users[] {
    const filterValue = name.toLowerCase();

    return this.nameOption.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filter(value: string): string[] {
    return this.options.filter(option => option.toLowerCase());
  }

  changeInventoryStatusForLaptops(event, inventoryModel) {
    if (event.value == "1") {
      this.inventoryModel.checkIsInuse = true;
      this.inventoryModel.state = 1;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '1';
      alterData.selectedTabIndex = 0;
      this.commonUIService.openAlert(alterData);
    } else if (event.value == "0") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 0;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '0';
      alterData.selectedTabIndex = 0;
      this.commonUIService.openAlert(alterData);
    }
    else if (event.value == "2") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 2;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '2';
      alterData.selectedTabIndex = 0;
      this.commonUIService.openAlert(alterData);
    }
    else if (event.value == "3") {
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 3;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = '3';
      alterData.selectedTabIndex = 0;
      this.commonUIService.openAlert(alterData);
    }
  }

  public editLaptop() {
    if (!this.inventoryModel.showEditSave) {
      this.inventoryModel.showEditSave = true;
    }
    if (this.inventoryDetailFormGroup.disabled) {
      this.inventoryDetailFormGroup.enable();
    }
  }

  saveLaptop(model: InventoryModel, statusChangeString = null) {
    if (this.inventoryModel.showEditSave == false && statusChangeString == 'status_change_laptop'){
      let invetoryObj = Object.assign({}, model);
      if (invetoryObj.name == undefined && invetoryObj.name != null) {
        invetoryObj.currentUser = null;
        invetoryObj.state = 0;
      }
      if (invetoryObj.currentUser != invetoryObj.name) {
        invetoryObj.currentUser = invetoryObj.name;
        invetoryObj.state = 1;
      } else {
        invetoryObj.currentUser = invetoryObj.name;
        if (![1, 2, 3].includes(invetoryObj.state)) {
          invetoryObj.state = 0;
        }
      }

      if (invetoryObj.currentUser == null) {
        if (![1, 2, 3].includes(invetoryObj.state)) {
          invetoryObj.state = 0;
        }
      }      
      invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
      invetoryObj.entryDateStr = invetoryObj.entryDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.entryDate) : invetoryObj.entryDate;
      invetoryObj.purchaseStr = invetoryObj.purchase ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.purchase) : invetoryObj.purchase;
      invetoryObj.mouseDateStr = invetoryObj.mouseDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.mouseDate) : invetoryObj.mouseDate;
      invetoryObj.keyboardDateStr = invetoryObj.keyboardDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.keyboardDate) : invetoryObj.keyboardDate;
      invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
      invetoryObj.screenSize = invetoryObj.screenSize ? Number(invetoryObj.screenSize) : null;
      if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
        this.inventoryService.submitUpdateInventory(invetoryObj, 0, this.saveInventoryUser);
      }
    } else {
      if (this.inventoryModel.isEdit == false) {
        this.inventoryDetailFormGroup.get('type').markAsTouched();
        this.inventoryDetailFormGroup.get('code').markAsTouched();
        this.inventoryDetailFormGroup.get('date').markAsTouched();
        this.inventoryDetailFormGroup.get('type').patchValue("LAPTOP");
        var codeValue = this.inventoryDetailFormGroup.get('code').value;
        var dateValue = this.inventoryDetailFormGroup.get('date').value;
        if (this.inventoryDetailFormGroup.invalid || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
          this.inventoryDetailFormGroup.enable();
          return
        }
        else {
          let invetoryObj = Object.assign({}, model);
          if (invetoryObj.name == undefined && invetoryObj.name != null) {
            invetoryObj.currentUser = null;
            invetoryObj.state = 0;
          }
          if (invetoryObj.currentUser != invetoryObj.name) {
            invetoryObj.currentUser = invetoryObj.name;
            invetoryObj.state = 1;
          } else {
            invetoryObj.currentUser = invetoryObj.name;
            if (![1, 2, 3].includes(invetoryObj.state)) {
              invetoryObj.state = 0;
            }
          }

          if (invetoryObj.currentUser == null) {
            if (![1, 2, 3].includes(invetoryObj.state)) {
              invetoryObj.state = 0;
            }
          }
          invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
          invetoryObj.entryDateStr = invetoryObj.entryDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.entryDate) : invetoryObj.entryDate;
          invetoryObj.purchaseStr = invetoryObj.purchase ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.purchase) : invetoryObj.purchase;
          invetoryObj.mouseDateStr = invetoryObj.mouseDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.mouseDate) : invetoryObj.mouseDate;
          invetoryObj.keyboardDateStr = invetoryObj.keyboardDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.keyboardDate) : invetoryObj.keyboardDate;
          invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
          invetoryObj.screenSize = invetoryObj.screenSize ? Number(invetoryObj.screenSize) : null;
          if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
            this.inventoryService.submitUpdateInventory(invetoryObj, 0, this.saveInventoryUser);
          }
          else {
            if (invetoryObj.checkIsInuse == false || invetoryObj.checkIsInuse == undefined)
              invetoryObj.state = 0;
            else
              invetoryObj.state = 1;

            if (invetoryObj.name != undefined || invetoryObj.checkIsInuse != null) {
              invetoryObj.state = 1;
              invetoryObj.currentUser = invetoryObj.name;
            }
            this.inventoryService.submitSaveInventory(invetoryObj, 0);
          }
        }
      } else {
        if (!!this.inventoryDetailFormGroup && this.inventoryDetailFormGroup.dirty) {
          this.inventoryDetailFormGroup.get('type').markAsTouched();
          this.inventoryDetailFormGroup.get('code').markAsTouched();
          this.inventoryDetailFormGroup.get('date').markAsTouched();
          this.inventoryDetailFormGroup.get('type').patchValue("LAPTOP");
          var codeValue = this.inventoryDetailFormGroup.get('code').value;
          var dateValue = this.inventoryDetailFormGroup.get('date').value;
          if (this.inventoryDetailFormGroup.invalid || codeValue == undefined || codeValue == null || dateValue == undefined || dateValue == null) {
            this.inventoryDetailFormGroup.enable();
            return
          }
          else {
            let invetoryObj = Object.assign({}, model);
            if (invetoryObj.name == undefined && invetoryObj.name != null) {
              invetoryObj.currentUser = null;
              invetoryObj.state = 0;
            }
            if (invetoryObj.currentUser != invetoryObj.name) {
              invetoryObj.currentUser = invetoryObj.name;
              invetoryObj.state = 1;
            } else {
              invetoryObj.currentUser = invetoryObj.name;
              if (![1, 2, 3].includes(invetoryObj.state)) {
                invetoryObj.state = 0;
              }
            }

            if (invetoryObj.currentUser == null) {
              if (![1, 2, 3].includes(invetoryObj.state)) {
                invetoryObj.state = 0;
              }
            }
            invetoryObj.dateStr = invetoryObj.date ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.date) : invetoryObj.date;
            invetoryObj.entryDateStr = invetoryObj.entryDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.entryDate) : invetoryObj.entryDate;
            invetoryObj.purchaseStr = invetoryObj.purchase ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.purchase) : invetoryObj.purchase;
            invetoryObj.mouseDateStr = invetoryObj.mouseDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.mouseDate) : invetoryObj.mouseDate;
            invetoryObj.keyboardDateStr = invetoryObj.keyboardDate ? this.formatDatepicker.ConvertDateFormatForSave(invetoryObj.keyboardDate) : invetoryObj.keyboardDate;
            invetoryObj.state = invetoryObj.state ? Number(invetoryObj.state) : 0;
            invetoryObj.screenSize = invetoryObj.screenSize ? Number(invetoryObj.screenSize) : null;
            if (invetoryObj.id && invetoryObj.id > 0 && invetoryObj.isEdit) {
              this.inventoryService.submitUpdateInventory(invetoryObj, 0, this.saveInventoryUser);
            }
            else {
              if (invetoryObj.checkIsInuse == false || invetoryObj.checkIsInuse == undefined)
                invetoryObj.state = 0;
              else
                invetoryObj.state = 1;

              if (invetoryObj.name != undefined || invetoryObj.checkIsInuse != null) {
                invetoryObj.state = 1;
                invetoryObj.currentUser = invetoryObj.name;
              }
              this.inventoryService.submitSaveInventory(invetoryObj, 0);
            }
          }
        }
        else {
          const snack = new SnackBar(SnackBarType.success, 'No data entered for update', '', 4000);
          this.commonUIService.openSnackBar(snack);
          return;
        }
      }
    }    
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  openLaptopChangeStateAlert(event, inventoryModel) {
    if (event.checked == true) {
      this.isChecked = true;
      this.inventoryModel.checkIsInuse = true;
      this.inventoryModel.state = 1;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = 'ChangeStatus';
      this.commonUIService.openAlert(alterData);
    } else {
      this.isChecked = false;
      this.inventoryModel.checkIsInuse = false;
      this.inventoryModel.state = 0;
      this.inventoryModel.name = null;
      this.inventoryModel.currentUser = null;
      let alterData = new AlertModel();
      alterData.title = 'Warning';
      alterData.msg = 'Are you sure you want to change the status?';
      alterData.id = inventoryModel.id;
      alterData.reference = 'DontChangeStatus';
      this.commonUIService.openAlert(alterData);
    }
  }

  subscribeAlertConfirmEvents() {
    this.subscriberForAlertConfirm = this.alertService.referenceDelete.subscribe(data => {
      if (data.reference == '1' && data.selectedTabIndex == 0) {
        this.onOpenAssignLaptopsForUsersPopup();
      } else if (data.reference == '0' && data.selectedTabIndex == 0) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 0;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveLaptop(this.inventoryModel, 'status_change_laptop');
      } else if (data.reference == '2' && data.selectedTabIndex == 0) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 2;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveLaptop(this.inventoryModel, 'status_change_laptop');
      } else if (data.reference == '3' && data.selectedTabIndex == 0) {
        this.inventoryModel.checkIsInuse = false;
        this.inventoryModel.state = 3;
        this.inventoryModel.name = null;
        this.inventoryModel.currentUser = null;
        this.saveLaptop(this.inventoryModel, 'status_change_laptop');
      }
    });
  }

  onOpenAssignLaptopsForUsersPopup() {
    const dialogViewData = this.commonUIService.setDataForModalDialog(null, null, null, this.inventoryModel, null, null);
    this.commonUIService.openDialog(AddInventoryUserComponent, dialogViewData)
  }

  subscribeAlertCloseEvents() {
    this.subscriber = this.alertService.referenceClose.subscribe(data => {
      if (data === true) {
        this.inventoryService.refreshInventor.emit({ list: this.inventoryModel, tabName: "" });
      }
    });
  }

  saveKeyboard(event, inventoryModel) {
    if (inventoryModel) {
      if (event.checked == true)
        inventoryModel.isKeyboardAvailable = true;
      else
        inventoryModel.isKeyboardAvailable = false;

      this.saveLaptop(this.inventoryModel);
    }
  }

  saveMouse(event, inventoryModel) {
    if (inventoryModel) {
      if (event.checked == true)
        inventoryModel.isMouseAvailable = true;
      else
        inventoryModel.isMouseAvailable = false;

      this.saveLaptop(this.inventoryModel);
    }
  }

  changeUserName(inventoryModel) {
    if (this.inventoryModel.currentUser != inventoryModel.name || this.inventoryModel.currentUser == null) {
      this.saveInventoryUser = true;
    } else {
      this.saveInventoryUser = false;
    }
  }

  numericOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }
} 
