import { ErmService } from 'src/app/erm/service/erm.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { SnackBar, SnackBarType } from './../shared/components/snackbar/entities/snackbar';
import { lang } from 'moment';
import { ApprovalsService } from './service/approvals.service';
import {Tooltip } from 'bootstrap';
import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { HrmLeaveFilterComponent } from './hrm-leave-filter/hrm-leave-filter.component';
import { HrmLeaveRejectComponent } from './hrm-leave-reject/hrm-leave-reject.component';
import { HrmLeaveApprovalComponent } from './hrm-leave-approval/hrm-leave-approval.component';
import { prototype } from 'events';
import { resolve } from 'url';
interface Leave {
  leaveTypeText: string;
  color: string;
  uitlized: string;
  entitlement: string;
}
@Component({
  selector: 'approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
})
export class ApprovalsComponent implements OnInit {
  public calenderView : boolean;
  currentUserRoles:any;
  currentUser:any;
  isHR:any;
  leaveObj:any;
  noDays:number=0;
  isDetailsShow:boolean=false;
  selectedLeaveObj:any;
  canReject:boolean=false;
  canApprove:boolean=false;
  buttonState: boolean = false
  isSpinnerShow: boolean = false;
  calendarEvents = [];
  selectedId;
  nextSelectionDate: Date
  nextSelectionMonth: any
  nextSelectionYear: number;
  isCalenderShow:boolean=false;

  totalItems = 100; // total number of items you have
  itemsPerPage = 10; // number of items to display per page
  currentPage = 0; // current page number, 0-based
  pageSizeOptions = [5, 10, 25, 100]; // options to display in the page size select box
  leaveCardList: any = [];
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // MUST ensure `this` context is maintained
    firstDay: 1,
    events: this.calendarEvents,
    eventDidMount: function(info) {
      var tooltip = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        placement: 'top',
        trigger: 'hover',
        container: 'body'
      });
    },
    headerToolbar: {
      left: 'prev,next today',
      center: '',
      right: 'title'
    }
  };

  constructor(
    public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    private approvalsService : ApprovalsService,
    public ermService: ErmService
  ) {
    this.calenderView = false;
  }

  handleDateClick(arg) {}

  ngOnInit()
  {
    this.leaveCardList = [];
    this.setUsersParametersAndRoles();
    this.fetchLeaveInfo();
    this.setObservables();
    this.setSelectableMonth();
  }
  
  setSelectableMonth()
  {
    this.nextSelectionDate = new Date();
    this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
    this.nextSelectionYear = this.nextSelectionDate.getFullYear();
  }

  monthSelectionChange(action) {
    var today = new Date();
    if (action == 'next') {
      this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() + 1);
      if (today.getFullYear() === this.nextSelectionDate.getFullYear()) {
        this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
        this.nextSelectionYear = this.nextSelectionDate.getFullYear();
      } else { this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() - 1); }
    };
    if (action == 'prev') {
      this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() - 1);
      if (today.getFullYear() === this.nextSelectionDate.getFullYear()) {
        this.nextSelectionMonth = this.nextSelectionDate.toLocaleString('default', { month: 'long' });
        this.nextSelectionYear = this.nextSelectionDate.getFullYear();
      }else {
         this.nextSelectionDate.setMonth(this.nextSelectionDate.getMonth() + 1); 
      }
    };
  }

  pageChanged(event) {
    this.currentPage = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    // do something with the new page information, for example, fetch the corresponding data from a server

    this.fetchLeaveInfo();
  }

  loadEmployeeLeaveCard(empId, year) {
    if (empId > 0) {
      this.ermService.getEmployeeLeaveCard(empId, year).subscribe(res => {
        if (res && res.data) {
          this.leaveCardList = [];
          this.leaveCardList = res.data;
          console.log(this.leaveCardList);
        }
        else {
          this.leaveCardList = [];
        }
      });
    } else {
      this.leaveCardList = [];
    }
  }
 
  refreshCalender(empid)
  {
    this.isSpinnerShow=true;
    this.ermService
    .getHolidaysForCurrentYear(empid,true,true,0).subscribe((res) => {
      this.isSpinnerShow = false;
      if (res.data) {
        this.calendarOptions.events = res.data;
      }else{
        this.calendarOptions.events=[];
      }
    },error => {
      console.log(error)
    });
  }

  setObservables()
  {
    this.approvalsService.returnReason().subscribe((data)=>{
      this.rejectLeave(data)
    });
    this.approvalsService.returnApprove().subscribe((data)=>{
      this.approveLeave(data)
    });
    this.approvalsService.getFilterData().subscribe((data)=>{
      this.filterLeaves(data)
    });
    this.approvalsService.streamFloatingButton().subscribe((data)=>{
      this.viewLeaveCalender();
    });
  }

  setRejectButtonAvailability(selectedLeave:any)
  {
    if (this.isHR) {
      this.canReject = selectedLeave.leaveStatusId === 2 || selectedLeave.leaveStatusId===3;
      this.canApprove = selectedLeave.leaveStatusId === 2 || selectedLeave.leaveStatusId===5;
    } else {
      this.canReject = selectedLeave.leaveStatusId === 1 || selectedLeave.leaveStatusId===2;
      this.canApprove = selectedLeave.leaveStatusId === 1 || selectedLeave.leaveStatusId===4;
    }
  }

  setUsersParametersAndRoles() {
    this.currentUserRoles = [];
    const user = JSON.parse(localStorage.getItem('appUser'));
    this.currentUser=user;
    //get all roles related to user
    if (user && user.userRole.length > 0) {
      this.currentUserRoles = user.userRole.map(element => ({ roleId: element.role.id, abbreviation: element.role.abbreviation }));
    }  
      //find the role - if user has HR and PM both role consider as HR 
      if (this.currentUserRoles.some(role => role.abbreviation === 'HR')) { 
        this.isHR = true;
      } else if(this.currentUserRoles.some(role => role.abbreviation === 'PM')){
        this.isHR = false;
      }else{
        this.isHR=null;
      };
  } 

  displaySuccessMessage(message:string)
  {
    const snack = new SnackBar(SnackBarType.success, message, '', 4000);
    this.commonUIService.openSnackBar(snack);
  }

  displayErrorMessage(message:string)
  {
    const snack = new SnackBar(SnackBarType.error, message, '', 4000);
    this.commonUIService.openSnackBar(snack);
  }

  fetchLeaveInfo()
  {
    if(this.isHR!=null)
    {
      this.approvalsService.getLeaveForApprovals(this.isHR,this.currentUser.id ,this.currentPage,this.itemsPerPage).subscribe(res=>{
        if(res){
          this.leaveObj=res.data;
          this.totalItems=res.rowCount;
          this.leaveObj.sort(function(a,b){
            return a.leaveStatusId - b.leaveStatusId;
          })
        }
      },error=>{
        console.log(error);
      });
    }
  }

  noDaysCalculation(leaveDetails:any[])
  {
    if(leaveDetails == undefined || leaveDetails == null) {
      return;
    }
    let noDays = 0;
    leaveDetails.forEach(element => {
      if(element.isFullDayLeave) {
        noDays += 1;
      } else {                                                                       
        noDays += 0.5;
      }
    });
    return noDays;
  }

  leaveRowClick(leaveId:any)
  {
    this.isCalenderShow=true;
    this.isDetailsShow=true;
    this.selectedLeaveObj=this.leaveObj.find(leave=> leave.id===leaveId);
    this.selectedLeaveObj.employeeLeaveHistory.sort((a, b) => b.id - a.id);
    this.setRejectButtonAvailability(this.selectedLeaveObj);
    this.refreshCalender(this.selectedLeaveObj.employee.id)
    this.loadEmployeeLeaveCard(this.selectedLeaveObj.employee.id, (new Date()).getFullYear());
    console.log( this.selectedLeaveObj)
  }

  statusMapping(leaveStatus:any)
  {
    if(leaveStatus==null)
      return 'Pending'
    if(leaveStatus==1 || leaveStatus==2){
      return 'Pending'
    }else if(leaveStatus==3){
      return 'Approved'
    }else if(leaveStatus==4 || leaveStatus==5){
      return 'Rejected'
    }else{
      return 'Canceled'
    }
  }

  leaveHistoryMapping(leaveStatus:any)
  {
    if(leaveStatus==null)
      return ''
    if(leaveStatus==1){
      return 'Leave request submitted by'
    }else if(leaveStatus==2){
      return 'Leave request approved by PM'
    }else if(leaveStatus==3){
      return 'Leave request approved by HR'
    }else if(leaveStatus==4){
      return 'Leave request rejected by PM'
    }else if(leaveStatus==5){
      return 'Leave request rejected by HR'
    }else if(leaveStatus==6){
      return 'Leave request canceled by user'
    }
  }

  leaveHistoryClassMapping(leaveStatus:any)
  {
    if(leaveStatus==null)
      return ''
    if(leaveStatus.id==1){
      return 'grey-status-circle'
    }else if(leaveStatus.id==2){
      return 'green-status-circle'
    }else if(leaveStatus.id==3){
      return 'green-status-circle'
    }else if(leaveStatus.id==4){
      return 'red-status-circle'
    }else if(leaveStatus.id==5){
      return 'red-status-circle'
    }else if(leaveStatus.id==6){
      return 'red-status-circle'
    }
  }

  rejectLeave(reason:any)
  {
    this.approvalsService.rejectLeave(this.isHR,this.currentUser.id,this.selectedLeaveObj.id,reason.reason).subscribe(res=>{
      if (res.data) {

        this.displaySuccessMessage('Leave succesfully rejected');
        this.isDetailsShow=false;
        this.fetchLeaveInfo();
      } else {
        this.displayErrorMessage('Leave rejection failed');
      }
    },error=>{
      this.displayErrorMessage('Leave rejection fail')
      console.log(error);
    })
  }

  approveLeave(reason:any)
  {
    this.approvalsService.approveLeave(this.isHR,this.currentUser.id,this.selectedLeaveObj.id).subscribe(res=>{
      if (res.data) {
        this.displaySuccessMessage('Leave succesfully approved');
        this.isDetailsShow=false;
        this.fetchLeaveInfo();
      } else {
        this.displayErrorMessage('Leave approve failed');
      }
    },error=>{
      this.displayErrorMessage('Leave approve fail')
      console.log(error);
    })
  }

  resetList()
  {
    this.itemsPerPage = 10; // number of items to display per page
    this.currentPage = 0; // current page number, 0-based
    this.pageSizeOptions = [5, 10, 25, 100];
    this.fetchLeaveInfo();
  }

  filterLeaves(data:any)
  {
    data.authPreson=this.currentUser.id;
    data.isHR=this.isHR;
    this.approvalsService.filterApprovals(JSON.stringify(data)).subscribe(res=>{
      if(res.data.length>0)
      {
        this.leaveObj=res.data;
        this.totalItems=res.rowCount;
      }else{
        this.displayErrorMessage('No data found for the given parameters')
      }
     
    },error=>{
      console.log(error);
    })
    
  }

  openHRMLeaveFilterPopup() {
    this.commonUIService.openDialog(HrmLeaveFilterComponent);
  }

  openRejectLeavePopup() {
    this.commonUIService.openDialog(HrmLeaveRejectComponent);
  }

  openApproveLeavePopup() {
    this.commonUIService.openDialog(HrmLeaveApprovalComponent);
  }

  viewLeaveCalender() {
    this.calenderView= !this.calenderView;
  }

}
