import { Component, OnInit } from '@angular/core';
import { CommonDropdownService } from '../../../shared/services/common.dropdown.service';
import { InventoryService } from '../../service/inventoryService';
import { UIService } from '../../../shared/services/ui.service';
import { APIService } from '../../../shared/services/api.service';
import { InventoryModel } from '../../entities/inventoryModel';
import { Subscription } from 'rxjs';
import { SimmModels } from '../../entities/simm';
import { ProvidersModel } from '../../entities/providers';
import { FilterLaptopDetailModel } from '../../entities/filterLaptopDetailModel';
import API from '../../../shared/services/api.routes.json';
import { FilterLaptopDetailComponent } from '../inventory-list/filter-laptop-details/filter-laptop-details.component';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory-main.component.html',
  styleUrls: ['./inventory-main.component.scss']
})

export class InventoryMainComponent implements OnInit {
  inventorylist: InventoryModel[] = [];
  simmList: SimmModels[] = [];
  emptylist: boolean = false;
  currentId: number;
  exitSimm: boolean = false;
  private subscriber: Subscription;
  isLoading: boolean = false;
  simmCurrentId: number;
  providerCurrentId: number;
  isChecked: boolean = false;
  providerList: ProvidersModel[] = [];
  exitProvider: boolean = false;
  selectedTabIndex: number = 0;
  state: any;
  brand: string;
  cpuType: string;
  currentStorageType: string;
  ramType: string;
  modelInvent: InventoryModel;
  laptopDetailForExcelExport: FilterLaptopDetailModel;
  selectedTab: number = 0;

  constructor(public commonDropdownService: CommonDropdownService,
    public inventoryService: InventoryService,
    public commonUIservice: UIService,
    public _apiService: APIService) {
    this.inventorylist = [];
    this.simmList = [];
    this.providerList = [];
    this.modelInvent = new InventoryModel();
    this.laptopDetailForExcelExport = new FilterLaptopDetailModel();
  }

  ngOnInit() {
    this.commonDropdownService.LoadInventoryList();
    this.loadinventorylist(null, 0, null);
    this.loadAddRefeshInventor();
    this.refreshSimmUpdate();
    this.refreshProvidersUpdate();
  }

  loadAddRefeshInventor() {
    this.inventoryService.refershListInventor.subscribe(res => {
      this.loadinventorylist(res.id, this.inventoryService.slectedInventoryIndex, null);
    });
  }

  refreshSimmUpdate() {
    this.inventoryService.refreshSimmUpdate.subscribe(res => {
      this.loadinventorylist(null, 4, res.simmId);
    })
  }

  refreshProvidersUpdate() {
    this.inventoryService.refreshProvidersUpdate.subscribe(res => {
      this.loadinventorylist(null, 5, res.providerId);
    })
  }

  inventorListFilter(event) {
    let data = {
      selectedTab: event.index,
      textLabel: event.tab.textLabel
    }

    if (event.index == 6) {
      this.selectedTab = 6;
      this.inventoryService.loadFurnitureDetails.emit(data);
    } else {
      this.selectedTab = event.index;
      this.inventoryService.isMoreFilterActive = false;
      this.inventoryService.loadInventoryDetails.emit(data);
      this.inventoryService.refreshLoadKPIStatistics.emit(event.index);
    }
  }

  loadinventorylist(id, index, simProviderIds) {
    let type = index;
    this.currentId = id;
    this.simmList = null;
    this.providerList = null;
    this.isLoading = true;
    if (type == 4) {
      this._apiService.get(API.Simm.ListAll).subscribe(res => {
        if (res && res.data) {
          this.exitSimm = true;
          this.emptylist = true;
          this.inventorylist = res.data;
          this.isLoading = false;
          this.simmCurrentId = simProviderIds;
        }
        else {
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }

      })
    } else if (type == 5) {
      this._apiService.get(API.provider.listAll).subscribe(res => {
        if (res && res.data) {
          this.exitProvider = true;
          this.emptylist = true;
          this.inventorylist = res.data;
          this.isLoading = false;
          this.providerCurrentId = simProviderIds;
        }
        else {
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }
      })
    }
    else {
      this.inventoryService.getInventoryList(type).subscribe(res => {
        if (res && res.data) {
          this.exitSimm = false;
          this.exitProvider = false;
          this.inventorylist = res.data;
          this.emptylist = true;
          for (let x = 0; x < this.inventorylist.length; x++) {
            if (this.inventorylist[x].type == "LAPTOP") {
              if (this.inventorylist[x].state == 0) { this.inventorylist[x].statestr = "Available" }
              else if (this.inventorylist[x].state == 1) { this.inventorylist[x].statestr = "In use" }
              //else if (this.inventorylist[x].state == 2) { this.inventorylist[x].statestr = "Damaged" }
              //else { this.inventorylist[x].statestr = "Lost" }
            } else {
              this.inventorylist[x].statestr = null;
            }
          }
          this.isLoading = false;
        }
        else {
          this.inventorylist = null;
          this.emptylist = false;
          this.isLoading = false;
        }
      });
    }
  }

  SearchSimmByUserNumber(event) {
    var x = null;
    var y;
    if (isNaN(event.target.value)) {
      x = event.target.value;
    }
    else {
      y = event.target.value;
      y = parseInt(y);
    }
    this.inventoryService.getBySimmNumberUser(x, y).subscribe(res => {
      if (res && res.data) {
        this.inventorylist = res.data;
        this.emptylist = true;
      } else
        this.emptylist = false;
    });
  }

  selectInventory(list, event) {
    if (list.type) {
      this.simmCurrentId = null;
      this.providerCurrentId = null;
      this.currentId = list.id;
      this.inventoryService.refreshInventor.emit({ list: list, tabName: "" });
    }
    else {
      if (this.selectedTabIndex == 5) {
        this.currentId = null;
        this.simmCurrentId = null;
        this.providerCurrentId = list.id;

        for (let x = 0; x < list.length; x++) {
          list[x].type = "Providers";
        }

        this.inventoryService.refreshInventor.emit({ list: list, tabName: "Providers" });
      } else {
        this.currentId = null;
        this.providerCurrentId = null;
        this.simmCurrentId = list.id;

        for (let x = 0; x < list.length; x++) {
          list[x].type = "SIMM Card";
        }

        this.inventoryService.refreshInventor.emit({ list: list, tabName: "SIMM Card" });
      }
    }
  }

  openAddInventoryView() {
    this.currentId = null;
    this.inventoryService.addInventoryEmitter.emit({ isEdit: false, selectedTabIndex: this.selectedTabIndex });
  }

  FilterSimmByUser(event) {
    this.isChecked = event.checked;
    this._apiService.get(API.Simm.ListAll).subscribe(res => {
      if (res && res.data) {
        this.exitSimm = true;
        this.emptylist = true;
        if (this.isChecked == true) {
          this.inventorylist = res.data.filter(x => x.user === '' || x.user === null || x.user === undefined);
          if (this.inventorylist.length == 0)
            this.inventorylist = null;
        } else {
          this.inventorylist = res.data;
        }
        this.isLoading = false;
        //this.inventorylist = null;
      }
      else { this.emptylist = false; }
    })
  }

  openAddProviderSimDetails(event) {
    if (this.selectedTabIndex == 4) {
      this.currentId = null;
      this.providerCurrentId = null;
      this.simmCurrentId = null;
      this.inventoryService.refreshInventor.emit({ list: null, tabName: "SIMM Card" });
    } else {
      this.currentId = null;
      this.simmCurrentId = null;
      this.providerCurrentId = null;
      this.inventoryService.refreshInventor.emit({ list: null, tabName: "Providers" });
    }
  }

  exportLaptopDetailsAsXLSX(): void {
    this.laptopDetailForExcelExport.state = this.state;
    this.laptopDetailForExcelExport.brand = this.brand;
    this.laptopDetailForExcelExport.cpuType = this.cpuType;
    this.laptopDetailForExcelExport.currentStorageType = this.currentStorageType;
    this.laptopDetailForExcelExport.ramType = this.ramType;

    if (this.laptopDetailForExcelExport) {
      const params = new URLSearchParams();
      for (const key in this.laptopDetailForExcelExport) {
        params.set(key, this.laptopDetailForExcelExport[key])
      }
      const query = params.toString().replace('+', '%20')
      var url = API.baseUrl + `/api/Inventory/LaptopDetailExcelExport?${query}`;
      window.open(url, '_blank');
    } else {
      return;
    }
  }

  openFilterLaptopPopup() {
    this.commonUIservice.openDialog(FilterLaptopDetailComponent, null)
  }
}
