export class ProjectTask {
  id: number;
  projectId: number;
  taskName: string;
  description: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: any;
  taskDate: any;
  taskUser: string;
  userId: number;
  taskTypeId: number;
  taskType: any;

  constructor() {
    this.status = true;
  }
}
