import { Injectable, Output, EventEmitter } from '@angular/core';
import { APIService } from 'src/app/shared/services/api.service';
import { UIService } from 'src/app/shared/services/ui.service';
import { designationModel } from 'src/app/shared/entities/designationModel';
import { SnackBar, SnackBarType } from '../../shared/components/snackbar/entities/snackbar';

@Injectable({
  providedIn: 'root'
})

export class EstimationService {
  constructor(
    private apiservice: APIService,
    public commonUIService: UIService
  ) { }  
} 
