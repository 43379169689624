import { Component, OnInit, Input } from '@angular/core';
import { InventoryRepair } from 'src/app/inventory/entities/inventoryRepair';
import { InventoryService } from 'src/app/inventory/service/inventoryService';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { UIService } from 'src/app/shared/services/ui.service';
import { AddInventoryRepairComponent } from './add-inventory-repair/add-inventory-repair.component';

@Component({
  selector: 'app-inventory-repair',
  templateUrl: './inventory-repair.component.html',
  styleUrls: ['./inventory-repair.component.scss']
})
export class InventoryRepairComponent implements OnInit {
  @Input() selectedInventoryId:any;
  inventoryRepairList:InventoryRepair[]=[];
  isLoading: boolean = false;

  constructor(private inventoryService: InventoryService,
    private commonUIService: UIService) { 
    this.inventoryRepairList = [];
  }

  ngOnInit() {
    this.subscribeEventEmiiter();
  }
  ngOnChanges(){
    if(this.selectedInventoryId > 0) this.loadInventoryRepairList(this.selectedInventoryId);
  }

  loadInventoryRepairList(inventoryId){
    this.isLoading = true;
    this.inventoryService.getInventoryRepairListByInventoryId(inventoryId).subscribe(res => {
      if(res && res.isSuccsess && res.data){
        this.inventoryRepairList = res.data;
        this.isLoading = false;
      }
      else{
        const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
        this.isLoading = false;
      }
    })
  } 
  openAddRepairPopup(){
    const dialogViewData = this.commonUIService.setDataForModalDialog(this.selectedInventoryId, null, null, null, null, null);
    this.commonUIService.openDialog(AddInventoryRepairComponent, dialogViewData);
  } 
  subscribeEventEmiiter(){
    this.inventoryService.refreshInventoryRepair.subscribe(res => {
      if(res) this.loadInventoryRepairList(this.selectedInventoryId);
    })
  }  
}
