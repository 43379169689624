import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { empty } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ValidationService {

constructor() { }

public noWhitespaceValidator(control: FormControl) 
{
   const isWhitespace = (control.value || '').trim().length === 0;
   const isValid = !isWhitespace;
   return  isValid ? null : { 'whitespace': true };
}

public isLightColor(control: FormControl ) {
   const isLightColor = (control.value || '').trim().length === 0;
   const isValid = isLightColor;

   if(control.value){

     var c = control.value.substring(1);      // strip #
     var rgb = parseInt(c, 16);   // convert rrggbb to decimal
     var r = (rgb >> 16) & 0xff;  // extract red
     var g = (rgb >> 8) & 0xff;  // extract green
     var b = (rgb >> 0) & 0xff;  // extract blue
 
     var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
 
     if (luma > 130) {
      return isValid ? null : { 'lightColor': true };
     }
   }
 }

public nullValueSave(arry,objectPass)
{
   var x=arry.length;
   for(var i=0;i<x;i++)
   {
     var y=arry[i];   
     if(objectPass[y]!=null)  {
           if( !objectPass[y].trim().length)
           {
            objectPass[y]=null;
           }
   }}
   return objectPass
}  

checkLimit(min: number, max: number): ValidatorFn {
   return (c: AbstractControl): { [key: string]: boolean } | null => {    
       if (c.value && (isNaN(c.value) || c.value.length < min)) {
           return { 'minlength': true };
       }
       if (c.value && (isNaN(c.value) || c.value.length > max)) {
         return { 'maxlength': true };
     }
       return null;
   };
}

  trimStartOnClick(event:any)
  {   
    event.target.value = event.target.value.toString().trimStart();
  }
}
