import { TemplateCategory } from './templateCategory';
import { TemplateSubcategory } from './templateSubcategory';

export class Template {
  id: number;
  categoryId: number;
  subcategoryId: number;
  name: string;
  espsubject: string;
  esptext: string;
  catsubject: string;
  cattext: string;
  engsubject: string;
  engtext: string;
  main: boolean;

  isEdit: boolean;
  isDelete: boolean;
  isSave: boolean;
  isAdd: boolean;
  isShow: boolean;

  category: TemplateCategory;
  subcategory: TemplateSubcategory;
}
