export class LoginModel{
    id: string;
    name: string;
    email: string;
    photoUrl: string;
    firstName: string;
    lastName: string;
    authToken: string;
    idToken: string;
    provider: string;

    constructor(id, firstName, lastName, email, photourl, authToken, idToken){
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.photoUrl = photourl;
        this.authToken = authToken;
        this.idToken = idToken;
    }
  }