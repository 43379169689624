import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { CRMDocumentTypeComponent } from './crm-document-type.component';
import { ViewCRMDocumentTypeComponent } from './view-crm-document-type/view-crm-document-type.component';
import { AddEditCRMDocumentTypeComponent } from './view-crm-document-type/add-edit-crm-document-type/add-edit-crm-document-type.component';

@NgModule({
  declarations: [
    CRMDocumentTypeComponent,
    ViewCRMDocumentTypeComponent,
    AddEditCRMDocumentTypeComponent
  ],
  imports: [RouterModule, SharedModule, AngularMaterialModule],
  entryComponents: [CRMDocumentTypeComponent, AddEditCRMDocumentTypeComponent]
})

export class CRMDocumentTypeModule {
}
