import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientService } from 'src/app/client/services/client.service';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ActionClient } from 'src/app/client/entities/actionClient';
import { isNumber } from 'util';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { Subscription, empty } from 'rxjs';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Users } from '../../../../auth/entities/user';
import { AuthAppService } from 'src/app/shared/services/auth-app.service';
import { TaskService } from 'src/app/client/services/task.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-edit-action',
  templateUrl: './add-edit-action.component.html',
  styleUrls: ['./add-edit-action.component.scss']
})

export class AddEditActionComponent implements OnInit {
  //region Variables
  actionFormGroup: FormGroup;
  _actionModal: ActionClient;
  _actionModalForSave: any;
  isEdit: boolean = false;
  isDisabledAction = false;
  _loggedInUser: Users = new Users();
  _taskCreationType: string = "";
  maxDate: Date = new Date();
  _actionTypeListForIcon: any = [];
  _selectedActionTypeId: any;
  _selectedActionTypeName: string;
  isTypeRequired: boolean = false;
  minDate: Date = new Date();
  _selectedTaskTypeId = 0;
  _selectedTaskTypeName = "";
  @ViewChild('pickerTaskDate', { read: null, static: true }) pickerTaskDate: MatDatepicker<Date>;
  doneTaskId: number = 0;
  isNextTaskUserRequired: boolean = false;
  isNextTaskDateRequired: boolean = false;
  loggedUserId: any;
  subscriber: Subscription;
  @ViewChild('description', { static: false }) descriptionField: ElementRef;
  @ViewChild('comment', { static: false }) commentField: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private formatDatepicker: FormatMatDatepicker,
    public commonDropdownService: CommonDropdownService,
    private validationService: ValidationService,
    public dialogRef: MatDialogRef<AddEditActionComponent>,
    public commonUIService: UIService,
    private authAppService: AuthAppService,
    public cdRef: ChangeDetectorRef,
    public taskService: TaskService,
    private alertService: AlertService) {
    this._actionModal = new ActionClient();
  }
  //endregion

  ngOnInit() {
    this.initActionFormGroup();  
    this.commonDropdownService.LoadUserList();
    this.commonUIService.loadTaskDateLabels();
    this.setActionClientDataForEditView();  
    this.taskService.LoadTaskTypeListForIcon();
    this.subscribeTaskDoneConfirmEvents();
    this.commonUIService.closeMask();  
  }

  initActionFormGroup() {
    this.actionFormGroup = this._formBuilder.group({
      actionTypeId: ['', [Validators.required]],
      actionDate: ['', [Validators.required]],
      comment: [''],
      userId: [''],
      actionTypeName: ['', [Validators.required]],
      doneTaskId: [''],
      taskDate: { disabled: true, value: '' },
      taskUserId: [''],
      status: [''],
      taskTypeId: [''],
      taskTime: [''],
      description: [''],
      taskTypeName: [''],
      author: ['']
    });
  }

  setActionClientDataForEditView(): void {
    if (this.modalViewData.data) {
      this._actionModal = Object.assign({}, this.modalViewData.data.modalData);
      this._selectedActionTypeId = this._actionModal.actionTypeId;
      this.taskService.selectedDateId = 0;
      this.loggedUserId = this._actionModal && this._actionModal.loggedUserId ? this._actionModal.loggedUserId : null;
      this.doneTaskId = this._actionModal && this._actionModal.doneTaskId ? this._actionModal.doneTaskId : null;
      this._actionModal.nextTaskUserId = !this._actionModal.nextTaskUserId ? this.loggedUserId : this._actionModal.nextTaskUserId;

      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = false;
        this._taskCreationType = this._actionModal ? this._actionModal.text : null;
        this._selectedActionTypeName = this._actionModal.actionTypeName ? this._actionModal.actionTypeName : null;
      } else {
        this.isEdit = true;
        this._selectedActionTypeName = this._actionModal.actionType.actionType1;
      }
    }
  }

  async submit(actionModal) {
    if (this.actionFormGroup.invalid) {
      this.isDisabledAction = false;
      this.isTypeRequired = !!(this.actionFormGroup.get('actionTypeId').errors && this.actionFormGroup.get('actionTypeId').errors.required && this.actionFormGroup.get('actionTypeName').errors.required);
      return;
    }
    this.ValidateNextTask(actionModal);
    if (this.isNextTaskUserRequired || this.isNextTaskDateRequired) return;
    let actionModalObj = Object.assign({}, actionModal);
    actionModalObj.actionDateStr = actionModalObj.actionDate ? this.formatDatepicker.ConvertDateFormatForSave(actionModalObj.actionDate) : actionModalObj.actionDate;
    actionModalObj.nextTaskDateStr = actionModalObj.nextTaskDate ? this.formatDatepicker.ConvertDateFormatForSave(actionModalObj.nextTaskDate) : actionModalObj.nextTaskDate;
    if (this.isEdit) {
      this.updateClientActionData(actionModal.id, actionModalObj);
    } else {
      this.SaveActionClientData(actionModalObj);
    }
  }

  ValidateNextTask(actionModal) {
    if (actionModal.nextTaskTypeId && actionModal.nextTaskTypeId > 0) {
      this.isNextTaskUserRequired = !actionModal.nextTaskUserId;
      this.isNextTaskDateRequired = !actionModal.nextTaskDate;
    }
  }

  SaveActionClientData(actionModalObj) {
    this.isDisabledAction = true;
    this._loggedInUser = this.authAppService.getLoggedInUser();
    this.BindToModel(actionModalObj);
    this.clientService.SaveClientAction(this._actionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          this.clientService.refreshClientAction.emit(res.data);
          this.clientService.refreshClientTask.emit('AfterAction');
          this.closeDialog();
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 2000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => {
        console.error(); this.closeDialog();
      });
  }

  updateClientActionData(actionModalId, actionModalObj) {
    this.BindToModel(actionModalObj);
    this.clientService.updateClientAction(actionModalId, this._actionModalForSave).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated', '', 2000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.clientService.refreshClientAction.emit(res.data);
        }
      },
      () => {
        console.error();
        this.closeDialog();
      });
  }

  BindToModel(actionModalObj) {
    this._actionModalForSave = Object.assign({}, null);
    this._actionModalForSave.clientId = this.isEdit ? actionModalObj.clientId : this.modalViewData.data.id;
    this._actionModalForSave.actionName = actionModalObj.actionName;
    this._actionModalForSave.type = actionModalObj.type;
    this._actionModalForSave.userId = actionModalObj.userId;
    this._actionModalForSave.comment = actionModalObj.comment;
    this._actionModalForSave.actionDate = actionModalObj.actionDate;
    this._actionModalForSave.actionDateStr = actionModalObj.actionDateStr;
    this._actionModalForSave.status = 1;
    this._actionModalForSave.actionTypeId = actionModalObj.actionTypeId;
    this._actionModalForSave.id = this.isEdit ? actionModalObj.id : 0;
    this._actionModalForSave.author = actionModalObj.author;
    this._actionModalForSave.doneTaskId = actionModalObj.doneTaskId;
    this._actionModalForSave.nextTaskTypeId = actionModalObj.nextTaskTypeId;
    this._actionModalForSave.nextTaskDate = actionModalObj.nextTaskDate;
    this._actionModalForSave.nextTaskUserId = actionModalObj.nextTaskUserId;
    this._actionModalForSave.nextTaskTime = actionModalObj.nextTaskTime;
    this._actionModalForSave.nextTaskDescription = actionModalObj.nextTaskDescription;
    this._actionModalForSave.nextTaskDateStr = actionModalObj.nextTaskDateStr;
    this._actionModalForSave.nextTaskTimeStr = actionModalObj.nextTaskTimeStr;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getPreviousDateForClientAction() {
    let yesterday = new Date(this._actionModal.actionDate);
    yesterday.setDate(yesterday.getDate() - 1);
    this._actionModal.actionDate = yesterday;
  }

  LoadActionTypeListForIcon() {
    this.commonDropdownService.LoadActionTypeListForHeader().subscribe(data => {
      if (data) {
        this._actionTypeListForIcon = data;
        if (this._actionTypeListForIcon.length > 0) {
          this._actionTypeListForIcon.forEach(ele => {
            ele.actionType1 = ele.actionType1.trim();
          });
        }
      }
    });
  }

  selectActionType(actionType) {
    this._actionModal.actionTypeId = actionType.id;
    this._selectedActionTypeId = actionType.id;
    this._selectedActionTypeName = actionType.actionType1;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.commentField.nativeElement.focus();
    }, 100);
  }

  setDateValueToPicker(item) {
    this._actionModal.nextTaskDate = this.taskService.setDateValueToPicker(item);
    this.isNextTaskDateRequired = !(this._actionModal.nextTaskDate && this._actionModal.nextTaskDate != null);
  }

  selectTaskType(taskType) {
    this._actionModal.nextTaskTypeId = taskType.id;
    this._actionModal.nextTaskUserId = !this._actionModal.nextTaskUserId ? this.loggedUserId : this._actionModal.nextTaskUserId;
    this._selectedTaskTypeId = taskType.id;
    this._selectedTaskTypeName = taskType.taskTypeName;
    this.isTypeRequired = false;
    setTimeout(() => {
      this.descriptionField.nativeElement.focus();
    }, 100);
  }

  userSelect() {
    this.isNextTaskUserRequired = !(this._actionModal.nextTaskUserId && this._actionModal.nextTaskUserId != null);
  }

  clearSelectedTaskType() {
    this._actionModal.nextTaskTypeId = 0;
    this._actionModal.nextTaskUserId = 0;
    this._actionModal.nextTaskDescription = null;
    this._actionModal.nextTaskDate = null;
    this._actionModal.nextTaskTimeStr = null;
    this._selectedTaskTypeId = 0;
    this._selectedTaskTypeName = "";
    this.isNextTaskDateRequired = false;
    this.isNextTaskUserRequired = false;
    this.taskService.selectedDateId = null;
  }

  OnAuthorChange(event) {
    if (event) {
      this._actionModal.author = event.source.selected ? event.source.selected.viewValue : null;
    }
  }

  cancelAction() {
    if (this.doneTaskId && this.doneTaskId > 0) this.openConfirmationAlert();
    else this.closeDialog();
  }

  openConfirmationAlert() {
    const data = new AlertModel();
    data.title = '';
    data.msg = 'Task is done?';
    data.id = this.doneTaskId;
    data.reference = 'TaskDone';
    data.alertType = 'Confirm';
    this.commonUIService.openAlert(data);
  }

  subscribeTaskDoneConfirmEvents() {
    this.subscriber = this.alertService.referenceConfirm.subscribe(data => {
      if (data && this.doneTaskId && data.id && data.id > 0 && data.isCancel) this.revertClientTaskStatus(this.doneTaskId);
      else this.closeDialog();
    })
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
    this.doneTaskId = null;
    this.cdRef.detectChanges = empty;
  }

  revertClientTaskStatus(doneTaskId) {
    this.commonUIService.openMask();
    this.clientService.changeClientTaskStatus(doneTaskId, 1).subscribe(
      res => {
        if (res.data === true) {
          const snack = new SnackBar(SnackBarType.success, 'Task done reverted', '', 5000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.clientService.refreshClientTask.emit(true);
          this.clientService.clientProjectTaskUpdate.emit(null);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on task revert', '', 5000);
          this.commonUIService.openSnackBar(snack);
        }
        this.commonUIService.closeMask();
      });
  }
}
