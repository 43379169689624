import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APIService } from './services/api.service';
import { UIService } from './services/ui.service';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { HeaderComponent } from './components/header/header.component';
import { AngularMaterialModule } from '../angular-material.module';
import { RouterModule } from '@angular/router';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AuthAppService } from './services/auth-app.service';
import { MangoCurrencyPipe } from './pipes/currency.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    SnackbarComponent, HeaderComponent, DateFormatPipe, AlertPopupComponent, MangoCurrencyPipe
  ],
  imports: [
    HttpClientModule,
    AngularMaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    NgxTippyModule
  ],
  providers: [
    APIService,
    UIService,
    AuthAppService
  ],
  exports: [HeaderComponent,
    DateFormatPipe,
    FormsModule,
    ReactiveFormsModule,
    MangoCurrencyPipe
  ],
  entryComponents: [
    SnackbarComponent
  ]
})

export class SharedModule {
}
