import { SafeResourceUrl } from '@angular/platform-browser';

export class EmployeeDocument {
  id: number;
  employeeId: number;
  date: any;
  documentTypeId: number;
  documentName: string;
  generatedFileName: string;
  fileName: string;
  insertDate: any;
  insertUser: string;
  updateDate: any;
  updateUser: string;
  status: boolean;
  file: any;
  fileList: any;
  extension: string = "";
  url: string;
  link: string;
  safeUrl: SafeResourceUrl;
  documentType: string;
  documentGroupId:number;
  dateStr: any; 
  nic:any;
  birthCertificate:any;
  resume:any;
 }
