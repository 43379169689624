import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMaterialModule } from '../angular-material.module';
import { CommonModule } from '@angular/common';
import { InventoryMaintenanceComponent } from './inventory-maintenance.component';
import { LaptopStorageComponent } from './laptop-storage/laptop-storage.component';
import { LaptopScreenSizeComponent } from './laptop-screen-size/laptop-screen-size.component';
import { LaptopScreenResolutionComponent } from './laptop-screen-resolution/laptop-screen-resolution.component';
import { LaptopCPUComponent } from './laptop-cpu/laptop-cpu.component';
import { LaptopOSComponent } from './laptop-os/laptop-os.component';
import { LaptopMouseTypeComponent } from './laptop-mouse-type/laptop-mouse-type.component';
import { LaptopKeyboardTypeComponent } from './laptop-keyboard-type/laptop-keyboard-type.component';
import { MobileDeviceTypeComponent } from './mobile-device-type/mobile-device-type.component';
import { MobileDeviceOSComponent } from './mobile-device-os/mobile-device-os.component';
import { MonitorTypeComponent } from './monitor-type/monitor-type.component';
import { MonitorScreenSizeComponent } from './monitor-screen-size/monitor-screen-size.component';
import { MonitorResolutionComponent } from './monitor-resolution/monitor-resolution.component';
import { MonitorOSComponent } from './monitor-os/monitor-os.component';
import { MiscellaneousTypeComponent } from './miscellaneous-type/miscellaneous-type.component';
import { CommonConditionComponent } from './common-condition/common-condition.component';
import { AddEditCommonconditionComponent } from './common-condition/add-edit-commoncondition/add-edit-commoncondition.component';
import { AddEditLaptopcpuComponent } from './laptop-cpu/add-edit-laptopcpu/add-edit-laptopcpu.component';
import { AddEditLaptopmousetypeComponent } from './laptop-mouse-type/add-edit-laptopmousetype/add-edit-laptopmousetype.component';
import { AddEditLaptoposComponent } from './laptop-os/add-edit-laptopos/add-edit-laptopos.component';
import { AddEditLaptopscreenresolutionComponent } from './laptop-screen-resolution/add-edit-laptopscreenresolution/add-edit-laptopscreenresolution.component';
import { AddEditLaptopscreensizeComponent } from './laptop-screen-size/add-edit-laptopscreensize/add-edit-laptopscreensize.component';
import { AddEditLaptopstorageComponent } from './laptop-storage/add-edit-laptopstorage/add-edit-laptopstorage.component';
import { AddEditMiscellaneoustypeComponent } from './miscellaneous-type/add-edit-miscellaneoustype/add-edit-miscellaneoustype.component';
import { AddEditMobiledeviceosComponent } from './mobile-device-os/add-edit-mobiledeviceos/add-edit-mobiledeviceos.component';
import { AddEditMobiledevicetypeComponent } from './mobile-device-type/add-edit-mobiledevicetype/add-edit-mobiledevicetype.component';
import { AddEditMonitorosComponent } from './monitor-os/add-edit-monitoros/add-edit-monitoros.component';
import { AddEditMonitorresolutionComponent } from './monitor-resolution/add-edit-monitorresolution/add-edit-monitorresolution.component';
import { AddEditMonitorscreensizeComponent } from './monitor-screen-size/add-edit-monitorscreensize/add-edit-monitorscreensize.component';
import { AddEditLaptopkeyboardtypeComponent } from './laptop-keyboard-type/add-edit-laptopkeyboardtype/add-edit-laptopkeyboardtype.component';
import { AddEditMonitortypeComponent } from './monitor-type/add-edit-monitortype/add-edit-monitortype.component';

@NgModule({
  declarations: [
    InventoryMaintenanceComponent,
    LaptopStorageComponent,
    LaptopScreenSizeComponent,
    LaptopScreenResolutionComponent,
    LaptopCPUComponent,
    LaptopOSComponent,
    LaptopMouseTypeComponent,
    LaptopKeyboardTypeComponent,
    MobileDeviceTypeComponent,
    MobileDeviceOSComponent,
    MonitorTypeComponent,
    MonitorScreenSizeComponent,
    MonitorResolutionComponent,
    MonitorOSComponent,
    MiscellaneousTypeComponent,
    CommonConditionComponent,
    AddEditCommonconditionComponent,
    AddEditLaptopcpuComponent,
    AddEditLaptopmousetypeComponent,
    AddEditLaptoposComponent,
    AddEditLaptopscreenresolutionComponent,
    AddEditLaptopscreensizeComponent,
    AddEditLaptopstorageComponent,
    AddEditMiscellaneoustypeComponent,
    AddEditMobiledeviceosComponent,
    AddEditMobiledevicetypeComponent,
    AddEditMonitorosComponent,
    AddEditMonitorresolutionComponent,
    AddEditMonitorscreensizeComponent,
    AddEditLaptopkeyboardtypeComponent,
    AddEditMonitortypeComponent,
  ],
  imports: [
    RouterModule,
    SharedModule,
    ColorPickerModule,
    AngularMaterialModule,
    CommonModule,
    BrowserModule
  ],
  entryComponents: [
    InventoryMaintenanceComponent,
    AddEditCommonconditionComponent,
    AddEditLaptopcpuComponent,
    AddEditLaptopmousetypeComponent,
    AddEditLaptoposComponent,
    AddEditLaptopscreenresolutionComponent,
    AddEditLaptopscreensizeComponent,
    AddEditLaptopstorageComponent,
    AddEditMiscellaneoustypeComponent,
    AddEditMobiledeviceosComponent,
    AddEditMobiledevicetypeComponent,
    AddEditMonitorosComponent,
    AddEditMonitorresolutionComponent,
    AddEditMonitorscreensizeComponent,
    AddEditLaptopkeyboardtypeComponent,
    AddEditMonitortypeComponent,
  ]
})

export class InventoryMaintenanceModule {

}
