import { Component, OnInit, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { UIService, DialogDataForModalView } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormatMatDatepicker, FormatTimeForDisplay } from 'src/app/shared/format-datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ClientService } from '../../../services/client.service';
import { SnackBar, SnackBarType } from '../../../../shared/components/snackbar/entities/snackbar';
import moment from 'moment';
import { EstimationPhase } from '../../../entities/estimationPhase';
import { EstimationPhaseModel } from '../../../entities/estimationPhaseModel';
import { Estimation } from '../../../entities/estimation';
import { isNumber } from 'util';
import { ClientViewService } from '../../../services/client-view.service';
import { AddEstimationOptionComponent } from '../add-estimation-option/add-estimation-option.component';
import { ProjectViewService } from '../../../services/project-view.service';
import { EstimationOption } from '../../../entities/estimationOption';
import API from '../../../../shared/services/api.routes.json';
import { EstimationDocument } from '../../../entities/estimationDocument';
import { Users } from '../../../../auth/entities/user';
import { AuthAppService } from '../../../../shared/services/auth-app.service';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'add-estimation-phase',
  templateUrl: './add-estimation-phase.component.html',
  styleUrls: ['./add-estimation-phase.component.scss']
})

export class AddEstimationPhaseComponent implements OnInit {

  isDisabledEstimationPhase = true;
  estimationPhaseFormGroup: FormGroup;
  estimationFormGroup: FormGroup;
  estimationPhaseModelFormGroup: FormGroup;
  isEdit: boolean = false;
  private _unsubscribeAll: Subject<any>;
  estimationPhase: EstimationPhase;
  fileList: any;
  estimationDocumentList: any[] = [];
  isLoading_document: boolean = false;
  estimationPhaseModal: EstimationPhase;
  showAddEstimation: boolean = false;
  estimationModal: Estimation;
  showQualitApps: boolean = false;
  showClient: boolean = false;
  estimationOptionList: any[] = [];
  estimationOptionModal: EstimationOption;
  isLoading_option: boolean = false;
  estimationPhaseId: number;
  estimationDocument: EstimationDocument;
  loggedUser: Users = new Users();
  estDocFileListForSave: any[] = [];
  estDocFileListForUpload: any[] = [];
  isMaximumHoursHigher: boolean = false;
  isMaximumBudgetHigher: boolean = false;
  invalidMaximumHours: string = null;
  invalidMaximumBudget: string = null;
  currentProjectId: number;
  successCount: number = 3;
  currentEstimationOption:any;

  constructor(public commonDropdownService: CommonDropdownService,
    public commonUIService: UIService,
    public _apiService: APIService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    private clientService: ClientService,
    public router: Router,
    public clientViewService: ClientViewService,
    private projectViewService: ProjectViewService,
    private formatTimeForDisplay: FormatTimeForDisplay,
    private authAppService: AuthAppService,
    public dialogRef: MatDialogRef<AddEstimationPhaseComponent>,
    @Inject(MAT_DIALOG_DATA) public currentconpanyData: DialogDataForModalView,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    public alertService: AlertService,) {
    this._unsubscribeAll = new Subject();
    this.estimationPhaseModal = new EstimationPhase();
    this.estimationModal = new Estimation();
    this.estimationOptionModal = new EstimationOption();
    this.estimationDocument = new EstimationDocument();
  }

  ngOnInit() {
    this.currentProjectId = this.modalViewData.data.projectId;
    this.initEstimationFormGroup();
    this.initEstimationPhaseFormGroup();
    if (this.modalViewData.data.type == 'add-phase') {
      this.setEstimationDataForEditView();
    } else if (this.modalViewData.data.type == 'edit-phase') {
      this.setEstimationPhaseDataForEditView();
    }
    this.commonDropdownService.LoadUserList();
    this.commonDropdownService.ListAllPhaseContactList();
    this.subscribeEstimationOptionData();
    this.commonDropdownService.LoadClientContactsByClientId(this.clientViewService.selectedClient.id);
    this.getLoggedinUser();
    this.subscribeEstimationOptionDelete();
  }

  getLoggedinUser() {
    this.loggedUser = this.authAppService.getLoggedInUser();
  }

  initEstimationFormGroup() {
    this.estimationFormGroup = this._formBuilder.group({
      title: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      description: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      type: ['', [Validators.required]],
      state: [''],
      estimationHours: [''],
      maxEstimationHours: [''],
      budgetHours: [''],
      maxBudgetHours: [''],
      totalBudget: [''],
      totalMaxBudget: [''],
      date: ['']
    });
  }

  initEstimationPhaseFormGroup() {
    this.estimationPhaseFormGroup = this._formBuilder.group({
      state: [''],
      type: [''],
      userType: [''],
      userId: [''],
      contactId: [''],
      date: [''],
      concept: [''],
      hours: [''],
      maxHours: [''],
      hourFee: [''],
      budget: [''],
      maxBudget: ['']
    });
  }

  setEstimationDataForEditView(): void {
    if (this.modalViewData.data) {
      this.estimationModal = Object.assign({}, this.modalViewData.data.modalData);
      if (isNumber(this.modalViewData.data.modalData.id)) {
        this.estimationModal.type = this.modalViewData.data.modalData.type.toString();
        this.estimationModal.state = this.modalViewData.data.modalData.state.toString();
        this.estimationModal.clientId = this.clientViewService.selectedClient.id;
        this.estimationPhaseModal.type = "1";
        this.estimationPhaseModal.estimationId = this.modalViewData.data.modalData.id;
        let today = moment();
        let date = moment(today);
        this.estimationPhaseModal.date = date;
        this.loadEstimationById(this.estimationPhaseModal.estimationId);
      }
    }
  }

  setEstimationPhaseDataForEditView(): void {
    if (this.modalViewData.data.id) {
      this.estimationPhaseModal = Object.assign({}, this.modalViewData.data.modalData);
      this.estimationPhaseModal.estimationId = this.modalViewData.data.modalData.estimationId;
      this.getEstimationById(this.estimationPhaseModal.estimationId);
      if (isNumber(this.modalViewData.data.id)) {
        this.isEdit = true;
        if (this.modalViewData.data.modalData.type != null)
          this.estimationPhaseModal.type = this.modalViewData.data.modalData.type.toString();
        if (this.modalViewData.data.modalData.state != null)
          this.estimationPhaseModal.state = this.modalViewData.data.modalData.state.toString();
        if (this.modalViewData.data.modalData.userType != null)
          this.estimationPhaseModal.userType = this.modalViewData.data.modalData.userType.toString();
        if (this.estimationPhaseModal.userId != null) {
          this.showQualitApps = true;
          this.showClient = false;
        }
        if (this.estimationPhaseModal.contactId != null) {
          this.showQualitApps = false;
          this.showClient = true;
        }
        if (this.modalViewData.data.modalData.estimationOption.length > 0) {
          this.estimationOptionList = this.modalViewData.data.modalData.estimationOption;
        } else {
          this.estimationOptionList = [];
        }
        if (this.modalViewData.data.modalData.estimationDocument.length > 0) {
          this.estimationDocumentList = this.modalViewData.data.modalData.estimationDocument;
          if (this.estimationDocumentList.length > 0) {
            this.estimationDocumentList.forEach(document => {
              document.extension = document.fileName.split('.')[(document.fileName.split('.')).length - 1];
              document.link = API.baseUrl + API.estimationDocument.getEstimationDocument + document.id + '/' + document.generatedFileName;
              if (document.url) {
                if (document.extension === 'pdf')
                  document.url = document.url.toString().includes('data:application/pdf;base64,') ? document.url : 'data:application/pdf;base64,' + document.url;
                if (document.extension === 'png')
                  document.url = document.url.toString().includes('data:image/png;base64,') ? document.url : 'data:image/png;base64,' + document.url;
                if (document.extension === 'jpg')
                  document.url = document.url.toString().includes('data:image/jpg;base64,') ? document.url : 'data:image/jpg;base64,' + document.url;
                if (document.extension === 'jpeg')
                  document.url = document.url.toString().includes('data:image/jpeg;base64,') ? document.url : 'data:image/jpeg;base64,' + document.url;
                if (document.extension === 'docx')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
                if (document.extension === 'xlsx' || document.extension === 'xls')
                  document.url = document.url.toString().includes('https://docs.google.com/gview?url=') ? document.url : 'https://docs.google.com/gview?url=' + document.link + '&embedded=true';
              }
            });
          }
        } else
          this.estimationDocumentList = [];
      } else {
        this.isEdit = false;
        this.estimationDocumentList = [];
      }
    } else {
      this.isEdit = false;
      this.estimationDocumentList = [];
    }
  }

  getEstimationById(id) {   
    if (id) {
      this.clientService.getEstimationById(id).subscribe(res => {
        if (res && res.data) {
          this.estimationModal = res.data;
          this.estimationModal.projectId = res.data.projectId;
          this.estimationModal.insertUser = this.loggedUser.name;
          this.estimationModal.updateUser = this.loggedUser.name;
          this.estimationModal.type = this.estimationModal.type ? this.estimationModal.type.toString() : null;
          this.estimationModal.state = this.estimationModal.state ? this.estimationModal.state.toString() : null;
        }
      });
    }
  }

  saveEstimationPhase() {
    this.isMaximumBudgetHigher = false;
    this.isMaximumHoursHigher = false;
    this.isDisabledEstimationPhase = false;

    let estimationObj = Object.assign({}, this.estimationModal);
    let estimationPhaseObj = Object.assign({}, this.estimationPhaseModal);

    estimationObj.projectId = this.modalViewData.data.projectId;
    this.currentProjectId = this.modalViewData.data.projectId;
    estimationObj.clientId = this.clientViewService.selectedClient.id;
    estimationObj.dateStr = estimationObj.date ? this.formatDatepicker.ConvertDateFormatForSave(estimationObj.date) : estimationObj.date;
    estimationObj.insertUser = this.loggedUser.name;
    estimationObj.updateUser = this.loggedUser.name;

    estimationPhaseObj.insertUser = this.loggedUser.name;
    estimationPhaseObj.updateUser = this.loggedUser.name;
    estimationPhaseObj.dateStr = estimationPhaseObj.date ? this.formatDatepicker.ConvertDateFormatForSave(estimationPhaseObj.date) : estimationPhaseObj.date;

    if (estimationPhaseObj.hours != null)
      estimationPhaseObj.hours = +estimationPhaseObj.hours;
    if (estimationPhaseObj.maxHours != null)
      estimationPhaseObj.maxHours = +estimationPhaseObj.maxHours;
    if (estimationPhaseObj.budget != null)
      estimationPhaseObj.budget = +estimationPhaseObj.budget;
    if (estimationPhaseObj.maxBudget != null)
      estimationPhaseObj.maxBudget = +estimationPhaseObj.maxBudget;

    if (this.estimationPhaseFormGroup.invalid) {
      this.isDisabledEstimationPhase = true;
      Object.keys(this.estimationPhaseFormGroup.controls).forEach(field => {
        const control = this.estimationPhaseFormGroup.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      return;
    } else {
      if (this.estimationFormGroup.invalid) {
        this.isDisabledEstimationPhase = true;
        Object.keys(this.estimationFormGroup.controls).forEach(field => {
          const control = this.estimationFormGroup.get(field);
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
        return;
      } else {
        if (estimationPhaseObj.hours != null && estimationPhaseObj.maxHours != null &&
          estimationPhaseObj.hours > estimationPhaseObj.maxHours) {
          this.isMaximumHoursHigher = true;
        } else {
          this.isMaximumHoursHigher = false;
        }

        if (estimationPhaseObj.budget != null && estimationPhaseObj.maxBudget != null &&
          estimationPhaseObj.budget > estimationPhaseObj.maxBudget) {
          this.isMaximumBudgetHigher = true;
        } else {
          this.isMaximumBudgetHigher = false;
        }

        if (this.isMaximumHoursHigher == true || this.isMaximumBudgetHigher == true) {
          this.isDisabledEstimationPhase = true;
          return;
        } else {
          if (this.isEdit) {
            this.updateEstimationPhaseData(estimationPhaseObj.id, estimationPhaseObj);
            this.updateEstimationData(estimationObj.id, estimationObj);
          }
          else {
            this.saveEstimationPhaseData(estimationPhaseObj);
            this.updateEstimationData(estimationObj.id, estimationObj);
          }
        }
      }
    }
  }

  saveEstimationPhaseData(estimationPhaseObj) {  
    this.clientService.addEstimationPhase(estimationPhaseObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEstimationPhase = true;
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved Estimation Phase', '', 4000);
          this.commonUIService.openSnackBar(snack);
          //add estimation document
          if (this.estimationDocumentList.length > 0) {
            this.onSaveEstimationDocument(res.data);
          } else {
            this.successCount = this.successCount - 1;
            this.emitRefreshEstimationList();
          }
          //add estimation option
          if (this.estimationOptionList.length > 0) {
            this.estimationOptionList.forEach(es => {
              es.estimationPhaseId = res.data.id;
            });
            this.addEstimationOptionData(this.estimationOptionList);
          } else {
            this.successCount = this.successCount - 1;
            this.emitRefreshEstimationList();
          }
          if (this.estimationDocumentList.length == 0 && this.estimationOptionList.length == 0) {
            this.successCount = 1;
            this.emitRefreshEstimationList();
            this.closeDialog();
          }
          let savedEstimationPhase: any = res.data;
          savedEstimationPhase.isEdit = false;
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save Estimation Phase', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEstimationPhase = true;
        }
      },error=>{
        console.log(error);
        this.closeDialog();
      });
  }

  updateEstimationPhaseData(estimationId, estimationPhaseObj) {
    this.clientService.updateEstimationPhase(estimationId, estimationPhaseObj).subscribe(
      res => {
        if (res.isSuccsess) {
          this.isDisabledEstimationPhase = true;
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated Estimation Phase', '', 4000);
          this.commonUIService.openSnackBar(snack);

          //update estimation document
          if (this.estimationDocumentList.length > 0) {
            this.onSaveEstimationDocument(res.data);
          } else {
            this.successCount = this.successCount - 1;
            this.emitRefreshEstimationList();
          }
          //update estimation option
          if (this.estimationOptionList.length > 0) {
            let _estOptionList = [];
            this.estimationOptionList.forEach(es => {
              if (es.id == 0) {
                es.estimationPhaseId = res.data.id;
                _estOptionList.push(es);
              }
            });
            if (_estOptionList.length > 0)
              this.addEstimationOptionData(_estOptionList);
            else {
              this.successCount = this.successCount - 1;
              this.emitRefreshEstimationList();
            }
          } else {
            this.successCount = this.successCount - 1;
            this.emitRefreshEstimationList();
          }
          if (this.estimationDocumentList.length == 0 && this.estimationOptionList.length == 0)
            this.closeDialog();
          let savedEstimationPhase: any = res.data;
          savedEstimationPhase.isEdit = true;
          savedEstimationPhase.estimationStatusGlobal = savedEstimationPhase.estimationStatusValue;
          let url = this.router.url;
          if (url == "/estimation") {
            //Don't remove this comments. Need for future developments
            //this.clientService.refreshEstimationPhase.emit(savedEstimationPhase);
          } else if (url == "/task") {
            //Don't remove this comments. Need for future developments
            //if (this.clientService.selectedEstimationPhaseAndProjectObject)
            //this.clientService.selectedEstimationPhase.emit(this.clientService.selectedEstimationPhaseAndProjectObject);
            //this.clientService.refreshUpdateEstimationPhase.emit(true);
          }
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on update', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.isDisabledEstimationPhase = true;
        }
      },
      error=> { console.log(error); this.closeDialog();})
  }

  updateEstimationData(estimationId, estimationObj) {
    this.clientService.updateEstimation(estimationId, estimationObj).subscribe(
      res => { },error=>{
        console.log(error);
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onChooseEstimationDocument(event: any): void {
    if (event) {
      this.fileList = event.target.files;
      if (this.estimationDocumentList.length <= 2) {
        let _docList: any = [];
        _docList.push(this.fileList);
        _docList.forEach(e => {
          let _extension = e[0].name.split('.');
          e.name = _extension[0];
          e.extension = _extension[1];
          e.id = 0;
        });
        this.estimationDocumentList.push(_docList);
      }
    }
  }

  cancelDialog(): void {
    this.successCount = 1;
    this.emitRefreshEstimationList();
    this.dialogRef.close();
  }

  changeUserType(event) {
    if (event) {
      if (event.value == "1") {
        this.showQualitApps = true;
        this.showClient = false;
        this.estimationPhaseModal.userId = this.loggedUser.id;
        this.estimationPhaseModal.contactId = null;
      }
      else if (event.value == "2") {
        this.showClient = true;
        this.showQualitApps = false;
        this.estimationPhaseModal.userId = null;
        if (this.commonDropdownService._clientContactList.length == 1) {
          this.estimationPhaseModal.contactId = this.commonDropdownService._clientContactList[0].id;
        }
      } else {
        this.showQualitApps = false;
        this.showClient = false;
        this.estimationPhaseModal.userId = null;
        this.estimationPhaseModal.contactId = null;
      }
    }   
  }

  onOpenEstimationOptionPopup(estimationPhase) {
    const dialogViewData = this.commonUIService.setDataForModalDialog(estimationPhase.id, null, null, estimationPhase, null, null);
    this.commonUIService.openDialog(AddEstimationOptionComponent, dialogViewData);
  }

  subscribeEstimationOptionData() {
    this.projectViewService.passEstimationOptionData.subscribe(res => {
      if (res) {
        this.estimationOptionList.push(res);
      }
    })
  }

  addEstimationOptionData(estimationOptionList) {
    this.clientService.addEstimationOption(estimationOptionList).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved Estimation Option', '', 4000);
          this.commonUIService.openSnackBar(snack);
        } else {
          const snack = new SnackBar(SnackBarType.error, 'Error on save option', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        this.successCount = this.successCount - 1;
        this.emitRefreshEstimationList();
      },error=>{
        console.log(error)
      });
  }

  updateEstimationOptionData(estimationOptionObj) {
    this.clientService.updateEstimationOption(null, estimationOptionObj).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully updated Estimation Option', '', 4000);
          this.commonUIService.openSnackBar(snack);
          let url = this.router.url;
          if (url == "/estimation") {
            //Don't remove this comments. Need for future developments
            //this.clientService.refreshEstimationPhase.emit(savedEstimationPhase);
          } else if (url == "/task") {
            //Don't remove this comments. Need for future developments
            //if (this.clientService.selectedEstimationPhaseAndProjectObject)
            //this.clientService.selectedEstimationPhase.emit(this.clientService.selectedEstimationPhaseAndProjectObject);
            //this.clientService.refreshUpdateEstimationPhase.emit(true);
          }
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on update Estimation Option', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        this.successCount = this.successCount - 1;
        this.emitRefreshEstimationList();
      },
      () => { console.error(); });
  }

  async onSaveEstimationDocument(estimationPhaseObj) {
    this.commonUIService.openMask();
    if (estimationPhaseObj.id) {
      this.estDocFileListForSave = [];
      if (this.estimationDocumentList.length > 0) {
        this.estimationDocumentList.forEach(doc => {
          if (doc.id) return;
          let _estDoc = {
            estimationPhaseId: estimationPhaseObj.id,
            fileName: doc[0][0].name,
            extension: (doc[0][0].name).split('.')[((doc[0][0].name).split('.')).length - 1]
          };
          this.estDocFileListForSave.push(_estDoc);
        });
      } if (this.estDocFileListForSave.length > 0) {
        this.clientService.saveEstimationDocument(this.estDocFileListForSave).subscribe(res => {
          if (res) {
            const formData: FormData = new FormData();
            if (res.data && res.data.length > 0) {
              for (let i = 0; i < this.estimationDocumentList.length; i++) {
                let d = this.estimationDocumentList[i];
                if (!d.id) {
                  formData.append("file[]", d[0][0], res.data.find(m => m.fileName == d[0][0].name).generatedFileName);
                }
              }
              formData.append('estimationPhaseId', res.data[0].estimationPhaseId.toString());
              formData.append('fileId', res.data[0].id.toString());
            }
            this.uploadEstimationDocument(formData, res.data[0].estimationPhaseId);
          }
        });
      } else {
        this.commonUIService.closeMask();
        this.successCount = this.successCount - 1;
        this.emitRefreshEstimationList();
      }
    }
  }

  uploadEstimationDocument(formData: FormData, estimationPhaseId) {
    this.clientService.uploadEstimationDocument(formData).subscribe(
      res => {
        this.commonUIService.closeMask();
        if (+res == 200 || +(res.statusCode) == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved Estimation Document', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save Estimation Document', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
        this.successCount = this.successCount - 1;
        this.emitRefreshEstimationList();
      },
      () => {
        console.error(); this.commonUIService.closeMask();
      });
  }

  deleteDocument(estimationDocument) {
    if (estimationDocument.id > 0) {
      this.deleteEstimationDocument(estimationDocument.id);
    } else {
      this.estimationDocumentList.forEach(doc => {
        if (doc.id) {
          this.deleteEstimationDocument(estimationDocument.id);
          return;
        }
        else {
          if (this.isEdit) {
            let index = this.estimationDocumentList.findIndex(r => r[0].name === estimationDocument[0].name);
            if (index !== -1)
              this.estimationDocumentList.splice(index, 1);
          } else {
            let index = this.estimationDocumentList.findIndex(r => r[0][0].name === estimationDocument[0][0].name);
            if (index !== -1)
              this.estimationDocumentList.splice(index, 1);
          }

        }
      });
    }
  }

  deleteOption(estimationOption) {  
    if (estimationOption.id == 0) {
      let index = this.estimationOptionList.findIndex(r => r.option === estimationOption.option);
      if (index !== -1)
        this.estimationOptionList.splice(index, 1);
    }
    else
    {
      this.deleleteEstimationOptionFromDB(estimationOption.id);
    }
  }

  deleteEstimationDocument(estimationDocumentId) {
    let index = this.estimationDocumentList.findIndex(r => r.id === estimationDocumentId);
    if (index !== -1)
      this.estimationDocumentList.splice(index, 1);
    this.clientService.setValuesForRefresh(true, null, null, true, 'delete' + 'Document', null, null);
    this.clientService.deleteEstimationDocument(estimationDocumentId).subscribe(
      res => {
        if (res.isSuccsess) {
          const snack = new SnackBar(SnackBarType.success, 'Estimation Document successfully deleted', '', 5000);
          this.commonUIService.openSnackBar(snack);
        }
      }
    )
  }

  emitRefreshEstimationList() {
    if (this.successCount == 1 && this.currentProjectId > 0) {
      this.closeDialog();
      this.projectViewService.refreshEstimationList.emit(this.currentProjectId);
    }
  }

  getExtension(ed) {
    return (ed.id ? ed.extension : (ed[0][0].name).split('.')[((ed[0][0].name).split('.')).length - 1]);
  }

  getFileName(ed) {
    return (ed.id ? ed.fileName : ed[0][0].name);
  }

  loadEstimationById(id) {
    if (id) {
      this.clientService.getEstimationById(id).subscribe(res => {     
        if (res && res.data) {
          if (res.data.estimationPhase.length > 0) {
            let _estimationPhase = res.data.estimationPhase.slice(-1);
            this.estimationPhaseModal.budget = _estimationPhase[0].budget;
            this.estimationPhaseModal.maxBudget = _estimationPhase[0].maxBudget;
            this.estimationPhaseModal.hours = _estimationPhase[0].hours;
            this.estimationPhaseModal.maxHours = _estimationPhase[0].maxHours;
            this.estimationPhaseModal.hourFee = _estimationPhase[0].hourFee;
          }
        }
      });
    }
  }

  //#region "Deletion confirmation messages"

  openDeleteEstimationOptionAlert(estimationOption) {
    const deleteData = new AlertModel();
    deleteData.title = 'Delete';
    deleteData.msg = 'Are you sure to delete this record?';
    deleteData.id = estimationOption.id;
    this.currentEstimationOption=estimationOption;
    deleteData.reference = 'Delete Estimation Option';    
    this.commonUIService.openAlert(deleteData);
  }

  subscribeEstimationOptionDelete(){
    this.alertService.referenceDelete.subscribe(data => {   
      if (data && data.reference=='Delete Estimation Option') {
        this.deleteOption(this.currentEstimationOption);
      }     
    });
  }
  //#endregion

  //#region "Delete estimation option from DB"
  deleleteEstimationOptionFromDB(id:number)
  {
    this.clientService.deleteEstimationOption(id).subscribe(res => {     
      if(res && res.isSuccsess)
      {
        const snack = new SnackBar(SnackBarType.success, 'Estimation Option successfully deleted', '', 5000);
        this.commonUIService.openSnackBar(snack);       
        this.clientService.getEstimationById(this.estimationPhaseModal.estimationId).subscribe(res => {
                  
          if (res && res.data) {         
            let finalizedOptionsList = [];    
            let updatedListFromDB = res.data.estimationPhase.filter(a=>a.id==this.estimationPhaseModal.id)[0].estimationOption ? res.data.estimationPhase.filter(a=>a.id==this.estimationPhaseModal.id)[0].estimationOption:[] ;
            updatedListFromDB.forEach(esOp => {
              finalizedOptionsList.push(esOp);
            });

            let estimationOptionsNewlyAdded =this.estimationOptionList.filter(b=>b.id==0);             
            estimationOptionsNewlyAdded.forEach(esOp => {
              finalizedOptionsList.push(esOp);
            });            

            this.estimationOptionList = [];
            this.estimationOptionList = finalizedOptionsList;
          }
        });
      }
      else{
        const snack = new SnackBar(SnackBarType.error, 'An error occurred while deleting the estimation option', '', 7000);
        this.commonUIService.openSnackBar(snack);
      }

    },error=>{
      console.log(error);
      const snack = new SnackBar(SnackBarType.error, 'An error occurred while deleting the estimation option', '', 7000);
      this.commonUIService.openSnackBar(snack);
    });
  } 
  //#endregion


}
