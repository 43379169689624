import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { InventoryMetaData } from 'src/app/shared/entities/inventoryMetadata';
import { UIService } from 'src/app/shared/services/ui.service';
import { APIService } from 'src/app/shared/services/api.service';
import { InventoryMetadataType } from 'src/app/shared/enum/inventoryMetadataType';
import { InventoryMaintenanceService } from '../service/inventory-maintenance.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { AlertModel } from 'src/app/shared/entities/alertModel';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AddEditCommonconditionComponent } from './add-edit-commoncondition/add-edit-commoncondition.component';


@Component({
  selector: 'app-common-condition',
  templateUrl: './common-condition.component.html',
  styleUrls: ['./common-condition.component.scss']
})
export class CommonConditionComponent implements OnInit {

  _commonConditionList: InventoryMetaData[] = [];
  commonConditionList: InventoryMetaData[] = [];
  updateCommonCondition: InventoryMetaData;
  _idSelectedcommonCondition: number;
  private subscriber: Subscription;
  private _unsubscribeAll: Subject<any>;
  isLoading: boolean = false;
  _idSelectedCondition: number;

  constructor(private uIService: UIService, private _apiService: APIService, private _inventoryMaintenanceService: InventoryMaintenanceService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.updateCommonCondition = new InventoryMetaData();
    this.LoadActionConditionList();
    this.subscribeAlertConfirmEvents()
    this._inventoryMaintenanceService.subjectCommonConditionRefresh.subscribe(isStatusRefesh => {
      if (isStatusRefesh) {
        this.LoadActionConditionList();
      }
    });
  }

  subscribeAlertConfirmEvents() {
    this.subscriber = this.alertService.referenceDelete
      .subscribe(data => {
        if (data === "Delete ActionType" && this._idSelectedCondition) {
          this.deleteActionType(this._idSelectedCondition);
          console.log(this._idSelectedCondition);
        }
      });
  }

  deleteActionType(conditionId) {
    this._inventoryMaintenanceService.deleteInventoryMetadata(conditionId).subscribe(
      res => {
        if (res.isSuccsess) {
          var snack = new SnackBar(SnackBarType.success, res.message, "", 5000);
          this.uIService.openSnackBar(snack);
          if (res.data) this.LoadActionConditionList();
        }


      });
  }

  addCommonCondition() {
    this.uIService.openDialog(AddEditCommonconditionComponent, null);
  }

  editCommonCondition(type) {
    const inventoryData = Object.assign({}, type);
    const dialogViewData = this.uIService.setDataForModalDialog(null, null, null, inventoryData, null, null);
    this.uIService.openDialog(AddEditCommonconditionComponent, dialogViewData);
  }

  private LoadActionConditionList() {
    this.isLoading = true;
    let url = this._apiService.getEndPoint("inventoryMetaData.getInventoryMetadataByType", [["{type}", InventoryMetadataType.commonCondition], ["{isOnlyActive}", false]]);
    this._apiService.get(url).subscribe(response => {
      if (response) {
        if (response.data) {
          this.commonConditionList = response.data;
          this._commonConditionList = response.data;
        }
      }
      this.isLoading = false;
    },
      err => {
        console.error(err);
      }, () => {
      });
  }

  activeDeactive(event, inventoryMetadata: InventoryMetaData) {
    inventoryMetadata.status = event.checked;
    this._inventoryMaintenanceService.updateInventoryMetadata(inventoryMetadata.id, inventoryMetadata).subscribe(
      (res: { isSuccsess: any; }) => {
        if (res.isSuccsess) {
          this._inventoryMaintenanceService.commonConditionRefresh(true);
          var snack = new SnackBar(SnackBarType.success, "Successfully updated", "", 2000);
          this.uIService.openSnackBar(snack);
        }
        else {
          snack = new SnackBar(SnackBarType.success, "Error on update", "", 5000);
          this.uIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  openAlertDeleteCondition(id) {
    let deleteData = new AlertModel();
    deleteData.title = "Delete";
    deleteData.msg = "Are you sure to delete this record?";
    deleteData.id = id;
    deleteData.reference = "Delete ActionType";
    this._idSelectedCondition = id;
    this.uIService.openAlert(deleteData);
  }
  
  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

}
