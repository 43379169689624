import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataForModalView, UIService } from 'src/app/shared/services/ui.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { InvoiceModel } from 'src/app/inventory/entities/inventoryInvoice';
import { FormatMatDatepicker } from 'src/app/shared/format-datepicker';
import { ClientService } from 'src/app/client/services/client.service';
import { SnackBar, SnackBarType } from 'src/app/shared/components/snackbar/entities/snackbar';
import { InventoryService } from 'src/app/inventory/service/inventoryService';

@Component({
  selector: 'app-attach-invoice',
  templateUrl: './attach-invoice.component.html',
  styleUrls: ['./attach-invoice.component.scss']
})
export class AttachInvoiceComponent implements OnInit {
  toggle = true;
  attachInvoiceFormGroup: FormGroup;
  invoiceModel: InvoiceModel;
  invoiceModelsave: InvoiceModel;
  inventoryId: number = 0;
  isDocumentRequired = false;
  inventoryInvoiceList: InvoiceModel[] = [];
  isExistLoad = false;
  delInvoiceId:number=0;

  constructor(public dialogRef: MatDialogRef<AttachInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public modalViewData: DialogDataForModalView,
    private _formBuilder: FormBuilder,
    private clientService: ClientService,
    private inventorService: InventoryService,
    private commonUIService: UIService,
    private formatDatepicker: FormatMatDatepicker,
    private validationService: ValidationService,
    public commonDropdownService: CommonDropdownService) {

    this.invoiceModel = new InvoiceModel();
    this.invoiceModelsave = new InvoiceModel();
    this.inventoryInvoiceList = [];
  }

  ngOnInit() {
    this.initattachInvoiceFormGroup();
    this.commonDropdownService.LoadDocumentTypeList()
  }


  initattachInvoiceFormGroup() {
    this.attachInvoiceFormGroup = this._formBuilder.group({
      documentName: ['', [Validators.required, this.validationService.noWhitespaceValidator]],
      date: ['', [Validators.required]],
      file: [null, [Validators.required]]
    });
  }

  public onChooseInventoryInvoice(event: any): void {
    if (event) {     
      let files: any = event.target.files;
      this.invoiceModel.fileList = files;
      this.toggle = true;
      this.isDocumentRequired = false;
    } else {
    }
  }

  submit(invoiceModel: InvoiceModel) {
    let valid = this.attachInvoiceFormGroup.get('file').touched && this.attachInvoiceFormGroup.get('file').valid;
    this.isDocumentRequired = !valid;
    this.toggle = valid;

    if (this.attachInvoiceFormGroup.invalid) {
      return;
    }
    invoiceModel.documentType = 'Invoice';
    this.invoiceModelsave = Object.assign({}, invoiceModel);
    this.invoiceModelsave.dateStr = invoiceModel.date ? this.formatDatepicker.ConvertDateFormatForSave(invoiceModel.date) : invoiceModel.date;
    this.saveInventoryInvoice()
  }

  saveInventoryInvoice() {
    if (this.invoiceModelsave) {
      if (this.modalViewData.data.id) {
        this.invoiceModelsave.inventoryId = this.modalViewData.data.id;
        if (this.invoiceModelsave.fileList.length > 0) {
          this.invoiceModelsave.fileName = (this.invoiceModelsave.fileList[0]).name;
          this.invoiceModelsave.extension = ((this.invoiceModelsave.fileList[0]).name).split('.')[(((this.invoiceModelsave.fileList[0]).name).split('.')).length - 1];
          this.inventorService.saveInventoryInvoice(this.invoiceModelsave).subscribe(res => {
            this.inventorService.newDocument.emit(res);
            if (res) {
              const formData: FormData = new FormData();
              formData.append('file', this.invoiceModel.fileList[0], res.data.generatedFileName);
              formData.append('inventoryId', res.data.inventoryId.toString());
              formData.append('fileId', res.data.id.toString());
              this.uploadInventoryDocument(formData);
            }
          });
          this.closeDialog();
        }
      }
    }
  }

  uploadInventoryDocument(formData: FormData) {
    this.clientService.uploadDocuments(formData).subscribe(
      res => {
        if (+res == 200 || +(res.statusCode) == 200) {
          const snack = new SnackBar(SnackBarType.success, 'Successfully saved', '', 4000);
          this.commonUIService.openSnackBar(snack);
          this.closeDialog();
          this.inventorService.refreshInventorInvoice.emit(true);
        } else {
          const snack = new SnackBar(SnackBarType.success, 'Error on save', '', 4000);
          this.commonUIService.openSnackBar(snack);
        }
      },
      () => { console.error(); });
  }

  closeDialogBox(): void {
    this.inventorService.cancelInventoryInvoice.emit(true);
  }

  loadExistingInventoryList() {
    this.isExistLoad = true;
    this.inventorService.ListExistingInvoiceForAttachInvoice(this.modalViewData.data.id.toString()).subscribe(res => {
      if (res.isSuccsess) {
        this.inventoryInvoiceList = res.data;
      }
      else {
        const snack = new SnackBar(SnackBarType.error, "Error on list return", '', 4000);
        this.commonUIService.openSnackBar(snack);
      }
      this.isExistLoad = false;
    })
  }

  attachSelectedInvoice(selectedInvoice) {
    this.commonUIService.openMask();
    let invoiceForAdd = Object.assign({}, selectedInvoice);
    invoiceForAdd.inventoryId = this.modalViewData.data.id;
    invoiceForAdd.existInventoryId = selectedInvoice.inventoryId;
    this.inventorService.copyExistingInventoryInvoice(invoiceForAdd).subscribe(res => {
      if (res && res.isSuccsess) {
        const snack = new SnackBar(SnackBarType.success, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
        this.inventorService.refreshInventorInvoice.emit(true);
        this.closeDialog();
        this.commonUIService.closeMask();
      }
      else {
        const snack = new SnackBar(SnackBarType.error, res.message, '', 4000);
        this.commonUIService.openSnackBar(snack);
        this.closeDialog();
        this.commonUIService.closeMask();
      }

    },
      err => {
        this.commonUIService.closeMask();
        console.log(err)
      })
  }

  addInvoiceTabChange(event) {
    if (event && event.index === 1) {
      this.loadExistingInventoryList();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
