import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UIService } from 'src/app/shared/services/ui.service';
import { CommonDropdownService } from 'src/app/shared/services/common.dropdown.service';
import { ErmService } from '../../service/erm.service';
import { Employee } from '../../entities/empoyee';
import { Users } from 'src/app/auth/entities/user';
import { LoginService } from 'src/app/auth/login/login.service';
import { APIService } from 'src/app/shared/services/api.service';
import moment from 'moment';

@Component({
  selector: 'app-erm-detail',
  templateUrl: './erm-detail.component.html',
  styleUrls: ['./erm-detail.component.scss']
})

export class ERMDetailComponent implements OnInit {

  employeeModel: Employee;

  notiCount: number = 0;
  newEmpList: Employee[] = [];
  currentusers: Users;
  loginEmployee: Employee;


  constructor(
    private LoginServic: LoginService,
    private apiservice: APIService,

    public commonUIService: UIService,
    public ermService: ErmService) {
    this.employeeModel = new Employee();
  }

  ngOnInit() {
    this.currentuser();
  }

   currentuser() {
    let loggedUser = JSON.parse(localStorage.getItem('appUser'));
    this.currentusers = loggedUser;
    if (this.currentusers.imageUrl == undefined) {
      this.currentusers.imageUrl = "assets/images/profile.jpg";
    }
    this.ermService.getEmployeeByEmail(this.currentusers.email).subscribe(res => {
      this.loginEmployee = res.data;
      this.selectEmployee(this.loginEmployee);
    });
  }

  selectEmployee(Employee) {
    if (Employee) {
      this.ermService.getEmployeeByEmail(Employee.email).subscribe(res => {
        if (res && res.data) {
          if (res.data.dob) {
            let _todayDate = new Date().toISOString().split("T")[0];
            let _dob = res.data.dob.split("T")[0];
            let month = moment(_todayDate).diff(moment(_dob), 'month', true);
            if (month >= 12) {
              let _year = (month - (month % 12)) / 12;
              res.data.employeeAge = _year;
            }
            else {
              res.data.employeeAge = null;
            }
          }
          if (res.data.jointDate != null) {
            var eventStartTime = new Date();
            var eventEndTime = new Date(res.data.jointDate);
            var diff = eventStartTime.valueOf() - eventEndTime.valueOf();
            var diff = Math.ceil(diff / (1000 * 3600 * 24)) / 30;
            res.data.workingMonth = Math.round(diff);
            this.newEmpList = [];
            this.notiCount = 0;
            res.data.totalClaim = 0;
            if (diff >= 12) {
              res.data.completedOneYear = true;
              res.data.entitleAmount = 50000;
              let insuranceList = res.data.employeeInsurance
              insuranceList.forEach(element => {
                let amount = parseInt(element.insuranceAmount);
                res.data.totalClaim = amount + Employee.totalClaim;
                if (res.data.totalClaim > 0) {
                  res.data.total = res.data.entitleAmount - res.data.totalClaim;
                }
              });
            } else {
              res.data.completedOneYear = false;
            }
          }
          if (res.data.prolfileImage && res.data.profilePath != null && res.data.profilePath != undefined) {
            res.data.prolfileImage = 'data:image/png;base64,' + res.data.prolfileImage;
          }
          this.ermService.refreshEmployee.emit({ employeeObject: res.data });
          this.loginEmployee = res.data;
        }
      });
      this.subscribeRefreshEmployee();
    }
  }

  subscribeRefreshEmployee() {
    this.ermService.refreshEmployee.subscribe(res => {
      if (res.employeeObject) {
        this.employeeModel = res.employeeObject;
      }
    });
  }
}
